.upload-container {
  text-align: center;
  min-height: 400px;
}

.upload-container .uploaded-files-container {
  margin-bottom: 0;
}

.uploaded-files-container .cs-uploaded-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
}

.cs-uploaded-files>div {
  width: 100%;
}

.cs-uploaded-files>.uploaded-files-icon {
  padding-left: 25px;
  padding-bottom: 5px;
  padding-right: 10px;
}

.uploaded-files-container .cs-uploaded-files .uploaded-files-close {
  padding: 0 20px;
}

.cs-uploaded-files .progress {
  height: 8px;
}

.cs-uploaded-files .progress .progress-bar {
  background: rgb(156, 106, 222);
}

.cs-uploaded-files .progress-bar-animated {
  animation: progress-bar-stripes 0.05s linear infinite;
  transition-duration: 1ms;
}

.progress-label {
  color: rgb(109, 114, 120);
  font-family: Graphik-Regular;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.16px;
  width: 20%;
  margin: 0;
  text-align: left;
  padding: 0 10px;
}

.upload-container .invalid-reason {
  font-family: Graphik-Regular;
  text-align: left;
}

.download-template-header-btn {
  width: 185px;
  height: 14px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding-left: 9px;
  cursor: pointer;
}

.download-template-header img {
  width: 12px;
  height: 16px;
}

.cs-file-name {
  font-family: Graphik-Regular;
}

.upload-container .cvm-dropzone.enable-dz:hover {
  cursor: pointer;
}

.upload-container .uploaded-files-name {
  font-family: Graphik-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.recently-uploaded-container {
  min-height: 400px;
}

.recently-uploaded-label {
  width: 145px;
  height: 24px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.31px;
  line-height: 24px;
  margin-bottom: 10px;
}

.cvm-dropzone.disable-dz:hover {
  cursor: not-allowed;
}

div[role='tabpanel']>div.tab-component-content {
  left: 0;
}

/* CLIENT STORY AND CLIENT ALIGNMENT CSS */
.client-upload-collapse-container{
  display: flex;
  width: 100%;
  margin-right: 25px;
}

.download-template-header{
  margin-left: auto;
  margin-right: 20px;
}

/*new changes related to client story start*/
.client-story-btn-parent{
  display: flex;
  justify-content: flex-end;
  margin: 16px 8px 0px 0px;
  column-gap: 12px; 
}
.client-story-btn-parent .download-client-story-disabled-btn-container,
.client-story-btn-parent .download-client-story-btn-container{
    height: 32px;
    width: 177px;
    background-color: white;
    color: rgb(161, 0, 255);
    border: 2px solid rgb(161, 0, 255);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.client-story-btn-parent .download-client-story-btn-container:hover .fileDownloadWhite,
.client-story-btn-parent .download-client-story-disabled-btn-container .fileDownload,
.client-story-btn-parent .download-client-story-btn-container .fileDownload{
  height: 16px;
  width: 12px;
}
.client-story-btn-parent .download-client-story-disabled-btn-container .download-template-header-btn,
.client-story-btn-parent .download-client-story-btn-container .download-template-header-btn {
  width: fit-content;
  height: 12px;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding-left: 10px;
  cursor: pointer;
}
.client-story-btn-parent .download-client-story-disabled-btn-container .fileDownload,
.client-story-btn-parent .download-client-story-disabled-btn-container .download-template-header-btn,
.client-story-btn-parent .download-client-story-disabled-btn-container{
  opacity: 0.65;
  cursor: not-allowed;
}
.client-story-btn-parent .download-client-story-btn-container:hover{
  background-color:rgb(161, 0, 255);
}
.client-story-btn-parent .download-client-story-btn-container:hover .fileDownloadWhite{
 display: block;
}
.client-story-btn-parent .download-client-story-btn-container:hover .fileDownload{
  display: none;
 }
 .client-story-btn-parent .download-client-story-btn-container .fileDownload{
  display: block;
 }
 .client-story-btn-parent .download-client-story-disabled-btn-container .fileDownloadWhite,
 .client-story-btn-parent .download-client-story-btn-container .fileDownloadWhite{
  display: none;
 }
.client-story-btn-parent .download-client-story-btn-container:hover .download-template-header-btn{
  color: white;
}
.client-story-editor-wrapper-class{
  border-radius: 8px;
  border: 1px solid #ced4da;
  background-color: rgba(255,255,255, 1);
  height: 160px;
  width: 100%;
  overflow: hidden;
}
.client-story-editor-error-wrapper-class{
  border-color: #d5002f;
}
.clientStory-container .__react_component_tooltip.place-top::before{
  height: 6px !important;
}
.client-story-toolbar-class{
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}
.client-story-toolbar-class .rdw-inline-wrapper,
.client-story-toolbar-class .rdw-list-wrapper,
.client-story-toolbar-class .rdw-text-align-wrapper{
  display: flex;
  justify-content: space-evenly;
  flex: 1;
}
.client-story-editor-class{
  padding: 15px;
  overflow-y: scroll;
  height: 120px;
}
.client-story-editor-class span{
  font-family: auto;
  font-weight: inherit;
  word-break: break-all;
}
.client-story-editor-class .public-DraftEditorPlaceholder-inner{
  color: grey;
  font-size: 14px;
}
/*new changes related to client story end*/

.download-template-header-btn {
  width: 185px;
  height: 14px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding-left: 9px;
  cursor: pointer;
}

.download-template-header img {
  width: 12px;
  height: 12px;
}

.with-additional-header-buttons .card-header {
  display: flex;
  width: 100%;
}

.with-additional-header-buttons .btn-link {
  width: 25px !important;
}

.with-additional-header-buttons #basicDetailsExpand{
  margin-left: auto;
}

.with-additional-header-buttons .client-attachment span {
  color: rgba(99,115,129,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 14px;
  align-self: center;
}

.with-additional-header-buttons .client-attachment {
  padding-left: 20px;
}

.with-additional-header-buttons .client-upload-ico{
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.with-additional-header-buttons .reset-all-button {
  padding: 3px 5px 3px 10px !important;
  align-items: center;
}

.client-upload-btn {
  flex-direction: row;
  border: 1px solid #a100ff;
  border-radius: 50px;
  color: #a100ff!important;
  align-self: center;
  justify-content: center;
  display: flex;
  line-height: 1.5;
  box-shadow: none !important;
  font-family: "Graphik-Medium";
  font-size: 12px;
  padding: 3px 20px;
}

.client-upload-btn:hover {
  background-color: #a100ff;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  color: #fff!important;
}

.client-upload-btn:hover img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.with-additional-header-buttons .client-dropzone-button{
  border-right: 1px solid rgba(209,213,219, 1);
  padding-right: 20px;
}

.with-additional-header-buttons #configCardID {
  padding-bottom: 0px !important;
}

.with-additional-header-buttons .card-body{
  padding: 12px 20px;
  border-top: 1px solid rgb(223, 227, 232);
  background-color: rgba(249,250,251, 1);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.with-additional-header-buttons .empty-program-container span {
  opacity: 1;
  color: rgba(99,115,129,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: left;
  margin: 0px;
}

.with-additional-header-buttons .empty-program-container {
  padding: 0;
  align-items: initial;
}

.with-additional-header-buttons .cs-uploaded-files .progress, 
.with-additional-header-buttons .uploaded-files-close,
.with-additional-header-buttons .cs-uploaded-files>.uploaded-files-icon {
  align-self: center;
}

.with-additional-header-buttons .uploaded-files-name {
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.with-additional-header-buttons .cs-uploaded-files>.uploaded-files-icon {
  padding: 0 20px 5px 0;
}

.with-additional-header-buttons .cs-uploaded-files{
  display: flex;
}

.with-additional-header-buttons .client-story-recentFile {
  background: none;
  border: none;
  border-radius: 0px;
  height: auto;
}

.with-additional-header-buttons .client-story-filename {
  padding: 0px;
  opacity: 1;
  color: rgba(99,115,129,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: left;
}

.with-additional-header-buttons .download-icon-label {
  filter: none;
}

.with-additional-header-buttons .download-clientstory-button {
  width: auto;
  height: auto;
  background: none;
  border: none;
  opacity: 1;
  color: rgba(161,0,255,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 14px;
  cursor: pointer;
}

.client-invalid-reason {
  opacity: 1;
  color: rgba(213,0,47,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: left;
  line-height: 20px;
}

.with-additional-header-buttons .configure-error {
  border: 1px solid rgba(213,0,47, 1) !important;
}

.with-additional-header-buttons .collapse.show ~ div.card-header .client-attachment div.svg-container {
  transform: rotate(0deg);
}

.with-additional-header-buttons .client-upload-btn.disabled-upload,
.with-additional-header-buttons .download-template-header-btn.disabled-download {
  cursor: not-allowed;
  color: #a100ffbf !important;
  background-color: transparent;
  border-color: #a100ffbf !important;
}

.with-additional-header-buttons .client-upload-btn.disabled-upload:hover img,
.with-additional-header-buttons .client-upload-btn.disabled-upload img,
.with-additional-header-buttons .download-template-header.disabled-download img  {
  filter: opacity(.7);
}

/*client story text area functionality*/
clientStory-container #groove-textarea label {
  display: none !important;
}

.clientStory-container #groove-textarea .floating-label-textbox .configure-error:focus,
.clientStory-container #groove-textarea .floating-label-textbox .configure-error:not(:placeholder-shown){
  border-top: 1px solid rgba(213,0,47, 1) !important;
}

.clientStory-container #groove-textarea .floating-label-textbox textarea:focus,
.clientStory-container #groove-textarea .floating-label-textbox textarea:not(:placeholder-shown){
  border-top: 1px solid #ced4da !important;
}

.clientStory-container #groove-textarea .hidden-feedback {
  visibility: visible;
  font-size: 12px;
  font-family: 'Graphik-medium';
  color: red;
}

.clientStory-textArea {
  border-radius: 8px !important;
  border: 1px solid #ced4da !important;
  background-color: rgba(255,255,255, 1) !important;
  text-align: justify;
  padding: 15px !important;
}

.clientStory-container div.groove-textbox-custom-label {
  opacity: 1;
  color: rgba(145,158,171,1) !important;
  font-family: "Graphik-Regular" !important;
  font-size: 12px !important;
  font-style: normal;
  letter-spacing: -0.26px !important;
  text-align: left;
  line-height: 16px;
}

.clientStory-container > div {
  padding: 0 10px;
  width: 100%;
}

.clientStory-container > div > div:first-child {
  opacity: 1;
  color: rgba(33,43,54,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 10px;
  text-align: center;
}

.clientStory-container {
  display: inline-flex;
  width: 100%;
}

.clienStory-label-icon {
  width: 25px;
  height: 25px;
  position: relative;
  bottom: 1px;
  margin-right: 8px;
}
/*End of functionality*/

/* END */