#groove-input.cvm-input {
    background-color: white;
    border-radius: 4px;
}

#groove-input.cvm-input .groove-input:focus{
    border-radius: 4px;
}

#groove-input.cvm-input.disabled .groove-input-custom-label {
    cursor: not-allowed;
}

#groove-input.cvm-input input.groove-input {
    position: relative;
    margin-bottom: 0;
}

#groove-input.cvm-input input.groove-input:disabled {
    background-color: transparent;
    border: 1px solid #dfe3e8;
    cursor: not-allowed;
    background: #f9fafb;
}

#groove-input.cvm-input .floating-label {
    margin-bottom: 0;
}

.cvm-shared-input{
  color: rgb(99, 115, 129);
  font-size: 1.2em;
  line-height: �1.42857143;
}