html {
  overflow-x: inherit !important;
}

body {
  font-family: Graphik-Regular-Web;
  color: #000000;
  overflow-y: overlay;
}

body::-webkit-scrollbar-thumb{
  background: transparent;
}

::-webkit-scrollbar-thumb:hover{
  display: inline;
  display: initial;
}

body:hover::-webkit-scrollbar-thumb{
  display: inline;
  display: initial;
  
}

::-webkit-scrollbar{
  width: 15px !important;
}

body::-webkit-scrollbar-track{
  margin-top: 45px;
}

.row {
  margin: 0 !important;
}

.full-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

@font-face {
  font-family: "Graphik-Regular-Web";
  src: url(/static/media/Graphik-Regular-Web.f181a169.woff) format("woff");
}

@font-face {
  font-family: "Graphik-Black-Web";
  src: url(/static/media/Graphik-Black-Web.20cacbe7.woff) format("woff");
}

@font-face {
  font-family: "Graphik-Bold-Web";
  src: url(/static/media/Graphik-Bold-Web.d62c122d.woff) format("woff");
}

@font-face {
  font-family: "Graphik-Extralight-Web";
  src: url(/static/media/Graphik-Extralight-Web.5ffcebc6.woff) format("woff");
}

@font-face {
  font-family: "Graphik-Semibold-Web";
  src: url(/static/media/Graphik-Semibold-Web.da92e23a.woff) format("woff");
}

@font-face {
  font-family: "Graphik-Medium-Web";
  src: url(/static/media/Graphik-Medium-Web.f5980e02.woff) format("woff");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url(/static/media/OpenSans-Regular.44177200.woff) format("woff");
}

@font-face {
  font-family: "OpenSans-Light";
  src: url(/static/media/OpenSans-Light.40fe9563.woff) format("woff");
}

@font-face {
  font-family: "OpenSans-Semibold";
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("ttf");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("ttf");
}

@font-face {
  font-family: "OpenSans-Italic";
  src: url(/static/media/OpenSans-Italic.c7dcce08.ttf) format("ttf");
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url(/static/media/SourceSansPro-SemiBold.3577ec71.woff) format("woff");
}

.btn-default .btn {
  font-size: 1em;
  font-family: Graphik-Regular-Web, sans-serif;
  background-color: #008dfd;
  color: #fff;
  font-weight: 500;
  border: 0px;
  border-radius: 0;
}

.asterisk {
  color: rgb(99, 115, 129);
  font-size: 1.2em;
  /* vertical-align: text-bottom; */
  line-height: �1.42857143;
}

.asterisk-red {
  color: red;
  font-size: 1.2em;
}

button:active span,
button:focus span,
button:active,
button:focus {
  position: relative;
  top: 0px;
  left: 0px;
}

div[class*="-singleValue"]{
  color: rgb(161, 0, 255);
}

.authentication-container{
    display: block;
    width: 300px;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -150px;
}
.authentication-container > .authentication-button{
    margin-top: 15px;
    width: 300px;
}
.hr-access {
  width: 96%;
  border: solid 1px #a0a0a0;
}

.title-access {
  margin-left: 60px;
  margin-bottom: 20px;
  color: #008dfd;
  margin-top: 30px;
  font-size: 2.3em;
  font-family: Graphik-Regular-Web, sans-serif;
}

/* .tab-content {
  margin: 10px;
} */

#left-tabs-access .li {
  margin-top: 50px;
  background-color: transparent;
  width: 80%;
  color: black;
}

#left-tabs-access > div > ul > li {
  background-color: transparent;
  color: black;
}

.nav-pills > li.active > a {
  background-color: transparent;
  color: #008dfd;
  font: bold 1.2em Graphik-Regular-Web;
  border-right: 4px solid #008dfd;
  border-radius: 0;
  outline: none;
}
.nav > li > a:focus {
  background-color: transparent;
}

.nav-pills > li.active > a:hover {
  background-color: transparent;
}

.nav-pills > li > a {
  text-align: right;
  font: normal 1.2em Graphik-Regular-Web;
}

.nav > li > a {
  color: #000;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #008dfd;
  background-color: #fff;
}

.nav > li > a:hover,
.nav > li > a:focus,
.nav .open > a:hover,
.nav .open > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #fff;
  color: #008dfd;
}

.page-title-container {
  padding: 20px 0;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(227, 234, 237);
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
      justify-content: space-between;
  z-index: 1;
}

.page-title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Bold-web;
  font-size: 24px;
  letter-spacing: -0.32px;
  line-height: 26px;
}

.container-access {
  margin: 15px 90px;
  background-color: #fff;
  border-radius: 8px !important;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 0px 0px 0px;
}

.container-access .nav {
  padding: 0 45px !important;
}

.container-access .tab-content {
  margin: 20px 45px 0 45px !important;
}

.um-head {
  display: -ms-flexbox;
  display: flex;
}

.um-head > div {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.um-head > div:nth-child(2) {
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.user-management-searchbar {
  position: relative;
  width: 100%;
}

.pa-head,
.rm-head {
  display: -ms-flexbox;
  display: flex;
}

.pa-head > div,
.rm-head > div {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.pa-head > div:nth-child(2),
.rm-head > div:nth-child(2) {
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.pending-approvals-searchbar,
.role-management-searchbar {
  position: relative;
  width: 100%;
}

.management-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(196, 205, 213);
  height: 40px;
  -ms-flex-pack: center;
      justify-content: center;
  margin-left: 20px;
  padding: 8px 20px;
  cursor: pointer;
  font-family: "Graphik-Medium-Web";
  color: #637381;
}

.fetch-btn {
  cursor: not-allowed !important;
}

.add-btn.management-btn {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(0, 186, 255) 100%
  );
  border-radius: 20px;
  height: 40px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium-Web;
  font-size: 14px !important;
  letter-spacing: -0.27px;
  line-height: 13px;
  text-align: center;
  cursor: pointer;
  border: none !important;
}

.page-title-container .extract-btn.management-btn.export-user-report {
  margin: 0 30px;
}

.pa-btn-new.management-btn,
.extract-btn.management-btn {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 2px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  letter-spacing: -0.25px;
}

.extract-btn.management-btn > a {
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
}

.management-icon {
  width: 15px;
  margin-right: 7px;
}

.management-fetch-icon {
  height: 15px;
  display: -ms-flexbox;
  display: flex;
  margin-right: 7px;
  float: left;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

.extract-btn > .management-icon {
  width: 17px;
}

.button-pendingapp,
.button-userManagement {
  height: 25px;
  width: 110px;
  border-radius: 0;
  padding: 0;
}

.refresh-logo,
.add-logo {
  width: 15px;
  margin-right: 5px;
  margin-bottom: 2px;
}

th > .checkbox-inline,
td > .checkbox-inline {
  margin-bottom: 20px;
  margin-left: 20px;
}

#table-rm,
#table-pa,
#table-um,
#table-foreport,
#table-ra,
#table-pm {
  margin-top: 15px;
  margin-bottom: 0 !important;
}

.dropdown-pa > select {
  height: 20px;
  padding: 0;
  font: normal 0.87em OpenSans-Regular;
  border-radius: 0px;
}

#table-pa .svg-inline--fa.fa-w-16,
#table-um .svg-inline--fa.fa-w-16 {
  width: 0.8em;
  margin: 0.5px 2px;
  color: #dbdbdb;
}

#table-pa .sort-direction .glyphicon,
#table-um .sort-direction .glyphicon {
  color: #dbdbdb;
  font-size: 0.88em;
}

/* Access Management Buttons */
/* .refresh-btn,
  .refresh-btn:hover,
  .refresh-btn:focus,
  .refresh-btn.btn-default,
  .refresh-btn.btn-default:hover,
  .refresh-btn.btn-default:focus,
  .add-btn,
  .add-btn:hover,
  .add-btn:focus,
  .add-btn.btn-default,
  .add-btn.btn-default:hover,
  .add-btn.btn-default:focus,
  .extract-btn,
  .extract-btn:hover,
  .extract-btn:focus,
  .extract-btn.btn-default,
  .extract-btn.btn-default:hover,
  .extract-btn.btn-default:focus,
  .extract-btn a {
    background-color: #fff;
    font: normal 0.9em OpenSans-Regular;
    color: #000;
    border-radius: 0px;
    border: 0px;
    width: 100px;
    border-style: outset;
    box-shadow: none;
    padding: 0px;
    outline: none;
    height: 25px;
    text-align: center;
  } */

/* .extract-btn,
  .extract-btn:hover,
  .extract-btn:focus,
  .extract-btn.btn-default,
  .extract-btn.btn-default:hover,
  .extract-btn.btn-default:focus,
  .extract-btn a {
    background-color: #fff;
    font: normal 0.9em OpenSans-Regular;
    color: #000;
    border-radius: 0px;
    border: 0px;
    width: 100px;
    border-style: outset;
    box-shadow: none;
    padding: 0px;
    outline: none;
    height: 25px;
    text-align: center;
  } */

.btn-text {
  padding: 0px 3px;
}

.search-btn,
.search-btn:hover,
.search-btn:focus,
.search-btn.btn-default,
.search-btn.btn-default:hover,
.search-btn.btn-default:focus {
  background-color: #008dfd;
  font: normal 0.9em OpenSans-Regular;
  color: #fff;
  border-radius: 0px;
  border: 0px;
  width: 100px;
  height: 25px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0px;
  float: right;
  text-align: center;
  margin: 0;
  top: 0 !important;
}

/* #pa_container .pa-btn,
  #pa_container .pa-btn:hover,
  #pa_container .pa-btn:focus,
  #pa_container .pa-btn.btn-default,
  #pa_container .pa-btn.btn-default:hover,
  #pa_container .pa-btn.btn-default:focus {
    font: normal 1.2em OpenSans-Regular;
    color: #fff;
    border-radius: 0px;
    border: 0px;
    width: 150px;
    height: 30px;
    box-shadow: none;
    padding: 0px;
    margin-left: 20px;
    float: right;
    margin-top: 25px;
    margin-bottom: 60px;
  } */

#pa_container .pa-footer .management-btn {
  width: 150px;
  height: 30px;
  margin-left: 20px;
  float: right;
  margin-top: 25px;
  margin-bottom: 60px;
}

#pa_container .pa-footer div {
  /* background: rgb(249, 250, 251); */
  background: red;
}
.no-padding {
  padding: 0px;
}

.container-access .tab-content .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

#pa_search,
#searchBox {
  height: 40px;
  padding: 0 15px 0 50px;
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(223, 227, 232);
  width: 60%;
}

.search-input,
.search-userManagement {
  height: 25px;
  border-radius: 0;
  border: 1px solid #dbdbdb;
  width: 70%;
  background-color: #fbfbfb;
  float: left;
  font: normal 0.9em OpenSans-Regular;
  padding-left: 6px;
}

select#showEntries {
  cursor: pointer;
  color: rgb(123, 142, 152);
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  padding: 5px 3px;
  margin: 0 10px;
  outline: none;
}

.no-padding label {
  font: normal 0.9em OpenSans-Regular;
  color: black;
}

.total-label {
  /* font: normal 0.9em OpenSans-Regular;
    color: #b6b6b6;
    height: 25px;
    padding: 3.5px 0px; */
  padding: 8px 0;
}

.userButton  {
  font-family: OpenSans-Regular;
  font-size: 0.9em;
  color: black;
  border: none;
  background: transparent;
  width: 120px !important;
  outline: none !important;
}
.alignEntries {
  margin-bottom: 55px;
  background: rgb(243, 243, 243);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #c8d5db;
  border-top: none;
  padding: 0 20px;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
}

.alignEntries label {
  color: rgb(123, 142, 152);
  font-family: "Graphik-Regular-Web";
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 10px;
}
.alignEntries .no-padding svg {
  fill: #7b8e98 !important;
}

.alignEntries > div {
  display: -ms-flexbox;
  display: flex;
  padding: 15px 0 5px 0;
  margin-right: 20px;
}

.alignEntries > div:nth-child(2) > div {
  margin-right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.alignEntries .disabled svg {
  margin: 0 !important;
}

.alignEntries .pagination {
  margin: 0 !important;
}

.alignEntries select option:hover,
.alignEntries select option:focus,
.alignEntries select option:active {
  -webkit-box-shadow: 0 0 10px 100px rgb(161, 0, 255) inset;
          box-shadow: 0 0 10px 100px rgb(161, 0, 255) inset;
  color: #ffed00 !important;
}

.alignEntries select option:checked {
  background-color: rgb(161, 0, 255) !important;
  color: #fff !important;
}

div#showEntries {
  margin: 0 5px 5px 10px;
}

div#showEntries > div {
  cursor: pointer;
}

.tabulator {
  position: relative;
  background-color: #fff;
  /* overflow: hidden; */
  font-size: 14px;
  text-align: left;
  -webkit-transform: translatez(0);
          transform: translatez(0);
  z-index: 1;
}

/* .tabulator-row {
    height: 29px;
  } */

.tabulator[tabulator-layout="fitDataFill"]
  .tabulator-tableHolder
  .tabulator-table {
  min-width: 100%;
}

.tabulator.tabulator-block-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tabulator .tabulator-header {
  width: 100%;
  border: 1px solid #c8d5db;
  color: #000;
  white-space: nowrap;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  font: bold 0.87em OpenSans-Regular;
}

.tabulator .tabulator-header,
.tabulator .tabulator-header .tabulator-col {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #f3f3f3;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
}

.tabulator .tabulator-header .tabulator-col {
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  vertical-align: bottom;
}

.tabulator-col.contact-header .tabulator-col-title-holder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.tabulator-headers {
  display: -ms-flexbox;
  display: flex;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  position: absolute;
  border: 1px solid #000;
  background: #e6e6e6;
  pointer-events: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.tabulator-col-title-holder {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0 20px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  font-weight: 100;
  text-align: center;
  color: rgb(82, 101, 127);
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
  letter-spacing: -0.43px;
  line-height: 24px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title
  .tabulator-title-editor {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  border: 1px solid #000;
  padding: 1px;
  background: #fff;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #000;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-group
  .tabulator-col-group-cols {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #000;
  overflow: hidden;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-group
  .tabulator-col-group-cols
  .tabulator-col:last-child {
  margin-right: -1px;
}

.tabulator
  .tabulator-header
  .tabulator-col:first-child
  .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator .tabulator-header .tabulator-col.ui-sortable-helper {
  position: absolute;
  background-color: #e6e6e6 !important;
  border: 1px solid #000;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 2px;
  width: 100%;
  text-align: center;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-header-filter
  input::-ms-clear {
  width: 0;
  height: 0;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable
  .tabulator-col-title {
  font-weight: 100;
  text-align: center;
  color: rgb(82, 101, 127);
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
  letter-spacing: -0.43px;
  line-height: 24px;
  margin-right: 10px;
}

.tabulator-col-title-holder > div {
  width: auto !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  cursor: pointer;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="none"]
  .tabulator-col-content
  .tabulator-arrow {
  border: 4px solid #dbdbdb;
  border-radius: 10px;
  display: none;
}

.arrow-5 {
  /* more triangle */
  position: relative;
  height: 0px;
  width: 0px;
  border: 12px solid;
  border-color: #dbdbdb #dbdbdb transparent transparent;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.arrow-5:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  height: 12px;
  width: 16px;
  background-color: darkcyan;
  -webkit-transform: rotate(-45deg) translate(2px, 1px);
          transform: rotate(-45deg) translate(2px, 1px);
}
/* .tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable[aria-sort="asc"]
    .tabulator-col-content
    .tabulator-col-title,
  .tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable[aria-sort="desc"]
    .tabulator-col-content
    .tabulator-col-title {
    font-weight: bold;
  } */

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="asc"]
  .tabulator-col-content
  .tabulator-arrow {
  height: 0px;
  width: 0px;
  border: 3px solid;
  border-color: #52657f #52657f transparent transparent;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  top: 1px;
  bottom: 0;
  margin: auto;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="asc"]
  .tabulator-col-content
  .tabulator-arrow:before,
.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="desc"]
  .tabulator-col-content
  .tabulator-arrow:before {
  /* content: ""; */
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  height: 4px;
  width: 7px;
  background-color: #000;
  -webkit-transform: rotate(-45deg) translate(2px, 1px);
          transform: rotate(-45deg) translate(2px, 1px);
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="desc"]
  .tabulator-col-content
  .tabulator-arrow {
  height: 0px;
  width: 0px;
  border: 3px solid;
  border-color: #52657f #52657f transparent transparent;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  position: absolute;
  top: 0;
  bottom: 5px;
  margin: auto;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical
  .tabulator-col-content
  .tabulator-col-title {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip
  .tabulator-col-title {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable
  .tabulator-col-title {
  padding-right: 0;
  padding-top: 20px;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip
  .tabulator-col-title {
  padding-right: 0;
  padding-bottom: 20px;
}

.tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-title,
.tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-title {
  font-family: "Graphik-Bold-Web" !important;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable
  .tabulator-arrow {
  right: calc(50% - 6px);
}

.tabulator .tabulator-header .tabulator-frozen {
  display: inline-block;
  position: absolute;
  z-index: 1;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #000;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #000;
}

.tabulator .tabulator-header .tabulator-calcs-holder {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 400%;
  background: #f2f2f2 !important;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row {
  background: #f2f2f2 !important;
}

.tabulator
  .tabulator-header
  .tabulator-calcs-holder
  .tabulator-row
  .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder {
  min-width: 400%;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableHolder {
  /* position: relative; */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-right: 1px solid #c8d5db;
  border-left: 1px solid #c8d5db;
}

.tabulator .tabulator-tableHolder:focus {
  outline: none;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder {
  width: 100% !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #cbcbcb;
}

.tabulator
  .tabulator-tableHolder
  .tabulator-placeholder[tabulator-render-mode="virtual"] {
  /* position: absolute; */
  top: 0;
  left: 0;
  height: 100%;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder span {
  display: inline-block;
  margin: 0 auto;
  padding: 10px;
  color: #000;
  font-weight: 700;
  font-size: 20px;
}

.tabulator .tabulator-tableHolder .tabulator-table {
  /* position: relative; */
  display: inline-block;
  background-color: #fff;
  white-space: nowrap;
  overflow: visible;
  color: #000;
  font: normal 0.87em OpenSans-Regular;
}

.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs {
  font-weight: 700;
  background: #f2f2f2 !important;
}

.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border-bottom: 2px solid #000;
}

.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border-top: 2px solid #000;
}

.tabulator .tabulator-col-resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.tabulator .tabulator-col-resize-handle.prev {
  left: 0;
  right: auto;
}

.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.tabulator .tabulator-footer {
  padding: 15px 0px;
  background-color: #fff;
  text-align: right;
  color: #000;
  font-weight: 100;
  white-space: nowrap;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% + 20px);
  margin: -5px -10px 5px;
  text-align: left;
  background: #f2f2f2 !important;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #000;
  overflow: hidden;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  background: #f2f2f2 !important;
}

.tabulator
  .tabulator-footer
  .tabulator-calcs-holder
  .tabulator-row
  .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  margin-bottom: -5px;
  border-bottom: none;
}

.tabulator .tabulator-footer .tabulator-pages {
  margin: 0 7px;
}

.tabulator .tabulator-footer .tabulator-page {
  display: inline-block;
  border: 0px;
  background: hsla(0, 0%, 100%, 0.2);
  color: #a0a0a0;
  font-family: inherit;
  font-weight: inherit;
  font-size: 1.3em;
  outline: none;
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: #008dfd;
  font-weight: 700;
}

.tabulator-page:disabled {
  opacity: 0.5;
  color: #a0a0a0;
}

.tabulator-page:not(.disabled):hover {
  cursor: pointer;
  color: #008dfd;
}

.tabulator-page:not(.disabled) {
  cursor: pointer;
  color: #666;
}

.tabulator-page:disabled:hover {
  cursor: not-allowed;
  color: #666;
}

.tabulator .tabulator-loader {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.tabulator .tabulator-loader .tabulator-loader-msg {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  font-weight: 700;
  font-size: 16px;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-loading {
  border: 4px solid #000;
  color: #000;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-error {
  border: 4px solid #d00;
  color: #590000;
}

.tabulator-row {
  /* position: relative; */
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 22px;
  border-bottom: 1px solid #c8d5db;
  color: #52657f;
  display: -ms-flexbox;
  display: flex;
}

.tabulator-row,
.tabulator-row:nth-child(2n) {
  background-color: #fff;
}

.tabulator-row.tabulator-selectable:hover {
  background-color: #a100ff;
  cursor: pointer;
  color: #fff;
}

.tabulator-row.tabulator-selected {
  /* background-color: #9abcea; */
}

.tabulator-row.tabulator-selected select {
  color: black;
}

.tabulator-row.tabulator-selected:hover {
  /* background-color: #008dfd; */
  cursor: pointer;
}

.tabulator-row.tabulator-moving {
  position: absolute;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none !important;
  z-index: 2;
}

.tabulator-row .tabulator-row-resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.tabulator-row .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.tabulator-row .tabulator-frozen {
  display: inline-block;
  position: absolute;
  background-color: inherit;
  z-index: 1;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #000;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #000;
}

.tabulator-row .tabulator-responsive-collapse {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 5px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table {
  font-size: 14px;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  /* display: flex;
    color: inherit;
    font-family: "Graphik-Regular-Web";
    font-size: 12px;
    letter-spacing: -0.43px;
    justify-content: flex-start;
    align-items: center;
    margin: 12px 0 !important;
    padding: 0 20px; */

  color: inherit;
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  letter-spacing: -0.43px;
  -ms-flex-align: center;
      align-items: center;
  margin: 12px 0 !important;
  padding: 0 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  height: 24px !important;
  line-height: 22px;
  outline: none;
}

.tabulator-cell[tabulator-field="roleCell"] {
  padding: 0;
}

.tabulator-cell[tabulator-field="contact"] {
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0;
}

.selectChkBx {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin: 0 !important;
}

img.edit-pen {
  width: 16px;
}

.role-text-container {
  height: 22px;
  width: 100%;
  padding: 0 5px 0 0;
}

.role-text {
  width: 100%;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 10;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

img.role-dropdown-icon {
  width: 9px;
  height: 9px;
  position: relative;
  right: -13px;
  top: 6px;
  z-index: 10;
}

.pa-role-select,
.pa-role-select:focus,
.pa-role-select:hover {
  padding: 3px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 2;
  color: #52657f;
  border: 1px solid #a100ff !important;
  border-radius: 4px;
  outline: none;
}

.pa-role-select .pa-role-option {
  background-color: white;
  color: #52657f;
}
/* .pa-role-select option:hover {
  background-color: #a100ff !important;
  color: white;
} */

#table-pa .tabulator-row .tabulator-cell.tabulator-editing {
  border: 0;
  padding: 0px;
  outline: 0px;
  overflow: visible;
  overflow: initial;
}

.tabulator-row.tabulator-selectable:hover .edit-pen {
  -webkit-filter: invert(0) sepia(0) saturate(0) hue-rotate(0deg) brightness(2);
          filter: invert(0) sepia(0) saturate(0) hue-rotate(0deg) brightness(2);
}

.tabulator-row .tabulator-cell:last-of-type {
  border-right: none;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid #1d68cd;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail {
  border: 1px solid #d00;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input,
.tabulator-row .tabulator-cell.tabulator-validation-fail select {
  border: 1px;
  background: transparent;
  color: #d00;
}

.tabulator-row .tabulator-cell:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row
  .tabulator-cell.tabulator-row-handle
  .tabulator-row-handle-box
  .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  margin-top: 2px;
  background: #666;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
  border-bottom-left-radius: 1px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  height: 11px;
  width: 11px;
  margin-right: 5px;
  border: 1px solid #000;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #000;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: #000;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #000;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #666;
  color: #fff;
  font-weight: 700;
  font-size: 1.1em;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
  opacity: 0.7;
}

.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle.open
  .tabulator-responsive-collapse-toggle-close {
  display: inline;
  display: initial;
}

.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle.open
  .tabulator-responsive-collapse-toggle-open,
.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle
  .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row.tabulator-group {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  padding: 5px;
  padding-left: 10px;
  background: #fafafa;
  font-weight: 700;
  min-width: 100%;
}

.tabulator-row.tabulator-group:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  margin-right: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
  border-bottom: 0;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 .tabulator-arrow {
  margin-left: 20px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 .tabulator-arrow {
  margin-left: 40px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 .tabulator-arrow {
  margin-left: 60px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 .tabulator-arrow {
  margin-left: 80px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 .tabulator-arrow {
  margin-left: 100px;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 16px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 0;
  border-left: 6px solid #666;
  vertical-align: middle;
}

.tabulator-row.tabulator-group span {
  margin-left: 10px;
  color: #666;
}

.tabulator-edit-select-list {
  position: absolute;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-height: 200px;
  background: #fff;
  border: 1px solid #000;
  font-size: 14px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 4;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item {
  padding: 4px;
  color: #000;
  font-size: 0.87em;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active {
  color: #fff;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: #fff;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-group {
  border-bottom: 1px solid #000;
  padding: 4px;
  padding-top: 6px;
  color: #000;
  font-weight: 700;
}

#roleDP {
  width: 99%;
  border: white;
  -moz-text-align-last: center;
       text-align-last: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  color: black;
}

.tabulator-row.tabulator-selected #roleDP {
  background-color: #008dfd;
  color: white;
}

#select-all {
  text-align: center;
  vertical-align: middle;
}

/* Role Assignment */
/* select#roles,
  #pm_container select.form-control {
    height: 25px;
    border-radius: 0px;
    background-color: #fbfbfb;
    cursor: pointer;
    font: normal 0.87em OpenSans-Regular;
    width: 100%;
    padding: 0px 5px;
  } */

label.no-padding.control-label {
  padding: 3px 0px;
  margin: 0px;
  font-size: 1em;
}

label.control-label {
  padding: 3px 20px;
  margin: 0px;
  font-size: 1em;
}

.add-margin {
  margin: 10px 0px;
}

/* Checkbox on Table Header */
.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px; /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  cursor: pointer;
}
.checkmark-container {
  margin: 0% 40%;
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #000;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #000;
  left: 8px;
  top: 12px;
}

.close {
  position: absolute;
  right: 300px;
  top: 7px;
  width: 10px;
  height: 10px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 15px;
  width: 3px;
  background-color: #333;
}
.close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.pa-modal .modal-content,
.rm_modal .modal-content,
.um_modal .modal-content {
  border-radius: 16px;
}

.pa-modal .modal-content,
.rm_modal .modal-body,
.um_modal .modal-body {
  padding: 0 !important;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
}

.pa_modal .modal-dialog,
.pa_modal_confirm .modal-dialog,
.um_modal .modal-dialog,
.um_modal_confirm .modal-dialog,
.ra_modal .modal-dialog,
.ra_modal_confirm .modal-dialog,
.rm_modal .modal-dialog,
.rm_modal_confirm .modal-dialog,
.error-modal .modal-dialog,
.dashboard-modal .modal-dialog {
  width: 400px;
  margin-top: 15%;
}

.pa_modal .modal-content,
.pa_modal_confirm .modal-content,
.um_modal .modal-content,
.um_modal_confirm .modal-content,
.ra_modal .modal-content,
.ra_modal_confirm .modal-content,
.rm_modal .modal-content,
.rm_modal_confirm .modal-content {
  /* border-radius: 0; */
  border: 0;
}

.pa_modal .button_container,
.dashboard-modal,
.um_modal_confirm .button_container,
.ra_modal_confirm .button_container,
.rm_modal_confirm .button_container {
  text-align: center;
}

.pa_modal .button_container,
.um_modal .button_container,
.rm_modal .button_container,
.ra_modal .button_container {
  background: rgb(249, 249, 249);
  border-radius: 0 0 16px 16px;
  border-top: 1px solid rgb(220, 226, 231);
  padding: 15px 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.pa_list_container li {
  list-style-type: disc;
  /* font: normal 0.87em "OpenSans-Regular"; */
  font: normal 1em "Graphik-Regular-Web";
}

.pa_modal .button_container .btn,
.pa_modal_confirm .button_container .btn,
.um_modal .button_container .btn,
.um_modal_confirm .button_container .btn,
.ra_modal .button_container .btn,
.ra_modal_confirm .button_container .btn,
.rm_modal .button_container .btn,
.rm_modal_confirm .button_container .btn {
  color: rgb(99, 115, 129);
  background: rgb(255, 255, 255);
  border-radius: 25px;
  border: 1px solid rgb(196, 205, 213);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  padding: 10px 20px;
  margin-left: 10px;
  width: 85px;
}

.pa_list_container,
.um_list_container {
  font-size: 0.9em;
  min-height: 200px;
}

.pa_list_container {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 30;
}

.ra_list_container,
.rm_list_container {
  font-size: 0.9em;
  min-height: 80px;
  padding-bottom: 10px;
}

.pa_modal #saveBtn,
.rm_modal #saveBtn,
.um_modal #saveBtn {
  margin-right: 0;
}

#denyBtn,
#approveBtn,
#okBtn,
#saveBtn,
#createBtn,
#yesBtn,
#addBtn {
  color: #fff;
  outline: none;
  background: rgb(161, 0, 255);
  border-radius: 25px;
  font-family: "Graphik-Medium-Web";
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  padding: 10px 20px;
  width: 85px;
  font-size: 14px;
}

.bo_create_modal_confirm #yesBtn,
.update_modal #yesBtn,
.modules_modal #yesBtn,
.uc_modal_confirm #yesBtn,
.supportMessageModal #yesBtn,
.pa_modal #approveBtn,
.pa_modal #denyBtn {
  margin-right: 15px;
}
.pa_modal #approveBtn {
  padding: 10px;
}

#deleteBtn {
  /* padding-left: 40px; */
  float: left;
  outline: none;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-right: auto !important;
  margin-left: 0;
}

.modal_header_row {
  border-bottom: 1px solid rgb(220, 220, 220);
  position: relative;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.pa_modal.title,
.rm_modal_title,
.um_modal_title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Medium-Web;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 24px;
  padding: 20px 30px 17px 30px;
  position: relative;
  width: 100%;
}

.ra_modal_title-message,
.um_modal_title-message,
.rm_modal_title-message {
  /* color: rgb(45, 58, 75); */
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  letter-spacing: -0.35px;
  padding: 20px 30px 17px 30px;
  position: relative;
  width: 100%;
}

.form-horizontal {
  padding: 10px 40px;
}

.modal-field-row {
  width: 100%;
  margin: 0 !important;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.modal-field-title {
  position: absolute;
  z-index: 1;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium-Web;
  font-size: 11px;
  letter-spacing: 0.2px;
  padding: 10px 15px !important;
}

.modal-field {
  padding: 25px 13px 5px 13px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  width: 100%;
}

.modal-field > #contact {
  cursor: pointer;
}

.radio-field .modal-field {
  border: none;
  padding: 0 !important;
  width: 20px;
  margin-right: 10px;
}

.modal-field-row.radio-field {
  padding-top: 20px !important;
}

.radio-field .modal-field-title {
  color: rgb(33, 43, 54);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  -ms-flex-item-align: center;
      align-self: center;
  position: relative;
  padding: 8px 0 !important;
}

.modal-field-blank {
  padding: 15px 13px 15px 13px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  width: 100%;
}

.modal-field > input,
.modal-field-blank > input {
  background: transparent !important;
  width: 100% !important;
  border: none !important;
  color: rgb(33, 43, 54);
  font-size: 16px !important;
  letter-spacing: -0.35px;
  border-radius: 4px !important;
  font-family: Graphik-Regular-Web !important;
}

/* .pa_modal_title,
  .um_modal .um_modal_title,
  .ra_modal .ra_modal_title,
  .rm_modal .rm_modal_title {
    font-family: OpenSans-Regular;
    color: #008dfd;
  } */

.rm_modal .ra_modal_prompt,
.ra_modal .ra_modal_prompt,
.um_modal .um_modal_prompt {
  font-family: OpenSans-Regular;
  color: #000;
}

.um_modal_confirm .modal-content,
.ra_modal_confirm .modal-content,
.rm_modal_confirm .modal-content,
.error-modal .modal-content {
  border: 0;
  border-radius: 16px;
}

.error-modal .modal-body svg.svg-inline--fa.fa-times-circle,
.rm_modal_confirm .modal-body svg.svg-inline--fa.fa-exclamation-circle {
  color: #a100ff;
}

.um_modal_confirm .modal-body,
.ra_modal_confirm .modal-body,
.rm_modal_confirm .modal-body,
.error-modal .modal-body {
  text-align: center;
}

.error-modal .error-title {
  font: normal 1.5em OpenSans-Regular;
}

.pa_modal_confirm .pa_modal_title,
  .rm_modal_confirm .rm_modal_title,
  .um_modal_confirm .um_modal_title,
/* .ra_modal_confirm .ra_modal_title,
.rm_modal_confirm .rm_modal_title, */
.error-modal .content_container {
  font-family: Graphik-Regular-Web;
  padding: 25px 0px;
  word-break: break-word;
}

.pa_modal_confirm .pa_modal_title,
.ra_modal_confirm .ra_modal_title,
.ra_modal .ra_modal_title {
  color: rgb(45, 58, 75) !important;
  font-size: 20px !important;
  font-family: Graphik-Medium-Web !important;
  font-weight: 500 !important;
  letter-spacing: -0.35px;
  line-height: 24px;
  word-break: break-word;
  margin-left: 35px !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.message_modal_header_row {
  text-align: center;
  padding: 15px;
}

.um_modal_confirm .fa-thumbs-up,
.um_modal_confirm .fa-thumbs-down,
.ra_modal_confirm .fa-thumbs-up,
.ra_modal_confirm .fa-thumbs-down,
.rm_modal_confirm .fa-thumbs-up,
.rm_modal_confirm .fa-thumbs-down,
.pa_modal .fa-thumbs-up,
.pa_modal .fa-thumbs-down {
  color: #008dfd;
  font-size: 2em;
  padding-top: 15px;
}

.um_modal_confirm .fa-check-circle,
.ra_modal_confirm .fa-check-circle,
.rm_modal_confirm .fa-check-circle {
  color: #008dfd;
}

.um_modal_confirm .fa-times-circle,
.ra_modal_confirm .fa-times-circle,
.rm_modal_confirm .fa-times-circle {
  color: red;
  font-size: 3em;
  padding-top: 15px;
}

.um_list_container .svg-inline--fa.fa-fw,
.ra_list_container .svg-inline--fa.fa-fw,
.rm_list_container .svg-inline--fa.fa-fw {
  width: 0.5em;
  color: #61b1ff;
  margin-bottom: 2px;
}

.rm_list_container .svg-inline--fa.fa-fw {
  margin-right: -3px;
}

.um_list_container div.form-group,
.um_list_container .form-group label,
.ra_list_container div.form-group,
.ra_list_container .form-group label,
.rm_list_container div.form-group,
.rm_list_container .form-group label {
  text-align: left;
  margin-bottom: 5px;
  font-weight: normal;
  padding-top: 10px;
}

/* .ra_list_container .form-group label, */
.rm_list_container .form-group label {
  padding-left: 0px;
}

.ra_list_container .form-group label {
  /* .rm_list_container .form-group label { */
  /* padding-left: 0px; */
}

.um_list_container .form-group div,
.ra_list_container .form-group div,
.rm_list_container .form-group div {
  padding: 0px;
}

.um_list_container .form-group label,
.um_list_container .form-group input .ra_list_container .form-group label,
.ra_list_container .form-group input .rm_list_container .form-group label,
.rm_list_container .form-group input {
  font: normal 0.87em "OpenSans-Regular";
  color: #000;
  padding-top: 5px;
}

.um_list_container .form-group input,
/* .ra_list_container .form-group input, */
.rm_list_container .form-group input {
  height: 25px;
  border: 1px solid #dbdbdb;
  background-color: #fbfbfb;
  border-radius: 0px;
  padding: 3px;
  width: 190px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: normal 1em OpenSans-Regular;
}

.rm_list_container .form-horizontal .form-group {
  margin: 0;
}

.ra_list_container .form-group input {
  width: 350px;
  height: 56px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;

  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  outline: none;
  padding-left: 14px;
}

#contact {
  width: 15px;
  padding: 0px;
  height: 20px;
}

.um_list_container .form-group div .requiredMessage,
.ra_list_container .form-group div .requiredMessage,
.rm_list_container .form-group div .requiredMessage {
  font: normal 0.87em "OpenSans-Regular";
  color: red;
  height: 1em;
}

.um_list_container .form-group input.required {
  border: 1px solid #008dfd;
}

.um_list_container .form-group div .requiredMessage {
  padding-left: 15px;
}

#RAValidationMsg .hide,
#UMValidationMsg .hide,
#RMValidationMsg .hide {
  display: none;
}
.ra_list_container .react-autosuggest__suggestions-container--open {
  width: 88%;
}

.ra_list_container ul.react-autosuggest__suggestions-list {
  padding: 0px;
  background-color: #fbfbfb;
  border: 1px solid #dbdbdb;
  font-family: OpenSans-Regular;
  font-style: normal;
  color: #000;
  max-height: 90px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.react-autosuggest__suggestions-list li:hover {
  background-color: #dbdbdb75;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  z-index: 2;
}

.message_modal_header_row .svg-inline--fa.fa-fw {
  width: 1.5em;
  color: #a100ff;
  margin: 15px;
  margin-bottom: 2px;
  border: 1px solid;
  border-radius: 100%;
  border-width: 3px;
  height: 1.5em;
  padding: 14px;
}

.required {
  border: 1px solid #a100ff;
  -webkit-box-shadow: 0 0 3px #a100ff6e;
          box-shadow: 0 0 3px #a100ff6e;
}

button.pa-btn,
button.pa-btn:hover,
button.pa-btn:focus,
button.pa-btn:active,
button.pa-btn:active:focus,
button.pa-btn:active:hover {
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  text-align: center;
  letter-spacing: -0.27px;
  padding: 8px 22px;
  float: right;
  margin: 0 10px;
  color: #a100ff;
  background: #fff;
  border-radius: 20px;
  border: 2px solid #a100ff;
}

button.pm-btn,
button.pm-btn:hover,
button.pm-btn:focus,
button.pm-btn:active,
button.pm-btn:active:focus,
button.pm-btn:active:hover {
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 12px 25px 12px 25px;
}

button.pm-btn.btn.btn-default:disabled,
button.pm-btn.btn.btn-default:disabled:hover {
  /* font: normal 1.2em OpenSans-Regular; */
  color: #fff;
  /* border-radius: 0;
  border: 0;
  width: 150px;
  height: 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  margin-left: 20px;
  float: right;
  margin-top: 25px;
  margin-bottom: 60px; */
  background-color: #a0a0a0;
  border-color: #a0a0a0;

  font-size: 14px !important;
  font-family: Graphik-Regular-Web !important;
  font-weight: 500 !important;
  text-align: center !important;
  letter-spacing: -0.25px !important;
  line-height: 14px !important;
  padding: 12px 25px 12px 25px !important;
}

button.pa-btn.btn.btn-default:disabled,
button.pa-btn.btn.btn-default:disabled:hover {
  background: #a0a0a0 !important;
  color: rgb(255, 255, 255) !important;
  cursor: not-allowed;
  border: 2px solid #a100ff;
}

/* #pa_container .pa-btn.btn-default:disabled,
#pa_container .pa-btn.btn-default:disabled:hover {
  background-color: #a0a0a0;
} */

.ra_list_container .form-group input.error,
.rm_list_container .form-group input#RoleDescription.required {
  border: 1px solid #a100ff;
  -webkit-box-shadow: 0 0 3px #a100ff6e;
          box-shadow: 0 0 3px #a100ff6e;
}

#pm_container select.form-control[disabled] {
  background-color: #dbdbdb;
}

.user-report-modal .modal-dialog {
  border-radius: 16px;
}

.user-report-modal .modal-content {
  background: rgb(255, 255, 255);
  border-radius: 16px !important;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}

.user-report-modal-header {
  color: rgb(45, 58, 75);
  font-family: "Graphik-Medium-Web";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 24px;
  display: -ms-flexbox;
  display: flex;
}

.user-report-modal-header > div {
  width: 100%;
}

.user-report-modal .modal-body {
  padding: 0;
}

.user-report-modal-body {
  color: rgb(99, 115, 129);
  font-family: "Graphik-Regular-Web";
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 24px;
  border-top: 1px solid rgb(220, 220, 220);
  border-bottom: 1px solid rgb(220, 220, 220);
  padding: 20px 40px;
}

.modal-header-title {
  padding: 20px 40px;
}

.user-report-modal .create-project-header-parent-img {
  margin: 0;
  padding: 20px 40px;
}

.button_container.user-report-modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 20px 40px;
  background: rgb(249, 250, 251);
  border-radius: 0 0 16px 16px;
}

.button_container.user-report-modal-footer > div {
  background: rgb(255, 255, 255) !important;
  border-radius: 24px;
  border: 2px solid rgb(145, 158, 171);
  height: 40px;
  width: 90px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0;
  -ms-flex-align: center;
      align-items: center;
  margin-left: 15px;
}

.button_container.user-report-modal-footer > div > button {
  color: rgb(99, 115, 129);
}

.user-report-modal .user-report-modal-footer > div {
  margin: 0 !important;
}

#uReport_container {
  padding: 25px 90px;
}

#cancelBtn {
  margin: 0;
}

.ra-dd-search,
.ra-refresh-add {
  display: -ms-flexbox;
  display: flex;
}

.ra-refresh-add {
  -ms-flex-pack: end;
      justify-content: flex-end;
}

select#roles.form-control {
  width: 200px;
  height: 40px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 20px;

  /*text*/
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 14px;
}

.ra-search-input {
  width: 300px !important;
}

.ra-search-container {
  padding-left: 15px;
}

img.ra-search-bar-icon {
  width: 16px;
  position: absolute;
  top: 11px;
  left: 237px;
  display: -ms-flexbox;
  display: flex;
  float: left;
}

.pa_modal .modal-body,
.pa_modal .modal-content,
.ra_modal .modal-body,
.ra_modal .modal-content {
  padding: 0 !important;
  border-radius: 16px;
  border: 1px solid #dce2e7;
  width: 426px;
  /* height: 276px; */
  background: rgb(255, 255, 255);
  /* border: 1px solid rgb(220, 226, 231); */
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}

.pa_list_container,
.ra_list_container {
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}

.pa_list_container h1 {
  font-size: 16px;
  padding-left: 20px;
}

.pa_modal-value,
.ra-modal-value {
  padding-left: 14px;
  padding-top: 17px;
}

.pm-header {
  display: -ms-flexbox;
  display: flex;
}

.pm-dd-ar,
.pm-dd-an {
  padding-left: 15px;
}

#table-pm .tabulator-col-title {
  overflow: inherit !important;
}

.pa-footer-cut-container {
  z-index: 10;
  /* background: red; */
  margin-left: -45px;
  margin-right: -45px;
  border-radius: 0px 0px 5px 5px;
  background: rgb(243, 243, 243);
  border-top: 1px solid #c8d5db;
  height: 80px;
  padding-top: 20px;
  /* padding: 20px 45px; */
}
#pending-buttons {
  margin-right: 40px;
}

.pa-footer-btn-container {
  float: right;
  padding-right: 47px;
}

.ra-add-user-btn,
.ra-add-user-btn:hover {
  cursor: not-allowed;
}

.ra-delete-icon {
  font-size: 14px;
  color: grey;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
  opacity: 0.8;
}

/* Custom Select START */
/*the container must be positioned relative:*/
.custom-select {
  /* position: relative; */
  font-family: "Graphik-Regular-Web";
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: #fff;
  color: black;
  border: 1px solid #dfe3e8;
  padding: 0 10px;
  position: relative;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  right: 10px;
  border: 5px solid transparent;
  border-color: #000 transparent transparent transparent;
  top: 10px;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #000 transparent;
  top: 5px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .select-selected {
  border: 1px solid #dfe3e8;
  padding: 0 10px;
      z-index: 999; 
  position: relative;
} */

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #fff;
  z-index: 99;
  border: 1px solid #dfe3e8;
  -webkit-box-shadow: 0 0 10px #dfe3e8;
          box-shadow: 0 0 10px #dfe3e8;
  border-radius: 0 0 5px 5px;
  padding: 5px 10px;
  width: 150px;
  height: 250px;
  overflow: auto;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: #a100ff;
  color: #fff !important;
  padding: 0 5px;
}

/* Custom Select END */

.add-new-program-container #groove-radio-button {
  display: -ms-flexbox !important;
  display: flex !important;
}

.add-new-program-container #groove-radio-button>.form-check {
  margin-right: 30px !important;
}

.add-new-program-container #groove-radio-button .form-check>label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.add-new-program-container .label16-medium-midnight {
  font-family: 'Graphik-Medium' !important;
  color: #212b36 !important;
}

.add-user-client-type {
  display: -ms-flexbox;
  display: flex;
}

.add-user-client-type>div:first-child {
  margin: 0 30px 0 0;
}

.add-user {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin: 15px 0;
}

span.invalid-selection {
  color: red;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  font-family: 'Graphik-Medium-Web';
}

span.invalid-selection.error-on-profile {
  bottom: unset;
}

span.invalid-selection.error-on-profile.another-qc-error,
span.invalid-selection.error-on-profile.client-error {
  line-height: 0.1px;
  top: 62px;
}

@media only screen and (max-width: 768px) {
  span.invalid-selection {
    font-size: 10px;
  }
}

.selected-profile-container {
  padding: 0 15px;
}

.selected-profile-container-background {
  background: #faf5fd;
  padding: 30px 40px 30px 30px !important;
  border-radius: 5px;
}

.selected-profile-container-background>div.row>div {
  padding-bottom: 30px;
  padding-top: 30px;
}

.selected-profile-container-background>div.row>div:last-child {
  padding: 0;
}

.selected-profile-container .form-group {
  margin-bottom: 0 !important;
}

.selected-profile-container-background>.row {
  position: relative;
}

.selected-profile-container-background>.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  padding-right: 15px;
}

.selected-profile-container>div>div>.svg-container {
  line-height: 0;
  cursor: pointer;
}

.dashboard-dropdown .multi-select-dropdown-with-count {
  height: 60px;
}

.dashboard-dropdown .multi-select-dropdown-with-count div[class*='-control']>div {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.profile-to-add-dropdown .multi-select-dropdown-with-count.disabled, .selected-profile-container .multi-select-dropdown-with-count.disabled, .dashboard-dropdown .multi-select-dropdown-with-count.disabled {
  background: #f9fafb;
  border-radius: 4px;
  height: 54px;
  width: 100%;
  cursor: not-allowed;
}

.profile-to-add-dropdown .multi-select-dropdown-with-count.disabled:hover, .selected-profile-container .multi-select-dropdown-with-count.disabled:hover, .dashboard-dropdown .multi-select-dropdown-with-count.disabled:hover {
  border: 1px solid #ced4da !important;
}

.selected-profile-container .multi-select-dropdown-with-count.disabled svg, .dashboard-dropdown .multi-select-dropdown-with-count.disabled svg {
  display: none;
}

.dashboard-dropdown>.Multi-Select-BT {
  padding-bottom: 20px;
}

#checkboxlist-enableClient .cbox-container {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

#checkboxlist-enableClient .cbox-container #groove-checkbox {
  margin-right: 10px;
}

#checkboxlist-enableClient .cbox-container>div:nth-child(3) {
  width: 70%;
}

#checkboxlist-enableClient .cbox-container>div:nth-child(3) #groove-checkbox-label {
  color: rgb(181, 192, 202);
}

#checkboxlist-enableClient #groove-checkbox div#groove-checkbox-label {
  line-height: 25px;
}

.deleteToast.saveDraft-toast .toast-header>svg {
  color: rgb(213, 0, 47);
}

.deleteToast.saveDraft-toast .toast {
  border: 1px solid rgb(234, 128, 151);
  background-color: rgb(255, 245, 247);
  font-size: 16px;
}

.deleteToast.saveDraft-toast .toast-header {
  background-color: rgb(255, 245, 247);
  color: rgb(213, 0, 47);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.undo-delete-btn {
  margin-left: 92px;
  text-decoration: underline;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
}

.userRemovedText {
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  margin-bottom: 0px;
}

.added-profiles, .select-profile {
  padding-bottom: 30px;
}

.profile-header {
  color: rgb(45, 58, 75);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.24px;
}

.duplicate-client.svg-inline--fa.fa-w-6 {
  border-radius: 16px;
  height: 16px;
  width: 16px;
  border: 1px solid red;
  color: red;
  margin: 0 5px 0 0;
  padding: 2px;
  cursor: pointer;
}

div[id^=client-] .tooltip-inner,
div[id^=offering-] .tooltip-inner,
div[id^=clientOffering-] .tooltip-inner {
  text-align: left;
}

/* Delete mapped user */
.select-project-modal .header24-semibold-midnight {
  opacity: 1;
  color: rgba(21,24,27,1);
  font-family: "Graphik-Semibold";
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.28px;
  text-align: left;
}

.stepperClose-Alert-Content.dmu-text {
  display: block;
}

.dmu-text > span {
  display: block;
  padding-bottom: 5px;
}

.dmu-text {
  opacity: 1;
  color: rgba(99,115,129,1);
  font-family: "Graphik-Regular";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  padding-bottom: 0 !important;
}

.dmu-client-list {
  color: rgba(161,0,255,1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.delete-mapped-user-buttons {
  background: none !important;
  border: none !important;
}

.configure-client {
  margin: 0 0 0 7px;
}

.configure-client button > * {
  font-family: "Graphik-Medium";
  font-size: 14px;
  margin: 0 5px;
}

.configure-client button svg {
  width: 13px !important;
}

.reporting-year-multi-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 24px;
  cursor: pointer;
  max-width: 500px;
}

.reporting-year-multi-select-dropdown > svg {
  margin-left: auto;
  font-size: 11px;
}

.selected-years {
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.selected-years > div {
  display: -ms-flexbox;
  display: flex;
  margin: 0 4px;
  background: rgb(250, 245, 253);
  border-radius: 16px;
  border: 1px solid rgb(161, 0, 255);
  height: 24px;
  width: 79px;
  padding: 0 6px 0 16px;
  -ms-flex-align: center;
      align-items: center;
  font-size: 12px;
  cursor: context-menu;
}

.remove-selected {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  font-size: 20px;
  width: auto;
  margin-left: auto;
  cursor: pointer;
}

.data-visualisation-container .multi-select-dropdown {
  padding: 0 5px 0 16px;
}

.data-visualisation-container .title-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: -15px;
}
.data-visualisation-container .title-section .value-meter-title {
  width: 100%;
}

.client-stage-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  margin-top: -10px;
}

.client-stage-details > span {
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  color: rgb(145, 158, 171);
  letter-spacing: -0.25px;
  line-height: 16px;
}

div#client-stage-defination {
  z-index: 9999;
}

.dashboard-client-details .client-details-button {
  margin-bottom: 0;
}

#client-stage-defination .tooltip-inner {
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
}

.dIL-dropdowns.db-not-project-details {
  margin-bottom: 10px;
}
.dIL-dropdowns.db-project-details {
  margin-bottom: 40px;
}
.client-stage-label {
  color: #7500c0 !important;
}

.title-section.bottom-margin {
  margin-bottom: 0px;
}
.dashboard-client-details.margin-left {
  margin-left: 40px;
}

div#client-stage-defination {
  margin-top: 3px;
}

.bodyText24-Medium {
  color: rgb(45, 58, 75);
  font-size: 24px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.7px;
}

.bodyText24-Semibold {
  color: rgb(45, 58, 75);
  font-size: 18px;
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
  letter-spacing: -0.28px;
}

.data-visualisation-container .default-view-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.default-view-container .default-view {
  background: rgb(255, 255, 255);
  border: 1px dashed rgb(161, 0, 255);
  border-radius: 16px;
  vertical-align: middle;
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 45rem;
  height: 14rem;
  margin-top: 5rem;
}

.default-view-container .default-view .btn {
  padding: 0.5rem 1.75rem;
  margin-top: 33px;
}

.default-view-container #groove-btn-id .btn-stat-alternate.disabled,
.default-view-container #groove-btn-id .btn-stat-alternate:disabled {
  background: rgb(231, 234, 238);
  border-color: rgb(231, 234, 238);
}

.details-view-section {
  margin-top: -30px;
}

.details-view-section .status-list ul {
  -ms-flex-pack: start;
      justify-content: flex-start;
  padding: 0;
}

.details-view-section .status-list ul li:first-child {
  margin-left: 0;
}

.details-view-section .status-list ul li {
  color: rgb(0, 0, 0);
  font-family: Graphik-Medium-Web;
}

.dot.dot-purple,
.bar-purple .progress-bar {
  background: rgb(117, 0, 192);
}

.dot.dot-blue,
.bar-blue .progress-bar {
  background: rgb(133, 215, 255);
}

.dot.dot-gray,
.bar-gray .progress-bar {
  background: rgb(196, 205, 213);
}

.visualization-collapse.accordion .card {
  margin-bottom: 24px;
  border-radius: 5px !important;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

.visualization-collapse.accordion .card:last-child {
  margin-bottom: 0px;
}

.visualization-collapse.configure-client-collapse .card-header {
  padding: 20px 25px;
}

.visualization-collapse.configure-client-collapse .card-body {
  padding: 0px 15px 20px 15px;
}
.program-box-container {
  /* max-height: 400px; */
  overflow-y: auto;
  overflow-x: hidden;
  /* padding: 5px 5px 30px; */
  overflow: visible;
  position: relative;
  margin-top: 20px;
}

.show-project-details.program-box-container {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 5px;
  overflow: visible;
}

.program-box-container > .modal-container {
  margin: 0;
  top: -130px;
  left: -18px;
}

.tab-component-content > .program-box-container {
  border-top: 1px solid rgb(181, 192, 202);
}

.tab-component-content > .program-box-container:first-child {
  border-top: none;
}

.program-box {
  padding: 15px;
}

.program-box-container .program-box:last-child {
  border-bottom: none;
}

.program-box-title svg,
.program-box-title-colored svg {
  font-size: 8px !important;
  margin-left: -1px;
}

.program-box-title > .program-box-title-container,
.program-box-title-colored > .program-box-title-container {
  width: 69%;
}

.program-box-title > .dashboard-button-container,
.program-box-title-colored > .dashboard-button-container {
  width: 24%;
  margin-bottom: auto;
}

.program-box-title > div > button:hover,
.program-box-title-colored > div > button:hover {
  background: rgb(161, 0, 255) !important;
}

.program-box-container .program-box-title > div > button:hover > #btn-icon-info > svg.fa-info,
.program-box-container .program-box-title > div > button:hover > span,
.program-box-container
  .program-box-title-colored
  > div
  > button:hover
  > #btn-icon-info
  > svg.fa-info,
.program-box-container .program-box-title-colored > div > button:hover > span {
  color: #fff !important;
}

.program-box-container .program-box-title > div > button:hover > #btn-icon-info,
.program-box-container .program-box-title-colored > div > button:hover > #btn-icon-info {
  border-color: #fff;
}

.program-box-title-colored {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  /* background: rgb(255, 251, 238);
  border: 0.5px solid rgb(255, 200, 44); */
  border-radius: 0px;
  /* border-left-color: rgb(255, 200, 44); */
  border-left-width: 8px;
  padding: 5px 10px;
}

.program-box-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  /* background: rgb(251, 245, 255); */
  border-radius: 0px;
  /* border: 0.5px solid rgb(227, 209, 255);
  border-left-color: rgb(222, 193, 255); */
  border-left-width: 8px;
  padding: 5px 10px;
}

#boi-no-aggre {
  width: 28px;
  height: 19px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.program-box-title-label #dashboard-info-icon > .svg-container > svg {
  font-size: 14px !important;
}

.program-box-title h5,
.program-box-title-colored h5 {
  color: rgb(45, 58, 75);
  max-width: 16.25rem;
  word-break: break-word;
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin: 0 10px 0 0;
}

.program-box-title #groove-btn-id .btn-outline-stat-alternate#info-details,
.program-box-title-colored #groove-btn-id .btn-outline-stat-alternate#info-details {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(161, 0, 255);
  border-radius: 16px;
  line-height: 13px;
  padding: 4px 7px;
  width: 115px;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-flex-align: center;
      align-items: center;
  float: right;
}

.program-box-title #info-details span,
.program-box-title-colored #info-details span {
  color: rgb(161, 0, 255);
  font-size: 10px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 12px;
}

.program-box-title #groove-btn-id > button > *,
.program-box-title-colored #groove-btn-id > button > * {
  font-size: 10px;
  margin: 0 2px;
}

#info-details #btn-icon-info {
  border: 1px solid rgb(161, 0, 255);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  letter-spacing: 0;
  line-height: 14px;
  font-family: 'Graphik-Regular';
  font-weight: 400;
}

.dashboard-button-container #groove-btn-id .btn-outline-stat-alternate.hover > .svg-container > svg,
.dashboard-button-container
  #groove-btn-id
  .btn-outline-stat-alternate:hover
  > .svg-container
  > svg {
  -webkit-transition: color 0.1s ease-in;
  transition: color 0.1s ease-in;

  color: rgb(161, 0, 255) !important;
}

.program-box p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  margin: 0 5px 30px 0px;
}

.program-progress {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: rgb(156, 106, 222);
  font-size: 10px;
  font-family: 'Graphik-Medium-Web';
  font-weight: 500;
  letter-spacing: -0.16px;
  margin-bottom: 8px;
}

.reverse .program-progress,
.reverse .xaxis-label {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}

.reverse .xaxis-label span {
  text-align: left !important;
}

.reverse .xaxis-label span > div > div {
  position: relative;
  left: -100%;
}

.reverse .xaxis-label > span:first-child > div > div {
  left: 0;
}

.program-progress.has-negative {
  margin-left: 50%;
}

.program-progress.has-negative.negative-val {
  margin-right: 50%;
  margin-left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}

.negative-val > .deliveredValue-progress,
.negative-val > .identifiedValue-progress {
  margin-left: 5px;
}

.program-progress > div {
  line-height: 1;
}

.program-progress .progress {
  height: 0.625rem;
}

.identifiedValue-progress,
.deliveredValue-progress {
  margin-right: 5px;
}

.program-progress .identifiedValue-progress .progress-bar {
  border-radius: 0px 6.5px 6.5px 0px;
}

.program-progress .deliveredValue-progress .progress-bar {
  border-radius: 0px 6.5px 6.5px 0px;
}

.xaxis-line {
  width: 100%;
  border-bottom: 1px solid rgb(181, 192, 202);
}

.xaxis-label {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.xaxis-label span {
  margin-left: -2px;
  color: rgb(0, 0, 0);
  font-size: 11px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.17px;
  margin-top: 5px;
  position: absolute;
  text-align: right;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.xaxis-label span > div {
  text-align: right !important;
  display: inline-block;
}

.xaxis-label span > div > div {
  margin-right: -50%;
  display: inline-block;
}

.xaxis-label span:first-child > div > div {
  margin: 0;
}

.xaxis-label span:last-child {
  min-width: auto;
}

.modal-container {
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 1;
  width: 100%;
}

.dashboard-projectDetails-modal {
  background: rgb(249, 250, 251);
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 23px 25px 36px;
}

.projectDetails-modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 24px;
}

.projectDetails-modal-header h5 {
  width: calc(100% - 50px);
  height: 14px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.22px;
  border-left-color: rgb(255 255 255 / 38%) !important;
  border-left: groove;
  border-left-width: 2px;
  padding-left: 7px;
  margin-right: 5px;
  margin-left: 5px;
  min-height: 16px;
}
.projectDetails-modal-header h4 {
  width: 28px;
  height: 19px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 3px;
}

.projectDetails-modal-header .fa-times {
  color: rgb(145, 158, 171);
}

.project-tab-content .project-desc {
  color: rgb(45, 58, 75);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 17px;
  margin-bottom: 24px;
}

.project-desc-chart {
  width: 50%;
  padding: 30px 0;
  margin-bottom: -6px;
  height: 300px;
}

/* .project-tabs-list button:active span, .project-tabs-list button:focus span, .project-tabs-list button:active, .project-tabs-list button:focus {
  position: absolute;
} */

.dim-tabs > div > div > div > div {
  margin-bottom: -7px !important;
  margin: 0 -15px;
}

.dim-tabs .MuiTabs-scroller.MuiTabs-scrollable > div {
  margin: 0 !important;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.project-detail-tabs button > span:first-child {
  text-transform: none;
  text-transform: initial;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 0 10px !important;
}

.project-detail-tabs button span {
  font-size: 0.75rem;
  padding: 0;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.21px;
  text-align: center;
  word-wrap: break-word;
  display: block;
  max-width: 200px;
}

.project-detail-tabs button > span:nth-child(2) {
  display: none;
}

.project-detail-tabs button {
  border: 3px solid transparent;
}

.project-detail-tabs button > svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.project-detail-tabs .MuiTabs-scrollButtons > svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.project-detail-tabs button[aria-selected='true'] {
  border-bottom-color: #a100ff;
}

.project-detail-tabs > div > div > span {
  display: none;
}

.project-detail-tabs span.MuiTabs-indicator {
  display: none;
}

@-webkit-keyframes chartjs-render-animation {
  from {
    opacity: 0.99;
  }
  to {
    opacity: 1;
  }
}

@keyframes chartjs-render-animation {
  from {
    opacity: 0.99;
  }
  to {
    opacity: 1;
  }
}

.chartjs-render-monitor {
  -webkit-animation: chartjs-render-animation 1ms;
          animation: chartjs-render-animation 1ms;
  cursor: pointer;
}

.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}

.chartjs-size-monitor-expand > div {
  position: absolute;
  width: 1000000px;
  height: 1000000px;
  left: 0;
  top: 0;
}

.chartjs-size-monitor-shrink > div {
  position: absolute;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0;
}

.data-visualisation-container .statusDropdown {
  width: 440px;
}

.data-visualisation-container .statusDropdown .css-14s1juc-control,
.statusDropdown .css-1csqycf-control {
  width: 420px !important;
}

.data-visualisation-container .statusDropdown .css-144vaf5-menu {
  width: 420px;
}

.data-visualisation-container .reportingYrDropdown .css-144vaf5-menu {
  width: 100px;
}

/* .recharts-surface > .recharts-layer.recharts-pie:nth-child(2),
.recharts-surface > .recharts-layer.recharts-pie:nth-child(4),
.recharts-surface > .recharts-layer.recharts-pie:nth-child(5) {
  cursor: pointer;
} */

.recharts-surface
  > .recharts-layer.recharts-pie:nth-child(2).recharts-layer
  .recharts-pie-sector
  > path[fill='#A9A9A9'],
.recharts-surface
  > .recharts-layer.recharts-pie:nth-child(4).recharts-layer
  .recharts-pie-sector
  > path[fill='#A9A9A9'],
.recharts-surface
  > .recharts-layer.recharts-pie:nth-child(5).recharts-layer
  .recharts-pie-sector
  > path[fill='#A9A9A9'] {
  cursor: not-allowed;
}

#dashboard-info-icon {
  display: inline-block;
}

#dashboard-tooltip-right {
  z-index: 3010;
}

#dashboard-tooltip-right .tooltip-inner {
  -webkit-box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  background-color: #fff;
  color: #000;
  padding: 15px 15px;
  max-width: 500px;
}

#dashboard-tooltip-right.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff !important;
}

.dashboard-details-wrap h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
  text-align: left;
}

.dashboard-details-wrap p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 14px;
  word-break: break-word;
  text-align: left;
}

#dashboard-tooltip-right .tooltip-parameter {
  margin-right: 20px;
}

#tooltiptext-dashboard {
  top: 35px !important;
  clear: both !important;
  left: -260px !important;
  -webkit-box-shadow: 0 0 2px black;
          box-shadow: 0 0 2px black;
}

.program-box-title-label {
  color: rgb(99, 115, 129);
  word-break: break-word;
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin: 0 0 0 0;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0px 10px;
}

#prog-view {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 23px;
  letter-spacing: -0.25px;
  display: inline;
  width: auto;
  padding-right: 10px;
  border-right: outset;
  border-right-width: 1.5px;
  border-right-color: rgb(255 255 255 / 30%);
  margin-right: 4px;
  margin-bottom: auto;
}
#prog-view-colored {
  color: rgb(99, 115, 129);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  height: 23px;
  letter-spacing: -0.22px;
  display: inline;
}

.aggregation-label {
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  border-left: 1px solid;
  white-space: nowrap;
  margin-left: 5px;
  padding: 0px 10px;
}

.program-box-title > div.dashboard-button-container:empty,
.program-box-title-colored > div.dashboard-button-container:empty {
  width: 0%;
}

.program-box-title > div.no-aggregation,
.program-box-title-colored > div.no-aggregation {
  width: 22vw;
}

.db-numerical-label {
  display: -ms-flexbox;
  display: flex;
  margin: 0 5px;
}

/* SVM Wheel summary css starts here */

.align-piechart {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  max-width: 513px;
  margin: auto;
}

.graph-view-section {
  height: 890px;
  overflow: hidden;
}

.svmOverview-page {
  padding-top: 10px;
}

.svmOverview-page #download {
  float: right;
  color: #637381;
  font-size: 12px;
  margin-top: -29px;
}

.svmOverview-page #hrTop {
  margin: 0 0 10px 0;
  border-top: 2px solid rgb(166, 166, 166);
}

span#download {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  width: 100%;
}

span#download > img {
  margin-right: 10px;
}

.col-lg-3 .dimension-card {
  margin-bottom: 24px;
}

.col-lg-3 .dimension-card:last-child {
  margin-bottom: 5px;
}

.dm-card-header {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 16px;
  padding: 6px 12px;
  width: 80%;
  border-radius: 4px;
  position: relative;
  margin-left: 8px;
  z-index: 1;
}

.dm-card-box {
  background: rgb(249, 250, 251);
  border-radius: 8px;
  border: 1px solid rgba(151, 151, 151, 0.36);
  margin-top: -14px;
  padding: 14px 12px 0 0;
  max-height: 430px;
  overflow-y: auto;
}

.dm-card-box-disabled {
  padding: 5px 14px;
  margin-left: 8px;
  width: 80%;
  height: 29px;
  background: #e2e2e2;
  border-radius: 2px;
  font-size: 13px;
  color: #282529;
}

.dm-card-details {
  padding: 0 0 4px 12px;
  max-height: 300px;
  overflow: hidden;
}

.dm-card-details ul {
  padding-left: 16px;
  margin-bottom: 0;
}

.dm-card-details h6 {
  color: rgb(74, 33, 93);
  font-size: 13px;
  line-height: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.23px;
  margin: 8px 0px;
}

.dm-card-details ul li {
  word-break: break-word;
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 13px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.21px;
  margin-bottom: 10px;
}

.dm-card-details ul li p,
.dm-card-details ul li div:first-child {
  color: rgb(40, 37, 41);
  font-family: Graphik-Medium;
  font-weight: 500;
  margin-bottom: 0;
  /* max-width: 82%; */
  display: inline-block;
  /* vertical-align: middle; */
  vertical-align: text-top;
}

.dm-card-details ul li p b,
.dm-card-details ul li div:first-child p b {
  font-family: Graphik-Semibold;
  font-weight: 600;
}

.dm-card-details ul li p b:first-child,
.dm-card-details ul li div:first-child p:nth-child(2) b {
  color: rgb(200, 154, 21);
  margin-left: 2px;
}

.dm-card-details ul li p b:nth-child(2),
.dm-card-details ul li div:first-child p:nth-child(3) b {
  color: rgb(12, 127, 170);
  margin-left: 2px;
}

.view-more-link {
  color: rgb(150, 72, 155);
  font-size: 12px;
  line-height: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
  /* margin: 10px 0 16px 28px; */
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 100%;
  margin-bottom: 15px
}

.btn-knowMore-container {
  position: absolute;
  margin-left: calc(50% - 78px);
  margin-top: calc(50% - 180px);
  margin-top: 0 !important;
  top: unset !important;
  bottom: 0;
}

.btn-knowMore {
  left: calc(50% - 60px);
}

.btn-knowMore-container #groove-btn-id > button > * {
  font-size: 14px;
}

#legend-container {
  margin-top: 10px;
  left: calc(50% - 62.66px);
  position: relative;
  font-size: 10px;
}

#legend-container #legend-identified {
  color: #f49342;
  margin-right: 3px;
}

#legend-container #legend-delivered {
  color: #26c1c9;
  margin-left: 11px;
  margin-right: 3px;
}
.dashboard-wheel-spacing {
  fill: #f9fafb;
  -webkit-transform-origin: calc(100% / 2) calc(28% + 7px / 2);
          transform-origin: calc(100% / 2) calc(28% + 7px / 2);
}
.dashboard-wheel-vd-spacing {
  fill: #f9fafb;
  -webkit-transform-origin: calc(100% / 2) calc(28% + 7px / 2);
          transform-origin: calc(100% / 2) calc(28% + 7px / 2);
}
@media (min-width: 992px) {
  .svmOverview-page .col-lg-3 {
    -ms-flex: 0 0 29%;
        flex: 0 0 29%;
    max-width: 29%;
  }
  .svmOverview-page .col-lg-6 {
    -ms-flex: 0 0 42%;
        flex: 0 0 42%;
    max-width: 42%;
  }
}

@media (width: 1536px) {
  .btn-knowMore-container {
    top: 560px;
  }
  .dm-card-box {
    max-height: 489px;
  }
}

@media (width: 1280px) {
  .btn-knowMore-container {
    top: 455px;
  }
}

/* SVM Wheel summary css ends here */

.db-back-icon {
  font-size: 24px;
  color: #637381;
  cursor: pointer;
}

.program-cumulative-container {
  width: 49%;
  height: 180px;
  position: relative;
}

.prog-UOM {
  width: 63px;
  height: 12px;
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  text-align: right;
  letter-spacing: -0.25px;
  line-height: 12px;
  position: relative;
  left: -28px;
  top: -10px;
}

.cumulative-container {
  padding: 10px 20px 10px 10px;
  position: relative;
  left: -10px;
}

.title-container {
  width: 100%;
  margin-bottom: 20px;
}

.cumulative-container .title-container {
  margin-left: 7px;
  -ms-flex-direction: column;
      flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

.title-container .title {
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
  width: 97px;
  margin: 0;
}

.title-container .fy {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
  width: 100%;
}

.graph-container,
.project-tab-content > div.charts {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.graph-left {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.graph-right {
  height: 180px;
  width: 100%;
  padding: 0 10px 0px 10px;
  /* margin: 0; */
}

.graph-right .trend-header {
  width: 100%;
  height: 18px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  position: relative;
  top: -55px;
}

.graph-right .program-trend-chart {
  height: 90%;
  width: 45%;
  padding-left: 15px;
}

.graph-right .no-trend-label {
  height: 18px;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  position: relative;
  top: 35%;
  /* left: 30%; */
  text-align: center;
}
.graph-right #program-trend-chart span.recharts-legend-item-text {
  font-size: 12px;
}

.graph-right #program-trend-chart p.recharts-tooltip-label {
  font-size: 14px !important;
  font-family: Graphik-Medium;
  font-weight: 500;
  color: rgb(21, 24, 27);
}

.graph-right #program-trend-chart .recharts-tooltip-item span.recharts-tooltip-item-name,
.graph-right #program-trend-chart .recharts-tooltip-item span.recharts-tooltip-item-separator {
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  color: rgb(21, 24, 27) !important;
}

.graph-right #program-trend-chart .recharts-tooltip-item span.recharts-tooltip-item-value,
.graph-right #program-trend-chart .recharts-tooltip-item span.recharts-tooltip-item-unit {
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.comment-section {
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0px;
  padding: 0 0px 0px 5px;
  width: 100%;
}

.comment-section .comment {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.22px;
  word-break: break-word;
  vertical-align: middle;
  margin: 5px;
  -ms-flex: 1 1;
      flex: 1 1;
}

.comment-section .comment-btn {
  color: rgb(1, 142, 255);
  font-family: Graphik-Regular !important;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 14px;
  min-width: 100px;
  vertical-align: middle;
  margin: 5px;
}

.commentary-holder {
  word-break: break-all;
}
.dot.square-palette-amber {
  background: rgb(255, 200, 44);
  border-radius: 0px;
  height: 8px;
  width: 8px;
}

.dot.square-palette-purple {
  background: rgb(111, 59, 171);
  border-radius: 0px;
  height: 8px;
  width: 8px;
}

.asterisk-notif {
  width: 112px;
  height: 12px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  font-style: italic;
  letter-spacing: -0.25px;
  line-height: 12px;
}
/* .dot.square-palette-blue {
  background: rgb(102, 187, 255);
  border-radius: 0px;
  height: 8px;
  width: 8px;
} */

div[role='tabpanel'] > div {
  /* max-height: 500px; */
  /* overflow-x: hidden; */
  padding: 0px;
  /* overflow-y: auto; */
  position: relative;
  left: 18px;
}

.vertical-divider {
  /* width: 1px; */
  height: 185px;
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  padding: 0 0.5px;
}

.dashboard-new-info-wrap {
  width: 35px;
}

.dashboard-new-info-wrap img.new-info-icon {
  -webkit-transform: scale(1);
          transform: scale(1);
  margin-top: 0;
  margin-left: -6px;
}

/* COMMENTARY */

.comment-section #addComment,
.comment-section #viewHistory {
  -ms-flex-align: center;
      align-items: center;
  padding: 0;
  float: right;
}

.comment-section #addComment span,
.comment-section #viewHistory span {
  font-size: 10px;
  font-family: Graphik-Regular;
  letter-spacing: -0.21px;
  line-height: 12px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-top: 2px;
}

.comment-section #btn-icon-plus {
  border: 1px solid #a100ff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  letter-spacing: 0;
  line-height: 10px;
  font-family: 'Graphik-Regular';
  font-weight: 400;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.comment-section .history-btn #groove-btn-id > button > * {
  margin: 0;
}

.project-tabs-list.no-aggregation-logic .project-tab-content {
  display: -ms-flexbox;
  display: flex;
}

.project-tabs-list.no-aggregation-logic .project-desc-chart {
  padding: 30px 0px 0px 0px;
  height: 250px;
  width: 40%;
}
#program-trend-chart svg.recharts-surface {
  overflow: visible;
}

#dashboard-tooltip-right.tooltip.show {
  opacity: 1;
}

.tspanLabel {
  font-family: 'Graphik-Regular-Web';
  font-weight: 400;
  font-size: 8px;
  visibility: hidden;
}

#dashboard-tooltip-right table tr th:first-child,
#dashboard-tooltip-right table tr td:first-child {
  padding-left: 0;
  padding-right: 20px !important;
}

.chart-col {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 50%;
}
.vertical-divider {
  display: block;
}
.graph-left {
  padding-right: 10px;
}

.projectDetails-modal-body .graph-container,
.project-tab-content > div.charts {
  -ms-flex-align: center !important;
      align-items: center !important;
}

@media (max-width: 1024px) {
  .projectDetails-modal-body .graph-container,
  .project-tab-content > div.charts {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .project-desc-chart {
    width: 100%;
  }
  .global-project-desc-chart {
    width: 100%;
  }
}
.taking-screenshot {
  width: 100% !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: row !important;
      flex-direction: row !important;
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

/*Trend chart y-axis*/
.graph-right tspan {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 1.2;
  opacity: 0.8;
}
.project-tabs-list #groove-select-dropdown div#selectmenu-anchor {
  border-radius: 8px !important;
  background: rgb(250, 245, 253) !important;
  border: 1px solid rgb(161, 0, 255);
}
.project-tabs-list #groove-select-dropdown div#selectmenu-options div.dropdown-list {
  max-height: 145px;
  color: #fff !important;
  width: -webkit-fill-available;
  margin: -10px 15px 5px 15px;
}
.project-tabs-list .dropdown-item:hover {
  background-color: #f4f6f8 !important;
  color: rgb(32, 31, 31) !important;
}
.project-tabs-list #groove-select-dropdown div#selectmenu-anchor .display-field.field-label {
  color: rgb(99, 115, 129) !important;
  font-family: Graphik-Medium;
  font-size: 11px;
}
.project-tabs-list
  #groove-select-dropdown
  div.selectmenu-options
  .searchable-dropdown-content
  .searchable-dropdown-input {
  width: 80%;
}
.project-tabs-list .body-small {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
}
.project-tabs-list .body-regular {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
  width: 458px;
}
.project-tabs-list #groove-select-dropdown #selectmenu-dropdown-icon {
  margin-top: 15px;
  margin-right: 3px;
}
.project-tabs-list #groove-select-dropdown svg {
  color: #212b36;
  width: 13px;
}

.summation {
  background: rgb(244, 251, 255);
  padding: 5px 10px;
  border-radius: 16px;
  opacity: 1;
  border: none;
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  height: 18px;
  letter-spacing: 0.25px;
  line-height: 11px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.sum-dim {
  top: -2px;
  position: relative;
  margin-left: 7px;
}

.sum-subdim {
  float: right;
  margin-top: -2px;
}

.project-tabs-list.no-aggregation-logic .comment-section-history {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.legend-list {
  /* position: relative;
  top: -15px; */
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.legend-list-item {
  /* color: rgb(145, 158, 171); */
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 10px;
  font-weight: normal;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding-right: 5px;
  white-space: nowrap;
}

.dot-square-palette-purple {
  background: rgb(111, 59, 171);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  /* position: relative;
  top: 3px; */
}
.dot-square-palette-gray {
  background: rgb(145, 158, 171);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  /* position: relative;
  top: 3px; */
}
.dot-square-palette-blue {
  background: rgb(102, 187, 255);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  /* position: relative;
  top: 3px; */
}

/* Global Dashboard CSS */
.global-dashboard-rs {
  padding: 50px 0 0 24px;
  max-width: calc(100% - 160px);
  margin-left: 0 !important;
}

.default-view-container .default-view-global {
  vertical-align: middle;
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 45rem;
  height: 14rem;
  margin-top: 5rem;
}

.global-graph-left {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.global-program-cumulative-container {
  width: 95%;
  height: 180px;
  position: relative;
}

.global-legend-list {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.global-legend-list-item {
  /* color: rgb(145, 158, 171); */
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding-right: 5px;
  white-space: nowrap;
}

.global-dot-square-palette-purple {
  background: rgb(161, 0, 255);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
}

.global-dot-square-palette-amber {
  background: rgb(255, 200, 44);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 3px;
}
.custom-tooltip {
  min-width: 150px;
}

.custom-tooltip-row-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  /* align-items: center; */
}

.custom-tooltip-row-label {
  width: 45%;
}

.custom-tooltip-row-value {
  width: 55%;
  white-space: nowrap;
  /* overflow: hidden; */
}

.statusDropdown.disabled > .multi-select-dropdown:hover {
  cursor: not-allowed;
}

.global-project-desc-chart {
  width: 100%;
  padding: 30px 0;
  margin-bottom: -6px;
  height: 300px;
}
.project-tabs-list.no-aggregation-logic .global-project-desc-chart {
  padding: 30px 0px 0px 0px;
  height: 250px;
  width: 40%;
}

@media screen and (min-width: 480px) {
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 100px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  #piechartContainer {
    height: 992px;
  }
  .btn-knowMore-container {
    margin-top: 100px;
  }
}

@media screen and (min-width: 768px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 100px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 992px;
  }
  .btn-knowMore-container {
    margin-top: 100px;
  }
}

@media screen and (min-width: 840px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 200px !important;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 992px;
  }
  .btn-knowMore-container {
    margin-top: 100px;
  }
}

@media screen and (min-width: 992px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control,
  .statusDropdown .css-144vaf5-menu {
    width: 350px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 685px;
  }
  .btn-knowMore-container {
    margin-top: calc(50% - 100px);
  }
}

/* Dashboard Responsive*/
@media screen and (max-width: 1024px) {
  /* Stage 3 */
  .col-md-5.graph-view-section,
  .col-md-7.details-view-section {
    -ms-flex: none !important;
        flex: none !important;
    max-width: 100% !important;
  }
  .graph-view-section {
    height: 500px;
  }
  .graph-view-section #piechartContainer #wheel-chart-container .recharts-wrapper,
  .graph-view-section #piechartContainer #wheel-chart-container .recharts-wrapper svg {
    height: 800px !important;
  }
}

@media screen and (min-width: 1200px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 380px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 992px;
  }
  .btn-knowMore-container {
    margin-top: calc(50% - 150px);
  }
}

@media screen and (min-width: 2560px) {
  /* Stage 1 */
  .data-visualisation-container .statusDropdown .css-14s1juc-control,
  .statusDropdown .css-1csqycf-control {
    width: 480px !important;
  }
  .data-visualisation-container .statusDropdown {
    width: auto;
  }
  /* Stage 2 */
  #piechartContainer {
    height: 1000px;
  }
  .btn-knowMore-container {
    margin-top: calc(50% - 340px);
  }
}

/* chart tooltip css */
.dot-square-palette-aqua {
  background: rgb(38, 193, 201);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 4px;
}

.dot-square-palette-amber {
  background: rgb(244, 147, 66);
  border-radius: 0px;
  height: 8px;
  width: 8px;
  margin-right: 4px;
}

.custom-tooltip .global-legend-list-item {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.25px;
  line-height: 16px;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
  padding-right: 5px;
  white-space: nowrap;
}

.custom-tooltip .global-legend-list {
  width: 100%;
  background: rgb(21, 24, 27);
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 52px;
  display: inline-block;
  padding: 10px;
  /* text-align: center; */
}

.tooltip-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.chart-tooltip {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 0px;
}

.tooltip-body > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.chartjs-tooltip > table {
  width: 100%;
}

.tooltip-row-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
}

.tooltip-row-label {
  width: 60%;
}

.tooltip-row-value {
  width: 40%;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  /* overflow: hidden; */
}

.tooltip-row-value > div {
  padding-left: 4px;
}

.disclaimer-BOI-UOM {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  width: 195%;
  height: 50px;
  background: rgb(251, 245, 255);
  border: 1px solid rgb(227, 209, 255);
  border-radius: 8px;
  padding: 0 10px;
  line-height: 20px;
  color: rgb(161, 0, 255);
  position: relative;
  left: 5px;
  top: 10px;
}

.disclaimer-BOI-UOM .disclaimer-usd {
  color: rgb(117, 0, 192);
  font-family: Graphik-Medium;
  font-size: 13px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.22px;
  line-height: 20px;
  padding-bottom: 10px;
  display: inline-table;
  margin-top: 11px;
}

.disclaimer-info-icon {
  margin-right: 8px;
  border: 2px solid rgb(117, 0, 192);
  border-radius: 50px;
  height: 20px;
  width: 20px;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.disclaimer-info-icon .svg-container {
  margin: 0 auto;
  width: auto;
}
.exclamation-icon-dashboard {
  color: #a100ff;
  height: 10px;
}

.dashboi-header {
  width: -webkit-fill-available;
}

.subtab-uom {
  background: rgb(181, 192, 202);
  border-radius: 0px;
  height: 1px;
  width: 100%;
  left: 0px;
  position: absolute;
}

.program-box-container .visualization-collapse.accordion .card:last-child {
  border-left: 8px solid rgb(181, 192, 202);
}

.program-box-container .visualization-collapse.configure-client-collapse .card-header {
  padding: 0px 15px 0px 5px !important;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.program-box-container .configure-client-collapse .card-header > button {
  -ms-flex-align: baseline;
      align-items: baseline;
  -moz-text-align-last: left;
       text-align-last: left;
  width: 4%;
}

/* .dashboard-button-container {
  position: absolute;
  right: 47px;
  top: 14px;
} */

.collapse.show ~ div.card-header #info-details div.svg-container {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.accordion-button-dashboard {
  position: absolute;
  right: 1px;
  top: 14px;
}

.program-box-container .dimension-header-container {
  margin: 0;
}

.dashboard-projectDetails-modal #scrollable-auto-tabpanel-0 > div {
  left: -10px;
}
#dashboard-info-icon .custom-boi {
  margin-top: 0;
}

.program-box-container .visualization-collapse.configure-client-collapse .card-body {
  padding: 0px 10px 20px 10px;
}

.project-detail-tabs button[aria-selected='true'] .subtabs-UOM {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
}
.project-detail-tabs button[aria-selected='false'] .subtabs-UOM {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
}

.project-tabs-list button.subtabs-UOM-Highlight {
  min-width: 64px;
}
#btn-icon-history .svg-inline--fa {
  vertical-align: 1 !important;
}
#btn-icon-history.svg-container {
  -webkit-transform: none;
          transform: none;
}

.data-visualisation-container .dm-card-details h6.dashboard-subdim {
  color: rgb(70, 0, 115);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.data-visualisation-container .dm-card-details .dashboard-boi .card{
  margin-bottom: 10px;
  padding: 10px !important;
}

.data-visualisation-container .dm-card-details .boi-title {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.data-visualisation-container .dm-card-details .comment-container {
  margin-bottom: 5px;
}

.data-visualisation-container .dm-card-details .boi-dahsboard-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 10px;
}

.data-visualisation-container .dm-card-details .boi-detail {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.data-visualisation-container .dm-card-details .boi-dahsboard-details span {
  -ms-flex: 1 1;
      flex: 1 1;
}

.data-visualisation-container .dm-card-details .boi-dahsboard-details .uom-container{
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px;
  border: none;
  margin-top: 0.1em;
  margin-right: 20px;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 50px;
  text-align: center;
  display: inline-block;
  margin-left: 3px;
}

.data-visualisation-container .dm-card-details .comment-container {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
}

/* QC START */
.data-visualisation-container.svm-tool .dqc-modal-loader > div {
  margin-left: 47.5% !important;
  margin-top: 19.5% !important;
}

.data-visualisation-container.svm-tool .dqc-modal-loader {
  height: 95% !important;
  width: 97.5% !important;
}

.data-visualisation-container.svm-tool #mt-count-tooltip div[class*='-container'] > div[class*='-control'] > div:first-child {
height: 50px !important;
}

.data-visualisation-container.svm-tool span#download>img {
  height: 38px;
  padding: 8px;
  width: 32px !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
}
/* QC END */
/* Master Config Landing */
.data-ingestion-placeholder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  color: rgb(181, 192, 202);
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.28px;
  padding: 50px 0 !important;
}

.data-ingestion-placeholder > img {
  width: 79px;
}

.data-ingestion-placeholder > span {
  color: rgb(181, 192, 202);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.38px;
  margin: 32px 0 24px 0;
}

.rvd-view-select {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  margin-bottom: 10px;
  /* margin-top: 10px; */
  -ms-flex-align: center;
      align-items: center;
}

.rvd-client-details-btn{
  -ms-flex-pack: justify;
      justify-content: space-between;
}

/* uncomment for corebeat */
/* .rvd-program-card-box.error{
  background: rgb(255, 245, 247);
  border-radius: 8px;
  animation: blink 2s step-end infinite;
}

@keyframes blink{
  50% {
    border: 1px solid rgb(213, 0, 47);
  }
} */

.dIL-actionrow {
  display: -ms-flexbox;
  display: flex;
  /* align-items: center; */
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-bottom: 36px;
}

.dIL-dropdowns {
  display: -ms-flexbox;
  display: flex;
}

.view-types {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
}

.view-types img {
  margin-left: 1rem;
  cursor: pointer;
}

.view-unselected {
  -webkit-filter: invert(50%) sepia(3%) saturate(1647%) hue-rotate(169deg) brightness(120%) contrast(86%);
          filter: invert(50%) sepia(3%) saturate(1647%) hue-rotate(169deg) brightness(120%) contrast(86%);
}

.view-selected {
  -webkit-filter: brightness(1) sepia(3) hue-rotate(205deg) saturate(42) !important;
          filter: brightness(1) sepia(3) hue-rotate(205deg) saturate(42) !important;
}

.statusDropdown {
  margin-right: 24px;
}

.statusDropdown .multi-select-dropdown,
.reportingYrDropdown .multi-select-dropdown,
.statusDropdown .multi-select-label,
.reportingYrDropdown .multi-select-label {
  width: inherit;
}

.statusDropdown .css-14s1juc-control,
.statusDropdown .css-1csqycf-control {
  width: 160px;
}

.statusDropdown .css-144vaf5-menu {
  width: 170px;
  position: absolute;
  right: 0;
}

.statusDropdown .css-dvua67-singleValue,
.reportingYrDropdown .css-dvua67-singleValue {
  color: rgb(161, 0, 255);
}

.statusDropdown .multi-select-dropdown .css-oq3rz7-multiValue {
  width: auto;
}

.dIL-dropdowns .reportingYrDropdown .css-14s1juc-control,
.dIL-dropdowns .reportingYrDropdown .css-1csqycf-control {
  width: 100px;
}

.dIL-dropdowns .statusDropdown .multi-select-dropdown [class*='control'] {
  width: 380px;
}

.rvd-sort-wrapper {
  position: relative;
  top: 20%;
  margin: 0 5px;
}
.rvd-view-select.landing-img-box {
  margin: 0 0 0 1.5rem !important;
}

.reportingYrDropdown .css-144vaf5-menu {
  width: 190px;
  position: absolute;
  right: 0;
}

.card-view-container .accordion .card-header {
  background: rgb(255, 255, 255);
  border-bottom: none;
  border-radius: 8px !important;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
  padding: 23px 36px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  cursor: pointer;
  height: 72px;
}

.card-view-container .accordion .card {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px !important;
  margin-bottom: 24px;
}

.card-view-container .accordion .card-body {
  padding: 0px 30px 10px 30px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #c4cdd5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.dot-awaitingApproval {
  background: #ffc82c;
}

.dot-approved {
  background: #7ec344;
}

.dot-rejected {
  background: #d5002f;
}


.status-list ul {
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-bottom: 20px;
}

.status-list ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  margin-left: 24px;
}

.accordion-toggle:after {
  font-family: 'FontAwesome';
  content: '\F078';
  float: right;
}

.accordion-opened .accordion-toggle:after {
  content: '\F054';
}

.data-IngestionContainer .client-details-row {
  margin: 20px 0px;
}

.data-IngestionContainer .client-details-row:first-child {
  margin-top: 0px;
}

.data-IngestionContainer .accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid rgb(223, 227, 232);
}

.data-IngestionContainer .css-1nszmjf {
  color: #a100ff!important;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

/* .data-IngestionContainer .collapse.show ~ div.card-header {  
  border-bottom: 1px solid rgb(231, 234, 238);
} */

.card-outcome {
  height: 120px;
  /* width: 200px; */
  display: block;
  width: auto;
  /* vertical-align: top; */
  position: relative;
  margin: 10px 0;
  background: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  cursor: pointer;
}

.card-indicate {
  position: relative;
  padding: 16px;
  height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.card-indicate::before {
  position: absolute;
  content: '';
  background: rgb(181, 192, 202);
  width: 4px;
  height: 100%;
  border-radius: 8px 0px 0px 8px;
  left: 0;
  top: 0;
}

.card-awaitingApproval.card-indicate::before {
  background: #ffc82c;
}

.card-approved.card-indicate::before {
  background: #7ec344;
}

.card-rejected.card-indicate::before {
  background: #d5002f;
}

.card-items {
  text-align: center;
  width: 100%;
}

.card-indicate img {
  width: 20px;
  height: 28px;
  margin-bottom: 16px;
}

.card-indicate p {
  line-height: 12px;
  color: rgb(181, 192, 202);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.19px;
  margin-bottom: 0;
  overflow: hidden;
  width: 100%;
  max-height: 49px;
  line-height: 13px;
}

.table-view-wrap {
  height: 500px;
}

.collapse.show ~ div.card-header div.svg-container {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.table-view-wrap .table-action-buttons > img {
  width: 16px;
  margin: 0 30px 0 0;
  cursor: pointer;
}

.table-view-wrap .cell-status {
  font-family: 'Graphik-Medium-Web';
}

.card-custom-view {
  -ms-flex: 0 0 20% !important;
      flex: 0 0 20% !important;
  max-width: 20% !important;
}

.rdv-label {
  font-size: 14px !important;
}

.system-generated-container .rdv-label {
  white-space: nowrap;
}

.rvd-card {
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 0.75rem;
}

.rvd-card > img:first-child {
  width: 20px;
  /* height: 28px; */
  margin-right: 10px;
}

.rvd-card-detail {
  margin-bottom: 10px !important;
}

.statusDropdown .css-15hpnlr-control {
  background: rgb(252, 251, 251);
  border-radius: 20px;
  border: 1.5px solid #a100ff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #a100ff;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
  max-width: 500px;
  min-height: 0px;
  width: 253px;
}

.statusDropdown .css-15hpnlr-control:hover {
  border: 1.5px solid #a100ff;
}

.statusDropdown .css-2dd3o1-control {
  background: rgb(252, 251, 251);
  border-radius: 20px;
  border: 1.5px solid #a100ff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #a100ff;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
  max-width: 500px;
  min-height: 0px;
  width: 253px;
}

.statusDropdown .css-2dd3o1-control:hover {
  border: 1.5px solid #a100ff;
}

/* r10 changes */
.rvd-program-card-box .program-card-detail > div:first-child {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.rvd-nav-dropdowns{
  display: -ms-flexbox;
  display: flex;
}

.rvd-nav-dropdowns > .rvd-contract-dd-cont,
.rvd-nav-dropdowns > .rvd-program-dd-cont {
  padding-left: 0;
}

.rvd-contract-dd-cont {
  /* width: 17.66667% !important; */
  -ms-flex: 0 0 17.66667% !important;
      flex: 0 0 17.66667% !important;
}

.rvd-card-detail .title-ellip {
  margin-right: 9%;
  padding-top: 10px;
}

.rvd-card-detail > .system-generated-container{
  /* display: flex;
  align-items: center;
  flex: 0 0 10em; */
  width: -webkit-fill-available;
}

.rvd-card-detail .system-generated-container .title-ellip {
  margin-bottom: 0px !important;
}

.rvd-program-card-box .prog-stage{
  padding-top: 7px;
}
.cvm-homepage {
  margin: 0;
  /* min-height: 640px !important; */
}

.cvm-banner {
  padding-bottom: 10px;
}

img.overlay-image {
  width: 505px;
  position: absolute;
  top: 0;
  left: -35px;
}

.cvm-banner > div:first-child {
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(255, 255, 255, 0.2)),
      to(rgba(0, 0, 0, 0.5))
    )
    rgb(161, 0, 255);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(0, 0, 0, 0.5) 100%
    )
    rgb(161, 0, 255);
  border-radius: 0 0 16px 16px;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 15%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 15%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 100px 30px 170px 30px;
  position: relative;
  margin: 0;
}


.cvm-banner > div {
  text-align: center;
  margin: 8px;
}

.cvm-welcome {
  color: #ffffff;
  font-family: "Graphik-Medium-Web";
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 50px;
}

.cvm-verbiage {
  color: #ffffffdb;
  font-family: "Graphik-Regular-Web";
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 75px !important;
}

.modules-cards-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: -170px !important;
}

.modules-cards-container.row > div {
  /* height: 100%; */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.modules-card {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%);
          box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%);
  width:100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  z-index: 1;
  padding: 0 20px;
  position: relative;
}

.modules-card:hover {
  -webkit-box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%),
    0px 0px 0px 2px rgb(161 0 255) inset;
          box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%),
    0px 0px 0px 2px rgb(161 0 255) inset;
}

.module-info {
  display: -ms-flexbox;
  display: flex;
  height: 0px;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-top: 10px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.module-info .svg-container {
  font-size: 14px !important;
}

.module-info:hover ~ .module-long-desc, .module-long-desc:hover {
  display: block;  
}

.module-long-desc {
  position: absolute;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 2px solid rgb(161, 0, 255);
  -webkit-box-shadow: 0px 2px 32px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 32px 0px rgb(0 0 0 / 10%);
  padding: 30px;
  opacity: 1 !important;
  z-index: 1;
  display: none;
  width: 105%;
  left: -3%;
  bottom : 0;
  min-height: 100%;
}

.modules-card.no-access .module-long-desc {
  border-color: rgb(191 191 191);
}

.module-desc-head {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}

.module-desc-icon {
  width: 35px;
  margin-right: 15px;
}
.module-desc-name {
  color: rgb(21, 24, 27);
  font-family: Graphik-Semibold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 28px;
  text-align: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.module-desc {
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.26px;
  line-height: 22px;
  text-align: justify;
}

.module-desc-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-top: 15px;
}

.module-desc-footer .module-button {
  margin: 0;
  height: 33px;
}


.module-icon > img {
  width: 60px;
  margin: 10px 0 0 0;
  height: 60px;
}

.larger-icon-dashboard > img {
  width: 80px;
  margin: 15px 0 -18px 0;
}

.larger-icon-data > img {
  margin: 26px 0 -4px 0;
}

.larger-icon-master > img {
  width: 80px;
  margin: 16px 0 -9px 0;
}

.module-name {
  color: rgb(21, 24, 27);
  font-family: Graphik-Semibold-Web;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 28px;
  text-align: center;
  margin-top: 5px;
}

.module-description {
  color: rgb(99, 115, 129);
  font-family: "Graphik-Regular-Web";
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
  text-align: center;
  width: 100%;
  padding: 0 30px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 12px 0;
}

.module-button {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 40px;
  width: 124px;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  cursor: pointer;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-align: center;
      align-items: center;
  margin: auto;
  margin-bottom: 30px;
}

.module-button > svg {
  margin: 0 0 0 10px;
  font-size: 12px;
}

.module-button:hover {
  background: rgb(161, 0, 255);
  color: #fff;
}

.modules-card.no-access {
  -webkit-box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  cursor: default;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.modules-card.no-access:hover {
  border: none;
}

.modules-card.no-access > .module-button:hover {
  cursor: not-allowed;
  background: none;
  color: rgb(161, 0, 255);
}

.modules-card.no-access > * {
  opacity: 0.5;
}

th {
  font-family: 'Graphik-Bold-Web';
}

td {
  font-family: 'Graphik-Regular-Web';
}

.form-control,
.textarea.form-control,
.btn-default {
  color: black;
  font-family: 'Graphik-Regular-Web';
}

.btn-default,
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default:active:focus {
  color: #fff;
  font-size: 1.2em;
  font-family: Graphik-Medium-Web;
  background-color: #a100ff;
  font-weight: 500;
  border: 0;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #a100ff;
  border-radius: 20px;
}

.btn-default:disabled {
  background-color: #a0a0a0;
  color: #fff;
}

/* Client select modal */

.tab-component-content {
  padding: 0 !important;
}

.cvm-project-tile-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 380px;
  padding: 0 0 10px 0;
}

.cvm-project-tile-container>.row {
  margin: 14px -10px 0 -10px;
}

.cvm-project-tile {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%);
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  padding: 30px 0px;
  margin: 16px 0;
  height: 90%;
}

.cvm-project-tile>* {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.4;
}

.cvm-project-tile:hover {
  background: rgb(250, 245, 253);
  border-radius: 8px;
  border: 1px solid rgb(161, 0, 255);
}

.cvm-project-tile:hover>* {
  -webkit-filter: none;
          filter: none;
  opacity: 1;
}

.project-logo {
  height: 72px;
  width: 72px;
  margin: auto;
}

/* Client Details */

.client-details {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
  width: 100%;
  padding: 28px 36px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 12px 0;
}

.container-title {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Medium';
  font-size: 18px;
  font-weight: 500;
  height: 24px;
  letter-spacing: -0.35px;
  line-height: 24px;
  margin: 0 0 18px 0;
}

.client-dg-lable {
  margin: 10px;
  color: rgb(255, 255, 255);
  font-family: 'Graphik-Medium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.client-details-row {
  display: -ms-flexbox;
  display: flex;
  margin: 12px 10px;
}

.client-details-column {
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 0 5px;
}

.title {
  margin: 0 12px 0 0;
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Medium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 22px;
  white-space: nowrap;
}

.value {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Regular';
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
}

.value.value-link {
  color: rgb(161, 0, 255);
  font-family: 'Graphik-Medium';
  font-size: 16px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.29px;
  line-height: 22px;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
}

.value-icon {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(161, 0, 255);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  color: #a100ff;
  margin: 0 0 0 7px;
}

.value svg {
  font-size: 12px;
  margin: 0 0 0 2px;
}

.reporting-year-details {
  padding: 28px 36px 38px 36px;
}

.reporting-year-title-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0 0 18px 0;
}

.reporting-year-title-row>.container-title {
  margin: 0;
}

.reporting-year-title-row>img {
  width: 21px;
  cursor: pointer;
}

.reporting-year-details .title {
  margin: 0 5px 0 0;
}

/* Search */

.search-bar-container {
  position: relative;
}

.search-bar {
  position: absolute;
  right: 0;
  margin: 10px 0 0 0;
}

.search-bar>input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(231, 234, 238);
  width: 240px;
  padding: 5px 10px 5px 40px;
  outline-style: none;
}

.search-bar svg {
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 16px;
}

/* tabs */

.tab-component-purple .react-tabs__tab {
  color: rgb(99, 115, 129) !important;
  font-weight: 500;
  padding: 14px 12px;
  border: 4px solid transparent !important;
}

@media only screen and (max-width: 798px) {
  .tab-component-purple .react-tabs__tab {
    font-size: 12px !important;
  }
}

.tab-component-purple .react-tabs__tab--selected {
  color: #a100ff !important;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-bottom: 4px solid #7500cc;
}

.star-container {
  right: 30px;
  position: absolute;
  bottom: 25px;
}

.star-container svg {
  color: #ffc82c;
}

.star-container.no-filter {
  -webkit-filter: none;
          filter: none;
  opacity: 1;
}

.client-details-select {
  width: 100%;
  border: none;
  text-align: center;
  background: transparent;
}

.project-name {
  padding-top: 10px;
}

/* HEADER */

#header_container {
  -webkit-box-shadow: none;
          box-shadow: none;
  position: relative;
  z-index: 1499;
  display: -ms-flexbox;
  display: flex;
  background: linear-gradient(-225deg,
      rgb(70, 0, 115) 0%,
      rgb(117, 0, 192) 48%,
      rgb(161, 0, 255) 100%);
  border-radius: 0px;
  height: 45px;
  width: -webkit-fill-available;
}

div#session-warning-message {
  position: fixed;
  left: 0;
  right: 0;
  /* margin: auto; */
  text-align: center;
  padding: 5px;
  color: #000000;
  width: 60%;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  font-size: 14px;
  font-family: 'Graphik-Medium';
  -webkit-box-shadow: 0 0 10px #3a3a3a;
          box-shadow: 0 0 10px #3a3a3a;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  top: -100%;
  opacity: 0;
  z-index: 1500 !important;
  width: -webkit-fill-available;
}

div#session-warning-message span {
  font-family: 'Graphik-Semibold';
  color: #9d00ff;
  cursor: pointer;
}

.message-show {
  opacity: 1 !important;
  top: 0 !important;
}

.def-cursor {
  cursor: default !important;
}

.header_title {
  display: -ms-flexbox;
  display: flex;
  padding-left: 10px !important;
  color: #ffffff;
  font-family: 'Graphik-Medium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  height: 45px;
}

.header_title>div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

#logo {
  width: 32px;
  margin: 0 16px 0 0;
}

#navigation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 0 5px;
}

.divider {
  border-right: 1px solid rgba(255, 255, 255, 0.35);
  height: 45%;
  margin: 0 20px;
}

.data-visualisation-container .divider {
  height: 41px;
  border-right: 1px solid rgba(184, 184, 184, 0.35);
  margin: 0 20px 0 0;
}


.navigation-link,
#profile-link,
#bell-link {
  margin-left: 45px;
  color: rgb(18, 43, 59);
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  white-space: nowrap;
}

.profile-pop-links>a {
  color: rgb(18, 43, 59) !important;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  width: 96px;
  margin-bottom: 20px;
}

#profile-link {
  margin-left: -10px;
}

.navigation-link:hover {
  color: #b200ff;
  border-bottom: none;
}

.navigation-link.selected {
  border-bottom: 4px solid #b200ff;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  color: #b200ff;
  margin: 0 -4px 0 41px;
}

.selected.menu-open {
  border: none;
  color: #122b3b;
  margin: 0px 0px 0 45px;
}

.navigation-link>img {
  height: 14px;
  margin-left: 10px;
  width: 14px;
}

.user-eid {
  color: rgb(255, 255, 255);
  font-family: 'Graphik-Medium';
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding-right: 20px;
  min-inline-size: -webkit-fit-content;
  min-inline-size: -moz-fit-content;
  min-inline-size: fit-content;
}

.profile-gap {
  margin-bottom: 14px;
}

#profile-pop {
  position: absolute;
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid rgb(223, 227, 232);
  -webkit-box-shadow: 0 0 12px rgb(60 60 60);
          box-shadow: 0 0 12px rgb(60 60 60);
  top: 45px;
  -webkit-transition: top 0.5s, opacity 0.2s;
  transition: top 0.5s, opacity 0.2s;
  cursor: default;
  right: 50px;
  width: 40em;
  margin-right: -15px;
  margin-left: -15px;
}

#profile-pop.visible {
  top: 65px;
  opacity: 1;
  height: auto;
}

.profile-pop-links:hover,
.bell-pop-links:hover {
  cursor: pointer;
  text-decoration: underline;
}

#profile-pop-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  cursor: default;
}

#profile-pop-overlay.visible {
  display: block;
}

.people_header {
  height: auto;
  font-size: 0.87em;
  margin: 3px auto;
  text-align: right;
  width: 29%;
  padding: 0;
}

#header_container>img {
  float: right;
  padding-right: 1.5%;
  width: 43px;
  padding-top: 15px;
}

.people_header>span {
  vertical-align: middle;
  padding: 0 5px;
}

#home {
  cursor: pointer;
  padding: 5px;
  width: 30px;
  padding-top: 10px;
}

#user_logo {
  width: 20px;
  margin: 0 10px;
}

#profile-link svg {
  margin: 0 6px;
}

#burger_logo {
  cursor: pointer;
  width: 26px;
  padding-top: 18px;
  right: -8.5%;
  background-color: white;
  position: absolute;
  float: right;
  margin: 0;
  padding-top: 15px;
}

.burger .hide {
  display: none;
}

.burger {
  right: 2%;
  width: auto;
}

#page-wrap {
  position: relative;
  background: #f9fafb;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-height: 100vh;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.content {
  height: 560px;
}

/* FOOTER */

.footer {
  background: rgb(255, 255, 255);
  border-radius: 0px;
  -webkit-box-shadow: 0px -2px 16px 0px rgba(33, 43, 54, 0.1), 0px -1px 0px 0px rgb(223, 227, 232);
          box-shadow: 0px -2px 16px 0px rgba(33, 43, 54, 0.1), 0px -1px 0px 0px rgb(223, 227, 232);
  width: 100%;
  /*display: flex;*/
  padding: 15px 25px;
  height: 64px !important;
}

#footer_container div {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 28px;
}

#accenture_logo {
  height: 25px;
}

.trademark {
  text-align: left;
  margin: 0 0 0 50px;
}

.footer-links-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.footer-links {
  /*margin-left: 40px;*/
  text-align: right;
  cursor: pointer;
  color: rgb(161, 0, 255) !important;
}

.footer-links:hover {
  text-decoration: underline;
}

.footer-links a {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 28px;
}

.footer .row #footer-logo>img {
  vertical-align: baseline !important;
}

.footer .row {
  -ms-flex-align: center;
      align-items: center;
}

.trademark span {
  padding: 0 20px;
  font-weight: bold;
}

.termsOfUse {
  cursor: pointer;
  font-weight: normal;
}

.termsOfUse:hover {
  text-decoration: underline;
}

.footerImage {
  float: left;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.acn_ops_growth_platform {
  color: white;
  font-size: 0.87em;
  font-weight: bold;
  padding: 12px 40px;
}

.acn_logo {
  padding: 1em;
}

.acn_logo>img {
  width: 100%;
  height: 100%;
  padding: 47px 52px;
}

.footer .downloadLink {
  color: white;
  font-family: Graphik-Regular-Web;
  font-size: 0.8em;
}

.footer .downloadLink:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.footer a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.quick_links {
  padding: 20px 30px;
}

@media (min-width: 992px) {
  .quick_links .col-md-5 {
    width: 39%;
  }
}

.quick_links_content,
.quick_links_header {
  padding: 0px;
}

.quick_links_header {
  padding: 10px 0;
}

/* MODAL TERMS */

.terms {
  padding: 10px;
  background-color: darkslategray;
  font-family: 'Graphik-Extralight-Web';
  color: white;
}

.terms>h2 {
  font-family: 'Graphik-Bold-Web';
}

/* NON-MODAL TERMS */

.termsAndAgreement {
  width: auto;
  margin: 3.5% 6%;
  padding: 2% 2%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.termsAndAgreement h1 {
  color: #34b6e5;
  font-size: 2em;
}

.termsAndAgreement p {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 34px;
  margin-bottom: 20px;
}

/* PRIVACY STATEMENT */

.privacyStatement {
  width: auto;
  margin: 3.5% 6%;
  padding: 2% 2%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.privacyStatement h1 {
  color: #34b6e5;
  font-size: 2em;
}

.privacyStatement p {
  margin: 0 0 20px 0;
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Regular-Web';
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 34px;
}

.privacyStatement h2 {
  color: rgb(45, 58, 75) !important;
  font-family: Graphik-Bold-Web;
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
  letter-spacing: -0.32px;
}

.privacyStatement a {
  color: #a100ff;
}

/* BURGER MENU */

.hamburgerButton {
  position: relative;
  top: 18px;
  left: 10px;
}

.userDetailsRowContainer {
  margin: 40px 0px 3px 0px !important;
  padding-left: 25px !important;
}

.userPhotoContainer {
  margin-top: 10px;
  padding: 0px;
  text-align: center;
}

.userDetailsContainer {
  margin-top: 10px;
  padding: 0px;
}

#userContainer {
  position: relative;
  font-family: 'Graphik-Regular-Web';
  text-align: center;
}

.userLogo {
  position: relative;
  width: 30%;
}

#userRole {
  font-size: 9.5px;
  font-style: italic;
  color: black;
  left: 1px;
}

.burgerContainer {
  background-color: rgb(43, 0, 255);
  height: 50px;
  width: 50px;
  position: absolute;
  left: 53px;
  float: right;
  cursor: pointer;
}

#userFullName {
  line-height: 23px;
  color: black;
  font-size: 15px;
}

.paragraph {
  text-align: justify;
  text-indent: 30px;
}

.modalBorder {
  border: 10px solid rgb(70, 132, 183);
}

.tab-content a {
  font-family: 'Graphik-Regular-Web';
}

.bm-burger-button>button {
  height: 50px !important;
}

/* Navigation Style */

.nav .open>a,
.dropdown-menu {
  background-color: steelblue;
  height: auto;
}

.menu-nav {
  width: 200px;
  float: left;
  text-align: center;
  font-size: 20px;
  font-family: 'Graphik-Extralight-Web';
}

.menu-nav>a,
.dropdown-menu>li>a {
  color: white;
}

.nav>li>a:hover,
.nav>li>a:focus,
.nav .open>a:hover,
.nav .open>a:focus,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  background-color: slateblue;
  color: white;
}

#nav-menu.hov {
  color: #b200ff;
}

#nav-menu.hov>#menu_black {
  display: none;
}

#nav-menu.hov>#menu_pink {
  display: block;
}

#menu_pink {
  display: none;
}

#header-overlay {
  background: #ffffff00;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: none;
}

#header-overlay.overlay-visible {
  display: block;
}

.terms-header {
  color: rgb(45, 58, 75) !important;
  font-family: Graphik-Bold-Web;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.32px;
  margin-top: 20px;
}

.privacy-header {
  color: rgb(45, 58, 75) !important;
  font-family: 'Graphik-Bold-Web';
  font-size: 28px !important;
  font-weight: bold;
  letter-spacing: -0.32px;
  line-height: 26px;
}

ul.header-profile {
  padding-left: 0px;
  text-align: right;
  list-style: none;
}

li.header-profile-name {
  padding-top: 10px;
}

li.header-profile-role {
  padding-right: 26px;
  max-width: 124px;
  font-size: 10.5px;
  font-style: italic;
}

/* groove override */

@media only screen and (max-width: 1024px) {
  #groove-select-dropdown .selectmenu-group-values {
    width: 70% !important;
  }
}

#groove-select-dropdown div#selectmenu-anchor:hover {
  border: 1.5px solid #a100ff !important;
}

#groove-select-dropdown div#selectmenu-anchor .display-field.field-label {
  color: #637381 !important;
}

#groove-datepicker .dtp-lbl-container:focus,
#groove-datepicker .dtp-lbl-container:hover {
  border: 1px solid #a100ff !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: none !important;
}

.react-datepicker__day--selected {
  background: #a100ff !important;
  color: #fff !important;
  font-family: 'Graphik-Medium';
  outline: none !important;
}

.react-datepicker__day {
  border: 0.1px solid #a100ff !important;
  background-color: #fcf5ff;
}

.react-datepicker__day--disabled {
  border: 0.1px solid #f9fafb !important;
}

.react-datepicker__day--today {
  color: #a100ff !important;
}

#groove-select-dropdown svg {
  color: #919eab;
}

div#calendar-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #a100ff !important;
  color: #fff !important;
}

#groove-input .groove-input .groove-input:focus,
#groove-input .groove-input:focus,
#groove-textarea .floating-label-textbox .groove-textbox:focus {
  outline: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: #fff !important;
  border: 1px solid #a100ff;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown)+label {
  margin: 10px 0 !important;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder)+label {
  margin: 10px 0 !important;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label,
#groove-textarea .floating-label-textbox .groove-textbox:focus+label {
  margin: 10px 0 !important;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown)+label {
  margin: 10px 0;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder)+label {
  margin: 10px 0;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  margin: 10px 0;
}

#groove-input .floating-label .groove-input:not(:-moz-placeholder-shown)+label, #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown):focus+label, #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown):hover+label, #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown)+label {
  color: #a100ff !important;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 5px 0;
  padding: 0 7px;
  opacity: 1 !important;
}

#groove-input .floating-label .groove-input:not(:-ms-input-placeholder)+label, #groove-input .floating-label .groove-input:not(:-ms-input-placeholder):focus+label, #groove-input .floating-label .groove-input:not(:-ms-input-placeholder):hover+label, #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder)+label {
  color: #a100ff !important;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 5px 0;
  padding: 0 7px;
  opacity: 1 !important;
}

#groove-input .floating-label .groove-input:focus+label,
#groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
#groove-input .floating-label .groove-input:not(:placeholder-shown):focus+label,
#groove-input .floating-label .groove-input:not(:placeholder-shown):hover+label,
#groove-textarea .floating-label-textbox .groove-textbox:focus+label,
#groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  color: #a100ff !important;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 5px 0;
  padding: 0 7px;
  opacity: 1 !important;
}

.add-program-details #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown)+label, .add-program-details #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown):focus+label, .add-program-details #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown):hover+label, .add-program-details #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown)+label {
  color: #637381 !important;
}

.add-program-details #groove-input .floating-label .groove-input:not(:-ms-input-placeholder)+label, .add-program-details #groove-input .floating-label .groove-input:not(:-ms-input-placeholder):focus+label, .add-program-details #groove-input .floating-label .groove-input:not(:-ms-input-placeholder):hover+label, .add-program-details #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder)+label {
  color: #637381 !important;
}

.add-program-details #groove-input .floating-label .groove-input:focus+label,
.add-program-details #groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
.add-program-details #groove-input .floating-label .groove-input:not(:placeholder-shown):focus+label,
.add-program-details #groove-input .floating-label .groove-input:not(:placeholder-shown):hover+label,
.add-program-details #groove-textarea .floating-label-textbox .groove-textbox:focus+label,
.add-program-details #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  color: #637381 !important;
}

#groove-input .floating-label .groove-input:not(:-moz-placeholder-shown) {
  padding: 1.3rem 16px 12px !important;
}

#groove-input .floating-label .groove-input:not(:-ms-input-placeholder) {
  padding: 1.3rem 16px 12px !important;
}

#groove-input .floating-label .groove-input:focus,
#groove-input .floating-label .groove-input:not(:placeholder-shown) {
  padding: 1.3rem 16px 12px !important;
}

#groove-radio-button .form-check>label {
  margin: 0 0 0 0 !important;
  font-size: 14px !important;
}

#groove-textarea .groove-textbox,
#groove-input .groove-input {
  outline: none !important;
  border: 1px solid #dfe3e8;
}

.hideStatusMessage {
  display: none !important;
}

#groove-input .floating-label label {
  top: 44px !important;
}

#groove-input .groove-input {
  margin-bottom: 1.3rem;
}

.header_title .form-group {
  max-width: 400px;
  margin: 0;
}

#groove-btn-id>button {
  line-height: 1.5;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

#groove-btn-id>button>span {
  font-family: 'Graphik-Medium';
}

.add-button-right {
  margin-left: auto;
}

/* dropdown override for header client select */

.header_title #groove-select-dropdown {
  width: auto;
}

.header_title #groove-select-dropdown div#selectmenu-anchor {
  background: transparent;
  border-radius: 0;
  border: none !important;
  height: 56px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 200px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
  -ms-flex-align: center;
      align-items: center;
  padding-right: 1.2rem;
}

.header_title #groove-select-dropdown div#selectmenu-anchor .display-field.field-label {
  display: none;
}

.header_title #groove-select-dropdown .selectmenu-selected-value {
  margin: 0;
}

.header_title #groove-select-dropdown div#display-selected {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.header_title #groove-select-dropdown #selectmenu-dropdown-icon {
  margin-top: 0;
  line-height: 0;
}

.header_title #groove-select-dropdown #selectmenu-anchor svg {
  color: #fff;
  width: 13px;
}

.header_title #groove-select-dropdown .default-display {
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.header_title #groove-select-dropdown div.selectmenu-options {
  position: absolute !important;
  top: 57px;
  left: -30px;
  width: 400px !important;
}

.header_title #groove-select-dropdown div.selectmenu-options.display-list {
  margin: 0;
}

.header_title #groove-select-dropdown div#selectmenu-anchor[disabled] {
  border: transparent !important;
  background: transparent !important;
  cursor: default !important;
}

.add-new-program-container #groove-select-dropdown div.selectmenu-options.display-list {
  max-height: 250px;
}

.add-boi-field-container #groove-select-dropdown div.selectmenu-options.display-list {
  max-height: 305px;
}

div#selectmenu-options {
  overflow: hidden !important;
}

div#dropdown-list.dropdown-list {
  max-height: 250px;
}

div.selectmenu-options.display-list .dropdown-item {
  word-break: break-all !important;
}

@media only screen and (max-width: 768px) {
  div.selectmenu-options.display-list .dropdown-item {
    font-size: 13px !important;
  }
}

.cvm-tab-content.add-boi-modal {
  min-height: 500px;
}

.modal-dialog.modal-lg {
  max-width: 95% !important;
}

.modal-dialog.modal-md {
  max-width: 70% !important;
}

.accordion>.card {
  overflow: visible !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
}

.accordion>.card>.card-header {
  margin-bottom: 0 !important;
  border-radius: 10px !important;
}

.collapse.show~div.card-header .btn-link>div.svg-container {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.searchable-dropdown-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: sticky !important;
  top: 10px;
}

.searchable-dropdown-content svg {
  font-size: 15px;
}

.searchable-dropdown-content .svg-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 0 5px;
}

body #groove-btn-id .btn-ops-blue {
  padding: 3px 15px;
  background-color: #a100ff;
  border-color: #a100ff;
}

body #groove-btn-id .btn-ops-blue:hover,
body #groove-btn-id .btn-outline-dg-slate:hover,
body #groove-btn-id .btn-outline-dg-slate:active {
  color: #fff !important;
  background-color: #8900d9 !important;
  border-color: #8900d9 !important;
}

body #groove-btn-id .btn-outline-dg-slate {
  padding: 3px 15px;
  color: #a100ff;
  border-color: #a100ff;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

label#groove-checkbox-label {
  font-size: 14px;
  font-family: 'Graphik-Regular';
  line-height: 2;
}

div#groove-select-dropdown .custom-checkboxlist {
  padding: 10px 20px 0 20px;
}

#groove-select-dropdown div#selectmenu-anchor[disabled] {
  border: 1px solid #dfe3e8 !important;
}

.react-tabs__tab--selected {
  background: transparent !important;
}

#groove-btn-id>button>* {
  font-size: 14px;
  margin: 0 5px;
}

.ag-cell {
  border: none !important;
}

.ag-react-container {
  width: 100%;
}

.bell-notification {
  color: #ffffff;
}

.bell-notification svg.svg-inline--fa.fa-w-14 {
  cursor: pointer;
  width: 21px;
  height: 19.8px;
}

#bell-pop {
  padding: 15px;
  position: absolute;
  right: -10px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid rgb(223, 227, 232);
  -webkit-box-shadow: 0px 2px 32px 0px rgb(223, 227, 232), 0px 1px 0px 0px rgb(223, 227, 232);
  box-shadow: 0px 2px 32px 0px rgb(223, 227, 232), 0px 1px 0px 0px rgb(223, 227, 232);
  -webkit-transition: top 0.5s, opacity 0.2s;
  transition: top 0.5s, opacity 0.2s;
  cursor: default;
  top: 45px;
  margin-left: 0px;
  white-space: nowrap;
}

#groove-select-dropdown div.selectmenu-options .searchable-dropdown-content {
  margin: 10px 15px !important;
  width: auto !important;
}

#groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  height: 100% !important;
}

div#groove-checkbox label {
  color: rgb(0, 0, 0);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 1.8;
}

div.custom-checkboxlist {
  padding-left: 0 !important;
  overflow: hidden !important;
  max-height: 100px !important;
}

.dimension-collapse .card-header>div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.custom-checkboxlist .form-check {
  padding-left: 20px !important;
  height: auto !important;
}

#groove-checkbox label#groove-checkbox-label {
  margin: 0 12px !important;
}

input::-webkit-input-placeholder {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

input::-moz-placeholder {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

input:-ms-input-placeholder {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

input::-ms-input-placeholder {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

input::placeholder {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.dropdown-invalid #selectmenu-anchor,
.multi-select-dropdown-with-count.dropdown-invalid {
  border: 1px solid red !important;
  -webkit-box-shadow: 0 0 5px #ff00007d;
          box-shadow: 0 0 5px #ff00007d;
}

#groove-input .input-box>input.dropdown-invalid {
  border: 1px solid red !important;
  -webkit-box-shadow: 0 0 5px #ff00007d;
          box-shadow: 0 0 5px #ff00007d;
}

#groove-textarea .groove-textbox.dropdown-invalid {
  outline: 1px solid red !important;
  border: 1px solid red !important;
  -webkit-box-shadow: 0 0 5px #ff00007d;
          box-shadow: 0 0 5px #ff00007d;
}

.dropdown-invalid label#dtp-lbl-container {
  border: 1px solid red !important;
  -webkit-box-shadow: 0 0 5px #ff00007d;
          box-shadow: 0 0 5px #ff00007d;
}

.infoIcon {
  height: 13px;
  width: 13px;
  border: 1.2px solid #a100ff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-left: 5px;
}

.groove-input-custom-label-disabled,
div.groove-input-custom-label {
  z-index: 0 !important;
}

.add-new-program-container .groove-input-custom-label-disabled,
.add-new-program-container div.groove-input-custom-label {
  font-size: 13px;
}

.add-new-program-container div.groove-input-custom-label>div {
  width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
}

.reporting-year-ddown #selectmenu-dropdown-icon svg {
  visibility: hidden;
}

.reporting-year-ddown #selectmenu-dropdown-icon {
  z-index: 999;
}

.reporting-year-ddown>.svg-container {
  position: absolute;
  top: 9px;
  right: 0;
  margin-right: 40px;
}

.reporting-year-ddown {
  position: relative;
}

#selectmenu-options .custom-checkboxlist {
  padding: 0 20px !important;
}

#groove-select-dropdown #selectmenu-dropdown-icon {
  width: auto !important;
}

div.cbox-container {
  min-height: 30px;
}

.input-invalid {
  border: 1px solid red !important;
  -webkit-box-shadow: 0 0 5px #ff00007d;
          box-shadow: 0 0 5px #ff00007d;
}

.input-conditional {
  border: 1px solid yellow !important;
  -webkit-box-shadow: 0 0 5px #ffc4007d;
          box-shadow: 0 0 5px #ffc4007d;
}

/* Notification list css starts here */

#popover-notification>div {
  max-height: 660px;
}

#popover-notification.popover .arrow {
  display: none;
}

#popover-notification.popover {
  min-width: 400px;
  inset: -10px 0px auto auto !important;
  z-index: 5000;
}

#popover-notification .close-btn {
  top: 20px;
}

.close-btn svg.svg-inline--fa.fa-w-11 {
  color: rgb(145, 158, 171);
  height: 15px;
  width: 15px;
}

#popover-notification .popover-header {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
  letter-spacing: -0.07px;
  background: #fff;
  padding: 24px 16px;
  line-height: 16px;
  border-bottom: none;
}

#popover-notification .popover-header>div {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.07px;
}

#popover-notification .popover-body {
  padding: 0;
}

#popover-notification .new-section {
  padding: 5px 16px 24px 16px;
  border-bottom: 1px solid rgb(223, 227, 232);
}

#popover-notification .new-header {
  color: rgb(156, 106, 222);
  font-size: 10px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: 12px;
  margin-bottom: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

#popover-notification .new-header .new-count {
  color: rgb(156, 106, 222);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.16px;
}

#popover-notification .new-header .mark-read,
.view-complete-list {
  cursor: pointer;
  color: rgb(161, 0, 255);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.16px;
  margin-right: 16px;
}

.notice-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 16px;
}

.notice-title h5 {
  margin-bottom: 0;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.notice-title p {
  margin-bottom: 0;
  color: rgb(145, 158, 171);
  font-size: 10px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  text-align: right;
  letter-spacing: -0.16px;
}

.notice-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.notice-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  text-align: left;
}

.user-wrap {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  background: #e7eaee;
}

.notice-text #user_logo {
  width: 32px;
  height: 32px;
  margin: 0;
}

.notice-text p {
  margin-bottom: 0;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 16px;
  word-break: break-word;
}

.reject.notification-item-wrap.card.active {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(234, 128, 151) !important;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0) !important;
          box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0) !important;
  border-radius: 4px !important;
}

.normal.notification-item-wrap.card.active {
  background: rgb(251, 245, 255);
  border: 1px solid rgb(227, 209, 255) !important;
}

.notification-item-wrap.card.active {
  background: #fff;
  border: 1px solid rgb(223, 227, 232) !important;
}

.notification-item-wrap.card {
  background: #fff;
  border: 1px solid rgb(223, 227, 232) !important;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.01);
          box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.01);
  border-radius: 4px !important;
  margin-bottom: 10px;
}

.notification-item-wrap.card:last-child {
  margin-bottom: 0;
}

.notification-item-wrap .card-header {
  padding: 16px 16px 28px 16px;
  border-radius: 0px;
  border: none;
  background: inherit;
}

.notification-item-wrap .card-header .btn {
  padding: 0;
}

.action-required.notice-title h5 {
  color: rgb(213, 0, 47);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.notice-desc-wrap {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  padding: 16px 6px 16px 16px;
}

.notice-desc {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 16px;
}

.notice-desc .notif-comment {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 16px;
  max-width: 120ch;
}

.notice-desc .veiw-more {
  color: rgb(161, 0, 255);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.16px;
  cursor: pointer;
  margin-left: 46%;
  right: 44px;
  position: absolute;
}

.notification-item-wrap .card-body {
  padding-top: 0px;
}

.notice-ext-link {
  padding-left: 55px;
  padding-right: 55px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
}

.old-notif.notice-ext-link {
  padding-left: 0;
  padding-right: 0;
}

.notice-ext-link span {
  color: rgb(161, 0, 255);
  font-size: 11px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  vertical-align: middle;
}

.notice-ext-link .svg-container {
  margin: auto 0;
}

.notice-ext-link .action-link {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.notification-item-wrap .btn-link.focus,
.notification-item-wrap .btn-link:focus,
.notification-item-wrap .btn-link:hover {
  text-decoration: none;
}

.notification-item-wrap .btn.focus,
.notification-item-wrap .btn:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.old-section {
  padding: 24px 16px;
}

.old-list,
.new-list {
  padding-right: 10px;
  max-height: 240px;
  overflow-y: auto;
}

.old-header {
  color: rgb(145, 158, 171);
  font-size: 10px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.16px;
  margin-bottom: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.old-header .old-title {
  color: rgb(145, 158, 171);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.16px;
}

.collapse.show~div.card-header .btn-link>.notice-header div.svg-container {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

/* Notification list css ends here */

#groove-input {
  height: 80%;
  position: relative;
  display: block;
}

span.tooltiptext {
  display: none;
  width: 400px;
  background-color: #a100ff;
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  font-family: 'Graphik-Medium';
  letter-spacing: 0.3px;
  left: 35px;
}

.error-message {
  height: 24px;
}

.stepper-tab-left-section .card {
  border: none;
}

.stepper-tab-left-section .accordion>.card>.card-header {
  border: none;
  border-radius: 0 !important;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  background: rgb(249, 250, 251);
}

.stepper-tab-left-section .accordion>.card>.card-header .col {
  padding: 0 5x 0 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}

.stepper-tab-left-section .accordion>.card>.card-header button {
  font-size: 24px;
  padding: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.stepper-tab-left-section .accordion>.card>.card-header button svg {
  color: rgb(99, 115, 129);
}

.stepper-tab-left-section .accordion>.card .card-body {
  padding: 1.25rem 0;
  background: rgb(249, 250, 251);
}

.stepper-tab-left-section .accordion>.card .card-body li {
  padding: 10px 30px 10px 45px;
}

.groove-dtpicker-label-disabled,
.groove-dtpicker-label-onfocus {
  color: #637381 !important;
  font-family: 'Graphik-Medium' !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px !important;
  line-height: 11px !important;
  margin: 5px 0 !important;
  padding: 0 7px !important;
  opacity: 1 !important;
  position: absolute !important;
  -webkit-transition: all 0.25s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  transition: all 0.25s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  left: 22px !important;
  top: 2px !important;
}

#groove-datepicker .react-datepicker__input-container input:not(:-moz-placeholder-shown) {
  padding: 21px 6px 15px !important;
}

#groove-datepicker .react-datepicker__input-container input:not(:-ms-input-placeholder) {
  padding: 21px 6px 15px !important;
}

#groove-datepicker .react-datepicker__input-container input:focus,
#groove-datepicker .react-datepicker__input-container input:not(:placeholder-shown) {
  padding: 21px 6px 15px !important;
}

div#groove-textarea .help-text {
  display: none;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.notifyModal-dropdown>.multi-select-dropdown {
  padding: 0 20px 15px 20px;
}

#mystery {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: none;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.cvm-span {
  font-family: Graphik-Medium;
  font-weight: 500;
}

.groove-selectmenu-dropdown-icon {
  margin-top: 0 !important;
}

/* Loader */

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  margin: 60px auto;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #a100ff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.ag-body-horizontal-scroll-viewport {
  overflow-x: unset !important;
}

#popover-notification .notification-item-wrap .card-header {
  padding: 5px 15px;
}

#popover-notification .notification-item-wrap .card-body {
  padding: 10px 35px 10px 55px;
}

.card-header .btn-link {
  width: 100%;
}

.notif-count {
  position: absolute;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  background: rgb(213, 0, 47);
  right: -7px;
  top: 10px;
  width: auto;
  height: 16px;
  text-align: center;
  border-radius: 20px;
  padding: 2px;
}

.refresh-btn {
  cursor: pointer;
  background: rgb(156, 106, 222);
  border-radius: 10px;
  vertical-align: middle;
  text-align: center;
  padding: 3px;
  margin-left: 8px;
  line-height: 0;
}

.refresh-btn svg {
  color: white;
  padding: 0.7px;
}

.refresh-btn svg.rotate {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
}

.notif-added {
  position: absolute;
  top: 95px;
  left: 90px;
  z-index: 1000;
  width: 204px;
  height: 32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(70, 0, 115);
  -webkit-box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.01);
          box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.01);
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  padding: 3px 0px;
  cursor: pointer;
  display: none;
}

.notif-added span {
  width: 152px;
  height: 16px;
  color: rgb(70, 0, 115);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 16px;
  cursor: pointer;
}

.notif-added span .svg-inline--fa.fa-w-10 {
  width: 15px;
  height: 15px;
  border: 1px solid rgb(70, 0, 115);
  border-radius: 10px;
  margin: -2.5px 0;
}

.show.notif-added {
  display: block;
}

.no-notif {
  height: 60px;
  background: rgb(255, 255, 255);
  border: 1.5px dashed rgb(161, 0, 255);
  border-radius: 16px;
  text-align: center;
  vertical-align: middle;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.no-notif span {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.comment-expand {
  height: 100% !important;
}

#bell-link.disable-notif,
#bell-link.disable-notif .bell-notification svg.svg-inline--fa.fa-w-14 {
  cursor: not-allowed;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.9;
}

#notif-goTo .tooltip-inner {
  font-size: 11px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  vertical-align: middle;
  max-width: 250px;
}

/* Profile Menu CSS */

.profile-main-section>div:first-child {
  background: rgba(161, 0, 255, 0.1);
  border-radius: 4px;
  margin-right: 5px;
  width: 35px;
  text-align: center;
  padding: 5px;
}

.profile-main-section>div:nth-child(2)>div:first-child {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Semibold';
  font-size: 14px;
  font-weight: 600;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.profile-main-section>div:nth-child(2)>div:nth-child(2) {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Regular';
  font-size: 12px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.profile-main-section>div:nth-child(3) {
  margin-left: auto;
  margin-top: auto;
  margin-right: 13px;
}

.profile-sub-section>div>div:nth-child(2) {
  margin-left: auto;
  margin-bottom: auto;
}

.profile-sub-section {
  background: rgb(231, 234, 238);
  border-radius: 8px;
  padding: 12px;
  margin: -5px -5px 10px -5px !important;
  display: block;
}

.profile-main-section {
  padding: 10px;
  cursor: pointer;
  margin-right: -15px;
  margin-left: -15px;
}

.profile-sub-section .sub-section-container {
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.profile-sub-section>div {
  width: 100%;
}

.profile-sub-section .profile-menu-chevron {
  margin-bottom: 6px;
}

.profile-sub-section>div>div>div:first-child {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Medium';
  font-size: 14px;
  font-weight: 500;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.profile-sub-section>div>div>div:nth-child(2) {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Regular';
  font-size: 12px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.profile-sub-section>div>div>div:nth-child(3) {
  color: rgb(117, 0, 192);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.profile-menu-chevron {
  color: rgb(99, 115, 129);
  height: 10px;
  width: 6px;
}

.profile-cursor {
  cursor: default;
}

/* END Profile Menu CSS */

/* icons */
.svg-inline--fa {
  width: 1em;
}

.groove-required-field {
  color: #637381 !important;
}

.add-boi-box-container #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  width: 75% !important;
}

.addedBOI-dropdown #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  width: 73% !important;
}

.select-boi-dropdown #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content {
  margin: 10px 8px !important;
}

#groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  width: 85% !important;
}

.program-config-link {
  color: #637381;
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -.25px;
  margin-left: auto;
  margin-right: 5px;
  margin-bottom: 9px;
}

.program-config-link-span {
  color: #a100ff;
  cursor: pointer;
}

.modal-body .cvm-tab-content.add-boi-modal {
  padding-top: 0px !important;
}

.rvd-icon {
  height: 12.8px;
  width: 16px;
}

.rvd-link-span {
  color: #a100ff;
  cursor: pointer;
}

/* For invalid project name input field */
.selected-menu-basic.invalid-project-name-field {
  border: 1px solid #aa0025 !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.invalid-project-desc-field:not(:-moz-placeholder-shown) {
  outline: 1px solid #aa0025 !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.invalid-project-desc-field:not(:-ms-input-placeholder) {
  outline: 1px solid #aa0025 !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.invalid-project-desc-field:not(:placeholder-shown) {
  outline: 1px solid #aa0025 !important;
}
.modal-content {
  border-radius: 16px !important;
  height: 95%;
}
.modal-header {
  border: none !important;
  padding: 40px 40px 0 40px !important;
}

.modal-header {
  overflow: hidden;
  margin-right: 0px;
  margin-left: 0px;
  word-break: break-all;
  
}
.modal-title.h4 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}

.modal-title.h4 > div {
  display: -ms-flexbox;
  display: flex;
}

.modal-title.h4 .close-modal-btn {
  /* background: rgb(145, 158, 171); */
  height: 20px;
  width: 15px;
  color: rgb(145, 158, 171);
  cursor: pointer;
}

.modal-body {
  padding: 0 !important;
}

.modal-body > div:first-child {
  padding: 16px 40px;
  max-height: 65%;
  overflow-y: auto;
}

.add-boi-popup {
  overflow: visible !important;
}

.tab-component-purple .react-tabs__tab--selected {
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-bottom: 4px solid #7500cc !important;
  border-right: 4px solid transparent !important;
  border-left: 4px solid transparent !important;
}

.tab-component-purple .react-tabs__tab {
  padding: 14px 12px;
  margin: 0 10px 0 0;
}

.modal-95w {
  max-width: 95% !important;
  height: 90% !important;
}

/* Pagination */
.cvm-table-pagination-container {
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid rgb(231, 234, 238);
  padding: 5px;
  display: -ms-flexbox;
  display: flex;
}

.pagination {
  margin: 0 !important;
}

li#prevPage > button {
  margin-right: 15px;
}

li#nextPage > button {
  margin-left: 15px;
}

.pagination>li>button {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  height: 30px;
  min-width: 30px;
  color: #7b8e98;
  outline: none;
  margin: 0 5px;
  line-height: 1;
}

.pagination > li > button:hover {
  border: 1px solid #a100ff;
}

button.pagination-btn-disable {
  color: rgb(220, 226, 231) !important;
  cursor: not-allowed;
  border: none !important;
}

button.pagination-btn-pages {
  background: none !important;
  border: none !important;
  min-width: 24px !important;
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 12px;
  text-align: center;
  margin: 0 !important;
}

button.pagination-btn-pages:hover {
  border: 1px solid #a100ff !important;
  margin-left: -2px !important;
}

button.pagination-btn-active.pagination-btn-pages {
  background: rgb(161, 0, 255) !important;
  border-radius: 3px !important;
  color: rgb(255, 255, 255);
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
}

button.pagination-btn-active.pagination-btn-pages:hover {
  margin: 0;
}

/* Page Size dropdown */
.page-size {
  display: -ms-flexbox;
  display: flex;
  margin: 0 10px;
  -ms-flex-align: center;
      align-items: center;
}

.page-size > label {
  color: rgb(123, 142, 152);
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin: 0 10px;
}

.page-size {
  display: -ms-flexbox;
  display: flex;
}

.page-size svg {
  width: 12px;
  fill: #7b8e98;
}

/* width */
.page-size ::-webkit-scrollbar {
  width: 7px !important;
  height: 1px !important;
}

/* Track */
.page-size ::-webkit-scrollbar-track {
  border-radius: 6px;
}

/* Handle */
.page-size ::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

/* Handle on hover */
.page-size ::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
}
.table-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
}

/* Table headers */
.ag-header-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(33, 43, 54);
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}

/* cell style */
.ag-theme-alpine .ag-ltr .ag-cell {
  border-right-width: 1px;
  color: rgb(99, 115, 129);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
  display: inline;
  margin: 10px 0;
}

.ag-theme-alpine .ag-root-wrapper {
  border: solid 1px;
  border-color: #e7eaee;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}

.ag-header,
.ag-pinned-left-header,
.ag-pinned-right-header {
  display: inline-block;
  overflow: hidden;
  position: relative;
  background: #f9fafb !important;
  border-bottom: 1px solid #e7eaee !important;
}

.ag-row-odd {
  background: #f9fafb !important;
  border: 1px solid #e7eaee !important;
  border-width: 0.5px 0 0.5px 0 !important;
}

.ag-row-even {
  background: #ffffff !important;
  border: 1px solid #e7eaee !important;
  border-width: 0.5px 0 0.5px 0 !important;
}

.ag-row .ag-cell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.ag-theme-alpine .ag-icon {
  font-weight: 900 !important;
}

.ag-theme-alpine .ag-icon-asc:before {
  content: "\F12B";
  font-size: 12px;
}

.ag-theme-alpine .ag-icon-desc:before {
  content: "\F128";
  font-size: 12px;
}
.table-container .ag-theme-alpine .ag-icon-desc:before {
  width: 9px;
  height: 9px;
  background: url(/static/media/caret-down.c22cf70f.svg) no-repeat center center;
  background-size: 100%;
  font-size: 0;
  display: inline-block;
  position: absolute;
  top: 42%;
}
.table-container .ag-theme-alpine .ag-icon-asc:before {
  width: 9px;
  height: 9px;
  background: url(/static/media/caret-down.c22cf70f.svg) no-repeat center center;
  background-size: 100%;
  font-size: 0;
  display: inline-block;
  position: absolute;
  top: 42%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.table-container .ag-theme-alpine .ag-icon-menu:before {
  display: none;
}
.ag-paging-panel {
  height: 40px !important;
  background: #f9fafb;
  display: none;
}

/* Pagination */
.cvm-table-pagination-container {
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid rgb(231, 234, 238);
  padding: 5px;
  display: -ms-flexbox;
  display: flex;
}

.pagination {
  margin: 0 !important;
}

li#prevPage > button {
  margin-right: 15px;
}
li#nextPage > button {
  margin-left: 15px;
}

.pagination > li > button {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  height: 30px;
  min-width: 30px;
  color: #7b8e98;
  outline: none;
  margin: 0 5px;
  line-height: 1;
}
.pagination > li > button:hover {
  border: 1px solid #a100ff;
}

button.pagination-btn-disable {
  color: rgb(220, 226, 231) !important;
  cursor: not-allowed;
  border: none !important;
}

button.pagination-btn-pages {
  background: none !important;
  border: none !important;
  min-width: 24px !important;
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 12px;
  text-align: center;
  margin: 0 !important;
}
button.pagination-btn-pages:hover {
  border: 1px solid #a100ff !important;
  margin-left: -2px !important;
}

button.pagination-btn-active.pagination-btn-pages {
  background: rgb(161, 0, 255) !important;
  border-radius: 3px !important;
  color: rgb(255, 255, 255);
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
}
button.pagination-btn-active.pagination-btn-pages:hover {
  margin: 0;
}

/* Page Size dropdown */
.page-size {
  display: -ms-flexbox;
  display: flex;
  margin: 0 10px;
  -ms-flex-align: center;
      align-items: center;
}

.page-size > label {
  color: rgb(123, 142, 152);
  font-family: "Graphik-Regular-Web";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin: 0 10px;
}

.page-size {
  display: -ms-flexbox;
  display: flex;
}

.page-size svg {
  width: 12px;
  fill: #7b8e98;
}

/* width */
::-webkit-scrollbar {
  height: 29px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border: 5px solid transparent;
  border-width: 10px 0 10px 0;
  border-radius: 14px;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.projectEntry-container {
  padding: 30px 80px;
  min-height: 840px !important;
}

.projectEntry-container .btn-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 0px;
}

.projectEntry-container .btn-container .project-title-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.btn-container>div {
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  word-break: break-all;
}

.projectEntry-container .title-section {
  margin-bottom: 5px;
}

.projectEntry-container .title-section .fa-times {
  cursor: pointer;
}

.select-project-modal .add-new-program-container {
  overflow-y: visible;
}

.select-project-modal .modal-header {
  padding-bottom: 15px !important;
}

.program-details-column {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3px;
}

.program-details-row {
  border-right: 1px solid #dfe3e8;
}

.program-details-row:last-child {
  border-right: none;
}

.program-details-row.col-lg-4 {
  padding-right: 10px;
  padding-left: 12px;
}

.project-title {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Semibold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 18px;
}

.projectEntry-subcontainer {
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px 40px 50px;
}

.projectEntry-subcontainer .project-title {
  width: 100%;
  word-break: break-word;
}

.projectEntry-container .sub-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.computeAll-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.sub-title-header {
  margin-right: 0px !important;
  margin-left: 0px !important;
  text-align: center;
}

.projectEntry-container .sub-title .sub-title-header h5 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 24px;
  margin-bottom: 0;
  -ms-flex-item-align: center;
      align-self: center;
}

.reset-all-button, .compute-all-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  border: #a100ff solid 1px;
  border-radius: 50px;
  cursor: pointer;
}

.reset-all-button {
  padding: 3px 0;
  padding-left: 15px;
}

.reset-all-button button {
  color: #a100ff !important;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 15px;
}

.compute-all-button button {
  color: #a100ff !important;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.reset-all-button img {
  height: 30px;
  width: 15px;
  position: absolute;
}

.compute-all-button img {
  height: 33px;
  width: 15px;
  position: absolute;
  margin-left: 15px;
}

.reset-all-button:hover, .compute-all-button:hover {
  background-color: #a100ff;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.reset-all-button:hover button, .compute-all-button:hover button {
  color: #fff !important;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.compute-all-button:hover img, .reset-all-button:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.projectEntry-container .sub-title-right span, .projectEntry-container .sub-title-right label {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  margin-left: 25px;
  cursor: pointer;
}

.reports-boiHistory-container {
  margin: 5px 0 10px 0;
}

.projectEntry-container .file-upload-wrap {
  padding: 19px 20px;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 24px 0px;
}

.projectEntry-container .file-upload-wrap.file-error {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
}

.projectEntry-container .file-upload-wrap.file-success {
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.projectEntry-container .accordion-action-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.subdimension-table {
  margin: 20px 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 0px;
}

/*css for columns based on status*/

@media (min-width: 992px) {
  .projectEntry-container .notif-table-header .row .col-lg-2, .projectEntry-container .subdimension-row.accordion .col-lg-2, .projectEntry-container .notif-table-header .row .col-lg-1, .projectEntry-container .subdimension-row.accordion .col-lg-1 {
    word-break: break-word;
  }
  .projectEntry-container .subdimension-row.accordion div:nth-child(3), .projectEntry-container .subdimension-row.accordion div:nth-child(4), .projectEntry-container .subdimension-row.accordion div:nth-child(5), .projectEntry-container .subdimension-row.accordion div:nth-child(6), .projectEntry-container .subdimension-row.accordion div:nth-child(7), .projectEntry-container .subdimension-row.accordion div:nth-child(8) {
    padding-left: unset !important;
  }
  .target-value-padding {
    padding-left: unset !important;
  }
}

.boiComm-awapp {
  left: 30px;
}

.wVis-awapp {
  left: 25px;
}

.projectEntry-subcontainer label.mini-toggle-switch {
  top: 6px;
}

.app-rej-awapp {
  left: 40px;
}

.app-rej-rejected, .app-rej-rejected-new {
  -ms-flex-item-align: baseline;
      align-self: baseline;
  padding-left: unset;
}

.app-rej-rejected .rvd-rejection-comment #groove-textarea, .app-rej-rejected-new .rvd-rejection-comment #groove-textarea, .app-rej-awapp-new .rvd-rejection-comment #groove-textarea {
  width: 80%;
}

.boiComm-rejected {
  right: 23px;
}

.subdimension-section-collapse .subdimension-row .card-header>button {
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.subdimension-row .card-header {
  padding: 20px 30px;
}

.subdimension-row .card-header div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  word-break: break-word;
}

.bodytext14 {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.parameter-title-center {
  margin-left: 185px;
}

.false {
  font-family: Graphik-Medium;
}

.bodytext14-regular {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.fa-icons {
  color: rgb(161, 0, 255);
  padding: 3px;
  font-size: x-large;
  cursor: pointer;
}

.data-entries {
  margin: 15px;
  opacity: 0.5;
}

.new-pde {
  margin-bottom: 40px !important;
}

.report-prd {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 24px;
  -ms-flex-pack: end;
      justify-content: flex-end;
  display: block !important;
  text-align: right;
}

.new-proj-btn {
  display: inherit;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.subdimension-row .card-header>button .svg-container {
  display: inline;
  margin-right: 5px;
}

.subdimension-row.accordion .card-header {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  border-top: 1px solid rgb(223, 227, 232);
  border-radius: 0px !important;
}

.subdimension-row.accordion:first-child .card-header {
  border-top: none;
  padding-left: 0px;
  padding-right: 0px;
}

.subdimension-row.accordion .card-header .row {
  width: inherit;
}

.subdimension-row.accordion .card-header>button {
  width: 3%;
}

.subdimension-section-collapse .card-header>button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: right !important;
      justify-content: right !important;
  width: 100%;
}

.subdimension-section-collapse .card-header>button>.bodytext18-medium-midnight {
  margin-left: 20px;
}

.parameter-field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0px 50px 0px;
}

.field-text {
  width: 34%;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 19px;
}

.field-value {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.field-value input {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px 5px;
  margin: 0px 12px 0px 40px;
}

.field-row-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.bodytext14-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

/* @media (min-width: 992px){
.subdimension-table .col-lg-2:nth-child(2) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
.subdimension-table .col-lg-2:nth-child(3) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
} */

.subdimension-row.accordion .collapse.show~div.card-header .btn-link>div.svg-container {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.subdimension-row.accordion .collapse.show~div.card-header div.svg-container {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  cursor: pointer;
}

.validate {
  color: red;
  margin-left: 10px;
}

.rdv-view-comment {
  display: -ms-flexbox;
  display: flex;
}

.expandAll-button.active>button, .collapseAll-button.active>button {
  color: #a100ff;
}

.info-icon {
  display: inline-block;
  margin: 0 10px;
  position: relative;
}

.info-icon .svg-container {
  -webkit-transform: none !important;
          transform: none !important;
  font-size: 0.9em !important;
}

.info-icon>.svg-container>svg {
  width: 11px;
  cursor: pointer;
}

.info-icon.top {
  position: absolute;
  right: -15px !important;
  top: -10px !important;
}

/* 
.ApprovalRequestModal .groove-textbox {
  margin-top: 9px;
} */

.custom-boi {
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px 10px;
  border: none;
  margin-top: 0.25em;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 70px;
  margin-left: 10px;
}

.custom-boi:hover {
  -webkit-box-shadow: 0 0 5px rgb(187 135 255);
          box-shadow: 0 0 5px rgb(187 135 255);
}

.modal-fade-custom-boi {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px;
  height: 1064px;
  width: 1440px;
}

.custom-boi-modal {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modalheader-title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  width: 308px;
}

.modalbody-details {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.2px;
  line-height: 20px;
  width: 63px;
}

.modalbody-desc {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.29px;
  line-height: 20px;
  width: 67px;
}

.modalbody-column {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modal-custom-BOI-container {
  /* position: fixed; */
  /* display: flex; */
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  height: 100%;
  /* width: 20vw; */
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.modal-container.show {
  opacity: 1;
  pointer-events: all;
}

.modal-Custom {
  width: 70vw;
  height: 80vh;
  /* max-width: 100%; */
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* background-color: #c47474; */
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.row-inside {
  display: -ms-flexbox;
  display: flex;
}

.row-outside {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
  display: -ms-flexbox;
  display: flex;
}

.row-outside h3 {
  position: absolute;
  margin: 2rem 3rem;
  font-size: x-large;
}

#close {
  position: absolute;
  margin: 1em 65rem;
  color: gray;
  border: none;
  background-color: white;
}

.col-50 {
  -ms-flex: 25% 1;
      flex: 25% 1;
  margin: 4rem 3rem;
}

.col-50 h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
}

.col-50 p {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.col-25 {
  -ms-flex: 12.5% 1;
      flex: 12.5% 1;
}

/* NOTIF HISTORY START */

.modal-dialog.notif-history-modal.modal-dialog-centered {
  max-width: 95vw !important;
}

.modal-dialog.notif-history-modal.modal-dialog-centered>div:first-child>div:nth-child(2) {
  padding-bottom: 30px !important;
}

.modal-dialog.notif-history-modal.modal-dialog-centered>div:first-child>div:nth-child(2)>div:first-child {
  padding-bottom: 5px;
}

.modal-dialog.notif-history-modal.modal-dialog-centered>div:first-child>div:first-child {
  padding: 20px 40px 0 40px !important;
}

.modal-dialog.notif-history-modal.modal-dialog-centered>div:first-child>div:first-child>.modal-title.h4 .close-modal-btn {
  height: 15px !important;
}

.notif-history-container>div:first-child {
  margin-top: -20px;
  padding-bottom: 60px;
}

.notif-history-container {
  /* height: 480px; */
}

.notif-table-container {
  border: 1px solid rgb(223, 227, 232);
  border-radius: 5px 5px 0px 0px;
  border-width: 1px 1px 0 1px;
}

.notif-table-header {
  background: rgb(249, 250, 251);
  border-bottom: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
}

.notif-table-body {
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  overflow-y: scroll;
  height: 260px;
}

.notif-table-header .row {
  padding: 15px 13px 7px 15px;
  color: #52657f;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  margin: 0;
}

.notif-table-header>.row>div {
  padding: 0 5px;
  display: -ms-flexbox;
  display: flex;
}

.notif-table-body>.row {
  margin: 0;
  padding: 19px 8px 8px 8px;
  height: 55px;
}

.notif-table-body>.row>div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notif-table-body>.row:nth-of-type(even) {
  background: #f9fafb !important;
  border: 1px solid #e7eaee !important;
  border-width: 0.5px 0 0.5px 0 !important;
}

.notif-sort {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.sort-btn-container {
  width: 20px;
  height: 16px;
  padding-left: 10px;
}

#notif-filter-popover {
  width: 21.5rem;
  max-width: inherit;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  border: none;
  left: -64px !important;
}

#notif-filter-popover.popover .arrow {
  display: none;
}

#notif-filter-popover .popover-body {
  padding: 0;
}

#notif-filter-popover .popover-body>div>div:first-child {
  padding: 0.75rem 1.25rem;
}

/* 
#notif-filter-popover .popover-body > div:nth-child(2) {
  padding: 0 !important;
} */

#notif-filter-popover .search-bar-container .search-bar {
  position: relative;
}

.filter-btn-container {
  padding-left: 10px;
}

@media (min-width: 992px) {
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:first-child, .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:first-child {
    max-width: 14% !important;
    padding-left: 30px;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(2), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(2) {
    max-width: 17% !important;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(3), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(3) {
    min-width: 25% !important;
    padding-right: 25px;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(4), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(4) {
    min-width: 30% !important;
    padding-left: 80px;
    padding-right: 20px;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(5), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(5) {
    width: 15% !important;
    margin-left: 110px;
  }
  .notif-history-container .notif-history-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(6), .notif-history-container .notif-history-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(6) {
    padding-left: 100px;
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(2), .notif-table-body>.row>div.col-lg-2:nth-child(2) {
    -ms-flex: 0 0 33%;
        flex: 0 0 33%;
    max-width: 33%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(3), .notif-table-body>.row>div.col-lg-2:nth-child(3) {
    -ms-flex: 0 0 10%;
        flex: 0 0 10%;
    max-width: 10%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(4), .notif-table-body>.row>div.col-lg-2:nth-child(4),
  .notif-table-header>.row>div.col-lg-2:nth-child(5), .notif-table-body>.row>div.col-lg-2:nth-child(5) {
    -ms-flex: 0 0 12%;
        flex: 0 0 12%;
    max-width: 12%
  }

  .notif-table-header>.row>div.col-lg-1:nth-child(7), .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    -ms-flex: 0 0 5%;
        flex: 0 0 5%;
    max-width: 5%
  }

}

.new-notif-hist, .old-notif-hist {
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.new-notif-hist {
  color: rgb(21, 24, 27);
}

.old-notif-hist {
  color: rgb(99, 115, 129);
  /* padding-top: 10px; */
}

.new-notif-hist-cell {
  margin-top: -11px;
}

.new-notif-hist-cell>span, .old-notif-hist-cell>span {
  color: rgb(156, 106, 222);
  font-family: Graphik-Medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.16px;
}

.new-notif-hist-cell>div:first-child {
  /* margin-top: -20px; */
}

.new-notif-hist-cell>div:first-child>.svg-container.normal {
  /* padding-right: 5px; */
}

.notif-status>div:first-child {
  padding-right: 7px;
}

@media (min-width: 1920px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: none;
  }
  .notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
    max-width: 155px !important;
  }
}

@media (min-width: 1535px) and (max-width: 1919px) {
  .notif-table-header>.row>div.col-lg-2, .notif-table-body>.row>div.col-lg-2 {
    -ms-flex: 0 0 16%;
        flex: 0 0 16%;
    max-width: 16%
  }

  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 23px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: 0px !important;
  }
}

@media (min-width: 1601px) and (max-width: 1919px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 248px;
  }
  .notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
    max-width: 155px !important;
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 228px;
  }
  .notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
    max-width: 155px !important;
  }
}

@media (min-width: 1351px) and (max-width: 1440px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 194px;
  }
  .notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
    max-width: 155px !important;
  }
}

@media (min-width: 1421px) and (max-width: 1599px) {
  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 1px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: -20px !important;
  }
}

@media (min-width: 1395px) and (max-width: 1535px) {
  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: -9px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: -29px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1350px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 175px;
  }
  .notif-table-header>.row>div.col-lg-2, .notif-table-body>.row>div.col-lg-2 {
    -ms-flex: 0 0 15.3333%;
        flex: 0 0 15.3333%;
    max-width: 15.3333%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(2), .notif-table-body>.row>div.col-lg-2:nth-child(2) {
    -ms-flex: 0 0 21%;
        flex: 0 0 21%;
    max-width: 21%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(3), .notif-table-body>.row>div.col-lg-2:nth-child(3) {
    -ms-flex: 0 0 12.3333%;
        flex: 0 0 12.3333%;
    max-width: 12.3333%
  }
  .notif-table-header>.row>div.col-lg-2:nth-child(6), .notif-table-body>.row>div.col-lg-2:nth-child(6) {
    -ms-flex: 0 0 13.3333%;
        flex: 0 0 13.3333%;
    max-width: 13.3333%
  }

  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 42px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: 20px !important;
  }
}

@media (min-width: 1162px) and (max-width: 1279px) {
  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 0px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: -20px !important;
  }
}

@media (min-width: 1101px) and (max-width: 1200px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 153px;
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  .notif-table-header>.row>div, .notif-table-body>.row>div {
    max-width: 129px;
  }

  .notif-table-header>.row>div.col-lg-2, .notif-table-body>.row>div.col-lg-2 {
    -ms-flex: 0 0 15%;
        flex: 0 0 15%;
    max-width: 15%
  }

  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: 25px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: 5px !important;
  }
}

@media (min-width: 1023px) and (max-width: 1162px) {
  .notif-table-header>.row>div.col-lg-1:nth-child(7) {
    margin-left: -9px !important;
  }

  .notif-table-body>.row>div.col-lg-1:nth-child(7) {
    margin-left: -26px !important;
  }
}

.notif-table-header>.row>div:nth-child(7) {
  max-width: 85px;
  margin-left: 25px;
}

.notif-table-body>.row>div:nth-child(7) {
  max-width: 70px;
  margin-left: 8px;
  padding-top: 7px;
}

.notif-table-header>.row>div:nth-child(6), .notif-table-body>.row>div:nth-child(6) {
  max-width: 119px;
}

.notif-history-searchbar .search-bar {
  position: relative;
}

.notif-history-searchbar.search-bar-container {
  height: 40px;
  display: -ms-flexbox;
  display: flex;
}

#notifSearchBox {
  width: 320px;
  border-radius: 20px 0 0 20px;
  height: 40px;
}

.importance-btn-container {
  width: 182px;
  border-radius: 0 20px 20px 0;
  float: left;
  height: 40px;
  cursor: default;
  border: 1px solid rgb(231, 234, 238);
  margin: 10px 0 0 0;
  padding-left: 13px;
  padding-top: 3px;
  display: -ms-flexbox;
  display: flex;
}

.importance-btn-clicked>.importance-button {
  background: #fff5f7 !important;
  color: #aa0025 !important;
  border: 1px solid #ea8097 !important;
}

.importance-btn-clicked>.important-x-btn-container svg.importance-plus-circle {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  cursor: pointer;
}

.importance-button {
  height: 32px;
  width: 150px;
  /* margin-top: 3px; */
  color: rgb(21, 24, 27);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  background: rgb(239, 242, 245);
  border-radius: 16px;
  border: 1px solid rgb(181, 192, 202);
  /* padding-top: 4px; */
  padding-left: 10px;
  /* margin-left: 15px; */
}

.importance-button>span {
  padding-left: 8px;
  padding-right: 8px;
  font-family: Graphik-Medium;
}

.importance-button>div:first-child {
  padding-left: 8px;
  padding-top: 4px;
}

.important-x-btn-container {
  padding-top: 6px;
  font-size: 11px;
  margin-left: -30px;
  z-index: 100;
  position: relative;
}

.importance-btn-container:hover {
  cursor: pointer;
}

.table-action-button {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  right: -15px !important;
  top: -10px !important;
}

.table-action-button .svg-container {
  -webkit-transform: none !important;
          transform: none !important;
  font-size: 0.9em !important;
}

.table-action-button>.svg-container>svg {
  width: 11px;
  cursor: pointer;
}

#notif-hist-tooltip {
  z-index: 3010;
  margin-left: -55px;
}

#notif-hist-tooltip .arrow {
  margin-left: 55px;
}

#notif-hist-tooltip .tooltip-inner {
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  max-width: 500px;
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 20px;
  color: rgb(33, 43, 54);
  padding: 0.7rem 0.7rem;
}

#notif-hist-tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff !important;
}

#notif-hist-tooltip .tooltip-parameter {
  margin-right: 20px;
}

#notif-hist-tooltip.tooltip.show {
  opacity: 1;
}

.notif-impt {
  cursor: default;
}
.contact-us-subject{
    height: 56px;
}

.contact-us-desc{
    height: 128px;
}

/** float container */
.contact-float-container {
    border-radius: 8px;
    border: 1px solid rgba(223, 227, 232, 1);
    /* background-color: rgb(55, 65, 81); */
    background-color: rgba(255, 255, 255, 1);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 16px;
    padding: 0 8px;
    position: relative;
    width: 100%;
    /** active label */
  }
  .contact-float-container input {
    /* border: none;
      font-size: 16px;
      outline: 0;
      padding: 16px 0 10px;
      width: 100%; */
  
    border: none;
    outline: 0;
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    /* height: 56px; */
    width: 100%;
    margin-top: 16px;
  }

  .contact-float-container textarea {
    border: none;
    outline: 0;
    resize: none;
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    height: 70%;
    margin-top: 16px;
    width: 100%;
  }
  .contact-float-container label {
    font-size: 16px;
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: translate(0, 16px) scale(1);
            transform: translate(0, 16px) scale(1);
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  
    opacity: 1;
    color: rgba(21, 24, 27, 1);
    /* background-color: rgb(55, 65, 81); */
    font-family: "Graphik-Regular";
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.35px;
    text-align: left;
    line-height: 20px;
  }
  .contact-float-container.active label {
    -webkit-transform: translate(0, 4px) scale(0.75);
            transform: translate(0, 4px) scale(0.75);
  }
  
  .contact-float-container_dark {
    border-radius: 8px;
    /* border: 1px solid rgba(223,227,232, 1); */
    background-color: rgb(55, 65, 81);
  
    -webkit-box-sizing: border-box;
  
            box-sizing: border-box;
    margin-bottom: 16px;
    padding: 0 8px;
    position: relative;
    width: 100%;
    /** active label */
  }
  .contact-float-container_dark input {
    /* border: none;
      font-size: 16px;
      outline: 0;
      padding: 16px 0 10px;
      width: 100%; */
  
    border: none;
    outline: 0;
    opacity: 1;
    /* background-color: rgba(255,255,255, 1); */
    background-color: rgb(55, 65, 81);
    color: white;
    /* height: 56px; */
    width: 100%;
    margin-top: 16px;
  }
  .contact-float-container_dark textarea {
    border: none;
    /* font-size: 16px; */
    outline: 0;
    /* padding: 16px 0 10px;
      width: 100%; */
    resize: none;
    opacity: 1;
    /* background-color: rgba(255,255,255, 1); */
    background-color: rgb(55, 65, 81);
    color: white;
    height: 70%;
    /* width: 937px; */
    margin-top: 16px;
    /* margin-left: 16px; */
    width: 100%;
  }
  .contact-float-container_dark label {
    font-size: 16px;
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: translate(0, 16px) scale(1);
            transform: translate(0, 16px) scale(1);
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  
    opacity: 1;
    /* color: rgba(21,24,27,1); */
    color: rgba(255, 255, 255, 1);
    background-color: rgb(55, 65, 81);
    font-family: "Graphik-Regular";
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.35px;
    text-align: left;
    line-height: 20px;
  }
  .contact-float-container_dark.active label {
    -webkit-transform: translate(0, 4px) scale(0.75);
            transform: translate(0, 4px) scale(0.75);
  }
  .dark-contact-nav-tabs.active {
    background-color: rgb(33, 43, 54);
  }


.hAXbWy {
    background-color: rgb(239, 242, 245);
    margin: 30px 0px 0px 0px !important;
    padding: 10px;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    border-radius: 5px;
    -ms-flex-align: center;
        align-items: center;
}

.cFOIUY {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.bRDpfN {
  margin: 0px 0px 0px 20px;
  display: -ms-flexbox;
  display: flex;
}

.dAhoyD {
  font-size: 12px;
  width: 100px;
}

.kbxoxF {
  color: rgb(145, 158, 171);
  font-size: 10px;
}

.la-dTuR {
  width: 400px;
}

.css-fbkt3g {
  font-weight: 500!important;
  width: 42px;
  margin-right: 10px;
  margin-left: 5px;
  color: RGB(128, 0, 204);
  font-size: 32px;
}

.contact-us-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.support-text {
  font-size: 12px;
  width: 210px;
}

.cursor {
  cursor: pointer;
}

.cGODdJ {
  margin: 0px 10px 0px 0px;
}

.fJlQtE {
  background-color: transparent;
  padding: 5px;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid rgb(145, 158, 171);
  border-radius: 3px;
  -ms-flex-align: center;
      align-items: center;
}

.jzuRnk {
  font-size: 12px;
  margin: 0px 5px 0px 0px;
  width: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.feedbackFormModal .modal-dialog {
  max-width: 75%;
}
.feedbackFormModal .modal-body {
  padding: 26px 32px 14px;
  /* max-height: calc(90vh - 128px);  */
  max-height: unset !important;
  overflow-y: auto;
  overflow-x: hidden; 
}
.feedbackFormModal .modal-content {
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1), 1px 0px 0px 0px rgb(244, 246, 248);
          box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1), 1px 0px 0px 0px rgb(244, 246, 248);
  border-radius: 16px;
  overflow: visible;
}
.feedbackFormModal .modal-content .modal-header {
  height: 56px;
  background: rgb(128, 0, 204);
  border-radius: 16px 16px 0px 0px;
  padding: 0 0 0 66px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.feedbackFormModal .modal-content .modal-header .close {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAJKADAAQAAAABAAAAJAAAAAAqDuP8AAACMUlEQVRYCb3YyVHDMBQA0C81ACc6gCpIKiBLCxw4wUABOMkkhAJg4MYMLWSBBghUQQec0gAW/9sWkWPJthZbF9mKlucvyUvY8v2rC/HvixDsABjMhr3OM7SYFuvNJQgY49hbxvkFhzh+FQKOAcQRCPG0WG1u2/Is15uIxszGPiELFwCHeYCYJxXzhcHPaAwhxJ3aMVk4FkzVQjqmik1GSofJDFOOa+YRTyZZgZKJeRMo6nM/MtmgE7JQhGDY784w06JCTl/al5hnADWbZAZc20parD7GeFqYQsbYaNDr6DpSWpcflkzTP4Z6yIGoYPn2ORJxTBHLJR+UCYPbfDw4O80t7AKIFCFRNhgaWwsKhbLFlIJ8US6YSpAryhVTC2SL8sHUBtVF+WKsQFUo+l13B9ZtbaprSsZdZmpguiXo6ttiqA9rEDWqg3LBOIOqUK4Y6jd5uNJB0ISLybW/5qbM8YFsHaE664eiQzuO6tpGyipCJgy9CUjEPsB2PdUGlWHku1JrN8Y6GBkZX1RlhGwwIVClIBeML8oI8sH4oLSgkC/7tmuqAAqJcYlU7sZowuAzOJJbWw5ik6dtWbTfhr5uaGmo5f8RKsMM+517tZHrcfolXPxQVG+eCagNjLwIEwp/Tz4YGf4/c40PngfZYJezKFRkdn2mR0YUYze0hjTf9M1hiJReaHFNkYXjnG2p0i41i5Hj6FBk4cD5OWPwjTvpBxi7amqaJETNk7FwTBo7MaDlD3Zux5+vs/ONAAAAAElFTkSuQmCC) no-repeat;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 16px;
  width: 16px;
}
.feedbackFormModal .modal-content .modal-header .close:hover {
  background-color: transparent;
  opacity: 0.9;
}
.feedbackFormModal .modal-content .modal-header .modal-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.33px;
  position: relative;
  margin-bottom: 40px;
  margin-left: 42px;
}
.feedbackFormModal .modal-content .modal-header .modal-title::before {
  position: absolute;
  content: "";
  display: -ms-inline-flexbox;
  display: inline-flex;
  left: -42px;
  top: 0;
  bottom: 0;
  width: 26px;
  height: 24px;
  margin: auto;
  background: url(/static/media/feedback-white.767adb07.svg) no-repeat;
}
.feedbackFormModal .modal-content .modal-header button,
.feedbackFormModal .modal-content .modal-header button:hover,
.feedbackFormModal .modal-content .modal-header button:active {
  color: #ffff;
  outline: none;
}
.feedbackFormModal .modal-content .modal-footer {
  height: 72px;
  background-color: rgb(244, 246, 248);
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-right: 32px;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.feedbackFormModal .modal-content .modal-footer .success-feedback {
  height: 72px;
  background-color: rgb(244, 246, 248);
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.feedbackFormModal .modal-content .modal-footer p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  padding: 0 32px;
}
.feedbackFormModal .modal-content .modal-footer p span {
  font-weight: 600;
}
.feedbackFormModal .modal-content .modal-footer button,
.feedbackFormModal .modal-content .modal-footer button:hover,
.feedbackFormModal .modal-content .modal-footer button:active {
  background: rgb(161, 0, 255);
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 14px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
  padding: 13px 24px;
}
.feedbackFormModal .modal-content .modal-footer .btn.disabled {
  opacity: 0.3;
  background: rgb(161, 0, 255);
}
.feedbackFormModal .modal-content .modal-footer button:active:focus,
.feedbackFormModal .modal-content .modal-footer button:focus,
.feedbackFormModal .modal-content .modal-footer button:hover {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: rgb(179, 51, 255);
}
.feedback-form-wrapper p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 22px;
}
.feedbackFormModal .tabs-container ul.nav-pills li {
  width: calc(33.33% - 22px);
  height: 84px;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15);
          box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15);
  border-radius: 8px;
  margin: 0 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
}
.feedbackFormModal .tabs-container ul.nav-pills li:first-child {
  margin-left: 0;
}
.feedbackFormModal .tabs-container ul.nav-pills li:last-child {
  margin-right: 0;
}
.feedbackFormModal .tabs-container ul.nav-pills li span {
  display: none;
}
.feedbackFormModal .tabs-container ul.nav-pills li:hover span {
  position: absolute;
  background: #212b36;
  padding: 14px 12px;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 4px;
  z-index: 999 !important;
  top: 84px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.feedbackFormModal .tabs-container ul.nav-pills li:hover span:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #000;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.feedbackFormModal .tabs-container .nav-pills .nav-link {
  width: 100%;
  line-height: 60px;
  padding: 0px;
  color: rgb(99, 115, 129) !important;
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
  border-radius: 8px !important;
  padding-top: 24px;
  position: relative;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link.active {
  border-left: none;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(33, 43, 54) !important;
  font-size: 16px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.35px;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link::after,
.feedbackFormModal .tabs-container .nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 4px;
  background: rgb(161, 0, 255);
  border-radius: 0px 0px 4px 4px;
  left: 0;
  right: 0;
  width: 0%;
  margin: auto;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link:hover::after,
.feedbackFormModal .tabs-container .nav-pills .nav-link.active::after {
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: 100%;
}
.feedbackFormModal .tabs-container .nav-pills .nav-link::before {
  position: absolute;
  content: "";
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 24px;
  height: 24px;
  left: 0;
  right: 0;
  top: 16px;
  margin: auto;
}
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.general::before {
  background: url("../Images/general-inactive.svg") no-repeat;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.general.active::before {
  background: url("../Images/general-active.svg") no-repeat;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.enhancement::before {
  background: url("../Images/enhancement-inactive.svg") no-repeat;
  top: 24px;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.enhancement.active::before {
  background: url("../Images/enhancement-active.svg") no-repeat;
  top: 24px;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.newidea::before {
  background: url("../Images/newidea-inactive.svg") no-repeat;
} */
/* .feedbackFormModal .tabs-container .nav-pills .nav-link.newidea.active::before {
  background: url("../Images/newidea-active.svg") no-repeat;
} */
.feedbackFormModal .tab-content .tab-pane h2 {
  color: rgb(161, 0, 255);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  margin: 0px 0px 6px 0px;
  padding-top: 10px;
}
.feedbackFormModal .tab-content .rating-star {
  margin-bottom: 10px;
}
.feedbackFormModal .tab-content .rating-star span {
  margin-right: 26px;
  cursor: pointer;
  position: relative;
  width: 26px;
  height: 26px;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.sup-rating {
  top: 0px;
  color: #aa0025;
  font-size: 16px;
  font-weight: bold;
}
.feedbackFormModal .tab-content .rating-star span::after {
  content: "";
  display: -ms-inline-flexbox;
  display: inline-flex;
  /* background: url("../Images/star-inactive.svg"); */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  width: 26px;
  height: 26px;
}
.feedbackFormModal .tab-content .rating-star span.checked::after {
  content: "";
  display: -ms-inline-flexbox;
  display: inline-flex;
  /* background: url("../Images/star-active.svg"); */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  width: 26px;
  height: 26px;
}
.feedbackFormModal .tab-content .form-check .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  cursor: pointer;
}
.feedbackFormModal .tab-content .form-check.checkbox-checked .form-check-label {
  font-family: Graphik-Medium;
  font-weight: 500;
}
.feedbackFormModal .tab-content label.form-check {
  padding: 0px 0 8px 30px;
  margin-top: 8px;
}
.feedbackFormModal .tab-content .form-check .checkmark {
  top: -7px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

/* .form-control {
  width: 95%;
} */

.contactus-tab-panel {
  padding: 0px 32px 32px;
  height: 100%;
  width: 96%;
  overflow-y: auto;
  overflow-x: hidden;
}

.contact-us-header {
  color: white;
}

.form-control-feedback {
  height: 200px;
}

.support-modal-body {
  padding: 0px 32px 32px;
  overflow-y: auto;
  overflow-x: hidden;
}

.issue-textbox {
  outline: unset !important;
}

.access-issue-input {
  height: 130px;
  resize: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
}

.feedback-input {
  height: 200px;
  resize: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
}

.contact-us-textarea-error {
  border: 1px solid red !important;
}

.success-message {
  color: green !important;
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 0px  !important;
}
  .upload-support-document {
    max-width: 750px !important;
  }
  
  .upload-support-document .modal-content {
    -webkit-box-shadow: 0 0 12px rgb(60 60 60);
            box-shadow: 0 0 12px rgb(60 60 60);
    cursor: default;
    border: unset;
    overflow: hidden;
  }
  
  .upload-support-document .content-wrapper {
    padding: 20px !important;
  }
  
  .upload-support-document .md-stepper-horizontal {
    margin: unset;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    border: unset;
    display: -ms-flexbox;
    display: flex;
    padding: unset;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    width: 100%;
    border-bottom: 2px solid #e7eaee;
    cursor: pointer;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step:active {
    border-radius: unset;
  }
  
  .upload-support-document .md-stepper-horizontal .active {
    border-bottom: 3px solid rgb(117, 0, 192);
  }
  
  .upload-support-document .modal-tab-content {
    padding: 20px;
    min-height: 250px;
  }
  
  .upload-support-document .modal-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end;
    padding-top: 20px;
  }
  
  .upload-support-document .modal-buttons button {
    width: 80px;
    margin-left: 10px;
    -ms-flex-pack: center;
        justify-content: center;
  }
  
  #groove-radio-button.upload-support-radio .form-check {
    margin: 15px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  
  #groove-radio-button.upload-support-radio .form-check > label {
    margin-left: 10px !important;
  }
  
  #groove-radio-button.upload-support-radio input.form-check-input {
    margin-top: 0;
    height: 20px;
    width: 20px;
    content: '';
    display: inline-block;
    visibility: visible;
    -moz-appearance: initial;
  }
  
  #groove-radio-button.upload-support-radio input.form-check-input[type='radio']:after {
    content: '';
    display: block;
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1.5px solid #6c757d;
  }
  
  #groove-radio-button.upload-support-radio input.form-check-input[type='radio']:hover:after,
  #groove-radio-button.upload-support-radio
    input.form-check-input[type='radio']:checked:after {
    height: 20px;
    width: 20px;
    border: 1.5px solid rgb(161, 0, 255) !important;
  }
  
  #groove-radio-button.upload-support-radio
    input.form-check-input[type='radio']:checked::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid white;
    background: rgb(161, 0, 255);
  }
  
  #groove-radio-button.upload-support-radio
    input.form-check-input[type='radio']:checked:after {
    height: 20px;
    width: 20px;
    border: 1.5px solid rgb(161, 0, 255) !important;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step.inactive .md-step-img-circle,
  .upload-support-document .md-stepper-horizontal .md-step.disabled .md-step-img-circle {
    background: rgb(161, 0, 255);
    opacity: .2;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step.inactive .md-step-img-circle {
    cursor: pointer;
  }
  
  .upload-support-document .md-stepper-horizontal .md-step .md-step-title {
    font-size: 12px;
    font-family: 'Graphik-Regular';
    color: rgb(70, 0, 115);
  }

  .upload-support-document .md-stepper-horizontal .md-step.active .md-step-title {
    font-size: 16px;
    font-family: 'Graphik-Medium';
  }
  
  .upload-support-document .cvm-dropzone {
    padding: 20px;
    margin: unset;
  }
  
  .upload-support-document .uploaded-files {
    width: 80%;
    padding: 0;
  }
  
  .upload-support-document .uploaded-files-container {
    margin: 10px 0 0 0;
  }
  
  
  .upload-support-document .view-button-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    height: 14px;
  }
  
  .upload-support-document .view-button {
    cursor: pointer;
  }
  
  .upload-support-document .view-button-wrapper,
  .upload-support-document .view-last-wrapper {
    color: #919eab;
    font-family: Graphik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 14px;
    margin-top: 10px;
  }
  
  .upload-support-document .view-last-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding: 10px;
  }
  
  .upload-support-document .view-button svg,
  .upload-support-document .view-last-wrapper > div:first-child span {
    margin-left: 5px;
    word-break: break-all;
  }

  .upload-support-document .view-last-wrapper > div:first-child {
    width: 70%;
  }

  .upload-support-document .view-last-wrapper > div:nth-child(2) {
    width: 150px;
    text-align: center;
  }
  
  .upload-support-tab-icon {
    width: 50px;
    height: 50px;
    background-color: white;
    -webkit-mask: url(/static/media/UploadSupportDocs.5f9b114b.svg) no-repeat center;
    mask: url(/static/media/UploadSupportDocs.5f9b114b.svg) no-repeat center;
  }
  
  .doc-type-tab-icon {
    width: 50px;
    height: 50px;
    background-color: white;
    -webkit-mask: url(/static/media/DocType.242a65f8.svg) no-repeat center;
    mask: url(/static/media/DocType.242a65f8.svg) no-repeat center;
  }
  
  .view-last-icon {
    color: rgb(156, 106, 222);
  }
  
  .view-last-download {
    cursor: pointer;
    color: rgb(70, 0, 115);
  }
  
  .upload-support-document .modal-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #ffffffcc;
    top: 0;
    left: 0;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }

  .upload-support-document .link-wrapper {
    padding: 20px 30px;
    background: rgb(251, 249, 254);
    border-radius: 16px;
    border: 2px dashed rgb(145, 158, 171);
    margin-bottom: 20px;
  }

  .upload-support-document .link-wrapper > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }

  .upload-support-document .link-wrapper input {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    font-size: 12px;
    margin-left: 10px;
  }

  .upload-support-document .link-wrapper input,
  .upload-support-document .link-wrapper input::-webkit-input-placeholder {
    font-family: Graphik-Regular;
    font-size: 14px;
    font-weight: normal;
    height: 14px;
    letter-spacing: -0.25px;
    line-height: 14px;
  }
@media only screen and (max-width: 2020px) and (min-width: 1821px) {
  .col-sm-2.target-realized-approved {
    -ms-flex: 0 0 14.66667%;
        flex: 0 0 14.66667%;
    max-width: 14.66667%;
  }
  .col-sm-1.wVis-approved.dashboard-visibility-rvd {
    -ms-flex: 0 0 10.33333%;
        flex: 0 0 10.33333%;
    max-width: 10.33333%;
  }
}

@media only screen and (max-width: 1200px) {
  /* Program ALA*/
  .dimension-table.dimension-table-program,
  .ALATable,
  .boi-history .dimension-table,
  .dimension-table,
  .rvd-subdimension-table-wrap,
  .file-history-container {
    overflow-x: scroll;
  }
  .dimension-table .dimension-table-header,
  .dimension-table .dimension-table-body,
  .ALATable .dimension-table-header,
  .ALATable .dimension-table-body,
  .rvd-subdimension-table,
  .file-history-container .select-boi-header,
  .file-history-container .select-boi-body,
  .ag-grid-table-wrap > div {
    width: 1100px;
  }
  .AA-accordion-dropdown div#dropdown-list {
    max-height: 94px;
  }
  /* ADD BOI MODAL */
  /* .added-boi-container, .add-boi-box-container{
    overflow-x: scroll !important;
  } */
  /* .add-boi-field-container{
    width: 1100px;
  } */

  /*BOI HISTORY*/
  .boi-history-content {
    overflow: hidden;
  }

  /* POP OVER */
  #boi-tooltip-right.bs-tooltip-top .tooltip-inner {
    margin-bottom: 0;
  }
  #boi-tooltip-right.bs-tooltip-top .arrow:before {
    border-top-color: #fff !important;
  }

  /* Project Config*/
  .add-boi-modal .select-boi-modal-column:first-child {
    width: 50px !important;
  }
  .add-boi-modal .select-boi-modal-column {
    padding-right: 5px;
    width: 15% !important;
  }
  .program-lead-landing
    .applyFlex-align-center.apply-relative
    .single-select-dropdown
    .single-select-label {
    width: 25% !important;
  }
  .fileupload-browse {
    text-align: center;
  }

  /* RVD File history*/
  .file-history-container .file-history-column:first-child {
    width: 5% !important;
  }
  .file-history-container .file-history-column:nth-child(2) {
    width: 33% !important;
  }
  .file-history-container .file-history-column:nth-child(3) {
    width: 25% !important;
  }
  .file-history-container .file-history-column:nth-child(4) {
    width: 20% !important;
  }
  .file-history-container .file-history-column:nth-child(5) {
    width: 17% !important;
  }

  .client-story-filename,
  .clientstory-download {
    width: 50% !important;
  }

  .rvd-subdimension-table-wrap .app-rej-awapp {
    max-width: 20%;
  }

  .ag-grid-table-wrap {
    overflow-x: scroll;
  }
  .ag-center-cols-viewport {
    overflow: unset !important;
  }
  .ag-body-horizontal-scroll-viewport {
    display: none;
  }

  /* ADMIN */
  .iomanagement-table-container .iouser-table tr th:nth-child(2),
  .iomanagement-table-container .iouser-table tr td:nth-child(2) {
    width: 20% !important;
  }

  /* Dashboard */
  .col-md-5.graph-view-section {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 35%;
    padding-left: 0;
    padding-right: 0;
  }
  .col-md-7.details-view-section {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 65%;
  }
  .cumulative-container {
    padding: 10px;
  }
  .visualization-collapse.configure-client-collapse .card-body {
    padding: 0px 10px 20px 10px !important;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px){
  .graph-view-section .recharts-pie-labels > g.recharts-layer > text > title ~ tspan {
    font-size: 6px!important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px){
  .program-card-detail {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .program-card-wrap {
    grid-auto-rows: 45px 31% 26% auto auto auto !important;
    height: 69% !important;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px){
  .graph-view-section .recharts-pie-labels > g.recharts-layer > text > title ~ tspan {
    font-size: 6px!important;
  }
}

@media only screen and (max-width: 1100px) {
  g.recharts-layer text, g.recharts-layer text tspan {
    font-size: 6px !important;
  }
}

@media only screen and (max-width: 1044px) {
  .data-visualisation-container .title-section {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .data-visualisation-container .value-meter-title {
    width: 100%;
  }
  .data-visualisation-container .dIL-dropdowns {
    margin-top: 10px;
  }

  /*RVD*/
  #aggregated-boi-popover,
  #aggregated-boi-popover .popover-body {
    width: 550px !important;
  }
}

@media only screen and (max-width: 1031px) {
  /* RVD CLIENT DETAILS */
  .projectEntry-container .sub-title > div {
    width: 100%;
  }
  .projectEntry-container .sub-title {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .sub-title-header.row > div {
    width: 50%;
    margin: 0;
    -ms-flex-pack: end;
        justify-content: flex-end;
  }
  .projectEntry-container .sub-title .sub-title-header h5 {
    text-align: left;
  }
  .sub-title .button-container > .pde-btn {
    width: 33.33%;
  }
  .sub-title .button-container > .pde-btn > div {
    width: 100%;
    -ms-flex-pack: center;
        justify-content: center;
    position: relative;
  }
  .reset-all-button img {
    left: 20px;
  }
  .projectEntry-container .sub-title .button-container {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

@media only screen and (max-width: 1024px) {
  /* ADD BOI MODAL */
  /* .added-boi-container, .add-boi-box-container{
    overflow-x: scroll !important;
  } */

  .customboi-card-collapse > button#primry-btn-1 {
    width: 120px;
    margin-right: 20px;
  }
  .accordion-action-btns button#primry-btn-2 {
    width: 140px;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .customboi-card-collapse button#primry-btn-1 {
    width: 85px;
    margin-right: 10px;
  }
  .lead-options {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .lead-options label.custom-boi-label {
    padding-right: 0;
  }
  .custom-boi-radio {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .lead-options,
  .lead-title {
    width: 100%;
  }
  .customboi-header-btn #groove-btn-id {
    margin-left: 0px !important;
  }

  .delete-cusboi button#primry-btn-1,
  .update-cusboi button#primry-btn-1 {
    width: 110px;
    padding: 5px 12px;
    -ms-flex-pack: center;
        justify-content: center;
  }

  /* BOI POPOVER*/
  #boi-tooltip-right.bs-tooltip-top .tooltip-inner {
    margin-bottom: 0;
    max-width: 500px !important;
    min-width: 500px !important;
  }
  #boi-tooltip-right.bs-tooltip-top .arrow:before {
    border-top-color: #fff !important;
  }
  #boi-tooltip-right.bs-tooltip-left .arrow:before {
    border-left-color: #fff !important;
  }
  #boi-tooltip-right .tooltip-inner {
    max-width: 500px !important;
    min-width: 500px !important;
  }

  /* Project Config*/
  .select-boi-modal-column {
    width: 25% !important;
  }

  /* RVD */
  .new-pde .applyFlex-align-center.apply-relative .single-select-dropdown .single-select-label {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  /* Home */
  .modules-cards-container > div {
    margin-bottom: 20px;
  }

  /* Modal */
  .modal-dialog.modal-md {
    max-width: 85% !important;
  }

  /* Program ALA*/
  .program-lead-landing
    .applyFlex-align-center.apply-relative
    .single-select-dropdown
    .single-select-label {
    width: 90px;
  }

  /* ADD BOI MODAL */
  .add-boi-field-container {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .add-boi-field-container .select-boi-dropdown {
    -ms-flex: 50% 1 !important;
        flex: 50% 1 !important;
    max-width: unset;
    margin-bottom: 5px;
    width: 50% !important;
  }

  .add-boi-field-container .select-boi-dropdown:first-child,
  .add-boi-field-container .select-boi-dropdown:nth-child(2) {
    margin-bottom: 5px;
  }

  #groove-select-dropdown .default-display {
    width: 75% !important;
  }

  .tabbed-dropdown-value {
    width: 80% !important;
  }

  .addboi-dropdown-containers .addedBOI-dropdown {
    max-width: unset !important;
    width: 50%;
  }

  .addboi-dropdown-containers {
    width: 90% !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    height: auto !important;
  }

  .new-info-wrap,
  .delete-boi {
    width: 5% !important;
  }

  .addboi-dropdown-containers .addedBOI-dropdown:first-child,
  .addboi-dropdown-containers .addedBOI-dropdown:nth-child(2) {
    margin-bottom: 5px;
  }

  /* BOI POPOVER*/
  .new-info-icon {
    margin-left: -3px !important;
  }

  /* Project Config */
  .program-dropdown-project-landing .single-select-label {
    width: 20% !important;
  }
  .project-header-select .program-dropdown,
  .project-header-select .project-dropdown {
    max-width: 95% !important;
    -ms-flex: 100% 1 !important;
        flex: 100% 1 !important;
    width: 100% !important;
  }
  .project-header-select .project-dropdown {
    margin-top: 5px;
  }
  #programDetails-popover.program-popover {
    left: unset;
    right: 325px;
  }
  #programDetails-popover.program-popover .arrow {
    right: calc(-0.5rem - 1px) !important;
    height: 1rem;
    margin: 0.3rem 0;
    left: unset;
  }
  #programDetails-popover.program-popover .arrow:before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
    left: unset;
    border-right-color: unset;
  }
  #programDetails-popover.program-popover .arrow:after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
    left: unset;
    border-right-color: unset;
  }
  /* Custom Boi */
  .program-details-row {
    border-right: 0 !important;
  }
  .fade.modal-custom-BOI-container.modal.show {
    padding: 0 20px;
  }

  /* RVD */
  .client-details-button {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .rvd-client-details-btn .dIL-dropdowns {
    width: 100%;
  }
  .dIL-dropdowns.dIL-actionrow {
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .new-proj-btn {
    padding-left: 0 !important;
  }
  .new-pde > div:first-child {
    margin-bottom: 5px;
  }
  .projectEntry-subcontainer .project-title,
  .projectEntry-subcontainer .report-prd {
    width: 50% !important;
  }
  .modal-dialog.rvd-commentary-history-modal.modal-dialog-centered {
    padding-left: 15px;
    padding-right: 15px;
  }
  #aggregated-boi-popover,
  #aggregated-boi-popover .popover-body {
    width: 363px !important;
  }
  .aggregated-boi-accordion-container {
    overflow-y: unset;
  }
  .aggregated-boi-accordion-container div#scrollable-auto-tabpanel-0 {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .aggregated-boi-label-container {
    width: 400px;
  }
  .document-name {
    word-break: break-all;
  }
  .envelope {
    padding: 0 10px !important;
  }
  /* Dashboard */
  .data-visualisation-container .statusDropdown {
    width: 390px;
  }

  .data-visualisation-container .statusDropdown .css-1csqycf-control,
  .data-visualisation-container .statusDropdown .css-144vaf5-menu,
  .data-visualisation-container .statusDropdown .css-14s1juc-control {
    width: 369px !important;
  }

  /* ADMIN */
  .iomanagement-table-container .iouser-table tr th:nth-child(2),
  .iomanagement-table-container .iouser-table tr td:nth-child(2) {
    width: 30% !important;
  }
  .iomanagement-table-container .iouser-table tr th:nth-child(1),
  .iomanagement-table-container .iouser-table tr td:nth-child(1) {
    width: 5px !important;
    padding: 0;
  }
  .select-offerings {
    margin-bottom: 1rem;
  }
  .select-offerings div#dropdown-popover,
  .select-sub-offerings div#dropdown-popover {
    left: 14px;
    bottom: 57px;
  }
  .select-profile div#selectmenu-dropdown-icon {
    margin-right: 4px !important;
  }
  .selected-profile-container-background > .row {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  #groove-select-dropdown
    div.selectmenu-options
    .searchable-dropdown-content
    .searchable-dropdown-input {
    text-overflow: ellipsis;
    width: 75%;
  }
  .upload-pending-desc {
    width: 100%;
    padding: 0 15px;
  }
  .selected-sub-offerings div#dropdown-popover {
    left: 14px !important;
    bottom: 86px !important;
  }
  .selected-profile-container-background > div.row > div {
    padding-bottom: 0;
  }
  .selected-offerings.col-md-6 {
    padding-right: 0;
  }
  .selected-offerings.col-md-5 {
    max-width: 46%;
    -ms-flex: 100% 1;
        flex: 100% 1;
  }
  .delete-profile-wrap {
    width: 4%;
    margin-top: 15px;
  }
  .selected-sub-offerings {
    max-width: 96%;
    -ms-flex: 100% 1;
        flex: 100% 1;
  }
}

@media only screen and (max-width: 922px) {
  /* Project Config */
  .project-config-select {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .landing-img-box {
    margin-left: 10px !important;
  }
  .landing-img-box img:first-child {
    margin-left: 0px !important;
    margin-right: 0 !important;
  }
  .project-config-select > div:nth-child(2) {
    width: 100%;
    margin-top: 5px;
  }

  /* DASHBAORD */
  .visualization-collapse.configure-client-collapse .card-body {
    padding: 0 10px 20px !important;
  }
  .visualization-collapse.configure-client-collapse .card-header {
    padding: 10px 25px !important;
  }

  /*RVD*/
  .rvd-new-project-data-entry-modal {
    max-width: 540px !important;
  }
}

@media only screen and (max-width: 814px) {
  /* Project Config */
  .landing-img-box {
    width: 50px;
  }
  .landing-img-box img:nth-child(2) {
    margin-left: 15px !important;
  }
  .sort-icon {
    margin-left: 5px;
  }
  .add-project-button {
    width: 176px;
  }
}

@media only screen and (max-width: 767px) {
  /* Header */
  .header_title > div {
    display: -ms-flexbox;
    display: flex;
  }
  /* Footer */
  .trademark {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 575px) {
  /* HEADER */
  #header_container {
    height: 80px;
  }
  .header_title {
    padding: 0 15px !important;
  }
  .header_title div {
    margin: 0 auto;
  }
  #navigation {
    padding: 0 15px;
    -ms-flex-pack: center;
        justify-content: center;
  }

  /* BANNER */
  .cvm-banner > div:first-child {
    padding: 100px 10px 170px 10px;
  }

  img.overlay-image {
    width: 100%;
  }

  /* Footer */
  .trademark {
    margin: 0;
    text-align: right;
  }
  .footer-links {
    margin-top: 5px;
  }
  #footer-logo {
    -ms-flex-order: 1;
        order: 1;
  }
  #company-copyright {
    -ms-flex-order: 3;
        order: 3;
  }
  #footer-terms {
    -ms-flex-order: 2;
        order: 2;
  }

  /* Modal */
  .modal {
    padding: 0;
  }
  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  .modal-dialog {
    margin: 0 auto !important;
  }

  .modal-dialog.modal-md {
    max-width: 90% !important;
  }

  .search-bar-container {
    position: relative;
  }

  .search-bar > input {
    width: 100%;
  }
  /* RVD Module */
  .projectEntry-subcontainer .button-container .pde-btn {
    -ms-flex-pack: end;
        justify-content: flex-end;
    width: 100%;
  }
  .projectEntry-subcontainer .button-container .pde-btn:nth-child(2) {
    padding: 10px 0px !important;
  }
  .projectEntry-subcontainer .button-container {
    display: block !important;
  }
}

.loading-animation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

/* Grid */
.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #a100ff;
  -webkit-animation: lds-grid 1.6s linear infinite;
          animation: lds-grid 1.6s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
}
.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  -webkit-animation-delay: -1.8s;
          animation-delay: -1.8s;
}
.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
}
@-webkit-keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}


/* Ripple */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #a100ff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.body-content {
  height: auto;
  min-height: 81.2vh;
}

/* .body_container {
  background: #f9f9f9;
} */

input::-ms-clear {
  display: none;
}

.modal-backdrop {
  background-color: #fff !important;
}

.rfa_container {
  width: 426px;
  margin: 60px auto;
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.rfa_container > div {
  padding: 0;
}

.report-title {
  margin-left: 60px;
  margin-bottom: 20px;
  color: #008dfd;
  margin-top: 30px;
  font-size: 2.3em;
  font-family: Graphik-Regular-Web;
}

.bo-title {
  margin-left: 60px;
  margin-bottom: 20px;
  color: #008dfd;
  margin-top: 30px;
  font-size: 2.3em;
  font-family: Graphik-Regular-Web;
}

.rfa_title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Semibold-Web;
  font-size: 18px;
  letter-spacing: -0.24px;
  line-height: 26px;
  padding-top: 8px;
}

/* Access Request Tabs */
.rfa_container .nav-tabs {
  border-bottom: 1px solid rgb(220, 220, 220);
  padding-top: 20px;
}
.rfa_container .nav-tabs > li.active > a,
.rfa_container .nav-tabs > li.active > a:hover,
.rfa_container .nav-tabs > li.active > a:focus {
  border-top: 0px;
  border-bottom: 4px solid #b200ff;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  cursor: pointer;
  padding: 0px 0px 15px 0px;
  margin-left: 30px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
}

.rfa_container .nav > li > a,
.rfa_container .nav > li > a:hover,
.rfa_container .nav > li > a:focus {
  margin-left: 30px;
  padding: 0;
  outline: none;
  font-family: Graphik-Regular-Web;
  color: #000;
  background-color: transparent;
  border: 0px;
  font-weight: normal;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
}

.rfa_container .tab-pane {
  margin: 20px 0px 0px 0px;
}

.rfa_container div.form-group,
.rfa_container .form-group label {
  text-align: left;
}

.rfa_container .form-group div {
  padding: 0px;
}

.rfa_container .form-group label,
.rfa_container .form-group input,
.rfa_container .form-group textarea {
  font: normal 0.87em OpenSans-Regular;
  color: #000;
  padding-top: 5px;
}

.rfa_container .form-group input {
  /* border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  height: 56px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  font-family: Graphik-Regular-Web !important;
  padding: 39px 13px 16px 13px; */

  border: 1px solid rgb(223, 227, 232);
  padding: 22px 14px 3px 14px;
  color: rgb(33, 43, 54);
  font-size: 16px !important;
  letter-spacing: -0.35px;
  line-height: 16px;
  border-radius: 4px !important;
  font-family: Graphik-Regular-Web !important;
  height: 58px !important;
}

.rfa_container .form-group textarea {
  width: 100%;
  resize: none;
  padding: 3px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  height: 100px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  font-family: Graphik-Regular-Web !important;
  padding: 29px 13px 16px 13px;
}

.rfa_wrapper {
  min-height: calc(100vh - 144px);
}

.rfa_container .form-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.rfa_container .form-horizontal .form-group {
  margin: 10px 35px;
}
.rfa_container .form-horizontal {
  padding: 0px;
}
.rfa_container .form-horizontal .form-group:last-child {
  margin-top: 15px;
}

.access-request-button-container {
  padding: 0;
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 16px 16px;
  border-top: 1px solid rgb(223, 227, 232);
  height: 72px;
  margin-top: 20px;
}

.form-horizontal .label-of-field {
  position: absolute;
  z-index: 1;
  color: rgb(99, 115, 129) !important;
  font-family: Graphik-Medium-Web !important;
  letter-spacing: 0.2px;
  line-height: 11px;
  padding: 10px 15px !important;
  font-size: 0.85em !important;
  font-weight: normal !important;
}

.form-horizontal .blank-field {
  padding: 14px !important;
  color: rgb(33, 43, 54);
  font-size: 16px !important;
  letter-spacing: -0.35px;
  line-height: 16px;
  border-radius: 4px !important;
  font-family: Graphik-Regular-Web !important;
  height: 58px !important;
}

.form-horizontal .blank-textarea {
  padding: 18.7px 13px !important;
}

.rfa_container button,
.rfa_container button .btn-default {
  color: rgb(161, 0, 255);
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(161, 0, 255);
  padding: 7.5px 16px;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  letter-spacing: -0.27px;
  text-align: center;
  cursor: pointer;
  float: right;
  width: 120px;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  top: 0;
  height: 40px;
}

.rfa_container .tab-content {
  margin: 0px !important;
}

.rfa_container button:hover,
.rfa_container button:focus,
.rfa_container button:active:focus,
.rfa_container button .btn-default:hover,
.rfa_container button .btn-default:focus,
.rfa_container button .btn-default:active:focus {
  color: #fff;
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  background-color: #a100ff;
  font-weight: 500;
  border: 0;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #a100ff;
  border-radius: 20px;
}

.rfa_container button:disabled,
.rfa_container .btn-default:disabled,
.rfa_container .btn-default[disabled]:hover {
  background-color: #a0a0a0;
  border: 1px solid #a0a0a0;
  color: #fff;
}

.rfa_container .svg-inline--fa.fa-fw {
  width: 0.5em;
  color: #a100ff;
  margin-bottom: 2px;
}

.rfa_container .form-group input.required {
  border: 1px solid rgb(161, 0, 255);
}

.rfa_container span.characterCounter {
  font: normal 0.7em OpenSans-Regular;
  color: #a0a0a0;
  float: right;
}

body.modal-open #root {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

/* Access Request Modal CSS */
.rfa_modal .modal-dialog {
  position: absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  width: 25%;
}

.rfa_modal .modal-content {
  border: 0px;
  border-radius: 16px;
}

.rfa_modal .modal-body {
  text-align: center;
}

.rfa_modal .modal-body span,
.rfa_modal .modal-body svg {
  display: inline-block;
  margin: 15px;
}

.rfa_modal .modal-body svg {
  color: #a100ff;
}

.rfa_modal .modal-body span {
  font-family: OpenSans-Regular;
  font-size: 1em;
  color: #000;
}

.rfa_modal button,
.rfa_modal button:hover,
.rfa_modal button:focus,
.rfa_modal .btn-default,
.rfa_modal .btn-default:hover,
.rfa_modal .btn-default:focus {
  border: 0px;
  color: #008dfd;
  font: normal 1.2em OpenSans-Regular;
  background-color: #fff;
  margin: auto;
  outline: none;
  border-style: outset;
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* Bulk Request Access */

.rfa-labels-container {
  margin: 0 40px;
  height: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rfa-footer-container {
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 16px 16px;
  border-top: 1px solid rgb(223, 227, 232);
  height: 70px;
  padding: 15px 25px;
}

label#RequestForAccessFileLabel.input-file {
  width: 100px;
  background-color: #a100ff;
  text-align: center;
  font: 0.87em 'OpenSans-Regular';
  height: 30px;
  cursor: pointer;
  color: #fff;
}

#requestFileName {
  color: rgb(78 78 78);
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: 20px;
}

#requestForAccessAnchor {
  color: rgb(161, 0, 255);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.access_denied_container,
.page_notfound_container {
  padding: 15.2% 0% 0% 0%;
  text-align: center;
}

.access_denied_logo {
  text-align: right;
  font-size: 11.5em;
  color: #008dfd;
  padding: 10px 0 0 8px;
  margin: 0;
}

.access_denied_content,
.page_notfound_content {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  padding: 15px 0 0 11.5px;
}

.access_denied_content h1,
.page_notfound_content h1 {
  font-size: 8em;
  font-family: Graphik-Extralight-Web;
  font-weight: normal;
  color: rgb(161, 0, 255);
  padding: 0;
  margin: 0;
  line-height: 1;
}

.access_denied_content p,
.page_notfound_content p {
  font-size: 1.32em;
  font-family: OpenSans-Light;
  color: #000;
  padding: 0 0 0 19px;
  margin: 0;
}

.glyphicon-ban-circle {
  color: rgb(161, 0, 255);
}

.access_denied_btn,
.page_notfound_btn {
  font-size: 1.1em;
  background-color: rgb(161, 0, 255);
  padding: 4.5px 26px;
  font-weight: normal;
  color: #fff;
  font-family: OpenSans-Regular;
  border: 0;
  margin: 15px 0 0 23px;
}

#bulkUploadFallout {
  font-family: Graphik-Regular-Web;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-top: 10px;
  display: none;
}

#bulkUploadFallout > a {
  color: rgb(178, 0, 255);
}

#bulkUploadError,
#bulkUploadSuccess,
#bulkUploadSecurityError,
#bulkUploadErrorFileSize {
  font-family: Graphik-Regular-Web;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  display: none;
  padding-top: 15px;
  padding-right: 40px;
}

.btn-default[disabled]:hover {
  background-color: #008dfd;
}

/* Module Modals CSS */
.modules_modal .modal-content {
  text-align: center;
  border-radius: 16px;
}

.modules_modal .button_container {
  text-align: center;
}

.modules_modal .btn,
.modules_modal .btn:hover,
.modules_modal .btn:focus {
  background-color: #fff;
  color: #008dfd;
  width: 80px;
}

.modules_modal button#noBtn {
  color: #a0a0a0;
}

.modules_modal .content_container .svg-inline--fa.fa-fw {
  width: 1.5em;
  color: #61b1ff;
  margin-bottom: 2px;
  border: 1px solid;
  border-radius: 100%;
  border-width: 3px;
  height: 1.5em;
  padding: 14px;
}

/* Form Modals CSS */
.form_modal .modal-content {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}

.form_modal .button_container {
  text-align: right;
  padding: 10px 10px;
}

.form_modal button {
  margin: 0px 5px;
  width: 100px;
}

/* div.modal-body div.content_container span {
  font-family: Graphik-Regular-Web;
} */

.bo_create_modal_title {
  font-family: Graphik-Regular-Web;
  padding: 15px;
}

/* Stepper CSS */
.stepper_container > img {
}

.stepper_container {
  display: inline;
  font-size: 1em;
  padding: 0;
}

.stepper {
  border-radius: 100%;
  border: solid 1px;
  border-color: #000;
  height: 30px;
  width: 30px;
  vertical-align: middle;
  display: inline-block;
  line-height: 1.7;
  margin: 2px;
}

.stepper_title {
  display: inline;
  font-family: SourceSansPro-SemiBold;
  vertical-align: middle;
}

span.stepper_title {
  display: inline;
  font-family: SourceSansPro-SemiBold;
  font-size: 13px;
  color: #7b8e98;
  vertical-align: middle;
}

span.stepper_active {
  color: #343d47;
  /* border-color: #000; */
}

.stepper_active {
  color: #343d47;
  /* border-color: #000; */
}

.stepbar {
  padding: 0 2px;
  display: inline-block;
  vertical-align: middle;
}

.stepbar > hr {
  border-bottom: 3px solid #bdc0c4;
  border-top: 0px;
  margin: 9px 9px;
}

.stepbar > hr.stepper_active {
  border-bottom: 3px solid #a100ff;
  border-top: 0px;
  margin: 9px 9px;
}

.stepper_box {
  text-align: center;
  padding: 0;
}

/* .stepper_title_spacer hr {
  clear: both;
  visibility: hidden;
  border: 0 none;
    height: 0px;
    color: #ffffff;
    shadow: none;
} */

.stepbar_title_spacer {
  padding: 0 0px;
  display: inline-block;
  vertical-align: middle;
}

/* Label/Field CSS */
.caret_indicator svg {
  color: #000;
  margin: 0 5px;
}

.disabled svg {
  color: #fff;
  margin: 0 5px;
}

.label_container {
  padding: 0;
}

.bo-search-field {
  height: 25px !important;
  border-radius: 0;
  border: 1px solid #dbdbdb;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 3px;
  font-family: 'OpenSans-Regular' !important;
  width: 100%;
  outline: none;
}

.bo-client-search-field {
  height: 34px !important;
}

.requiredMessage {
  font: normal 0.87em 'OpenSans-Regular';
  color: red;
  height: 1em;
  margin-right: 15px;
}

.indicator_container {
  cursor: pointer;
}
/* 
.custom-tooltip {
 background-color: #a0a0a0 !important;
  $.place-right:after {
  border-right-color: #a0a0a0 !important;
  border-right-style: solid !important;
  border-right-width: 6px !important;
 }
} */
#customTooltip {
  opacity: 1 !important;
}

#customTooltip > .tooltip-inner {
  background-color: #a0a0a0;
  border: 1px solid #a0a0a0;
  max-width: unset;
  border-radius: unset;
}

#customTooltip > .tooltip-arrow {
  border-right-color: #a0a0a0 !important;
}

.customeTheme {
  color: #ff6e00 !important;
  background-color: orange !important;
}
.customeTheme ::before {
  color: orange !important;
  content: 'askfdja;sd';
  border-top-color: orange !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

.form-select-item > div > div:nth-child(1) {
  cursor: text;
  padding: 0.5px 2px;
  min-height: 20px;
}

.form-select-item > div:nth-child(3) > div > div {
  min-height: 21px;
}

.form-select-item > div:nth-child(3) > div {
  max-height: 200px;
}

/* DateRangePicker CSS */
.DayPickerInput {
  width: 47%;
}

.DayPickerInput > input {
  width: 100%;
  outline: 0;
  border: 1px solid #dbdbdb;
  height: 25px;
}

/* Radio Button CSS */
.DeliveredBy > div,
.appAI > div {
  /* display: flex; */
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.appAI > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  height: 20px;
}
.DeliveredBy > div > label,
.AppDecision > label {
  position: relative !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  /* width: 40%; */
}

.DeliveredBy > div > div,
.appAI > div > div {
  width: 100%;
}

input[type='radio'] + span {
  font-size: 0.8em;
  line-height: 1;
}

.glyphicon {
  top: 0;
}

svg.svg-inline--fa.fa-frown.fa-w-16.fa-fw {
  font-size: 12em;
  color: #a0a0a0;
}

/* Executive Summary */
.exec-summary-textarea > div {
  padding: 0;
}

.exec-summary-textarea > div:nth-child(1) {
  margin-bottom: 10px;
}

.exec-summary-textarea textarea {
  border-radius: 0 !important;
}

.exec-summary-count {
  font-size: 0.7em;
  color: #a0a0a0;
  position: absolute;
  bottom: -9px; /*15px*/
  right: 45px;
  background-color: white;
}

.exec-summary-textarea > button {
  padding: 5px 35px;
  margin: 10px 0 40px 0;
}

.ba-project-table .tabulator-col .tabulator-col-content .tabulator-col-title {
  overflow-wrap: break-word !important;
  white-space: normal !important;
  white-space: initial !important;
  vertical-align: top !important;
  width: 80% !important;
}

.ba-project-table
  .tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-arrow {
  right: 6% !important;
}

label.ba-subscript-file.file-link {
  margin-top: -30px;
  color: #a0a0a0;
}

.btnSecurityError,
.btnSecurityError:hover {
  color: red;
  cursor: pointer;
  text-decoration: underline;
}

#securityError .modal-dialog {
  width: 400px;
}

#securityError .modal-content {
  border-radius: 0;
}

#securityError .content_container {
  max-height: 300px;
  overflow-x: auto;
}

#securityError .button_container {
  text-align: center;
}

/* Bulk Request CSS */

.dropzone-container {
  margin: 0 40px 5px;
}

.dropzone-container .dropzone {
  border-radius: 4px;
  border: 2px dashed rgb(196, 205, 213);
  height: 80px;
}

.dropzone-button {
  position: relative;
  text-align: right;
  opacity: 0;
  z-index: 2;
  height: 76px !important;
  width: 100%;
  cursor: pointer;
}

.dropzone-upload-icon {
  width: 25px;
  height: 25px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAF6ADAAQAAAABAAAAGAAAAAAkt0Z4AAACUklEQVRIDe1VTWhTQRCe2X3lSRoqeLAIxbtSoT+omHoxBymIniRXDwk5CIIQTxXLWuzFikqhF6sIehMFD1VoEWIRWgoSFYqKWJCSg/9VEkvavt1xXunqy8trEuhJcC87833ffMxO9m0QmlzZgeFdZtV7zPJXRw/sSadSKd2o1Gkk8Pm0urpDl0tTQNDJadfk3FtNRBlEpHr1sh7pc6fVWNyUfkxx2B3Qdk88mW4rzDydDGA1YV3zM6Oj7sqXpQlu73C4kggO9SSS9GI2Px3mbL6puVJ559vnd/cBqN+KI/YjvX3JpcJMfi6CAxEF8jyxWH52m41PRPFBjE9wPZ27dCqI2Tiy8+IvOQZEaStqsCPzx3sTyfnCbP5NUOsTVStzbugsd36tCmwmQVgV6BwcHzn/0sprx4IQA6h/xWxxcEdCbUC3VmHBZLM4k7tY4hsTr+IRH9y6MniyCgsltZ2HBFtJ/5tHTu/fHYuTGRhuhxXdIaWo+aAo5r2+odQy+U8rf+fhlVUqhsvO3jCutSFwZdERK9RvAFwGwhpwKu5XBj8g0D22/vscIFS4k4ei4u709FpPTSED7NuJmdxQNopcx1B86oh7j5RSXljDmFMs4zEgbA9zNq9vziopxE8gswhCrNkiMKYFUOzWxmz/g0UEDf/mNgz2QXhstDFGARUwsG3dOxgzsKWriBI/3hwZvEMCFwHx/fjlC3f50ftuD9FwLFYYtQv/Z3PMc/BEF6Dhs8l5BLOfb1aLrxeSaCGqsBnMgHHJgz4C08o3tQ1JJ6yxlHLhNwr01x8ITxOwAAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100%;
  position: relative;
  display: inline-block;
}

.dropzone-initial {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  bottom: 195px;
  width: 320px;
  margin-left: 20px;
}

.dropzone-initial > div:first-child {
  margin-right: 15px;
  margin-top: 7px;
}

.dropzone-label-dropfile {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.31px;
  line-height: 20px;
}

.dropzone-lable-sizelimit {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.dropzone-label-browse {
  color: rgb(178, 0, 255);
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.back-to-rfa-button {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(196, 205, 213);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.rfa-inner-container {
  padding: 20px 0;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(227, 234, 237);
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.back-to-rfa-list {
  display: -ms-flexbox;
  display: flex;
  margin-left: 30px;
}

.back-to-rfa-button svg {
  position: absolute;
  font-size: 14px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: rgb(45, 58, 75);
}

.rfa-export-icon {
  width: 15px;
  height: 15px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACshmLzAAADAUlEQVRYCe2XO2gUURSGz02ysRAsbFUQlIiFqKkSO3tfCzEE3GxKLU1loSb4QEuxEds8QNG1UNOLSBJQFK3ELWQFY28hQrJm/M5k7nB35s7uzjKYxgN377nn8Z9/7mtmRbZZTN76ixLsIucQrd/NBWizJPJlXMxP195Jz0VgXoJLAN4PRAZ9wICt45ueEvPA5/fZuibwSIJ9GyJfKTDgA7I2AJtMzcELYr5ZW7u+LZib2BQ5botTZB7fR9ePfgx/VWNow4yLJQAgSxzLUlXM03iEwvKcp6uqDQJurJoypS/T848c/wls+wx4T0EggWGbj7Db9zpbYcTR26pswlE2Jd2WgLNWEVk1YmKb9aUIRMWfEVB2o13dJmf1xF7Gpy0UzV0QeQ52OUkitQQEjhFf3kot7hcSZ5nV8SRiagYIOGCDogtnyY5tD+tVq9tebZueAvhPUTy8H4iJsW1eigBFl3XKIpmgr3HpvLSGrL4i5ju+5OV0GptihALustVtD6lWmRTzBuNVtZKgL50aG0qBckmUU4swhAe7zoO8ToKkCGgAJO6QcE31Xkh4is9S/LbiJYU6Iq8kGFgTOYy60w1gTa9A4JzaCFynG+u0HMni5LzoF7mrGI782iPy+aSYpuED48gfgnDudwK8aicSnuJenMjYgNgZLpxAN92JdpGuL4tEzuIhJFgrZk4CndoSg0/0c24xV4ekfoZdVFuSRLI4/oeE1TU2Q6bAO4pvQ4+hfXfXWd97GQmhmUI/SLxBG6SIng69tFTi3Y4+A86t0JrxQ94oLiVQ8p6CjDxuE3OTwrPqVxJ0NW2RruYZvgdTxRckGH4iwW4NSEouApqcJNGpOE87wWl6/5tPOEiw71olNwFNVxIgTTIb7xi+BaTie/LWUv5R6ir2h6WtXL2LWLW1Fcg+ZgnqO0Qa/GfgxLdKzwRaYdqPuFk/ZEUoAT73w5MwxHpNZwUWbB+K8DZyX0RFEmEPrfSxgfTvVqNI4C6xGlobEtkvoy6BegmLX0a9JBea8xfD/tb64ktsYAAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 100%;
  position: relative;
  display: inline-block;
  top: 2px;
}

.rfa-export-icon-small {
  width: 12px;
  height: 12px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACshmLzAAADAUlEQVRYCe2XO2gUURSGz02ysRAsbFUQlIiFqKkSO3tfCzEE3GxKLU1loSb4QEuxEds8QNG1UNOLSBJQFK3ELWQFY28hQrJm/M5k7nB35s7uzjKYxgN377nn8Z9/7mtmRbZZTN76ixLsIucQrd/NBWizJPJlXMxP195Jz0VgXoJLAN4PRAZ9wICt45ueEvPA5/fZuibwSIJ9GyJfKTDgA7I2AJtMzcELYr5ZW7u+LZib2BQ5botTZB7fR9ePfgx/VWNow4yLJQAgSxzLUlXM03iEwvKcp6uqDQJurJoypS/T848c/wls+wx4T0EggWGbj7Db9zpbYcTR26pswlE2Jd2WgLNWEVk1YmKb9aUIRMWfEVB2o13dJmf1xF7Gpy0UzV0QeQ52OUkitQQEjhFf3kot7hcSZ5nV8SRiagYIOGCDogtnyY5tD+tVq9tebZueAvhPUTy8H4iJsW1eigBFl3XKIpmgr3HpvLSGrL4i5ju+5OV0GptihALustVtD6lWmRTzBuNVtZKgL50aG0qBckmUU4swhAe7zoO8ToKkCGgAJO6QcE31Xkh4is9S/LbiJYU6Iq8kGFgTOYy60w1gTa9A4JzaCFynG+u0HMni5LzoF7mrGI782iPy+aSYpuED48gfgnDudwK8aicSnuJenMjYgNgZLpxAN92JdpGuL4tEzuIhJFgrZk4CndoSg0/0c24xV4ekfoZdVFuSRLI4/oeE1TU2Q6bAO4pvQ4+hfXfXWd97GQmhmUI/SLxBG6SIng69tFTi3Y4+A86t0JrxQ94oLiVQ8p6CjDxuE3OTwrPqVxJ0NW2RruYZvgdTxRckGH4iwW4NSEouApqcJNGpOE87wWl6/5tPOEiw71olNwFNVxIgTTIb7xi+BaTie/LWUv5R6ir2h6WtXL2LWLW1Fcg+ZgnqO0Qa/GfgxLdKzwRaYdqPuFk/ZEUoAT73w5MwxHpNZwUWbB+K8DZyX0RFEmEPrfSxgfTvVqNI4C6xGlobEtkvoy6BegmLX0a9JBea8xfD/tb64ktsYAAAAABJRU5ErkJggg==) no-repeat center center;
  background-size: 100%;
  position: relative;
  display: inline-block;
  top: 2px;
}

.rfa-export-btn,
.rfa-export-btn:hover,
.rfa-export-btn:active,
.rfa-export-btn:focus {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  font-weight: 500;
  letter-spacing: -0.27px;
  line-height: 13px;
  text-align: center;
  width: 110px;
  font-family: Graphik-Medium-Web;
  height: 40px;
  cursor: pointer;
  margin-right: 30px;
  font-size: 17px;
}
.rfa-table-report-container {
  padding: 20px 90px 0px 90px;
}

.exec-summary-edit-header-container {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium-Web;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 24px;
  position: absolute;
  z-index: 1;
  padding: 10px 15px;
}

.textarea.db-edit-summary-text.form-control,
.textarea.db-edit-summary-text.form-control:hover,
.textarea.db-edit-summary-text.form-control:focus,
.textarea.db-edit-summary-text.form-control:active,
.textarea.db-edit-summary-text.form-control:active:focus,
.textarea.db-edit-summary-text.form-control:active:hover {
  /* background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(227, 234, 237);
  -webkit-box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.1); */
  max-height: 157px;
  width: 100%;
  /* padding: 25px 25px 25px 10px; */
  display: -ms-flexbox;
  display: flex;
  outline: none;
  border: none !important;
  padding: 0px;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.exec-summ-save {
  margin-top: 33px !important;
  width: 100px !important;
}
/* 
.content_container {
  padding: 0 15px 15px 15px;
} */

#modal_BO.modules_modal .content_container {
  padding: 15px;
}
#exportMonetization.modules_modal .content_container {
  padding: 0 15px 15px 15px;
  text-align: justify;
}
.text-center {
  text-align: center;
}

.instructions-container {
  margin: 0 40px;
  padding: 40px;
}

.instructions-container .tab-component-content {
  padding: 20px !important;
  -webkit-box-shadow: 0 0 5px #d0d0d0;
          box-shadow: 0 0 5px #d0d0d0;
  border-radius: 5px;
}

.instructions-steps {
  margin-bottom: 20px;
  font-size: 14px;
}

.instructions-container .tab-component-content > img {
  margin: 0 50px 30px;
  -webkit-box-shadow: 0 0 5px #b1b1b1;
          box-shadow: 0 0 5px #b1b1b1;
}

/* Custom checkbox code starts here */
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.25px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-checkbox {
  position: absolute;
  top: -3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark-checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark-checkbox {
  background-color: #a100ff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark-checkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark-checkbox:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Custom checkbox code ends here */

.cvm-multiselect-text {
  position: relative;
}

.cvm-multiselect-text
  #groove-input
  .floating-label
  .groove-input:not(:-moz-placeholder-shown) {
  padding-right: 90px !important;
}

.cvm-multiselect-text
  #groove-input
  .floating-label
  .groove-input:not(:-ms-input-placeholder) {
  padding-right: 90px !important;
}

.cvm-multiselect-text #groove-input .floating-label .groove-input:focus,
.cvm-multiselect-text
  #groove-input
  .floating-label
  .groove-input:not(:placeholder-shown) {
  padding-right: 90px !important;
}
.cvm-multiselect-text > #groove-input > .floating-label > input.groove-input {
  margin: 0;
}

.cvm-multiselect-text #groove-btn-id {
  position: absolute;
  right: 15px;
  top: 9px;
}

.cvm-multiselect-text-selected {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 10px 0 15px 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.cvm-multiselect-text-selected-values {
  background: rgb(250, 245, 253);
  border-radius: 16px;
  border: 1px solid rgb(161, 0, 255);
  padding: 8px 15px;
  color: rgb(117, 0, 192);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  width: 48%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin: 5px 0;
}

.cvm-multiselect-text-selected-values > .svg-container {
  height: auto !important;
  width: 13px !important;
}

.cvm-multiselect-text-selected-values > .svg-container > svg {
  font-size: 13px;
  color: #460073;
  cursor: pointer;
}

.cvm-multiselect-text-selected-values > div {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 
.cvm-multiselect-text div.groove-input-custom-label {
  margin: -37px 0 !important;
} */

.table-action-buttons > img {
  width: 22px;
  margin: 0 60px 0 0;
  cursor: pointer;
}

.client-steakholder-title {
  margin: 5px 0 20px 0;
}

.admin-client-details .client-details-button {
  display: none;
}

.admin-client-details .client-stage-details span {
  color: #7500C0;
}
.notify-toast,
.notify-toast-error {
  position: fixed;
  top: 70px;
  right: 40px;
  z-index: 1;
}
.notify-toast .close,
.notify-toast.close:before,
.notify-toast .close:after,
.notify-toast-error .close,
.notify-toast-error.close:before,
.notify-toast-error .close:after {
  position: inherit;
}

.notify-toast h5 {
  font-size: 14px;
  font-family: Graphik-Medium-Web;
}

.notify-toast-error.saveDraft-toast h5 {
  font-family: Graphik-Regular;
  font-size: 16px;
  margin-bottom: 0px;
}

.notify-toast .sr-only,
.notify-toast .close:after,
.notify-toast .close::before,
.notify-toast-error .sr-only,
.notify-toast-error .close:after,
.notify-toast-error .close::before {
  display: none;
}
.notify-toast .toast {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  max-width: inherit;
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.notify-toast-error.saveDraft-toast .toast {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  max-width: inherit;
  border: 1px solid rgb(234, 128, 151) !important;
  background-color: rgb(255, 245, 247) !important;
}

.notify-toast .toast-header,
.notify-toast-error.saveDraft-toast .toast-header{
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f2f9ec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}

.notify-toast-error.saveDraft-toast .toast-header{
  background-color: rgb(255, 245, 247);
  color: rgb(213, 0, 47);
  font-family: Graphik-Medium;
  font-size: 16px !important;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 0px;
}

.notify-toast .toast-header svg {
  color: #7ec344;
  font-size: 30px;
  margin-right: 25px;
}

.notify-toast-error.saveDraft-toast .toast-header > svg {
  color: rgb(213, 0, 47);
  font-size: 30px;
  margin-right: 25px;
}
.notify-toast .close,
.notify-toast-error .close {
  font-size: 2rem;
  opacity: 1;
}
.notify-toast .close.ml-2,
.notify-toast-error .close.ml-2{
  margin-left: 2rem !important;
}

.notify-toast .close.mb-1,
.notify-toast-error .close.mb-1 {
  margin-bottom: 1.2rem !important;
}

.undo-delete-btn {
  margin-left: 92px;
  text-decoration: underline;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
}

.check-box {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
  float: left;
  margin-right: 11px;
}

.cvm-checkbox .svg-container {
  display: none;
  margin-right: 11px;
}

.cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.cvm-checked .svg-container {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px !important;
  padding: 11px;
}

.labels {
  position: relative;
  display: -webkit-inline-box;
}

.cont {
  height: 34px;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

#mt-count-tooltip .label14-regular-midnight {
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  position: sticky;
  margin: 18px 13px 18px 13px;
}

#mt-count-tooltip .label14-regular-midnight .sea {
  width: 100%;
}

.dropdown-buttons {
  margin: 11px 0px 11px 0px !important;
}

.dropdown-cancel {
  margin: 0px;
}

.dropdown-apply {
  margin: 0px;
}

.dropdown-cancel .btn-outline-stat-alternate {
  margin-left: 23px;
}

#mt-count-tooltip .search-bar-container .search-bar>input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  outline-style: none;
  padding-left: 42px !important;
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}

#mt-count-tooltip .css-1hwfws3 {
  overflow: hidden !important;
  height: 38px;
  /* overflow-x: auto !important; */
  cursor: pointer;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  white-space: nowrap;
}

#mt-count-tooltip .css-15hpnlr-control {
  border-color: rgb(181, 192, 202);
}

#mt-count-tooltip .css-15hpnlr-control:hover {
  border-color: rgb(181, 192, 202);
}

.arigato {
  background-color: blue;
}

#mt-count-tooltip .css-bg1rzq-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

#mt-count-tooltip .css-bg1rzq-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

#mt-count-tooltip .css-1szy77t-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

#mt-count-tooltip .css-1szy77t-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  position: relative;
}

#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer {
  position: relative !important;
}

#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer:hover {
  position: relative !important;
}

#mt-count-tooltip .css-1wy0on6 {
  width: 90px;
}

#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>svg {
  background-color: blue;
}

#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>div>div {
  background-color: rgb(0, 255, 34);
}

#mt-count-tooltip .css-14ojxsb-control {
  border-color: white;
}

.data-IngestionContainer .client-details-button .multi-select-dropdown-with-count {
  width: 186px;
  min-height: 0 !important;
  width: 274px;
  border-radius: 30px;
}

.data-IngestionContainer .css-1nszmjf {
  color: #919eab;
  padding: 7px;
}

.Multi-Select-BT .multi-select-dropdown-with-count {
  cursor: pointer;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer']>svg {
  height: 30px !important;
  width: 50px !important;
  color: #919eab;
}

.multi-select-dropdown-with-count .css-1gl4k7y {
  color: rgb(21, 24, 27);
  text-align: -webkit-left;
  text-align: left;
  margin-left: 2px;
  font-family: 'Graphik-Regular-Web';
}

.requiredText {
  color: red;
}

.required-multi-select .css-1nszmjf::after {
  content: '*';
  color: red;
}

.placeholder-title {
  color: #637381;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 0 0 10px 0;
  padding: 0 7px;
  opacity: 1;
  top: -9px;
  position: relative;
  left: 7px;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover,
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control],
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover {
  margin-top: -16px;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover,
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control],
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover {
  margin: auto 0;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]>div:nth-child(2)>div[class*='-indicatorContainer'] {
  top: -12px;
}

.select-profile .multi-select-dropdown-with-count,
.added-profiles .multi-select-dropdown-with-count {
  height: 60px;
  padding: 0.375rem 0 0 0;
}

.add-new-program-container .multi-select-dropdown-with-count div[class*='-multiValue']>div:first-child {
  font-size: 10px;
}

.add-new-program-container #mt-count-tooltip div[class*='-container']>div[class*='-menu'] {
  width: 420px;
}

.multi-select-dropdown-with-count.disabled {
  background-color: #f9fafb;;
  cursor: not-allowed;
}

.multi-select-dropdown-with-count.disabled:hover{
  border: 1px solid #ced4da !important;
}

.multi-select-dropdown-with-count.disabled #mt-count-tooltip div[class*=-container]>div[class*=-control]{
  border: none !important;
  cursor: not-allowed !important;
}

.multi-select-dropdown-with-count.disabled #mt-count-tooltip .css-1hwfws3 {
  cursor: not-allowed;
}

.multi-select-dropdown-with-count.invalid-replacement-users, .multi-select-dropdown-with-count.invalid-replacement-users:hover {
  border: 1px solid red !important;
}

/* FOR YEAR DROPDOWN ON Value Meter Dashboard */
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1,
.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1:hover {
  border: none !important;
  background-color: transparent !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 #mt-count-tooltip div[class*='-container'] > div[class*='-control'] > div:first-child {
  height: auto !important;
  margin: 0 0 0 3px !important;
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 div[class*='-indicatorContainer'] > svg {
  height: 18px !important;
  width: 36px !important;
  fill: #a100ff !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 .dropdown-buttons {
  margin: 0 !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 .dropdown-cancel button{
  margin-left: 4px !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip > div[class*='-container'] > div[class*='-control'] > div > div[class*='-multiValue'] {
  padding: 0 !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1>#mt-count-tooltip div[class*=control], .multi-select-dropdown-with-count.reportingYrDropdown-checkbox1>#mt-count-tooltip div[class*=control]:hover {
  height: 40px !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip div[class*='-control'] > div:nth-child(2) {
  width: 38px !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 .cvm-checked .svg-container {
  height: 10px !important;
  width: 10px !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip {
  position: relative !important;
}

.multi-select-dropdown-with-count.reportingYrDropdown-checkbox1 > #mt-count-tooltip:hover #dropdown-popover {
  display: block !important;
  top: 44px !important;
  max-width: 200px !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.single-dropdown-multi-view div[class*='-container'] > span[class*='-a11yText'] {
  display: none;
}

.single-dropdown-multi-view .placeholder-title {
  margin: 0;
  padding: 0 0 0 5px;
  top: -4px;
  z-index: 1;
}
.modal-content {
    border-radius: 16px !important;
    height: 95%;
  }
  .modal-header {
    border: none !important;
    padding: 40px 40px 0 40px !important;
  }
  
  .modal-header {
    overflow: hidden;
    margin-right: 0px;
    margin-left: 0px;
    word-break: break-all;
    
  }
  .modal-title.h4 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: center;
        align-items: center;
    width: 100%;
  }
  
  .modal-title.h4 > div {
    display: -ms-flexbox;
    display: flex;
  }
  
  .modal-title.h4 .close-modal-btn {
    /* background: rgb(145, 158, 171); */
    height: 20px;
    width: 15px;
    color: rgb(145, 158, 171);
    cursor: pointer;
  }
  
  .modal-body {
    padding: 0 !important;
  }
  
  .modal-body > div:first-child {
    padding: 16px 40px;
    max-height: 65%;
    overflow-y: auto;
  }
  
  .add-boi-popup {
    overflow: visible !important;
  }
  
  .tab-component-purple .react-tabs__tab--selected {
    border-radius: 0 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    border-bottom: 4px solid #7500cc !important;
    border-right: 4px solid transparent !important;
    border-left: 4px solid transparent !important;
  }
  
  .tab-component-purple .react-tabs__tab {
    padding: 14px 12px;
    margin: 0 10px 0 0;
  }
  
  .modal-95w {
    max-width: 95% !important;
    height: 90% !important;
  }
  
  .replacement-modal-message{
    color: rgba(145,158,171,1);
    font-family: "Graphik-Regular";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
  }

  .replacement-modal-header{
    color: rgba(21,24,27,1);
    font-family: "Graphik-Semibold";
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -0.28px;
    text-align: left;
  }

  .replacement-modal {
    max-width: 590px !important;
  }

  .replacement-multiselect-dropdown,
  .single-dropdown-multi-view {
    padding: 20px 0px;
  }

  .replacement-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }

  .replacement-buttons div:first-child {
    padding-right: 15px;
  }

  .replacement-modal #groove-input #newUser.invalid.groove-input {
    border: 1px solid red;
  }

  .replacement-buttons .button-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    margin-left: auto;
  }

  .replacement-multiselect-dropdown > .multi-select-dropdown-with-count > #mt-count-tooltip > .css-1pcexqc-container > .css-1szy77t-control,
  .replacement-multiselect-dropdown > .multi-select-dropdown-with-count > #mt-count-tooltip > .css-1pcexqc-container > .css-bg1rzq-control { 
    top: 15px;
  }

  .replacement-multiselect-dropdown > .blank-multi-field > #mt-count-tooltip > .css-1pcexqc-container > .css-1szy77t-control,
  .replacement-multiselect-dropdown > .blank-multi-field > #mt-count-tooltip > .css-1pcexqc-container > .css-bg1rzq-control { 
    top: 0px !important;
  }

  .replacement-multiselect-dropdown > .multi-select-dropdown-with-count > #mt-count-tooltip > .css-1pcexqc-container > .placeholder-title { 
    display: none;
  }
.bodytext12-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.bodytext12-regular {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 5px;
}

.bodytext14 th {
  color: black;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.iouser-table .fa-plus-circle {
  cursor: pointer;
}

td .delete-icon {
  /* margin: 0 60px 0 0; */
  cursor: pointer;
  opacity: 0;
}

tr:hover td .delete-icon {
  opacity: 1;
}

.iomanagement-table-container .subdimension-row .card-header {
  padding: 10px 8px;
}

.iomanagement-table-container .card-header .row {
  padding-left: 0;
}

.iomanagement-table-container .subdimension-header>.row {
  padding: 12px;
  padding-left: 50px;
}

.iomanagement-table-container .collapse.show~div.card-header div.svg-container svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.iomanagement-table-container .svg-container.tiny {
  font-size: 0.9em;
}

.iomanagement-table-container .svg-container {
  margin-left: 5px;
}

.iomanagement-table-container .iouser-table tr td .svg-container {
  margin-left: 10px;
}

.iomanagement-table-container .svg-container.small {
  height: 14px;
  width: 14px;
}

.iomanagement-table-container .svg-container .fa-caret-right {
  margin-left: 0px;
}

.iomanagement-table-container .card-header .bodytext14 {
  color: rgb(161, 0, 255);
}

.iomanagement-table-container .subdimension-row.accordion .card-header .col-lg-3:nth-child(2) {
  padding-left: 8px;
}

.iomanagement-table-container .subdimension-row.accordion .card-header .btn:focus, .iomanagement-table-container .subdimension-row.accordion .card-header .btn.focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.iomanagement-table-container .subdimension-row.accordion .card-header .btn {
  padding: 0.375rem 0.5rem;
  width: 50px;
}

.iomanagement-table-container .iouser-table {
  width: 100%;
}

.iomanagement-table-container .iouser-table tr th:nth-child(1), .iomanagement-table-container .iouser-table tr td:nth-child(1) {
  width: 50%;
}

.iomanagement-table-container .iouser-table tr th:nth-child(2), .iomanagement-table-container .iouser-table tr td:nth-child(2) {
  width: 16%;
}

.iomanagement-table-container .iouser-table tr th:nth-child(3), .iomanagement-table-container .iouser-table tr td:nth-child(3) {
  width: 29%;
}

.iomanagement-table-container .iouser-table tr th:nth-child(4), .iomanagement-table-container .iouser-table tr td:nth-child(4) {
  width: 5%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  /* text-align: center; */
}

.iomanagement-table-container .subdimension-header .col-lg-3 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.iomanagement-table-container .subdimension-header .svg-container.small {
  width: 16px;
  height: 16px;
  font-size: 1em;
}

@media only screen and (max-width: 988px) {
  .iomanagement-table-container .subdimension-header .col-lg-3 {
    width: 50%;
  }
}

.subdimension-body-container {
  max-height: 420px;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 420px;
  background: white;
}

.iomanagement-table-container .subdimension-table {
  border: none;
  background: transparent;
}

.iomanagement-table-container .subdimension-header {
  background: rgb(249, 250, 251);
  border-radius: 5px 5px 0px 0px;
  border: 1px solid rgb(231, 234, 238);
  border-bottom: 1px solid rgb(231, 234, 238);
}

.iomanagement-table-container .subdimension-row .card {
  background: transparent;
}

.iomanagement-table-container .subdimension-row .card-header {
  background: rgb(255, 255, 255);
  border-radius: 0px !important;
  border: 1px solid rgb(231, 234, 238);
}

.iomanagement-table-container .subdimension-row .card-body {
  padding: 0 1.5rem;
}

.iomanagement-table-container .iouser-table tr:first-child {
  background: rgba(0, 186, 255, 0.06);
  border-radius: 4px;
}

.iomanagement-table-container .iouser-table tr:nth-child(even) {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding-left: 100px;
}

.iomanagement-table-container .iouser-table tr th, .iomanagement-table-container .iouser-table tr td {
  padding: 10px;
}

#filter-popover {
  width: 21.5rem;
  max-width: inherit;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  border: none;
  left: -64px !important;
}

#filter-popover.popover .arrow {
  display: none;
}

#filter-popover .popover-body {
  padding: 0.75rem 1.25rem;
}

#popover-searchbox .search-bar {
  left: 0;
  top: 0;
  margin: 0;
}

#popover-searchbox #searchBox {
  width: 82%;
}

#filter-popover .search-bar-container .search-bar {
  position: relative;
}

.filter-select-table ul {
  list-style: none;
  padding-left: 0;
  margin: 0.5rem 0;
  max-height: 10rem;
  overflow-y: auto;
}

.filter-select-table ul li {
  padding: 13px 16px;
}

.filter-select-table ul li:hover {
  background: rgb(244, 246, 248);
  border-radius: 0px;
}

.filter-action-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 8px 16px;
  background: rgb(244, 246, 248);
  border-radius: 0px 0px 8px 8px;
}

.delete-profile {
  cursor: pointer;
}

.undo-delete-btn {
  margin-left: 92px;
  text-decoration: underline;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
}

.deleteToast.saveDraft-toast .toast {
  border: 1px solid rgb(234, 128, 151);
  background-color: rgb(255, 245, 247);
  font-size: 16px;
}

.deleteToast.saveDraft-toast .toast-header {
  background-color: rgb(255, 245, 247);
  color: rgb(213, 0, 47);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.userRemovedText {
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  margin-bottom: 0px;
}

.deleteToast.saveDraft-toast .toast-header>svg {
  color: rgb(213, 0, 47);
}

.panel {
  display: none;
  overflow: hidden;
}

.checkbox-label {
  margin-top: 60px;
  margin-left: 37px;
  font-weight: 500;
  margin-right: 20px;
}

.filter-btn {
  cursor: pointer;
}

.search-container {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  height: 32px;
  width: 261px;
}

.search-container {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
}

.filter-container {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 266px;
  width: 341px;
}

.buttonFrame {
  background: rgb(244, 246, 248);
  border-radius: 0px 0px 8px 8px;
  height: 50px;
  width: 275px;
  margin-top: 120px;
}

.clearButton {
  margin-left: 20px;
  margin-top: 10px;
}

.applyButton {
  margin-top: 10px;
  margin-left: 20px;
}

.searchbar {
  width: 350px;
  height: 32px;
}

.add-new-program-container div.groove-input-custom-label {
  font-size: 16px;
  font-family: Graphik-Medium-Web;
}

.sort-io {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.sort-btn-container {
  width: 20px;
  height: 16px;
}

.replacement-modal #groove-input .groove-input:disabled {
  background: #f2f2f2;
}

.replacement-modal #groove-input .groove-input,
.replacement-modal #groove-input .groove-input:disabled {
  border: 1px solid rgba(0, 0, 0, .2);
}

.replacement-modal #groove-input .groove-input:focus {
  border-radius: 0.3rem;
}
/* Master Config Landing */
.master-config-contents {
  margin: 20px 0;
  height: 100%;
}
.management-table-container .table-container {
  height: 500px;
  width: 100%;
}

@media (min-width: 992px){
  
  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:first-child,
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:first-child{
    max-width: 14% !important;
    padding-left: 30px;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(2),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(2){
    max-width: 17% !important;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(3),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(3){
    min-width: 25% !important;
    padding-right: 25px;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(4),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(4){
    min-width: 30% !important;
    padding-left: 80px;
    padding-right: 20px;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(5),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(5){
    width: 15% !important;
    margin-left: 110px;
  }

  .management-table-container .pde-table-list .ag-header-cell.ag-focus-managed.ag-header-cell-sortable:nth-child(6),
  .management-table-container .pde-table-list .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-cell-value:nth-child(6){
    padding-left: 100px;
  }
}
/* Search */

.search-bar-container {
  position: relative;
}
.search-bar {
  position: absolute;
  right: 0;
  margin: 10px 0 0 0;
}

.search-bar > input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(231, 234, 238);
  outline-style: none;
}

.search-bar svg {
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 16px;
}

.master-config-contents .search-bar-container {
  height: 80px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.master-config-contents .search-bar-container > .search-bar,
.master-config-contents .search-bar-container > #groove-btn-id {
  position: relative;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
}

.master-config-contents .search-bar-container > .search-bar input,
.master-config-contents .search-bar-container input#searchBox {
  width: 300px;
  padding: 8px 10px 7px 45px;
}

.bodytext12-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.bodytext12-regular {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 5px;
}

.bodytext14 th {
  color: black;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.iouser-table .fa-plus-circle {
  cursor: pointer;
}

td .delete-icon {
  /* margin: 0 60px 0 0; */
  cursor: pointer;
  opacity: 0;
}

tr:hover td .delete-icon {
  opacity: 1;
}

.qcmanagement-table-container .subdimension-row .card-header {
  padding: 10px 8px;
}

.qcmanagement-table-container .card-header .row {
  padding-left: 0;
}

.qcmanagement-table-container .subdimension-header>.row {
  padding: 12px;
  padding-left: 50px;
}

.qcmanagement-table-container .collapse.show~div.card-header div.svg-container svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.qcmanagement-table-container .svg-container.tiny {
  font-size: 0.9em;
}

.qcmanagement-table-container .svg-container {
  margin-left: 5px;
}

.qcmanagement-table-container .iouser-table tr td .svg-container {
  margin-left: 10px;
}

.qcmanagement-table-container .svg-container.small {
  height: 14px;
  width: 14px;
}

.qcmanagement-table-container .svg-container .fa-caret-right {
  margin-left: 0px;
}

.qcmanagement-table-container .card-header .bodytext14 {
  color: rgb(161, 0, 255);
}

.qcmanagement-table-container .subdimension-row.accordion .card-header .col-lg-3:nth-child(2) {
  padding-left: 8px;
}

.qcmanagement-table-container .subdimension-row.accordion .card-header .btn:focus, .qcmanagement-table-container .subdimension-row.accordion .card-header .btn.focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.qcmanagement-table-container .subdimension-row.accordion .card-header .btn {
  padding: 0.375rem 0.5rem;
  width: 50px;
}

.qcmanagement-table-container .iouser-table {
  width: 100%;
}

.qcmanagement-table-container .iouser-table tr th:nth-child(1), .qcmanagement-table-container .iouser-table tr td:nth-child(1) {
  width: 30%;
}

.qcmanagement-table-container .iouser-table tr th:nth-child(2), .qcmanagement-table-container .iouser-table tr td:nth-child(2) {
  width: 30%;
}

.qcmanagement-table-container .iouser-table tr th:nth-child(3), .qcmanagement-table-container .iouser-table tr td:nth-child(3) {
  width: 30%;
  vertical-align: top;
}

.qcmanagement-table-container .iouser-table tr th:nth-child(4), .qcmanagement-table-container .iouser-table tr td:nth-child(4) {
  width: 10%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  /* text-align: center; */
}

.qcmanagement-table-container .subdimension-header .col-lg-3 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.qcmanagement-table-container .subdimension-header .svg-container.small {
  width: 16px;
  height: 16px;
  font-size: 1em;
}

@media only screen and (max-width: 988px) {
  .qcmanagement-table-container .subdimension-header .col-lg-3 {
    width: 50%;
  }
}

.subdimension-body-container {
  max-height: 420px;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 420px;
  background: white;
}

.qcmanagement-table-container .subdimension-table {
  border: none;
  background: transparent;
}

.qcmanagement-table-container .subdimension-header {
  background: rgb(249, 250, 251);
  border-radius: 5px 5px 0px 0px;
  border: 1px solid rgb(231, 234, 238);
  border-bottom: 1px solid rgb(231, 234, 238);
}

.qcmanagement-table-container .subdimension-row .card {
  background: transparent;
}

.qcmanagement-table-container .subdimension-row .card-header {
  background: rgb(255, 255, 255);
  border-radius: 0px !important;
  border: 1px solid rgb(231, 234, 238);
}

.qcmanagement-table-container .subdimension-row .card-body {
  padding: 0 1.5rem;
}

.qcmanagement-table-container .iouser-table tr:first-child {
  background: rgba(0, 186, 255, 0.06);
  border-radius: 4px;
}

.qcmanagement-table-container .iouser-table tr:nth-child(even) {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding-left: 100px;
}

.qcmanagement-table-container .iouser-table tr th, .qcmanagement-table-container .iouser-table tr td {
  padding: 10px;
}

#filter-popover {
  width: 21.5rem;
  max-width: inherit;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  border: none;
  left: -64px !important;
}

#filter-popover.popover .arrow {
  display: none;
}

#filter-popover .popover-body {
  padding: 0.75rem 1.25rem;
}

#popover-searchbox .search-bar {
  left: 0;
  top: 0;
  margin: 0;
}

#popover-searchbox #searchBox {
  width: 82%;
}

#filter-popover .search-bar-container .search-bar {
  position: relative;
}

.filter-select-table ul {
  list-style: none;
  padding-left: 0;
  margin: 0.5rem 0;
  max-height: 10rem;
  overflow-y: auto;
}

.filter-select-table ul li {
  padding: 13px 16px;
}

.filter-select-table ul li:hover {
  background: rgb(244, 246, 248);
  border-radius: 0px;
}

.filter-action-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 8px 16px;
  background: rgb(244, 246, 248);
  border-radius: 0px 0px 8px 8px;
}

.delete-profile {
  cursor: pointer;
}

.undo-delete-btn {
  margin-left: 92px;
  text-decoration: underline;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
}

.deleteToast.saveDraft-toast .toast {
  border: 1px solid rgb(234, 128, 151);
  background-color: rgb(255, 245, 247);
  font-size: 16px;
}

.deleteToast.saveDraft-toast .toast-header {
  background-color: rgb(255, 245, 247);
  color: rgb(213, 0, 47);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.userRemovedText {
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  margin-bottom: 0px;
}

.deleteToast.saveDraft-toast .toast-header>svg {
  color: rgb(213, 0, 47);
}

.panel {
  display: none;
  overflow: hidden;
}

.checkbox-label {
  margin-top: 60px;
  margin-left: 37px;
  font-weight: 500;
  margin-right: 20px;
}

.filter-btn {
  cursor: pointer;
}

.search-container {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  height: 32px;
  width: 261px;
}

.search-container {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
}

.filter-container {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 266px;
  width: 341px;
}

.buttonFrame {
  background: rgb(244, 246, 248);
  border-radius: 0px 0px 8px 8px;
  height: 50px;
  width: 275px;
  margin-top: 120px;
}

.clearButton {
  margin-left: 20px;
  margin-top: 10px;
}

.applyButton {
  margin-top: 10px;
  margin-left: 20px;
}

.searchbar {
  width: 350px;
  height: 32px;
}

.add-new-program-container div.groove-input-custom-label {
  font-size: 16px;
  font-family: Graphik-Medium-Web;
}

.sort-io {
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.sort-btn-container {
  width: 20px;
  height: 16px;
}
.notify-toast,
.notify-toast-error {
  position: fixed;
  top: 70px;
  right: 40px;
  z-index: 1;
}
.notify-toast .close,
.notify-toast.close:before,
.notify-toast .close:after,
.notify-toast-error .close,
.notify-toast-error.close:before,
.notify-toast-error .close:after {
  position: inherit;
}

.notify-toast h5,
.notify-toast-error h5 {
  font-size: 14px;
  font-family: "Graphik-Medium-Web";
  margin-bottom: 0px;
}
.notify-toast .sr-only,
.notify-toast .close:after,
.notify-toast .close::before,
.notify-toast-error .sr-only,
.notify-toast-error .close:after,
.notify-toast-error .close::before {
  display: none;
}
.notify-toast .toast {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  max-width: inherit;
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.toast-header-error .toast {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  max-width: inherit;
  border: 1px solid red;
  background-color: #f2f9ec;
}

.notify-toast .toast-header{
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f2f9ec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.notify-toast-error .toast-header{
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f9ecec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}

.notify-toast .toast-header svg {
  color: #7ec344;
  font-size: 30px;
  margin-right: 25px;
}

.notify-toast-error .toast-header svg {
  color: red;
  font-size: 30px;
  margin-right: 25px;
}
.notify-toast .close,
.notify-toast-error .close {
  font-size: 2rem;
  opacity: 1;
}
.notify-toast .close.ml-2,
.notify-toast-error .close.ml-2{
  margin-left: 2rem !important;
}

.notify-toast .close.mb-1,
.notify-toast-error .close.mb-1 {
  margin-bottom: 1.2rem !important;
}
.check-box {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
  float: left;
  margin-right: 11px;
}

.cvm-checkbox .svg-container {
  display: none;
  margin-right: 11px;
}

.cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.cvm-checked .svg-container {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px !important;
  padding: 11px;
}

.labels {
  position: relative;
  display: -webkit-inline-box;
}

.cont {
  height: 34px;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

#mt-count-tooltip .label14-regular-midnight {
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  position: sticky;
  margin: 18px 13px 18px 13px;
}

#mt-count-tooltip .label14-regular-midnight .sea {
  width: 100%;
}

.dropdown-buttons {
  margin: 11px 0px 11px 0px !important;
}

.dropdown-cancel {
  margin: 0px;
}

.dropdown-apply {
  margin: 0px;
}

.dropdown-cancel .btn-outline-stat-alternate {
  margin-left: 23px;
}

#mt-count-tooltip .search-bar-container .search-bar>input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  outline-style: none;
  padding-left: 42px !important;
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}

#mt-count-tooltip .css-1hwfws3 {
  overflow: hidden !important;
  height: 38px;
  /* overflow-x: auto !important; */
  cursor: pointer;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  white-space: nowrap;
}

#mt-count-tooltip .css-15hpnlr-control {
  border-color: rgb(181, 192, 202);
}

#mt-count-tooltip .css-15hpnlr-control:hover {
  border-color: rgb(181, 192, 202);
}

.arigato {
  background-color: blue;
}

#mt-count-tooltip .css-bg1rzq-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

#mt-count-tooltip .css-bg1rzq-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

#mt-count-tooltip .css-1szy77t-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

#mt-count-tooltip .css-1szy77t-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  position: relative;
}

#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer {
  position: relative !important;
}

#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer:hover {
  position: relative !important;
}

#mt-count-tooltip .css-1wy0on6 {
  width: 90px;
}

#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>svg {
  background-color: blue;
}

#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>div>div {
  background-color: rgb(0, 255, 34);
}

#mt-count-tooltip .css-14ojxsb-control {
  border-color: white;
}

.data-IngestionContainer .client-details-button .multi-select-dropdown-with-count {
  width: 186px;
  min-height: 0 !important;
  width: 274px;
  border-radius: 30px;
}

.data-IngestionContainer .css-1nszmjf {
  color: #919eab;
  padding: 7px;
}

.Multi-Select-BT .multi-select-dropdown-with-count {
  cursor: pointer;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer']>svg {
  height: 30px !important;
  width: 50px !important;
  color: #919eab;
}

.multi-select-dropdown-with-count .css-1gl4k7y {
  color: rgb(21, 24, 27);
  text-align: -webkit-left;
  text-align: left;
  margin-left: 2px;
  font-family: 'Graphik-Regular-Web';
}

.requiredText {
  color: red;
}

.required-multi-select .css-1nszmjf::after {
  content: '*';
  color: red;
}

.multi-select-dropdown-with-count .required-multi-select .css-1nszmjf::after {
  content: '*';
  color: #637381 !important;
}

.placeholder-title {
  color: #637381;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 0 0 10px 0;
  padding: 0 7px;
  opacity: 1;
  top: -9px;
  position: relative;
  left: 7px;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover,
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control],
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover {
  margin-top: -16px;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover,
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control],
.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover {
  margin: auto 0;
}

.multi-select-dropdown-with-count #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]>div:nth-child(2)>div[class*='-indicatorContainer'] {
  top: -12px;
}

.select-profile .multi-select-dropdown-with-count,
.added-profiles .multi-select-dropdown-with-count {
  height: 60px;
  padding: 0.375rem 0 0 0;
}

.add-new-program-container .multi-select-dropdown-with-count div[class*='-multiValue']>div:first-child {
  font-size: 10px;
}

.add-new-program-container #mt-count-tooltip div[class*='-container']>div[class*='-menu'] {
  width: 420px;
}
.multi-select-dropdown-with-count.multiselectcheck{
  background: #fff;
    border-radius: 20px;
    border: 1.5px solid #a100ff;
    color: #a100ff;
    font-family: Graphik-Medium-Web;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.25px;
    line-height: 14px;
    padding: 0 5px 0 24px;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}
.column-search-container.client-details-button .multi-select-dropdown-with-count.multiselectcheck{
  width: 215px;
  height: 40px;
}
.multi-select-dropdown-with-count.multiselectcheck div[class*='-indicatorContainer'] > svg{
  width: auto !important;
  height: auto !important;
  color: #a100ff;
}
.multi-select-dropdown-with-count.multiselectcheck > #mt-count-tooltip div[class*='-control'] > div:nth-child(2){
  width: auto;
}
.multi-select-dropdown-with-count.multiselectcheck #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]>div:nth-child(2)>div[class*='-indicatorContainer']{
  top: 2px;
  right: 0px;
}


.multiselectcheck #mt-count-tooltip div[class*=-container]>div[class*=-control]>div:first-child{
height: auto;
padding: 0;
width: 70px;
-ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.multi-select-dropdown-with-count.multiselectcheck > #mt-count-tooltip > div[class*='-container'] > div[class*='-control'] > div > div[class*='-multiValue']{
border: none;
background: transparent;
height: auto;
margin: 0;
padding: 0px 0px 0px 0px;
}
.multiselectcheck #mt-count-tooltip div[class*='-container'] > div[class*='-menu']{
  min-width: 172px;
  left: -105px;
}
.multi-select-dropdown-with-count.multiselectcheck div[class*='-multiValue']>div:nth-child(2) svg{
  display: none;
}

.multi-select-dropdown-with-count.multiselectcheck div[class*='-multiValue']>div:first-child span{
  font-weight: 500;
  font-family: 'Graphik-Medium';
  font-size: 14px;
  top: 1.5px;
  position: relative;
}
.multi-select-dropdown-with-count.multiselectcheck .cvm-checkbox{
  height: 20px;
  width: 20px;
  border: 1px solid rgb(156, 163, 175);
  background: #ffffff;
}
.multi-select-dropdown-with-count.multiselectcheck .cvm-checked{
  background: #ffffff;
}
.multi-select-dropdown-with-count.multiselectcheck .cvm-checked .svg-container{
  position: relative;
  top: -2px;
  font-size: 10px !important;
  padding: 0;
}
.multi-select-dropdown-with-count.multiselectcheck .cvm-checked .svg-container svg{
  color:#111827;
}
.menulist-class .labels{
top: 4px;
}
.menulist-class{
  padding: 4px 10px;
}
.multiselectcheck #dropdown-popover{
  max-width: 200px;
}
.multiselectcheck .filtered-label {
  margin-right: 4px;
}
.single-select-dropdown-with-search {
  padding: 0.375rem 0;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik-Medium;
  line-height: 1;
  color: #A100FF;
  background: #fff;
  border-radius: 16px;
  height: 32px;
  width: 100%;
  border: 1.5px solid #A100FF;
  cursor: pointer;
}

.single-select-dropdown-with-search .multi-select-label {
  margin-top: -26px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.single-select-dropdown-with-search .check-box {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.single-select-dropdown-with-search .cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
  float: left;
  margin-right: 11px;
}

.single-select-dropdown-with-search .cvm-checkbox .svg-container {
  display: none;
  margin-right: 11px;
}

.single-select-dropdown-with-search .cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.single-select-dropdown-with-search .cvm-checked .svg-container {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px !important;
  padding: 11px;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container'] > div[class*='-control'],
.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container'] > div[class*='-control']:hover
{
  /* background-color: #fff !important; */
  border-style: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  /* margin-top: -16px; */
  /* margin-top: -22px !important; */
  height: 35px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container'],
.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container']:hover
{
  border-radius: 5px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0 ;
  z-index: 2;
  /* width: 100%; */
  font-size: 16px;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*='-container'] > div[class*='-menu'] {
  background: white !important;
  margin-top: 0;
  z-index: 100000 !important;
}

.single-select-dropdown-with-search .labels {
  position: relative;
  display: -webkit-inline-box;
}

.single-select-dropdown-with-search .cont {
  height: 34px;
  overflow: scroll !important;
  overflow-x: hidden !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -ms-flex-direction: row;
      flex-direction: row;
}

.single-select-dropdown-with-search .cont> div[class*='-singleValue'] {
  background-color: white;
  border-radius: 16px;
  display: -ms-flexbox;
  display: flex;
  margin: 3px 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 0;
  height: 24px;
  padding: 0px 0px 0px 0px;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  /* max-width: 235px; */
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #A100FF;
  font-size: 12px;
  font-weight: 500;
  font-family: Graphik-Medium;
}

.single-select-dropdown-with-search #mt-count-tooltip .label14-regular-midnight {
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  position: sticky;
  margin: 5px 8px 5px 10px;
}

.single-select-dropdown-with-search #mt-count-tooltip .label14-regular-midnight .sea {
  width: 100%;
}

.single-select-dropdown-with-search .dropdown-buttons {
  margin: 11px 0px 11px 0px !important;
}

.single-select-dropdown-with-search .dropdown-cancel {
  margin: 0px;
}

.single-select-dropdown-with-search .dropdown-apply {
  margin: 0px;
}

.single-select-dropdown-with-search .dropdown-cancel .btn-outline-stat-alternate {
  margin-left: 23px;
}

.single-select-dropdown-with-search #mt-count-tooltip .search-bar-container .search-bar>input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  outline-style: none;
  padding-left: 42px !important;
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1hwfws3 {
  overflow: hidden !important;
  height: 38px;
  /* overflow-x: auto !important; */
  cursor: pointer;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  white-space: nowrap;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-15hpnlr-control {
  border-color: rgb(181, 192, 202);
}

.single-select-dropdown-with-search #mt-count-tooltip .css-15hpnlr-control:hover {
  border-color: rgb(181, 192, 202);
}

.single-select-dropdown-with-search .arigato {
  background-color: blue;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-bg1rzq-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-bg1rzq-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1szy77t-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1szy77t-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  position: relative;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer {
  position: relative !important;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer:hover {
  position: relative !important;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1wy0on6 {
  width: 90px;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>svg {
  background-color: blue;
}

.single-select-dropdown-with-search #mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6>div>div {
  background-color: rgb(0, 255, 34);
}

.single-select-dropdown-with-search #mt-count-tooltip .css-14ojxsb-control {
  border-color: white;
}

.data-IngestionContainer .client-details-button .single-select-dropdown-with-search {
  width: 186px;
  min-height: 0 !important;
  width: 274px;
  border-radius: 30px;
}

.single-select-dropdown-with-search .data-IngestionContainer .css-1nszmjf {
  color: #919eab;
  padding: 7px;
}

.Multi-Select-BT .single-select-dropdown-with-search {
  cursor: pointer;
}

.single-select-dropdown-with-search div[class*='-indicatorContainer']>svg {
  height: 25px !important;
  width: 25px !important;
  color: #A100FF;
}

.single-select-dropdown-with-search .css-1gl4k7y {
  color: rgb(21, 24, 27);
  text-align: -webkit-left;
  text-align: left;
  margin-left: 2px;
  font-family: 'Graphik-Regular-Web';
}

.single-select-dropdown-with-search .requiredText {
  color: red;
}

.single-select-dropdown-with-search .required-multi-select .css-1nszmjf::after {
  content: '*';
  color: red;
}

.single-select-dropdown-with-search .placeholder-title {
  color: #637381;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 0 0 10px 0;
  padding: 0 7px;
  opacity: 1;
  top: -9px;
  position: relative;
  left: 7px;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover,
.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control],
.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]:hover {
  margin-top: -22px;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover,
.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>div[class*=-control],
.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>div[class*=-control]:hover {
  margin: auto 0;
}

.single-select-dropdown-with-search #mt-count-tooltip div[class*=-container]>label.placeholder-title+div[class*=-control]>div:nth-child(2)>div[class*='-indicatorContainer'] {
  top: -12px;
}

.single-select-dropdown-with-search .cont>div[class*=-Placeholder] {
  color: #A100FF;
}

.select-profile .single-select-dropdown-with-search,
.added-profiles .single-select-dropdown-with-search {
  height: 60px;
  padding: 0.375rem 0 0 0;
}

.add-new-program-container .single-select-dropdown-with-search div[class*='-multiValue']>div:first-child {
  font-size: 10px;
}

.add-new-program-container .single-select-dropdown-with-search #mt-count-tooltip div[class*='-container']>div[class*='-menu'] {
  width: 420px;
}
.multi-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
  max-width: 500px;
  width: 455px;
}

.multi-select-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin: 12px 0px;
  width: 160px;
}

.multi-select-dropdown
  > div[class*="-container"]
  > div[class*="-control"]
  > div {
  max-height: 65px;
  overflow-y: auto;
}


.multi-select-dropdown > div[class*="-container"] > div[class*="-control"] > div:first-child {
  padding: 2px 5px !important;
}

.notifyModal-dropdown .multi-select-dropdown > div[class*="-container"] > div[class*="-control"] > div{
  max-height: none;
  max-height: initial;
}


.notifyModal-dropdown .multi-select-dropdown > div[class*="-container"] > div[class*="-control"]{
  min-width: 100%;
}

.multi-select-dropdown  div[class*="-multiValue"]{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 0 5px;
  min-width: 75px;
}
.single-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
}


.single-select-dropdown
  > div[class*="-container"]
  > div[class*="-control"]
  > div {
  max-height: 65px;
  overflow-y: auto;
}

.single-select-dropdown > div:nth-child(2), .single-select-dropdown > div:nth-child(2) > div {
  width: 100%;
}
.sub-header {
  /* width: 1360px;
  height: 112px; */
  height: 56px;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(33, 43, 54, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(33, 43, 54, 0.1);
  border-radius: 0px 0px 16px 16px;
  padding: 18px 34px;
  color: rgb(33, 43, 54);
  font-size: 18px;
  font-family: Graphik-Semibold-Web;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 18px;
  margin: 0 40px;
}
.sub-header .fa-chevron-left {
  margin-right: 26px;
}
#module-container {
  width: 100%;
  display: block;
  background: #f9fafb;
  position: relative;
  overflow-y: auto
}
.left-sidebar { /*left -> top navbar*/ 
  height: 35px;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1),
    1px 0px 0px 0px rgb(244, 246, 248);
          box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1),
    1px 0px 0px 0px rgb(244, 246, 248);
  border-radius: 0 0 8px 8px;
  padding: 0px 10px 0 0;
  text-align: center;
  position: absolute;
  z-index: 1;
  width: -webkit-fill-available;
}

.left-sidebar ul{
  display: -ms-flexbox;
  display: flex;
}
.left-sidebar ul li {
  padding: 7px 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  white-space: nowrap;
  height: 35px;
  font-size: 14px;
}
.left-sidebar ul li.active,
.left-sidebar ul li:hover {
  background: #ede3f4;
}

.left-sidebar ul li.active span,
.left-sidebar ul li:hover span {
  font-family: "Graphik-Medium" !important;
}

.left-sidebar ul li.active img,
.left-sidebar ul li:hover img {
  -webkit-filter: brightness(1) sepia(1) hue-rotate(207deg) saturate(25);
          filter: brightness(1) sepia(1) hue-rotate(207deg) saturate(25);
}

.left-sidebar ul li span {
  margin-left: 10px;
  vertical-align: middle;
}

.right-section {
  -ms-flex: 1 1;
      flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 20px 0 0 0;
  margin: 40px;
  max-width: calc(100% - 80px);
  min-height: 80vh;
}

.right-section.global-dashboard-rs{
  padding: 0 !important;
  margin: 40px 0 0 40px !important;
}

.right-section-comment {
  -ms-flex: 1 1;
      flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  max-width: 100%;
}
.title-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin: 0 0 12px 0;
}
/* Shared */
.title-section h4 {
  color: rgb(33, 43, 54);
  font-family: "Graphik-Semibold";
  font-size: 24.1px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  line-height: 24px;
  margin: 0;
  margin-right: auto;
}

.project-title .title-section h4 {
  color: rgb(33, 43, 54);
  font-family: "Graphik-Medium";
  font-size: 24.1px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  line-height: 24px;
  margin: 0;
  margin-right: auto;
}

.program-dropdown-project-landing {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 30px 0 15px 0;
}

.program-dropdown-project-landing > svg {
  cursor: pointer;
}

.left-sidebar ul li.no-access {
  cursor: default;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}

.left-sidebar ul li.no-access:hover img {
  -webkit-filter: none;
          filter: none;
}

.left-sidebar ul li.no-access:hover {
  background: transparent;
  cursor: not-allowed;
}

.select-client-container {
  min-height: 600px;
  vertical-align: middle;
  /* text-align: center; */
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.applyFlex-justify-center .select-client-container {
  min-height: 0px;
}

.select-client-container img {
  margin-bottom: 30px;
  height: 90px;
  width: 90px;
}

.select-client-container span {
  color: rgb(181, 192, 202);
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.28px;
}

.select-client-container p {
  color: rgb(181, 192, 202);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  height: 29px;
  letter-spacing: -0.38px;
  margin-bottom: 24px;
}

.dashboard-no-data {
  width: 100%;
  min-height: 400px;
}

/*Responsive Nav Bar & header*/
@media (min-width: 426px) and (max-width: 852px) { 
  .left-sidebar ul li span{
    display: none;
  }

  .left-sidebar ul {
    -ms-flex-pack: center;
        justify-content: center;
  }

  .left-sidebar ul li:hover {
    width: auto;
  }
  
  .left-sidebar ul li:hover span,
  .left-sidebar ul li:active span{
    display: inline;
    width: 100%;
    text-align: left;
    margin-left: 10px;
  }
}

@media (max-width: 426px){ /*MOBILE*/
  .left-sidebar ul li span{
    display: none;
  }

  .left-sidebar ul {
    -ms-flex-pack: center;
        justify-content: center;
  }
}

@media (max-width: 1023px) and (min-width: 852px) {
  .left-sidebar ul li span{
    display: none;
  }

  .left-sidebar ul {
    -ms-flex-pack: center;
        justify-content: center;
  }

  .left-sidebar ul li:hover {
    width: auto;
  }
  
  .left-sidebar ul li:hover span,
  .left-sidebar ul li:active span{
    display: inline;
    width: 100%;
    text-align: left;
    margin-left: 10px;
  }

  #navigation{
    -ms-flex-pack: end;
        justify-content: end;
  }

  .header_title.col-md-9{
    -ms-flex: 0 0 70%;
        flex: 0 0 70%;
    max-width: 70% ;
  }

  #navigation{
    -ms-flex: 0 0 30%;
        flex: 0 0 30%;
    max-width: 30%;
  }
}


@media (max-width: 851px) and (min-width: 639px){
  .header_title.col-md-9{
    -ms-flex: 0 0 60%;
        flex: 0 0 60%;
    max-width: 60% ;
  }

  #navigation{
    -ms-flex: 0 0 40%;
        flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 732px) and (min-width: 511px){
  .header_title.col-md-9{
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50% ;
  }

  #navigation{
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .cvm-span{
    display: none;
  }

  .cvm-span.admin-module{
    display: inline;
  }

  .left-sidebar ul li span{
    display: none;
  }

  .left-sidebar ul {
    -ms-flex-pack: center;
        justify-content: center;
  }

  
}

@media (max-width: 766px) and (min-width: 505px){
  #profile-pop{
    width: 400px !important;
  }
}
/*MOBILE*/

@media (max-width: 501px) and (min-width: 427px){
  #header_container #selectmenu-options{
  left: -100px;
  width: 300px !important;
  }

  #profile-pop{
    width: 350px !important;
  }
}

@media (max-width: 426px) and (min-width: 377px){
  #header_container #selectmenu-options{
    width: 280px !important;
    left: -80px !important;
  }

  #profile-pop{
    width: 350px !important;
  }

}

@media (max-width: 376px){
  .cvm-span{
    display: none;
  }

  .cvm-span.admin-module{
    display: inline;
  }

  #header_container #selectmenu-options{
    width: 280px !important;
    left: -80px !important;
  }

  #profile-pop{
    width: 300px !important;
  }

  #logo{
    margin: 0 !important;
  }
}

@media (max-width: 321px){
  #profile-pop{
    width: 250px !important;
  }

  #logo{
    margin: 0 !important;
  }
}


.multi-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0 5px 0 24px;
  cursor: pointer;
  max-width: 500px;
  width: 455px;
}

.multi-select-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin: 12px 0px;
}

.multi-select-dropdown
  > div[class*="-container"]
  > div[class*="-control"]
  > div {
  max-height: 65px;
  overflow-y: auto;
}


.dashboard-commentary-modal {
  max-width: 600px !important;
}

.dashboard-commentary-history-modal {
  max-width: 800px !important;
}

.dashboard-commentary-history-modal .dashboard-commentary-comments {
  border: none;
}

.dashboard-commentary-modal .commentary-comment > div {
  width: 100% !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.dashboard-commentary-history-modal .dashboard-commentary-comments div.comment-card {
  padding: 10px 0px 0px;
}

.dashboard-commentary-comments .commentary-history-label {
  margin-bottom: 0px !important;
  background: white !important;
  cursor: default !important;
}

.commentary-boidetails-container .commentary-boi-details {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.commentary-boidetails-container .commentary-boi-details > div {
  width: 100%;
  font-size: 12px;
  padding-right: 20px;
}

.commentary-boi-details > div > div:first-child {
  font-weight: 600;
}

.dashboard-commentary-modal .modal-header,
.dashboard-commentary-history-modal .modal-header {
  padding: 20px 20px 0px !important;
}

.dashboard-commentary-modal .modal-body > div:first-child {
  padding: 20px !important;
}

.dashboard-commentary-modal .commentary-add-comments {
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.commentary-add-comments > div {
  padding: 0px 10px;
}

.dashboard-commentary-modal .commentary-add-comments > div:nth-child(3) {
  margin-top: 7px !important;
  margin-right: 7px;
  cursor: pointer;
}

.dashboard-commentary-modal .project-detail-tabs button[aria-selected='false'] span {
  color: rgb(99, 115, 129);
}

.dashboard-commentary-modal .commentary-add-comments #addComment {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
}

.commentary-add-comments #addComment::-webkit-input-placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment::-moz-placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment:-ms-input-placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment::-ms-input-placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment::placeholder {
  font-size: 12px;
}

.dashboard-commentary-modal .commentary-add-comments #addComment:focus-visible {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: #fff !important;
  border: 1px solid rgb(161, 0, 255);
  outline: none;
}

.dashboard-commentary-modal .modal-title > div,
.dashboard-commentary-history-modal .modal-title > div > div {
  font-size: 20px !important;
}

.commentary-comments {
  min-height: 200px;
  max-height: 300px;
  border-top: 1px solid rgb(223, 227, 232);
  border-bottom: 1px solid rgb(223, 227, 232);
  margin: 9px 0px;
  overflow-y: auto;
}

.dashboard-commentary-comments {
  padding: 10px;
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid rgb(223, 227, 232);
  border-bottom: 1px solid rgb(223, 227, 232);
}

.dashboard-commentary-comments div.comment-card {
  padding: 10px 0px;
}
.dashboard-commentary-comments div.edit-comment-card {
  padding: 0px 0px 10px 0px;
}

.dashboard-commentary-comments div.comment-card > div,
.dashboard-commentary-comments div.edit-comment-card > div {
  display: -ms-flexbox;
  display: flex;
}

.dashboard-commentary-comments div.comment-card > div > div > div,
.dashboard-commentary-comments div.edit-comment-card > div > div > div {
  padding: 0px 5px;
}

.dashboard-commentary-comments div.comment-card > div > div:nth-child(2),
.dashboard-commentary-comments div.edit-comment-card > div > div:nth-child(2) {
  padding: 5px 0px;
}

.dashboard-commentary-comments div.comment-card > div > div:nth-child(2) > div:first-child,
.dashboard-commentary-comments div.edit-comment-card > div > div:nth-child(2) > div:first-child {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Medium-Web';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.dashboard-commentary-comments div.comment-card > div > div:nth-child(2) > div:nth-child(2),
.dashboard-commentary-comments div.edit-comment-card > div > div:nth-child(2) > div:nth-child(2) {
  color: rgb(99, 115, 129);
  font-family: 'Graphik-Regular-Web';
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 14px;
}

.dashboard-commentary-comments div.edit-comment-card .agnostic-disclaimer-container {
  height: 60px;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 16px;
}

.dashboard-commentary-comments .commentary-comment > div {
  background: rgb(255, 255, 255) !important;
  border-radius: 6px !important;
  -webkit-box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%) !important;
          box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%) !important;
  min-height: 40px !important;
  padding: 10px !important;
  width: 93% !important;
  margin-left: auto !important;
  margin-right: 5px !important;
  cursor: unset !important;
}

.dashboard-commentary-history-modal
  .dashboard-commentary-comments
  .commentary-comment
  > div:not(.projectHistory) {
  width: 100% !important;
  margin-left: unset !important;
  padding: 0 !important;
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
}

.commentary-comment > div > span {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 16px;
}

.dashboard-commentary-comments .commentary-comment > div > div.btn-icon {
  white-space: nowrap;
  float: right;
}

.dashboard-commentary-comments .commentary-comment > div > div.btn-icon > span {
  float: right;
  padding-top: 5px;
  color: rgb(161, 0, 255);
  cursor: pointer;
  margin-left: 10px;

  font-size: 12px;
  line-height: 16px;
}

.send-disabled {
  color: #bcbcbc;
  cursor: not-allowed;
  pointer-events: none;
}

.commentary-no-comment {
  width: 100%;
  text-align: center;
  font-size: 13px;
  padding: 40px 0px !important;
}

.commentary-no-comment > div {
  display: block !important;
}

.commentary-update-comment #addComment {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
  width: 93%;
  margin-left: auto;
  margin-right: 5px;
}

.commentary-update-comment #addComment::-webkit-input-placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment::-moz-placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment:-ms-input-placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment::-ms-input-placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment::placeholder {
  font-size: 12px;
}

.dashboard-commentary-modal .commentary-update-comment #addComment:focus-visible {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: #fff !important;
  border: 1px solid rgb(161, 0, 255);
  outline: none;
}

.dashboard-commentary-tabs .update-comments-buttons {
  display: -ms-flexbox;
  display: flex;
  float: right;
  margin-right: 10px;
  margin-top: 15px;
}

.update-comments-buttons > div:first-child {
  margin-right: 15px;
}

.comment-edit-header > div {
  font-size: 20px;
  margin-left: 10px;
}

.comment-edit-header > svg {
  padding-top: 5px;
}

/* start */
/* .commentary-popup .commentary-dropdown {
    flex: 1;
  } */

.commentary-dropdown {
  margin: 0 20px;
}

.commentary-dropdown .single-select-dropdown,
.commentary-dropdown .single-select-label {
  width: inherit;
}

.commentary-dropdown .css-dvua67-singleValue {
  color: rgb(161, 0, 255);
}

.dIL-dropdowns .commentary-dropdown .css-mf9lpj-control {
  width: 200px;
}

.commentary-dropdown .css-144vaf5-menu {
  width: 190px;
  position: absolute;
  right: 0;
}

.commentary-dropdowns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  /* justify-content: center; */
}

.dashboard-commentary-modal
  .commentary-comment
  #groove-input
  .floating-label
  input.form-control.groove-input.commentary-label {
  padding: 10px !important;
}

.dashboard-commentary-comments > div {
  margin: 2px !important;
}

.dashboard-commentary-history-modal .back-modal-btn,
.dashboard-commentary-modal .back-modal-btn {
  margin-right: 15px;
  cursor: pointer;
}

.comment-loader {
  height: 200px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.comment-loader .lds-ellipsis {
  position: relative;
  margin: 0;
}

.dashboard-commentary-modal .card-header > button > div {
  word-break: break-all;
  text-align: left;
}

.commentary-tab-panel {
  left: 0 !important
}
.title-section .fa-chevron-left {
  margin-right: 24px;
}

.client-config-container {
  display: -ms-flexbox;
  display: flex;
  border-radius: 0 0 16px 16px;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.stepper-tab-left-section {
  width: 21.2%;
  background: rgb(249, 250, 251);
  border-right: 1px solid rgb(223, 227, 232);
  padding: 25px 0;
  border-radius: 0 0 0 16px;
}

.stepper-tab-left-section .tab-section-header {
  margin: 0 0 15px 0;
  padding: 0 24px;
}

.card-body .tab-section-header {
  margin: 0 20px 15px 20px;
}
.stepper-tab-right-section {
  background: rgb(255, 255, 255);
  padding: 22px 35px;
  width: 100%;
  border-radius: 0 0 16px 0;
}
/* .tab-section-header {
  margin-bottom: 50px;
} */
.tab-section-header {
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.tab-section-header button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 6px 20px;
  /* margin: 0 0 0 25px; */
}
.subtab-list {
  margin-top: 30px;
}
.subtab-list ul li {
  border-radius: 0px;
  padding: 10px 25px;
  margin-bottom: 5px;
  cursor: pointer;
  color: rgb(99, 115, 129);
}
.subtab-list ul,
.left-sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.subtab-list ul li.active,
.subtab-list ul li:hover {
  background: #a100ff;
  color: rgb(255, 255, 255);
}
/* .progress-detail-section {
  display: flex;
} */
.progress-detail-section {
  display: -ms-flexbox;
  display: flex;
  margin: 50px 0 0 0;
}
.progress-bar-unit {
  width: 126px;
  height: 126px;
  margin-left: 28px;
}
.progressbar-innertext {
  text-align: center;
  position: relative;
  top: -72px;
  background-color: white;
  width: 73px;
  left: 56px;
}
.progressbar-innertext h5 {
  color: rgb(33, 43, 54);
  font-size: 18px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 22px;
  margin-bottom: 8px;
}
.progressbar-innertext p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.21px;
  margin: 0 5px;
}

.progress-visual {
  padding: 0 16px;
}
.program-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 35px 60px !important;
}

.details-row {
  margin: 8px 0;
  display: -ms-flexbox;
  display: flex;
}
.progress-label {
  margin: 20px 0px 0px 0px;
}
.stepper-tab-right-section hr {
  margin-top: 30px;
  margin-bottom: 22px;
  margin-right: 20px;
  border-top: 1px solid rgb(234, 234, 234);
}
.project-list-details {
  padding-right: 20px;
  max-height: 430px;
  overflow-y: auto;
}
.project-list-details h4 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin: 0px 0px 0px 8px;
}
.project-detail-section {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  padding: 21px 27px;
  margin-top: 24px;
}
.project-detail-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 30px;
}
.project-detail-header h5 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0;
}
.project-detail-row {
  margin-bottom: 15px;
}
.project-detail-row .row-label {
  margin-right: 10px;
}

.stepped-progressbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.stepped-progressbar p {
  margin: 0 6px;
}
.stepped-progressbar .progressbar-stepslist {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.stepped-progressbar .progressbar-stepslist .progress-step {
  width: 26px;
  height: 5px;
  background: rgb(231, 234, 238);
  border-radius: 0px 0px 0px 0px;
  margin-left: 5px;
}
.progressbar-stepslist .progress-step:first-child {
  border-radius: 5px 0px 0px 5px;
  margin-left: 0px;
}
.progressbar-stepslist .progress-step:last-child {
  border-radius: 0px 5px 5px 0px;
}
.progressbar-stepslist .progress-step.done {
  background: rgb(228, 183, 255);
}
.progressbar-stepslist .progress-step.active {
  background: rgb(117, 0, 192);
}
/* .container-PIV {
  padding: 20px 15px 20px 20px;
} */
.container-PIV {
  padding: 0 0 20px 0;
}
.container-PIV-box {
  padding-right: 25px;
}
.errorbox {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  border-radius: 8px;
  padding: 11px 16px;
  padding-right: 9rem;
  color: rgb(197, 0, 6);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
}

.CVMSideModal-container {
    padding: 28px 25px 0px 23px;
    width: 368px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(220, 226, 231);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: absolute;
    display: block;
    bottom: 0;
    height: 100%;
    right: 0px;
    z-index: 999!important;
    overflow-y: auto;
}
.CVMSideModal-close {
    position: absolute;
    right: 25px;
    top: 18px;
    z-index: 9999!important;
    color: #919eab;
    font-weight: 500;
    cursor: pointer;
}

.CVMSideModal-title {
    color: rgb(45, 58, 75);
    font-size: 18px;
    font-family: Graphik-Semibold;
    font-weight: 600;
    text-align: left;
    letter-spacing: -0.28px;
    line-height: 18px;
    margin-bottom: 17px;
}
.CVMSideModal-tab-container {
    overflow-y: auto;
    padding: 0;
    margin-top: 16px;
}
.CVMSideModal-tab-container .card {
    border: none;
}
.CVMSideModal-tab-container .card-header .btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}
.CVMSideModal-tab-container .card-header {
    border: none;
    background: #fff;
    padding: 0;
}
.CVMSideModal-tab-container .btn-link.focus, 
.CVMSideModal-tab-container .btn-link:focus, 
.CVMSideModal-tab-container .btn-link:hover {
    text-decoration: none;
}
.CVMSideModal-tab-container .btn-link {
    color: #a100ff;
}
.CVMSideModal-tab-container .btn-link:hover {
    color: #7500c0;
}
.CVMSideModal-tab-container .btn.focus, 
.CVMSideModal-tab-container .btn:focus {
    outline: 0;
    -webkit-box-shadow:none;
            box-shadow:none;
}
.CVMSideModal-tab-container .card-body {
    padding: 0 0 0 1.25rem;
}
.CVMSideModal-tab-container .card-header svg {
    margin-right: 10px;
}

/* #groove-textarea .floating-label-textbox #comment-textbox.groove-textbox {
    border: 1px solid #dfe3e8 !important;
    padding: 20px 15px 10px 15px !important;
    color: #212b36;
    font-size: 1rem;
    letter-spacing: -0.35px;
}
.CVMSideModal-container #groove-textarea .floating-label-textbox #comment-textbox.groove-textbox {
    height: 368px;
}
#groove-textarea .floating-label-textbox #comment-textbox.groove-textbox:focus {
    border: 1px solid #a100ff !important;
    padding: 20px 15px 10px 15px !important;
} */
.rejection-modal-buttons #groove-btn-id > button {
    line-height: 1.4;
}
.CVMSideModal-container {
    padding: 28px 25px 0px 23px;
    width: 368px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(220, 226, 231);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: absolute;
    display: block;
    bottom: 0;
    height: 100%;
    right: 0px;
    z-index: 999!important;
}
.CVMSideModal-close {
    position: absolute;
    right: 25px;
    top: 18px;
    z-index: 9999!important;
    color: #919eab;
    font-weight: 500;
    cursor: pointer;
}

.CVMSideModal-title {
    color: rgb(45, 58, 75);
    font-size: 18px;
    font-family: Graphik-Semibold;
    font-weight: 600;
    text-align: left;
    letter-spacing: -0.28px;
    line-height: 18px;
    margin-bottom: 17px;
}
.CVMSideModal-footer {
    border-top: 1px solid rgb(223, 227, 232);
    border-radius: 0px 0px 16px 16px;
    padding: 16px;
    -ms-flex-pack: end;
        justify-content: flex-end;
    width: 100%;
    background: rgb(249, 250, 251);
}
.footer-position {
    position: absolute;
    bottom: 0;
    right: 0;
}
.rejection-modal-buttons.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end;
    margin: 0;
}
.rejection-modal-buttons button {
    font-family: "Graphik-Medium";
    font-size: 14px;
    padding: 9px 20px;
    margin: 0 0 0 25px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  border-style: dashed;
  border: 2px dashed #c4cdd5;
  border-radius: 4px;
  margin-bottom: 15px;
  border-color: #c4cdd5;
  height: 110px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  outline: none;
  margin: 20px 0;
}
.upload-btn-wrapper .btn {
  font-family: Graphik-Medium;
  font-size: 14px;
  position: relative;
  color: #637381;
  font-family: graphik;
  display: block;
  -ms-flex-align: center;
      align-items: center;
  top: 0;
  right: 0;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  font-size: 100px;
}
#approvalEmailFile {
  display: none;
}
.approvalFileBtn {
  color: #a100ff;
  border: none;
  background: transparent;
  margin: 0;
  font-family: Graphik-Medium;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}
.fileupload-browse .approval-attach {
  font-family: graphik;
  margin-left: 5px;
  color: #637381;
  font-size: 12px;
}
.filedrop-msg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.filedrop-msg p {
  color: #637381;
  font-family: Graphik-Medium-Web;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
}
.submit-settings-container #groove-radio-button .form-check > label {
  font-size: 16px !important;
}
.submit-settings-container .row {
  margin: 0;
}
.submitSettings-dialog .modal-header {
  padding: 30px 20px 0px 40px !important;
}
.submitSettings-dialog .modal-body {
  padding: 0px !important;
}
/* .submit-modal-body {
    padding: 20px 20px 0px 40px !important;
} */
.submit-settings-container .submit-content {
  margin-top: 40px;
  /* padding-right: 20px;
  max-height: 420px;
  overflow-y: auto; */
}
.submit-settings-container .rejection-recipient p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-bottom: 12px;
}
.div.cbox-container {
  max-height: 200px;
}
.submit-settings-container .rejection-recipient .groove-select-dropdown {
  margin-bottom: 24px;
}
.submit-settings-container div.custom-checkboxlist {
  max-height: 154px;
}
.attaching-file-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 20px 0px 30px 0px;
}
.attaching-file-container .progress {
  width: 55%;
  height: 10px;
  border-radius: 4px;
  font-size: 10px;
  margin: 0 10px;
}
.attaching-file-container .progress-bar {
  background: rgb(161, 0, 255);
}
.attaching-file-container .attaching-file-name {
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
}
.submitSettings-dialog .selectmenu-options {
  border: 1px solid #dfe3e8;
  -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
          box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.submitSettings-dialog .selectmenu-options .checkbox-dropdown-content {
  margin: 10px 15px !important;
}
.radio-button-container > form > div{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}

.flip-switch-container {
  margin: 10px 0 20px;
  
}

.radio-button-container > form > div > div {
  margin: 0 0 0 8px
}
.submitSettings-dialog .selectmenu-options {
  border: 1px solid #dfe3e8;
  -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
          box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.submitSettings-dialog .selectmenu-options .checkbox-dropdown-content {
  margin: 10px 15px !important;
}

.dimension-details {
  width: 100%;
  margin: 0 !important;
}
.dimension-details > div,
.sub-dimension-details > div,
.boi-details > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
      align-items: baseline;
  padding: 0;
  margin: 0 !important;
}
.dimension-details .inputs,
.dimension-details .inputs > div,
.sub-dimension-details .sub-dimension-input,
.sub-dimension-details .sub-dimension-input > div,
.boi-details .sub-dimension-input,
.boi-details .sub-dimension-input > div {
  width: 100%;
}
.dimension-details .form-group {
  margin: 0;
}

.viewComment-button > :disabled {
  cursor: not-allowed;
}

.dimension-details .btn-link {
  padding: 0;
}

.dimension-details .btn-link:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.radio-button-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.radio-button-container > div {  
  padding: 4px 36px;
  border: 1px solid rgb(151, 151, 151);
  cursor: pointer;
}
.radio-button-container > div.selected {  
  background-color: #a100ff;
  color: rgb(255 255 255);
}
.radio-button-container > div.not-selected {  
  background-color: rgb(151, 151,151);
  color: white;
}

.dimension-details .svg-container {
  margin: 0 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.dimension-collapse {
  width: 100%;
  margin: 0 0 30px 0;
}

.dimension-collapse > .card {
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
}

.dimension-collapse .card-header {
  background: #fff;
  padding: 15px 28px;
  border: none;
}
.dimension-collapse .card-body {
  padding: 0 1.25rem;
}
.dimension-collapse .card-header > button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  text-decoration: none;
  padding: 0;
}

.sub-dimension-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  border-radius: 8px 8px 0 0;
  border: 1px solid rgb(223, 227, 232);
  padding: 16px 44px 16px 44px;
}
.sub-dimension-details > .sub-dimension-title {
  margin: 0 0 0 -25px !important;
}

.dimension-container .card-body > form {
  padding-bottom: 30px;
}
.boi-details {
  border-radius: 0 0 8px 8px;
  border-top: none !important;
  padding: 16px 44px 16px 44px;
  border: 1px solid rgb(223, 227, 232);
}
.boi-details > form > .boi-details-input {
  margin: 0 0 15px 0 !important;
}

.boi-details > form > .row > div {
  padding: 0 !important;
}


.boi-details .sub-dim-input > div {
  padding-left: 0;
}

.sub-dimension-title svg {
  width: 13px !important;
  line-height: 3;
  margin: -3px 5px;
}

.dimension-details span {
  margin: 0 0 0 20px;
}

.sub-dim-input {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
}
.sub-dim-input > div {
  width: 100%;
  /* max-width: 490px; */
}

.sub-dim-input > span {
  margin: 0 0 0 20px;
  width: 80px;
}

/* modal css */

.add-boi-field-container {
  padding: 15px;
  border: 1px solid rgb(234, 234, 234);
  border-radius: 8px;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0;
  position: relative;
  -ms-flex-align: center;
      align-items: center;
}

.add-boi-field-container .new-info-wrap {
  height: auto;
}

.add-boi-field-container > div {
  height: 56px;
}

.delete-boi-button.disabled-delete{
  color: #bcbcbc;
  cursor: not-allowed;
  pointer-events:none;
}

.add-boi-field-container:hover .delete-boi {
  display: -ms-flexbox;
  display: flex;
}

.select-boi-dropdown {
  width: 100%;
  max-width: 25%;
  padding: 0 8px;
}

.addedBOI-dropdown {
  width: 100%;
  padding: 0 8px;
  height: 56px;
  max-width: 25%;
  /* overflow-x: overlay; */
  contain: size;
}

.addboi-dropdown-containers {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.addedBOI-dropdown #groove-select-dropdown div#selectmenu-anchor, .addedBOI-dropdown #groove-input .groove-input {
  border-radius: 8px;
}

.delete-boi {
  -ms-flex-align: center;
      align-items: center;
  padding: 0 5px;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
  width: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}


.delete-dimension-boi {
  padding: 0 5px;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
  width: 50px;
  display: block;
  -ms-flex-item-align: center;
      align-self: center;
}

#deleteboi-popover[x-placement^=left] {
  display: none;
  left: calc(100% - 336px);
}

#deleteboi-popover[x-placement^=right] {
  display: none;
  right: auto;
  left: auto;
  margin-left: 22%;
}

#deleteboi-dimension-popover {
  display: none;
  position: absolute;
  float: right;
  left: calc(100% - 450px);
}
.delete-boi:hover #deleteboi-popover,
.delete-dimension-boi:hover #deleteboi-dimension-popover,
.addedBOI-dropdown:hover #deleteboi-popover{
  display: table-caption !important;
}
#deleteboi-popover .bs-popover-auto[x-placement^=left]>.arrow, .bs-popover-left>.arrow,
#deleteboi-dimension-popover .bs-popover-auto[x-placement^=left]>.arrow, .bs-popover-left>.arrow{
  top: 33px;
}

.add-boi-field-container .form-group {
  margin: 0;
}

.divider-horizontal {
  border-top: 1px solid rgb(231, 234, 238);
  margin: 10px 0;
}
.added-boi-container {
  /* max-height: 500px; */
  overflow: visible;
}

.add-boi-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.add-boi-button > button, .view-project-detail > button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 8px 20px;
  border: none !important; 
  color: #a100ff !important;
  background-color: transparent !important;
}

.view-project-detail > button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.add-boi-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.add-boi-buttons button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 6px 20px;
  margin: 0 0 0 25px;
}


.deleted-bois-container {
  margin: 10px 0;
  border-radius: 8px;
}

.deleted-boi-details {
  position: absolute;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 5px #8c8c8c;
          box-shadow: 0 0 5px #8c8c8c;
  top: 100%;
  display: none;
  z-index: 1;
  min-width: 220px;
  left: 0;
}
.deleted-bois {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 10px 20px;
    border: 1.5px solid #a100ff !important;
    margin: 10px 0;
    border-radius: 8px;
}

.deleted-bois-text, .deleted-bois-text > div {
  font-size: 14px;
  font-family: Graphik-Medium;
  display: inline;
}

.deleted-bois-text {
  max-width: 85%;
}

.deleted-bois-text > div {
  color: rgb(161, 0, 255);
  position: relative;
  cursor: default;
  margin: 0 5px;
  word-break: break-all;
}

.deleted-bois-text > div:hover > .deleted-boi-details {
  display: block;
}

.deleted-boi-details > .deleted-boi-details-label {
  color: #292929;
}

.undo-deleted-boi-btn {
  margin-left: auto;
  margin-right: 35px;
}

.deleted-bois > .svg-container:first-child {
  margin-right: 15px;
}

.deleted-bois > .svg-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.deleted-bois svg {
  font-size: 15px;
}

.tab-header-row.row {
  margin: 0px 0px 16px 0px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: baseline;
      align-items: baseline;
}

.tab-header-row.row > div:first-child {
  max-width: 45%;
}

.tab-header-row > div:nth-child(2) {
  margin-left: auto;
}
.expandAll-button button >span,
.collapseAll-button button >span{
  font-size: 14px !important;
}
.expandAll-button button,
.collapseAll-button button,
.viewComment-button button {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.25px;
  line-height: 14px;
  border: none;
  padding: 0 8px;
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.expandAll-button button {
  border-right: 1.5px solid rgb(99, 115, 129);
}
.expandAll-button .btn:focus,
.collapseAll-button .btn:focus,
.viewComment-button .btn:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #a100ff;
}
.expandAll-button .btn:hover,
.collapseAll-button .btn:hover,
.viewComment-button .btn:hover {
  color: #a100ff;
}
.tab-header-row .add-project-button {
  margin-left: 24px;
}
.tab-header-row-left {
  margin: 10px 0 10px 30px !important;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  min-width: 480px !important;
}
.program-identified-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.program-identified-buttons button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 8px 20px;
  margin: 0 0 0 10px;
}
.viewComment-button .svg-container.small {
  height: inherit;
  width: inherit;
  margin-right: 2px;
}
.ApprovalRequestModal .notifyModal-dropdown {
  /* margin-top: 24px; */
}

.groove-radio-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
}

.groove-radio-button > div {
  margin: 0 16px 0 0;
}

.svg-container:hover + .tooltiptext, .info-icon:hover + .tooltiptext, .new-info-icon:hover + .tooltiptext {
  display: table-caption;
}

.new-info-icon {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  margin-top: 8px;
}

.viewComment-button button,
.viewComment-button .btn:hover,
.viewComment-button .btn:focus {
  color: #a100ff !important;
}

.check-box.check-box-all-boi {
  margin: 20px 0;
}

.check-box-all-boi .check-label{
  width: auto !important;
}

.detail-row.project-section{
  border-left: 1px solid rgb(223, 227, 232);
  border-bottom-left-radius: 0 !important;
}

.stage-container {
  width: 100%;
  padding: 43px 15px 20px 15px;
}

.configure-client-collapse, .subdimension-section-collapse {
  width: 100%;
  margin: 10px 0px !important;
}

.configure-client-collapse > .card,
.subdimension-section-collapse > .card {
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
}

.configure-client-collapse .card-header,
.customboi-card-collapse .card-header,
.subdimension-section-collapse .card-header {
  background: #fff;
  padding: 10px 20px;
  border: none;
}

.configure-client-collapse .card-header > button,
.customboi-card-collapse .card-header > button,
.subdimension-section-collapse .card-header > button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  text-decoration: none;
  padding: 0;
}

.configure-client-collapse .card-header > button:focus,
.customboi-card-collapse .card-header > button:focus,
.subdimension-section-collapse .card-header > button:focus {
  outline: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.configure-client-collapse .card-header > button .svg-container,
.customboi-card-collapse.card-header > button .svg-container,
.subdimension-section-collapse .card-header > button .svg-container {
  /* height: 20px; */
  display: -ms-flexbox;
  display: flex;
}

.configure-client-collapse .card-body,
.customboi-card-collapse .card-body,
.subdimension-section-collapse .card-body {
  padding: 0px 20px;
}

.add-project-button {
  margin-left: auto;
}

.add-project-button button > * {
  font-family: "Graphik-Medium";
  font-size: 14px;
  margin: 0 5px;
}

.add-project-button button svg {
  width: 13px !important;
}

.rvd-proj-btn {
  margin: auto 0 !important;
  -ms-flex-pack: center;
      justify-content: center;
}

.add-new-program-container {
  padding: 25px 40px;
  max-height: none !important;
  max-height: initial !important;
  overflow-y: visible !important;
}

.add-program-buttons,
.notifyModal-buttons,
.add-boi-buttons,
.CVMSideModal-footer,
.add-program-buttons.enable-new-client {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 20px 40px;
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 16px 16px;
  border: 1px solid rgb(223, 227, 232);
}

.add-program-buttons {
  border: none;
  background: rgb(249, 250, 251);
  border: 1px solid rgb(223, 227, 232);
}

.configure-client-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.add-program-buttons button,
.configure-client-buttons button,
.notifyModal-buttons button,
.CVMSideModal-footer button {
  font-family: "Graphik-Medium";
  font-size: 14px;
  padding: 6px 20px;
  margin: 0 0 0 25px;
}

.added-program-container {
  width: 100%;
}

.empty-program-container {
  width: 100%;
  padding: 50px;
}

.empty-program-container {
  width: 100%;
  padding: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.empty-program-container > span {
  margin: 32px 0 24px 0;
}

.added-program-table-container {
  height: 400px;
}
.saveDraft-toast,
.notify-toast {
  position: fixed;
  top: 70px;
  margin-top: inherit;
  right: 40px;
  z-index: 1070;
}
.saveDraft-toast .close,
.saveDraft-toast.close:before,
.saveDraft-toast .close:after,
.notify-toast .close,
.notify-toast.close:before,
.notify-toast .close:after {
  position: inherit;
}
.saveDraft-toast h5,
.notify-toast h5 {
  font-size: 14px;
  font-family: "Graphik-Medium-Web";
  margin-bottom: 0px;
}
.saveDraft-toast .sr-only,
.saveDraft-toast .close:after,
.saveDraft-toast .close::before,
.notify-toast .sr-only,
.notify-toast .close:after,
.notify-toast .close::before {
  display: none;
}
.saveDraft-toast .toast,
.notify-toast .toast {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  max-width: inherit;
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}
.saveDraft-toast .toast-header,
.notify-toast .toast-header {
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f2f9ec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.saveDraft-toast .toast-header svg,
.notify-toast .toast-header svg {
  color: #7ec344;
  font-size: 30px;
  margin-right: 25px;
}
.saveDraft-toast .close,
.notify-toast .close {
  font-size: 2rem;
  opacity: 1;
}
.saveDraft-toast .close.ml-2,
.notify-toast .close.ml-2 {
  margin-left: 2rem !important;
}
.saveDraft-toast .close.mb-1,
.notify-toast .close.mb-1 {
  margin-bottom: 1.2rem !important;
}
@media (min-width: 992px) {
  .notifyModal-dialog.modal-dialog.modal-lg,
  .notifyModal-dialog.modal-dialog.modal-xl,
  .modal65.modal-dialog.modal-lg,
  .modal65.modal-dialog.modal-xl,
  .notifyModal-dialog.modal-dialog,
  .submitSettings-dialog.modal-dialog,
  .modal-dialog.select-project-modal {
    max-width: 51.2% !important;
  }
}
@media (min-width: 1200px) {
  .submitSettings-dialog.modal-dialog {
    max-width: 70% !important;
  }
}
.notifyModal .tab-component-purple .react-tabs__tab,
.notifyModal .tab-component-purple .react-tabs__tab--selected,
.notifyModal .tab-component-purple ul.react-tabs__tab-list {
  margin-bottom: 16px;
}
.notifyModal-dropdown .multi-select-dropdown {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  max-width: inherit;
  width: inherit;
  display: block;
}
.notifyModal-dropdown .css-oq3rz7-multiValue {
  width: 190px;
}
.notifyModal-dropdown .css-14s1juc-control {
  width: 530px;
  max-width: 540px;
  color: rgb(99, 115, 129);
}
.notifyModal-dropdown .css-du0ed4-indicatorContainer {
  color: rgb(99, 115, 129);
}
.notifyModal-dropdown .css-1csqycf-control {
  max-width: inherit;
  width: inherit;
  color: rgb(99, 115, 129);
}
.notifyModal-dropdown .css-gvyuxe-indicatorContainer {
  color: rgb(99, 115, 129);
}
.notifyModal-dropdown .css-1hwfws3 {
  padding-left: 0px;
}
.notifyModal-dropdown .css-oq3rz7-multiValue {
  color: rgb(161, 0, 255);
}
.notifyModal-textarea #groove-textarea .floating-label-textbox {
  margin-top: 32px;
}
.notifyModal-textarea #groove-textarea .groove-textbox {
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  outline: none;
  padding-left: 24px;
  padding-top: 12px;
}
.notifyModal-textarea {
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  outline: none;
  padding: 12px 24px;
  margin-top: 32px;
}
.notifyModal-textarea .textareaLabel {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-bottom: 0.5rem;
}
.notifyModal-textarea textarea {
  border: none;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  letter-spacing: -0.25px;
  line-height: 12px;
  font-weight: normal;
  outline: none;
  color: #212b36;
  font-size: 1rem;
  width: 100%;
}
.padding-b15 {
  padding-bottom: 15px;
}

.config-client-button-container {
  text-align: right;
}

.config-client-button-container > div {
  display:-ms-inline-flexbox;
  display:inline-flex;
}

.error-message {
  color: red;
}

.add-program-buttons.client-config {
  padding: 20px;
}

.notifyModal-dropdown > div:first-child > div:first-child > div:first-child {
  display: none;
}
#groove-textarea .groove-textbox{
  font-family: "Graphik-Regular";
  font-size: 12px !important;
  color: #212b36;
}
.add-project-modal {
  padding: 30px 0 0 0;
  max-height: none !important;
  max-height: initial !important;
  overflow-y: visible !important;
}
.add-project-modal #groove-textarea .floating-label-textbox {
  margin-bottom: 1rem !important;
}

.saveDraft-toast {
  position: fixed;
  top: 70px;
  right: 40px;
  z-index: 1;
}
.saveDraft-toast .close,
.saveDraft-toast.close:before,
.saveDraft-toast .close:after {
  position: inherit;
}
.saveDraft-toast h5 {
  font-size: 14px;
  font-family: "Graphik-Medium-Web";
  margin-bottom: 0px;
}
.saveDraft-toast .sr-only,
.saveDraft-toast .close:after,
.saveDraft-toast .close::before {
  display: none;
}
.saveDraft-toast .toast {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  max-width: inherit;
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}
.saveDraft-toast .toast-header {
  border-bottom: 0px;
  padding: 1rem;
  background-color: #f2f9ec;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.saveDraft-toast .toast-header svg {
  color: #7ec344;
  font-size: 30px;
  margin-right: 25px;
}
.saveDraft-toast .close {
  font-size: 2rem;
  opacity: 1;
}
.saveDraft-toast .close.ml-2 {
  margin-left: 2rem !important;
}
.saveDraft-toast .close.mb-1 {
  margin-bottom: 1.2rem !important;
}
.required-field {
  border-color: red;
}
.tooltip-bottom > .tooltip-inner {
  background-color: #fff;
  color: rgb(5, 5, 5);
  border: 1px solid rgb(107, 107, 107);
}
#boi-tooltip-right.bs-tooltip-top .tooltip-inner {
  margin-bottom: 0;
}
#boi-tooltip-right.bs-tooltip-top .arrow:before {
  border-top-color: #fff !important;
}
.tooltip-bottom > .tooltip-arrow {
  border-top: 5px solid #fff;
}
.program-info {
  text-align: left;
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
}
.project-tab-section-header {
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
}
.project-tab-section-add-btn {
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

#groove-checkbox .form-check {
  position: relative;
  display: block;
  padding-left: 2.25rem;
}

.project-details-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin: 46px 0 0 0 !important;
}

.project-details-button button {
  margin: 0 0 0 25px;
}

.Btns {
  float: right;
  margin-left: 10px;
}
.selectBtnBOI {
  height: 40px;
  width: 147px;
  float: right;
}
.headerAlign {
  display: -ms-flexbox;
  display: flex;
    -ms-flex-align: baseline;
        align-items: baseline;
    min-height: 45px;
}

.toggle-dimensions {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: right;
  width: 168px;
}

.viewComment-button > :disabled {
  cursor: not-allowed;
}

.infoIcon {
  margin: 1px 4px;
}
.missing {
  margin: 0;
  color: red;
  padding: 0 40px;
  margin-right: auto;
}

#groove-input .input-box > input.input-invalid {
  border: 1px solid red !important;
  -webkit-box-shadow: 0 0 5px #ff00007d;
          box-shadow: 0 0 5px #ff00007d;
}

.header {
  color: gray;
}

.table-border {
  border: 1px solid gray;
  border-radius: 10px 9px 0px 0px;
  margin: 8px 35px 0 35px;
  padding: 5px 0px !important;
  overflow-x: hidden;
}
.table-border > div {
  padding: 5px 30px;
}

.table-border > div > div:first-child {
  max-width: 70px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.table-border > div > div {
  max-width: 22%;
  word-break: break-word;
}

.table-header {
  font-weight: bold;
}
.table-header,
.table-content {
  /* display: flex; */
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 2px;
  text-align: left;
  border-bottom: 1px solid gray;
  color: gray;
}

.table-content:last-child {
  border-bottom: none;
}
.boiCount {
  padding: 5px 35px;
  color: gray;
}
#groove-checkbox{
  min-height:25px;
}
/* .header-checkbox{
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin: 6% 13%;
} */
.config-complete-container {
  padding: 60px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
}
.config-complete-container > .row > div > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.completed-icon {
  border: 3px solid #a100ff;
  border-radius: 50%;
  padding: 2px 7px;
}

.completed-status-details {
  width: 90%;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  padding: 35px;
}
.completed-status-details .main-text {
  margin: 25px 0 10px 0;
}

.completed-status-details .left-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.completed-label {
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0 0 0;
}
.completed-details {
  display: -ms-flexbox;
  display: flex;
}

.completed-details > div {
  margin: 0 35px;
}
.completed-value {
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  height: 24px;
  letter-spacing: -0.28px;
  margin: 0 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.completed-programs-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 0;
  -ms-flex-pack: center;
      justify-content: center;
}
.completed-program-tile {
  padding: 20px 25px;
  background: rgb(249, 250, 251);
  border-radius: 16px;
  border: 1px solid rgb(117, 0, 192);
  margin: 20px auto;
}

.completed-program-title {
  color: rgb(117, 0, 192);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
  margin: 0 0 10px 0;
}

.completed-program-label {
  color: rgb(117, 0, 192);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.clientStage-page-container {
  padding: 30px 80px;
}
.stage-back {
  margin: 0 0 35px 0;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.stage-back svg {
  color: rgb(161, 0, 255);
}

.client-stage-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row;
      flex-flow: row;
  width: 100%;
}

.reporting-year-dropdown {
  /* display: flex;
  justify-content: flex-end; */
  margin-left: 790px;
}

.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  padding: 30px 50px 31px 50px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px 16px 0 0;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.md-stepper-horizontal .md-step {
  display: table-cell;
  position: relative;
  padding: 0;
  text-align: center;
  width: 20%;
}
.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
}

.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 4px;
}
.md-stepper-horizontal .md-step .md-step-title-1 {
  margin-top: 0px;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  font-weight: 600;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Semibold-Web;
  text-align: center;
  letter-spacing: 0px;
  line-height: 14.3px;
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  /* top: 50px; */
  top: 38px;
  height: 2px;
  border-top: 1.5px solid rgb(227, 209, 255);
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 30px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 30px;
}
.stepper-section {
  border-radius: 16px;
  background: transparent;
  /* box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1),
    1px 0px 0px 0px rgb(244, 246, 248); */
  position: relative;
}

.progress-detail-section {
  display: -ms-flexbox;
  display: flex;
}
.progress-bar-unit {
  width: 126px;
  height: 126px;
  margin-left: 28px;
}
.progressbar-innertext {
  text-align: center;
}
.progressbar-innertext h5 {
  color: rgb(33, 43, 54);
  font-size: 18px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 22px;
  margin-bottom: 8px;
}
.progressbar-innertext p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.21px;
  margin: 0 5px;
}
.text-label {
  width: 140px;
  display: inline-block;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: 'Graphik-Medium-Web';
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 20px;
}
.text-value {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: 'Graphik-Regular-Web';
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
}
.progress-label {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.21px;
  margin: 20px 0px 0px 0px;
  display: inline-block;
}
.project-list-details {
  padding-right: 20px;
  max-height: 430px;
  overflow-y: auto;
}
.project-list-details h4 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin: 0px 0px 0px 8px;
}
.project-detail-section {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  padding: 21px 27px;
  margin-top: 24px;
}
.project-detail-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 30px;
}
.project-detail-header h5 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0;
}
.project-detail-row {
  margin-bottom: 15px;
}
.project-detail-row .row-label {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 20px;
  margin-right: 10px;
}
.project-detail-row .row-value {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 20px;
}

.md-stepper-horizontal .md-step .md-step-img-circle {
  border-radius: 50%;
  /* background: none !important; */
  height: 50px;
  width: 50px;
  margin: 13px;
  background: '#eff2f5';
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  cursor: not-allowed;
}

.md-stepper-horizontal .md-step.done .md-step-img-circle,
.md-stepper-horizontal .md-step.active .md-step-img-circle {
  cursor: pointer;
}

.md-step-img-circle.stepper-initial-pointer{
  cursor: default !important;
}

/* .md-step.active > .md-step-img-circle {
  background: linear-gradient(
    90deg,
    rgb(117, 0, 192) 0%,
    rgb(161, 0, 255) 100%
  ) !important;
} */

.md-stepper-horizontal > div > .md-step-img-circle {
  background: rgb(228 231 234);
  opacity: 0.5;
}
.md-stepper-horizontal > div > .md-step-title {
  opacity: 0.5;
}

.md-step.active > div.md-step-img-circle {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  -webkit-box-shadow: 0px 1px 4px 0px rgb(98, 54, 255);
          box-shadow: 0px 1px 4px 0px rgb(98, 54, 255);
  opacity: 1;
}

.md-step.active > .md-step-title {
  opacity: 1;
}

/* .md-step.active ~ div > .md-step-img-circle {
  background: none !important;
  opacity: 1 !important;
} */

.md-stepper-horizontal .md-step.done .md-step-img-circle {
  background: rgb(161, 0, 255);
  opacity: 1;
}
.md-stepper-horizontal .md-step.done .md-step-title {
  color: rgb(117, 0, 192);
  font-weight: normal;
}
.md-stepper-horizontal .md-step.active .md-step-title {
  color: rgb(70, 0, 115);
}

/* .container-PIV {
  padding: 20px 15px 20px 20px;
} */
.container-PIV-box {
  padding-right: 25px;
}
.errorbox {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  border-radius: 8px;
  padding: 11px 16px;
  padding-right: 9rem;
  color: rgb(197, 0, 6);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
}
.stepper-close {
  /* position: absolute;
  right: 25px;
  top: 18px; */
  color: #919eab;
  font-weight: 500;
  cursor: pointer;
  font-size: 24px;
}
.stepperClose-Alert-Content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 32px;
}
.stepperClose-Alert-Icon {
  font-size: 32px;
  margin-right: 50px;
  color: #ffc82c;
}
.stepperClose-Alert-text {
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.stepperClose-Alert-text h5 {
  font-size: 16px;
  line-height: 16px;
  font-family: Graphik-Medium-Web;
}
.stepperClose-Alert-text p {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 5px;
}
.infoIcon {
  height: 13px;
  width: 13px;
  border: 1.2px solid #a100ff;
  border-radius: 50%;
  position: absolute;
  top: 0;
}
.stage-title {
  position: relative;
}
.close-btn {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #919eab;
  font-weight: 500;
  cursor: pointer;
  font-size: 18px;
}

#info-popover.popover {
  width: 22rem;
  max-width: none;
  border: none;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
  border-radius: 10px;
}
#info-popover .popover-header {
  font-size: 14px;
  font-family: 'Graphik-Medium-Web';
  background: white;
  border-bottom: none;
  border-radius: 10px 14px 0px 0px;
  max-width: 90%;
}
#info-popover .popover-body {
  padding: 0.25rem 1.25rem;
  padding-bottom: 0.75rem;
}
#info-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
#info-popover ul li {
  width: 100%;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 14px;
  margin-bottom: 0.5rem;
}
#info-popover ul li .line-label {
  font-size: 14px;
  font-family: 'Graphik-Regular-Web';
  width: 40%;
  color: rgb(99, 115, 129);
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 14px;
}
#info-popover ul li .line-text {
  font-size: 14px;
  font-family: 'Graphik-Medium-Web';
  width: 60%;
  color: rgb(99, 115, 129);
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 14px;
}
/* Placeholder css */
 ::-webkit-scrollbar {
  height: 7px;
  width: 15px;
  border-radius: 5px;
  
}
::-webkit-scrollbar-thumb {
  background: #9a9da1 !important;
  min-height: 50px;
  height: 70%;
  width: 70%;
  
  
}
 
 ::-webkit-scrollbar-track {
  display: #f1f1f1;
} 

.config-complete-container {
  padding: 60px;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
}

.config-complete-container>.row>div>div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.completed-icon {
  border: 3px solid #a100ff;
  border-radius: 50%;
  padding: 2px 7px;
}

.completed-status-details {
  width: 90%;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  padding: 35px;
}

.completed-status-details .main-text {
  margin: 25px 0 10px 0;
}

.completed-status-details .left-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.completed-label {
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0 0 0;
}

.completed-details {
  display: -ms-flexbox;
  display: flex;
}

.completed-details>div {
  margin: 0 35px;
}

.completed-value {
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  height: 24px;
  letter-spacing: -0.28px;
  margin: 0 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.completed-programs-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 0;
  -ms-flex-pack: center;
      justify-content: center;
}

.completed-program-tile {
  padding: 20px 25px;
  background: rgb(249, 250, 251);
  border-radius: 16px;
  border: 1px solid rgb(117, 0, 192);
  margin: 20px auto;
}

.completed-program-title {
  color: rgb(117, 0, 192);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
  margin: 0 0 10px 0;
}

.completed-program-label {
  color: rgb(117, 0, 192);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.dimension-table,
.dimension-table .card-header .btn-link {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.dimension-table .dimension-table-header {
  color: rgb(33, 43, 54);
  letter-spacing: 0px;
  background: rgb(249, 250, 251);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
  padding: 12px 24px;
  border-bottom: none;
}

.dimension-table-collapse {
  width: 100%;
}

.dimension-table-row,
.dimension-table .card-header .btn-link {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
      align-items: baseline;
  text-align: left;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.dimension-table-row-approved {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 5px 0;
}

.dimension-table-row>div:first-child,
.dimension-table-row>div:nth-child(2) {
  width: 15%;
}

.dimension-table-row>div:nth-child(3) {
  width: 20%;
  position: relative;
  margin-left: 10px;
}

.dimension-table-row>div:nth-child(4) {
  width: 14%;
}

.dimension-table-row>div:nth-child(5) {
  width: 9%;
}

.dimension-table-row>div:nth-child(6) {
  width: 78px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.dimension-table-row.dimension-table-row-unedit> {
  width: 7%;
  margin-right: 10px;
}

.dimension-table-row.dimension-table-row-unedit>div:nth-child(7).row-visibility {
  width: 8%;
  margin-right: 10px;
}

.dimension-table-row>div:nth-child(7) {
  width: 7%;
  margin-right: 10px;
}

.dimension-table-row>div.toggle-swtch {
  width: 78px;
}

.dimension-table-row-awapp>div:first-child,
.dimension-table-row-awapp>div:nth-child(7),
.dimension-table-row-rejected>div:first-child,
.dimension-table-row-rejected>div:nth-child(7) {
  width: 11%;
}

.dimension-table-row-approved>div:first-child,
.dimension-table-row-approved>div:nth-child(7) {
  width: 11%;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row-approved>div:nth-child(7) {
  position: relative;
  left: 4px;
}

.dimension-table-row-rejected>div:nth-child(10),
.dimension-table-row-awapp>div:nth-child(10) {
  width: 24px;
}

.dimension-table-row-approved>div:nth-child(10) {
  width: 4%;
  -webkit-transform: translate(-20px, -4px);
          transform: translate(-20px, -4px);
}

.dimension-table-row-approved>div:nth-child(11) {
  width: 4%;
}

.dimension-table-row-awapp>div:nth-child(7) {
  -ms-flex-item-align: self-start;
      align-self: self-start;
}

.dimension-table-row-awapp>div:nth-child(2) {
  width: 14%;
  margin-right: 15px;
}

.dimension-table-row-approved>div:nth-child(2) {
  width: 15%;
  margin-right: 15px;
}

.dimension-table-row-awapp>div:nth-child(3) {
  width: 18%;
  position: relative;
  margin-left: 10px;
}

.dimension-table-row-approved>div:nth-child(3) {
  width: 16%;
  position: relative;
  /* margin-left: 10px; */
}

.dimension-table-row-awapp>div:nth-child(4),
.dimension-table-row-awapp>div:nth-child(5),
.dimension-table-row-rejected>div:nth-child(4) {
  width: 16%;
}

.dimension-table-row-approved>div:nth-child(5) {
  width: 16%;
}

.dimension-table-row-approved>div:nth-child(4) {
  width: 14%;
}

.dimension-table-row-rejected>div:nth-child(5) {
  width: 9%;
}

.dimension-table-header>.dimension-table-row-awapp>div:nth-child(8) {
  margin-left: 0;
}

.dimension-table-row-awapp>div:nth-child(6) {
  width: 8%;
  -ms-flex-item-align: auto;
      align-self: auto;
}

.dimension-table-row-approved>div:nth-child(6) {
  width: 8%;
}

div.uom-string {
  position: relative;
  left: 6px;
}

.dimension-table-row-awapp>div:nth-child(6) {
  margin-left: 12px;
}

.dimension-table-row-rejected>div:nth-child(6) {
  width: 13%;
  -ms-flex-item-align: self-start;
      align-self: self-start;
}

.dimension-table-row-awapp>div:nth-child(8) {
  width: 13%;
  -moz-text-align-last: left;
       text-align-last: left;
  margin-left: 12px;
}

.dimension-table-row-rejected>div:nth-child(8) {
  width: 14%;
  -moz-text-align-last: left;
       text-align-last: left;
}

.dimension-table-row-approved>div:nth-child(8) {
  width: 12%;
  /* text-align-last: left; */
  margin-right: 0px;
}

.dimension-table-row-awapp>div:nth-child(9) {
  width: 8%;
  -webkit-transform: translate(-10px, 5px);
          transform: translate(-10px, 5px);
}

.dimension-table-row-awapp>div:nth-child(9) div.svg-container.large {
  position: relative;
  left: 33px;
}

.dimension-table-row-approved>div:nth-child(9) {
  width: 14%;
  -webkit-transform: translate(-5px, -1px);
          transform: translate(-5px, -1px);
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row-approved>div:nth-child(9) {
  left: 8px;
  position: relative;
  top: 4px;
}

.dimension-table-row-rejected>div:nth-child(9) {
  width: 8%;
  -webkit-transform: translate(-10px, 5px);
          transform: translate(-10px, 5px);
}

.dimension-table-row-rejected,
.dimension-table-row-awapp {
  padding: 10px 0;
}

.dimension-table-row-rejected {
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 10px;
}

.dimension-table-collapse .card {
  border-radius: 0px;
}

.dimension-table .card-header,
.dimension-table .card-header .btn-link {
  padding: 8px 12px;
}

.dimension-table .card-header {
  padding: 8px 24px;
  border: none;
  background: #fff;
}

.dimension-table .dimension-table-header .dimension-table-row,
.dimension-table-body .card-body .dimension-table-row {
  width: 95%;
}

.dimension-table .dimension-table-header .history-header,
.dimension-table .dimension-table-header .dimension-table-row-approved.dim-label,
.dimension-table .dimension-table-header .dimension-table-row-rejected,
.dimension-table .dimension-table-header .dimension-table-row-unedit,
.dimension-table .dimension-table-header .dim-title,
.dimension-table .dimension-table-header .dim-header {
  width: 95%;
}

.dimension-table .dimension-table-header .dimension-table-row-awapp {
  width: 94%;
}

.dimension-table .dimension-table-header .dim-awapp,
.dimension-table .dimension-table-header .dim-rejected,
.dimension-table .dimension-table-header .dim-label {
  width: 94%;
}

.dimension-table-row.dimension-table-row-rejected.dim-rejected>div[name="status"] {
  padding-left: 15px;
}

.row.boi-column {
  margin-left: 10px;
}

.dimension-table-row-unedit>div:first-child,
.dimension-table-row-unedit>div:nth-child(2) {
  width: 106px;
}

.dimension-table-row-unedit>div:nth-child(6) {
  width: 12%;
  -ms-flex-align: center;
      align-items: center;
}

.dimension-table-row-unedit>div:nth-child(8) {
  width: 9%;
  -webkit-transform: translate(0px, 8px);
          transform: translate(0px, 8px);
  position: relative;
  top: -8px;
}

.dimension-table-collapse .collapse~.dimension-table-row-unedit>div:nth-child(8) {
  display: none;
}

.dimension-table-row-unedit>div:nth-child(9) {
  width: 30px;
}

.dimension-table .card-body {
  padding: 16px 24px;
}

/* .dimension-table-row .info-icon {
  height: 10px;
  width: 10px;
  border: 1.2px solid #a100ff;
  border-radius: 55%;
  position: absolute;
  bottom: 50%;
  right: inherit;
  top: inherit;
  margin-left: 5px;
} */
.dimension-table-row input[type='text'],
.dimension-table-row-approved input[type='text'] {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
  width: 100%;
}

.dimension-table-collapse .subdimension-rows {
  border-top: 1px solid rgb(223, 227, 232);
}

.dimension-table-collapse .card-body {
  padding: 0;
}

.dimension-table-collapse .card-body .dimension-table-row,
.dimension-table-collapse .card-body .dimension-table-row-approved {
  padding: 16px 0;
  /* width: 95%; */
}

.dimension-table-collapse .card-body .dimension-table-row {
  padding-left: 36px;
}

.dimension-table-collapse .collapse.show~div.card-header .btn-link>div.svg-container {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.dimension-table-collapse .collapse~div.card-header .dimension-table-row>div,
.dimension-table-collapse .collapse~div.card-header .dimension-table-row-approved>div {
  display: none;
}

.dimension-table-collapse .collapse~div.card-header .dimension-table-row>div:first-child,
.dimension-table-collapse .collapse~div.card-header .dimension-table-row-approved>div:first-child {
  display: block;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child {
  pointer-events: none;
  width: 100%;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>#groove-select-dropdown div#display-selected,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div>#groove-select-dropdown div#display-selected {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
  max-width: 100%;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div.form-group>div#selectmenu-anchor,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div.form-group>div#selectmenu-anchor,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div>div.form-group>div#selectmenu-anchor {
  border: none;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div>div.form-group#groove-select-dropdown>div#selectmenu-anchor.form-control[disabled],
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div.form-group#groove-select-dropdown>div#selectmenu-anchor.form-control[disabled],
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div>div.form-group#groove-select-dropdown>div#selectmenu-anchor.form-control[disabled] {
  border: none !important;
  background: none;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div.form-group,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div.form-group {
  margin-bottom: unset;
}

.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row>div:first-child>div.form-group>div#selectmenu-anchor>div#selectmenu-dropdown-icon,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div.form-group>div#selectmenu-anchor>div#selectmenu-dropdown-icon,
.dimension-table-collapse .collapse:not(.show)~div.card-header .dimension-table-row-approved>div:first-child>div>div.form-group>div#selectmenu-anchor>div#selectmenu-dropdown-icon {
  display: none;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row>div,
.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row-approved>div {
  display: block;
}

.boi-column {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: baseline;
      align-items: baseline;
}

.dimension-table-collapse .collapse~div.card-header .dimension-table-row>.boi-column,
.dimension-table-collapse .collapse~div.card-header .dimension-table-row-approved>.boi-column {
  display: none !important;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row>.boi-column {
  display: -ms-flexbox !important;
  display: flex !important;
  margin-left: 10px;
  margin-right: 12px;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row-approved>.boi-column {
  display: -ms-flexbox !important;
  display: flex !important;
  margin-left: 5px;
  margin-right: 16px;
}

.text-field {
  width: 265px;
  height: 78px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(181, 192, 202);
  border-radius: 4px;
}

.comment-input {
  height: 90px;
  resize: none;
  outline: none;
  background: rgb(239, 242, 245);
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
}

textarea {
  width: 100%;
  height: 100%;
  background: white;
}

.top-left-container-icon {
  width: 17.1px;
  height: 17.55px;
  margin: -4px 5px 0;
  cursor: pointer;
}

.top-left-container-text {
  width: 86px;
  height: 19px;
  color: rgb(161, 0, 255);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 10px;
  cursor: pointer;
}

.dimension-header-container {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 95%;
}

.dimension-header-container .svg-inline--fa.fa-w-18 {
  -webkit-transform: inherit;
          transform: inherit;
}

button.dim-acc-btn.btn.btn-link {
  width: 30px;
}

.accordion>.card>.dim-card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

textarea.comment-input:-moz-read-only {
  cursor: not-allowed;
}

textarea.comment-input:read-only {
  cursor: not-allowed;
}

.program-column {
  max-width: 90%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0px !important;
  text-align: left;
}

.program-table,
.program-table .card-header .btn-link {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.program-table-header {
  color: #ffff;
  letter-spacing: 0;
  background: #919eab;
  border-radius: 5px 5px 0 0;
  padding: 12px 24px;
  border: 1px solid #e7eaee;
  border-bottom: none;
  text-align: center;
}

.program-table-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  border-bottom: 1px solid #dfe3e8;
}

.program-table .program-table-header .program-table-row,
.program-table-body .card-body {
  width: 95%;
}

.accordion>.card>.prog-card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prog-card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.program-header-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: -webkit-fill-available;
}

.project-table-header {
  color: #15181b;
  letter-spacing: 0;
  background: rgba(0, 186, 255, 0.06);
  padding: 12px 24px;
  border: 1px solid #e7eaee;
  border-bottom: none;
  text-align: center;
  margin: 0px 24px;
}

.collapse.show~div.card-header>.program-header-container>.btn-link>div.svg-container {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.project-table-header~.dimension-table-body .program-column:nth-child(5) {
  width: 27px !important;
}

.project-header-container>.dimension-table-row {
  width: 100% !important;
  -ms-flex-align: center;
      align-items: center;
  padding: 2px;
}

.project-table-header~.dimension-table-body {
  margin: 0 24px;
  padding: 0 24px;
}

.project-table-header>.dimension-table-row {
  width: 100% !important;
}

.program-table-header~.dimension-table-body>.accordion>.card {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-radius: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.dim-del-icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

/*======================================== REJECTED BOI CSS ===============================================*/

.collapse.show~div.card-header div.svg-container>.quick-delete {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

div.svg-container>.quick-delete {
  vertical-align: middle;
  cursor: pointer;
  color: rgb(161, 0, 255);
}

.boi-status {
  word-break: keep-all;
  width: 80px;
  cursor: default;
}

.boi-status.no-status {
  background: transparent;
}

.boi-status.status-break {
  word-break: break-all;
}

.reject-status-table .dimension-table-row>div {
  word-break: break-word;
}

.disabled-delete>div.svg-container>.quick-delete {
  color: #bcbcbc;
  cursor: not-allowed;
}

.disabled-uom-accordion {
  position: relative;
}

span.quickDelete.tooltiptext {
  left: auto;
  right: 0;
  margin-top: 5px;
}

input:hover+.tooltiptext,
div#groove-select-dropdown:hover+.tooltiptext {
  display: table-caption;
}

span.boiUom.tooltiptext {
  right: auto;
  left: auto;
  margin-top: 5px;
}

span.boiUomUsedInProj.tooltiptext {
  right: -140px;
  left: auto;
  margin-top: 5px;
}

span.boiAgnosticdimension.tooltiptext {
  left: 20px;
  margin-top: 5px;
}

.wheel-visibility-button input:checked+.mini-toggle-slider {
  background-color: rgb(161, 0, 255) !important;
}

.wheel-visibility-button input:checked+.mini-toggle-slider:before {
  border-color: rgb(161, 0, 255) !important;
}

.wheel-visibility-button input:checked+.mini-toggle-slider:after {
  border-color: transparent !important;
}

.wheel-visibility-button span.mini-toggle-slider[disabled] {
  cursor: not-allowed !important;
}

.wheel-visibility-button .mini-toggle-switch {
  margin-top: 9px;
}

.info-icon-wrap {
  display: inline-block;
}

.row.boi-column>div:first-child>div:not(.info-icon-wrap) {
  margin-left: 10px;
}

.row.boi-column>div:first-child>.info-icon-wrap+div {
  margin-left: 0;
}

.dimension-new-info img.new-info-icon {
  -webkit-transform: scale(1);
          transform: scale(1);
  margin-top: 0;
}

.new-info-icon {
  margin-top: -11px !important;
  margin-bottom: -17px !important;
  margin-left: -9px !important;
}

.disabled-switch .mini-toggle-slider {
  cursor: not-allowed !important;
}

#boi-tooltip-right {
  opacity: 1;
}

#groove-toggle .mini-toggle-slider {
  height: 21px !important;
  width: 40px !important;
}

#groove-toggle .mini-toggle-slider:before {
  height: 19px !important;
  width: 20px !important;
}

#groove-toggle input:checked+.mini-toggle-slider:before {
  height: 19px !important;
}

.wheel-visibility-button-approve input:checked+.mini-toggle-slider {
  background-color: rgb(161, 0, 255) !important;
}

.wheel-visibility-button-approve input:checked+.mini-toggle-slider:before {
  border-color: rgb(161, 0, 255) !important;
}

.wheel-visibility-button-approve input:checked+.mini-toggle-slider:after {
  border-color: transparent !important;
}

.wheel-visibility-button-approve span.mini-toggle-slider[disabled] {
  cursor: not-allowed !important;
}

td {
  vertical-align: top;
}

.dim-delete {
  margin-top: 7px;
}

/* Edit Stage 2 Dimension Accordion CSS */

.dimension-table-row-awapp #groove-select-dropdown,
.dimension-table-row-approved #groove-select-dropdown,
.dimension-table-row-rejected #groove-select-dropdown {
  height: 35px;
  margin-bottom: 0px;
  contain: size;
}

.dimension-table-row-awapp #groove-select-dropdown .default-display,
.dimension-table-row-approved #groove-select-dropdown .default-display,
.dimension-table-row-rejected #groove-select-dropdown .default-display {
  width: 65%;
  font-size: 10px;
  margin-top: -15px;
}

.dimension-table-row-awapp #groove-select-dropdown div#selectmenu-anchor,
.dimension-table-row-awapp #groove-select-dropdown div#selectmenu-anchor[disabled],
.dimension-table-row-approved #groove-select-dropdown div#selectmenu-anchor,
.dimension-table-row-approved #groove-select-dropdown div#selectmenu-anchor[disabled],
.dimension-table-row-rejected #groove-select-dropdown div#selectmenu-anchor,
.dimension-table-row-rejected #groove-select-dropdown div#selectmenu-anchor[disabled] {
  font-size: 10px;
  border-radius: 20px;
  height: 35px;
  width: 100%;
  padding: 5px;
}

.dimension-table-row-awapp #groove-select-dropdown #selectmenu-dropdown-icon,
.dimension-table-row-approved #groove-select-dropdown #selectmenu-dropdown-icon,
.dimension-table-row-rejected #groove-select-dropdown #selectmenu-dropdown-icon {
  position: relative;
  margin-top: 5px;
  margin-right: 0px;
  float: right;
}

.dimension-table-row-awapp #groove-select-dropdown .selectmenu-group-values,
.dimension-table-row-approved #groove-select-dropdown .selectmenu-group-values,
.dimension-table-row-rejected #groove-select-dropdown .selectmenu-group-values {
  width: 65%;
}

.dimension-table-row-awapp #groove-select-dropdown .selected-no-placeholder,
.dimension-table-row-approved #groove-select-dropdown .selected-no-placeholder,
.dimension-table-row-rejected #groove-select-dropdown .selected-no-placeholder {
  margin-top: 5px;
}

.dimension-table-row-awapp #groove-select-dropdown div#display-selected,
.dimension-table-row-approved #groove-select-dropdown div#display-selected,
.dimension-table-row-rejected #groove-select-dropdown div#display-selected {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  /* max-width: 41px; */
}

.collapse.show~div.card-header .dimension-table-row-awapp .AA-accordion-dropdown div.svg-container,
.collapse.show~div.card-header .dimension-table-row-approved .AA-accordion-dropdown div.svg-container,
.collapse.show~div.card-header .dimension-table-row-rejected .rejected-accordion-dropdown div.svg-container {
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}

.dimension-table-row-awapp #groove-select-dropdown div.selectmenu-options.display-list .dropdown-item,
.dimension-table-row-approved #groove-select-dropdown div.selectmenu-options.display-list .dropdown-item,
.dimension-table-row-rejected #groove-select-dropdown div.selectmenu-options.display-list .dropdown-item {
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: normal;
  padding: 5px;
  font-size: 12px;
}

.dimension-table-row-awapp #groove-select-dropdown div.selectmenu-options.display-list,
.dimension-table-row-approved #groove-select-dropdown div.selectmenu-options.display-list,
.dimension-table-row-rejected #groove-select-dropdown div.selectmenu-options.display-list {
  width: 185px;
}

.dimension-table-row-awapp #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input,
.dimension-table-row-approved #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input,
.dimension-table-row-rejected #groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input {
  left: 30px;
  width: 70%;
}

.dim-history #groove-btn-id .btn-utility-stat-alternate>.svg-container>svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.dimension-table-row-duplicate-boi {
  border-color: red !important;
  padding: 10px 0;
  border: 1px solid;
  border-radius: 8px;
}

.dimension-table-row .row-visibility,
.dimension-table-program .row-visibility {
  white-space: normal;
}

.dim-textarea {
  margin-right: 16px !important;
}

@media only screen and (max-width: 1300px) {

  .dimension-table-row-approved #groove-select-dropdown div#display-selected,
  .dimension-table-row-awapp #groove-select-dropdown div#display-selected,
  .dimension-table-row-rejected #groove-select-dropdown div#display-selected {
    max-width: unset;
  }
}

.dimension-table-row-rejected>div:nth-child(3) {
  margin-left: 0px !important;
}

#groove-select-dropdown .selectmenu-group-values {
  width: 70% !important;
}

input#offering[disabled],
input#dtTargetEndDate[disabled],
input#projectName[disabled],
#groove-textarea .groove-textbox:disabled {
  color: #919eab;
}

.row.boi-column div {
  display: unset !important;
}

.empty-div {
  width: 24px;
  margin-left: 12px;
}

.accordion-child-div-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0 24px;
}

.dimension-table-row-duplicate-boi {
  -ms-flex-align: start !important;
      align-items: flex-start !important;
}

.dimension-table-row-duplicate-boi>div:nth-child(8) {
  padding-top: 4px;
}

.dimension-table-row-duplicate-boi>div:nth-child(10) {
  padding-top: 8px;
}

.db-visib-tooltip {
  padding-bottom: 10px;
}

.toggle-swtch {
  /* display: flex !important; */
}

#groove-toggle .mini-toggle-slider[disabled]:hover {
  background-color: #c4cdd5;
}

.reject-msg-on-toggle {
  text-align: center;
}

.reject-msg-on-toggle .svg-inline--fa.reject-BOI-msg {
  color: white;
  background-color: rgb(170, 0, 37);
  -webkit-transform: none;
          transform: none;
  height: 100%;
  width: 100%;
  border-radius: 100px;
}

.reject-msg-on-toggle .svg-container.large {
  height: 87.75px;
  width: 87.75px;
}

.reject-msg-on-toggle p {
  text-align: center;
}

.reject-msg-on-toggle p.header {
  color: rgb(21, 24, 27);
  font-family: Graphik-Semibold;
  font-size: 18px;
  font-weight: 600;
  height: 18px;
  letter-spacing: -0.28px;
}

.reject-msg-on-toggle p.body-msg {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 48px;
  line-height: 24px;
}

.reject-msg-btn-container {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 auto;
}

.reject-msg-btn-container button {
  width: 94px;
  height: 40px;
  margin: 0 25px;
}

.reject-msg-btn-container #groove-btn-id>button>* {
  margin: 3px auto;
}

.disabler {
  pointer-events: none;
}

.svg-inline--fa.fa-exclamation-triangle.reject-alert {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  color: rgb(170, 0, 37);
  height: 14.4px;
  width: 16px;
}

.svg-inline--fa.fa-exclamation-triangle.reject-alert.first-boi {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  color: rgb(170, 0, 37);
  height: 14.4px;
  width: 16px;
}

.tooltip-dbVisibUpdate>span {
  font-family: Graphik-Medium !important;
  font-size: 11px !important;
}

.tooltip-dbVisibUpdate {
  opacity: 1 !important;
  padding: 11px !important;
  text-align: left !important;
}

.collapse.show~div.card-header .deleted-bois>div.svg-container {
  -webkit-transform: unset;
  transform: unset;
}

.dimension-table-collapse .collapse.show~div.card-header .dimension-table-row>div.toggle-swtch {
  display:-ms-flexbox;
  display:flex;
}

.add-program-details .floating-label.input-box input#programName:disabled{
  color: #919eab;
}

.categorization-table-collapse > .card {
  border-radius: 5px !important;
  border: 1px solid rgba(231,234,238, 1);
  background-color: rgba(255,255,255, 1);
  margin-bottom: 15px;
  padding: 0px 15px !important;
}

.categorization-table-collapse > .card > .card-header {
  background: none;
  border: none;
  padding: 5px 0px 0px !important;
}

.categorization-table-collapse .card-header > .dimension-header-title {
  color: rgba(33,43,54,1);
  font-family: "Graphik-Medium";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
}

.categorization-table-collapse .dimension-table-program {
  padding-bottom: 15px !important;
}

.categorization-table-collapse  > .card > .card-header > .btn:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.cvm-text-input {
  outline: none;
}

.cvm-text-input::-webkit-input-placeholder {
  font-size: 11px;
  text-align: center;
}

.cvm-text-input::-moz-placeholder {
  font-size: 11px;
  text-align: center;
}

.cvm-text-input:-ms-input-placeholder {
  font-size: 11px;
  text-align: center;
}

.cvm-text-input::-ms-input-placeholder {
  font-size: 11px;
  text-align: center;
}

.cvm-text-input::placeholder {
  font-size: 11px;
  text-align: center;
}


.disabled-id-val-field {
  cursor: not-allowed;
}

.cvm-radio-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}

.cvm-radio {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.cvm-radio-button {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(196, 205, 213);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 3px;
  padding: 2px;
}

.cvm-radio.selected-radio>.cvm-radio-button {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(161, 0, 255);
}

.cvm-radio.selected-radio>.cvm-radio-button>div {
  background: rgb(161, 0, 255);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.cvm-radio-label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 20px 10px 20px 0;
  white-space: nowrap;
}

.selected-radio>.cvm-radio-label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
}

.cvm-input-textarea {
  resize: none;
  outline: none;
  height: 100px;
  border-radius: 4px;
  font-size: 12px;
}

.cvm-input-textarea-rejected-req {
  border: 1px solid rgb(170, 0, 37);
}

.cvm-input-textarea-con-app-req {
  border: 1px solid rgb(244, 147, 66) !important;
}

.cvm-input-textarea-rejected {
  background: rgb(255, 245, 247);
}

.cvm-input-textarea-con-app {
  background: rgb(253, 239, 228)
}


.cvm-text-input:focus, .cvm-input-textarea:focus {
  border: 1px solid rgb(161, 0, 255) !important;
  -webkit-box-shadow: 0 0 4px rgb(161 0 255 / 79%);
          box-shadow: 0 0 4px rgb(161 0 255 / 79%);
}

.cvm-text-input.input-error-rejected {
  border: 1px solid rgb(170, 0, 37) !important;
  -webkit-box-shadow: 0 0 4px rgb(170, 0, 37);
          box-shadow: 0 0 4px rgb(170, 0, 37);
}

.cvm-text-input.input-error-con-app {
  border: 1px solid rgb(244, 147, 66) !important;
  -webkit-box-shadow: 0 0 4px rgb(244, 147, 66);
          box-shadow: 0 0 4px rgb(244, 147, 66);
}

.disabled-toggle {
  pointer-events: none;
}

.disabled-toggle-parent {
  cursor: not-allowed;
}

input:hover+.tooltiptext {
  display: table-caption;
}

span.targetvalue.tooltiptext {
  left: 400px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 5px;
}

.svg-inline--fa.reject-BOI-msg {
  color: white;
  background-color: rgb(170, 0, 37);
  -webkit-transform: none;
          transform: none;
  height: 15px;
  width: 15px;
  border-radius: 15px;
}

.reject-msg {
  color: rgb(170, 0, 37);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.25px;
  line-height: 16px;
  width: calc(100vw - 59vw);
}

.reject-msg .svg-container {
  height: auto;
}

div#reject-rdbtn-tooltip > div.tooltip-inner{
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 11px;
  letter-spacing: -0.25px;
  line-height: 16px;
  text-align: left;
  max-width: 314px;
}
.agnostic-boi-input-container{
  padding: 10px 4px !important;
}
.dimension-table .dimension-table-header .specific-history-header {
  width: 100% !important;
  -ms-flex-align: center;
      align-items: center;
}

.dim-header-container {
  width: 100% !important;
}

.dimension-boi-history {
  line-height: 1.4em;
}
.dimension-boi-history > div > .boi-divider {
  position: relative;
  padding: 0 5px;
}

.dimension-boi-history > div > .boi-divider:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 130%;
  background-color: rgb(99, 115, 129);
}

.dimension-boi-history > div:nth-child(6) {
  display: block;
}

.dimension-boi-history > div:first-child,
.dimension-boi-history > div:nth-child(2) {
  width: 15% !important;
}
.dimension-boi-history > div:nth-child(3) {
  width: 20%;
  position: relative;
}
.dimension-boi-history > div:nth-child(4) {
  width: 12%;
}
.dimension-boi-history > div:nth-child(5) {
  width: 12% !important;
}
.dimension-boi-history > div:nth-child(6) {
  width: 12% !important;
}
.dimension-boi-history > div:nth-child(7) {
  width: 20%;
}
.dimension-boi-history > div:nth-child(8) {
  width: 20%;
}

.dim-card-header {
  padding: 0 24px !important;
}

.card {
  padding: 8px 0 !important;
}

.boi-column > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: auto;
  word-break: break-word;
}

.boiHistoryModal {
  max-width: 95vw !important;
  min-height: 300px !important;
}

.boiHistoryModal .back-modal-btn {
  cursor: pointer;
}

.boi-history {
  margin: 0;
}

.boi-history .title {
  width: 759px;
  height: 36px;
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}

.boi-history .close-btn {
  right: 50px;
  top: 30px;
  color: #637381;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
}

.boi-history .table-view-wraps {
  /* display: table; */

  width: 100%;
  margin: 0 auto;
  padding: 20px 40px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.boi-history .table-view-wraps.tab-modified .dimension-table-row > div:nth-child(6) {
  -ms-flex-item-align: center;
      align-self: center;
}

.boi-history .table-view-wraps .dimension-table-body {
  max-height: 400px;
  overflow: auto;
  border-bottom: 1px solid rgb(234, 234, 234);
}

.btn-boi-history-container {
  display: inline-block;
}

#groove-btn-id > button#btn-boi-history {
  cursor: pointer;
}

#groove-btn-id > button#btn-boi-history:active,
#groove-btn-id > button#btn-boi-history:focus,
#groove-btn-id > button#btn-boi-history:hover {
  background: transparent;
}

#groove-btn-id > button#btn-boi-history > * {
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 0;
}
/* #btn-icon-history button[aria-hidden='true'] .svg-container small {
  transform: none;
} */

#btn-icon-history.svg-container > svg {
  -webkit-transform: none !important;
          transform: none !important;
}

#custom-toggle .mini-toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 34px;
}

#custom-toggle .mini-toggle-switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 50px;
}

.wheel-visibility-button .mini-toggle-switch {
    margin-top: 9px;
}

#custom-toggle .mini-toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

#custom-toggle .mini-toggle-switch input {
    height: 0;
    opacity: 0;
    width: 0;
}

#custom-toggle .mini-toggle-slider {
    height: 21px !important;
    width: 40px !important;
}

#custom-toggle .mini-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    height: 25px;
    width: 45px;
    border-radius: 20px;
    background-color: #c4cdd5;
}

#custom-toggle .mini-toggle-slider {
    background-color: #c4cdd5;
    border-radius: 20px;
    bottom: 0;
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: .4s;
    transition: .4s;
    width: 45px;
}

#custom-toggle .mini-toggle-slider:before {
    height: 19px !important;
    width: 20px !important;
}

#custom-toggle .mini-toggle-slider:before {
    position: absolute;
    height: 23px;
    width: 24px;
    background-color: #fff;
    -webkit-transition: 0s;
    transition: 0s;
    border-radius: 20px;
    content: "";
    -webkit-box-shadow: 0 0 0 0 rgb(0 0 0 / 24%);
            box-shadow: 0 0 0 0 rgb(0 0 0 / 24%);
    margin: 1px 1px 1px 2px;
}

#custom-toggle .mini-toggle-slider:before {
    background-color: #fff;
    border-radius: 20px;
    -webkit-box-shadow: 0 0 0 0 rgb(0 0 0 / 24%);
            box-shadow: 0 0 0 0 rgb(0 0 0 / 24%);
    content: "";
    height: 23px;
    margin: 1px 1px 1px 2px;
    position: absolute;
    -webkit-transition: 0s;
    transition: 0s;
    width: 24px;
}

#custom-toggle input:checked+.mini-toggle-slider:before {
    height: 19px!important;
}

#custom-toggle input:checked+.mini-toggle-slider:before {
    border: 1px solid #a100ff;
    margin-top: 0.85px;
    height: 22px;
    border-radius: 12px;
    -webkit-transform: translateX(18px);
    transform: translateX(18px);
}

#custom-toggle input:checked+.mini-toggle-slider:after {
    content: "";
    position: absolute;
    left: 30px;
    bottom: 6px;
    width: 4px;
    height: 13px;
    border: solid #a100ff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    padding-bottom: 2px;
    border-color: transparent!important;
}

#custom-toggle .mini-toggle-slider>div {
    margin-left: 55px;
    width: auto;
}

#custom-toggle .mini-toggle-slider>div {
    margin-left: 55px;
    width: auto;
}

#custom-toggle input:checked+.mini-toggle-slider {
    background-color: #a100ff;
}

#custom-toggle input:checked+.mini-toggle-slider {
    background-color: #a100ff;
}

#custom-toggle .mini-toggle-slider {
    height: 21px !important;
    width: 40px !important;
}

#custom-toggle .mini-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    height: 25px;
    width: 45px;
    border-radius: 20px;
    background-color: #c4cdd5;
}

#custom-toggle .mini-toggle-slider {
    background-color: #c4cdd5;
    border-radius: 20px;
    bottom: 0;
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: .4s;
    transition: .4s;
    width: 45px;
}

#custom-toggle.disabled span#switch_toggle_click{
    cursor: not-allowed;
}
.tabbed-dropdown-field {
  background: #fff;
  border-radius: 4px;
  height: 54px;
  width: 100%;
  color: #000;
  font-family: Graphik-Regular-Web;
  border: 1px solid #ced4da;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  position: relative;
  cursor: pointer;
}

.tabbed-dropdown-field.tabbed-disabled {
  cursor: not-allowed;
  border: 1px solid #dfe3e8 !important;
  background: #f9fafb;
}

.tabbed-disabled .tabbed-dropdown-value {
  color: #919eab !important;
}

.tabbed-dropdown-label {
  color: #a100ff !important;
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

.tabbed-dropdown-label span {
  color: red !important;
  margin-left: 2px;
}

.tabbed-dropdown-placeholder {
  color: #919eab;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.tabbed-dropdown-value {
  position: absolute;
  top: 20px;
  bottom: 0;
  margin: auto;
  /* display: flex; */
  -ms-flex-align: center;
      align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65%;
}
.tabbed-dropdown-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.tabbed-dropdown-menu {
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 8px;
  background-color: #fff;
  width: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow-y: auto;
  margin: 8px 0;
  z-index: 2;
  position: relative;
  width: 378px;
}

.tabbed-dropdown-menu .react-tabs__tab-list {
  border-bottom: 1px solid #dbdbdb !important;
}

.react-tabs__tab-panel {
  padding: 4px 8px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background: none;
}

.tabbed-dropdown-option {
  margin-top: 8px;
  max-height: 180px;
  overflow: auto;
}

.tabbed-dropdown-select {
  cursor: pointer;
  padding: 8px 16px 8px 16px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: normal;
  width: 100%;
  color: #212b36;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 16px;
  font-family: Graphik-Regular;
  font-weight: 400;
}

.tabbed-dropdown-select:hover,
.tabbed-dropdown-select.tabbed-selected {
  background: #a100ff !important;
  color: #fff;
}

.tabbed-dropdown-field .svg-container {
  right: 24px;
  position: absolute;
  font-size: 1.3em;
}

.agnostic-dropdown {
  width: 100% !important;
}

.select-agnostic {
  padding: 2px 4px;
  cursor: pointer;
  font-family: Graphik-Regular-Web;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 16px;
}

.select-agnostic:hover {
  background-color: #a100ff;
  color: #fff;
}

.agnostic-serachbox {
  width: 100% !important;
}

.agnostic-search-bar svg {
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 16px;
}

.agnostic-search-bar > input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid rgb(231, 234, 238);
  outline-style: none;
}

.select-agnostic-no-option {
  font-family: 'Graphik-Regular';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.35px;
  cursor: pointer;
}

.close-modal-btn {
  cursor: pointer;
}

.check-box {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
  float: left;
  margin-right: 11px;
}

.cvm-checkbox .svg-container {
  display: none;
  margin-right: 11px;
}

.cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.cvm-checked .svg-container {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px !important;
  padding: 11px;
}
.labels {
  position: relative;
  display: -webkit-inline-box;
}
.cont {
  height: 34px;
  overflow: scroll !important;
  overflow-x: hidden !important;
}
#mt-count-tooltip .label14-regular-midnight {
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  position: sticky;
  margin: 18px 13px 18px 13px;
}
#mt-count-tooltip .label14-regular-midnight .sea {
  width: 100%;
}
.dropdown-buttons {
  margin: 11px 0px 11px 0px !important;
}

.dropdown-cancel {
  margin: 0px;
}
.dropdown-apply {
  margin: 0px;
}
.dropdown-cancel .btn-outline-stat-alternate {
  margin-left: 23px;
}
#mt-count-tooltip .search-bar-container .search-bar > input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  outline-style: none;
  padding-left: 42px !important;
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}
#mt-count-tooltip .css-1hwfws3 {
  overflow: hidden !important;
  height: 38px;
  /* overflow-x: auto !important; */
  cursor: pointer;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}

#mt-count-tooltip .css-15hpnlr-control {
  border-color: rgb(181, 192, 202);
}
#mt-count-tooltip .css-15hpnlr-control:hover {
  border-color: rgb(181, 192, 202);
}
.arigato {
  background-color: blue;
}

#mt-count-tooltip .css-bg1rzq-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}
#mt-count-tooltip .css-bg1rzq-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}
#mt-count-tooltip .css-1szy77t-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}
#mt-count-tooltip .css-1szy77t-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  position: relative;
}
#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer {
  position: relative !important;
}
#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer:hover {
  position: relative !important;
}
#mt-count-tooltip .css-1wy0on6 {
  width: 90px;
}
#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6 > svg {
  background-color: blue;
}
#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6 > div > div {
  background-color: rgb(0, 255, 34);
}

#mt-count-tooltip .css-14ojxsb-control {
  border-color: white;
}

.data-IngestionContainer .client-details-button .multi-select-dropdown-with-count {
  width: 186px;
  min-height: 0 !important;
  width: 274px;
  border-radius: 30px;
}

.data-IngestionContainer .css-1nszmjf {
  color: #919eab;
  padding: 7px;
}

.Multi-Select-BT .multi-select-dropdown-with-count {
  cursor: pointer;
}
.multi-select-dropdown-with-count div[class*='-indicatorContainer'] > svg {
  height: 30px !important;
  width: 50px !important;
  color: #919eab;
}

.multi-select-dropdown-with-count .css-1gl4k7y {
  color: rgb(21, 24, 27);
  text-align: -webkit-left;
  text-align: left;
  margin-left: 2px;
  font-family: 'Graphik-Regular-Web';
}

.requiredText {
  color: red;
}

.required-multi-select .css-1nszmjf::after {
  content: '*';
  color: red;
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count div[class*='-multiValue'] {
  margin: 3px 4px !important
}

#serviceLine-1 {
  word-break: break-word !important;
}
#groove-btn-id > button > * {
    font-size: 12px;
    margin: 0 5px;
}

.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:focus:not(:-moz-placeholder-shown) {
    border-top: 1px solid #a100ff!important;
    padding: 1.3rem 16px 15px;
}

.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:focus:not(:-ms-input-placeholder) {
    border-top: 1px solid #a100ff!important;
    padding: 1.3rem 16px 15px;
}

.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:focus,
.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:focus:not(:placeholder-shown) {
    border-top: 1px solid #a100ff!important;
    padding: 1.3rem 16px 15px;
}

.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown) {
    border-top: 1px solid #dfe3e8 !important;
    padding: 1.3rem 16px 15px;
}

.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder) {
    border-top: 1px solid #dfe3e8 !important;
    padding: 1.3rem 16px 15px;
}

.deleted-boi #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown) {
    border-top: 1px solid #dfe3e8 !important;
    padding: 1.3rem 16px 15px;
}

.deleted-item {
    max-width: 72%;
    word-break: break-word;
}

.info-icon > span.tooltiptext {
   width: 250px;
}

#groove-textarea #deleted-boi.groove-textbox {
    border: 1px solid rgb(181, 192, 202);
}

#groove-textarea #deleted-boi.groove-textbox:focus:not(:-moz-placeholder-shown) {
    border: 1px solid rgb(181, 192, 202) !important;
}

#groove-textarea #deleted-boi.groove-textbox:focus:not(:-ms-input-placeholder) {
    border: 1px solid rgb(181, 192, 202) !important;
}

#groove-textarea #deleted-boi.groove-textbox:focus, 
#groove-textarea #deleted-boi.groove-textbox:focus:not(:placeholder-shown) {
    border: 1px solid rgb(181, 192, 202) !important;
}

.ppc-sfa-modal-container {
    overflow-y: visible!important;
}
.multi-select-dropdown-with-count {
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: #fff;
  border-radius: 4px;
  height: 54px;
  width: 100%;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.multi-select-dropdown-with-count>.multi-select-label {
  color: #637381;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  padding: 0 14px;
  width: 100%;
}

.multi-select-dropdown-with-count:hover {
  border: 1.5px solid #a100ff !important;
}

.multi-select-dropdown-with-count>.multi-select-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin: 0px !important;
}

.multi-select-dropdown>div[class*='-container']>div[class*='-control']>div {
  max-height: 65px;
  overflow-y: auto;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer'] {
  padding: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.multi-select-dropdown-with-count div[class*='-menu'] {
  font-size: 16px !important;
  margin-top: 0;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer']>svg {
  height: 30px !important;
  width: 50px !important;
}

.multi-select-dropdown-with-count div[class*='-control']>div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.multi-select-dropdown-with-count div[class*='-control']>div:first-child {
  overflow-x: auto;
}

.multi-select-dropdown-with-count div[class*='-control']>div:nth-child(2) {
  width: 120px;
}

.multiWithAddButton.multi-select-dropdown-with-count div[class*='-multiValue'] {
  min-width: 0 !important;
  min-width: initial !important;
  padding: 0 5px;
  height: 24px;
  /* margin: 0 3px;
  position: relative;
  top: -4px; */
}

.multi-select-dropdown-with-count>div[class*='-container']>div[class*='-control']>div>div[class*='-multiValue'] {
  min-width: unset !important;
}

.multi-select-dropdown-with-count div[class*='-multiValue']>div:first-child {
  padding-left: 3px;
}

.multi-select-dropdown-with-count div[class*='-multiValue']>div:nth-child(2) {
  padding: 0;
}

.selected-count-container {
  color: #fff;
  background: #a100ff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 28px;
  padding: 3px 10px;
  border-radius: 30px;
  font-size: 14px;
  font-family: 'Graphik-Medium';
  line-height: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  top: 15px;
  right: 75px;
}

.selected-count-container>div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 13px !important;
  margin-top: -2px;
}

.Multi-Select-BT .multi-select-dropdown {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 54px;
  width: 100%;
}

.disabled-mulitSelect>div {
  cursor: not-allowed !important;
}

.Multi-Select-BT .multi-select-dropdown-with-count {
  border: 1px solid #ced4da !important;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count {
  background: rgb(249, 250, 251);
  cursor: not-allowed;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count div[class*='-control']>div:first-child {
  overflow: hidden;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count div[class*='-multiValue'] {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(231, 234, 238);
  height: 22px;
  margin: 0 3px;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count div[class*='-multiValue']>div {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count div[class*='-multiValue']>div:nth-child(2) {
  display: none;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count>div:nth-child(2)>div {
  background: transparent !important;
}

.Multi-Select-BT.disabled-mulitSelect .selected-count-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
}

.Multi-Select-BT .selected-count-container .svg-container>svg {
  color: rgb(99, 115, 129);
  font-size: 10px;
}

.Multi-Select-BT .selected-count-container .svg-container {
  width: 12px;
  height: 18px;
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count div[class*='-multiValue'] {
  background: rgb(250, 245, 253);
  border-radius: 16px;
  border: 1px solid rgb(161, 0, 255);
  height: 22px;
  margin: 0 3px;
}

.Multi-Select-BT.dwith-label .multi-select-dropdown-with-count div[class*='-multiValue']>div {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count>div:nth-child(2)>div:first-child {
  background: transparent !important;
}

.Multi-Select-BT.with-label .selected-count-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.with-label .selected-count-container .svg-container>svg {
  color: rgb(99, 115, 129);
  font-size: 10px;
}

.Multi-Select-BT.with-label .selected-count-container .svg-container {
  width: 12px;
  height: 18px;
}

.multiWithAddButton #mt-count-tooltip div[class*=-container]>div[class*=-control]>#groove-btn-id {
  width: 100px;
  border-right: 1px solid hsl(0, 0%, 80%);
}

.multiWithAddButton #mt-count-tooltip div[class*=-container]>div[class*=-control]>#groove-btn-id+div {
  width: 50px;
}

.multiWithAddButton #mt-count-tooltip div[class*=-container]>.placeholder-title+div[class*=-control]>#groove-btn-id,
.multiWithAddButton #mt-count-tooltip div[class*=-container]>.placeholder-title+div[class*=-control]>#groove-btn-id+div {
  position: relative;
  top: -13px;
}

.multiWithAddButton .has-value-selected {
  position: relative;
  top: -11px;
}

.multiWithAddButton .has-value-selected div[class*=-control]>div:first-child {
  top: 12px;
}

.multiWithAddButton .has-value-selected div[class*=-control]>div:first-child>div:nth-child(2) {
  position: relative;
  width: auto;
  overflow: hidden;
}

.caret-indicator {
  cursor: pointer;
}

div[class*=-selected-list], div[class*=-add-input].no-val{
  position: absolute;
}

.multiWithAddButton div[class*=-selected-list] {
  height: 30px;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  overflow-y: hidden;
  -ms-flex-pack: start;
      justify-content: flex-start;
  padding-bottom: 10px;
}
.multiWithAddButton div[class*=-selected-list]::-webkit-scrollbar-thumb {
  min-height: 10px;
}
.multiWithAddButton div[class*=-selected-list].no-val {
  height: 22px;
  overflow-y: visible;
}
.multiWithAddButton div[class*=-add-input] {
  min-width: 5%;
}
.multiWithAddButton #mt-count-tooltip>div[class*=-container]{
  padding-bottom: 15px;
}

.requiredText {
  color: #637381 !important;
}

.Multi-Select-BT.disabled-mulitSelect.disabled-addselectfields{
  cursor: not-allowed;
}
.Multi-Select-BT.disabled-mulitSelect.disabled-addselectfields .multiWithAddButton .has-value-selected{
pointer-events: none;
}
.Multi-Select-BT.disabled-mulitSelect.disabled-addselectfields .multiWithAddButton #groove-btn-id .btn-stat-alternate{
opacity: .5;
}
.multi-select-dropdown-with-count {
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: #fff;
  border-radius: 4px;
  height: 54px;
  width: 100%;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.multi-select-dropdown-with-count > .multi-select-label {
  color: #a100ff;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  padding: 0 14px;
}

.multi-select-dropdown-with-count:hover {
  border: 1.5px solid #a100ff !important;
}

.multi-select-dropdown-with-count > .multi-select-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin: 0px !important;
}

.multi-select-dropdown > div[class*='-container'] > div[class*='-control'] > div {
  max-height: 65px;
  overflow-y: auto;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer'] {
  padding: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.multi-select-dropdown-with-count div[class*='-menu'] {
  font-size: 16px !important;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer'] > svg {
  height: 30px !important;
  width: 50px !important;
}

.multi-select-dropdown-with-count div[class*='-control'] > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.multi-select-dropdown-with-count div[class*='-control'] > div:first-child {
  overflow-x: auto;
}

.multi-select-dropdown-with-count div[class*='-control'] > div:nth-child(2) {
  width: 120px;
}

.multi-select-dropdown-with-count > #mt-count-tooltip div[class*='-control'] > div:nth-child(2) {
  width: 50px;
  cursor: pointer;
}

.multi-select-dropdown-with-count
  > #mt-count-tooltip
  > div[class*='-container']
  > div[class*='-control']
  > div
  > div[class*='-multiValue'] {
  min-width: unset !important;
}

.multi-select-dropdown-with-count div[class*='-multiValue'] {
  min-width: 0 !important;
  min-width: initial !important;
  padding: 0 5px;
  height: 30px;
}

.multi-select-dropdown-with-count div[class*='-multiValue'] > div:first-child {
  padding-left: 3px;
}
.multi-select-dropdown-with-count div[class*='-multiValue'] > div:nth-child(2) {
  padding: 0;
  cursor: pointer;
}

.selected-count-container {
  color: #fff;
  background: #a100ff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-family: 'Graphik-Medium';
  line-height: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  top: 15px;
  right: 75px;
}

.selected-count-container > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 13px !important;
  margin-top: -2px;
}

.Multi-Select-BT .multi-select-dropdown {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 54px;
  width: 100%;
}

.Multi-Select-BT .multi-select-dropdown-with-count {
  border: 1px solid #ced4da !important;
}

.Multi-Select-BT .multi-select-dropdown-with-count.dropdown-invalid {
  border: 1px solid red !important;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count {
  background: rgb(249, 250, 251);
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-control']
  > div:first-child {
  overflow: hidden;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count div[class*='-multiValue'] {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(231, 234, 238);
  height: 22px;
  margin: 0 3px;
  /* width: 120px; */
  -ms-flex-pack: center;
      justify-content: center;
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-multiValue']
  > div {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-multiValue']
  > div:nth-child(2) {
  display: none;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count > div:nth-child(2) > div {
  background: transparent !important;
}

.Multi-Select-BT.disabled-mulitSelect .selected-count-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
}

.Multi-Select-BT .selected-count-container .svg-container > svg {
  color: rgb(99, 115, 129);
  font-size: 10px;
}

.Multi-Select-BT .selected-count-container .svg-container {
  width: 12px;
  height: 18px;
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count div[class*='-multiValue'] {
  background: rgb(250, 245, 253);
  border-radius: 16px;
  border: 1px solid rgb(161, 0, 255);
  height: 22px;
  margin: 0 3px;
  min-width: auto;
}

.Multi-Select-BT.dwith-label .multi-select-dropdown-with-count div[class*='-multiValue'] > div {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count > div:nth-child(2) > div {
  background: transparent !important;
}

.Multi-Select-BT.with-label .selected-count-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.with-label .selected-count-container .svg-container > svg {
  color: rgb(99, 115, 129);
  font-size: 10px;
}

.Multi-Select-BT.with-label .selected-count-container .svg-container {
  width: 12px;
  height: 18px;
}

.popover-dropdown-container {
  border-radius: 2px;
  color: rgb(161, 0, 255);
  font-size: 12px;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Graphik-Medium !important;
  font-weight: 600 !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

#dropdown-popover {
  display: none;
  z-index: 10000;
  -ms-flex-direction: column;
      flex-direction: column;
  -webkit-box-shadow: 0 2px 16px 0 rgb(0 0 0 / 25%);
          box-shadow: 0 2px 16px 0 rgb(0 0 0 / 25%);
  border-radius: 10px;
  width: 350px;
  font-family: Graphik-Medium-Web;
  font-size: 14px;
  background: #ffffff;
  position: absolute;
}

.pop-title {
  color: #a100ff;
  padding: 10px;
}

.pop-body {
  padding: 0 20px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#dropdown-popover.pop-top {
  bottom: calc(100% + 10px);
  width: 95%;
  margin: auto;
  left: 0;
  right: 0;
}

#dropdown-popover.pop-left {
  bottom: 0;
  /* right: 98%; */
  left: -335px;
}

.dropdown-tooltip {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
  border-radius: 16px;
  border: 1px solid rgb(231, 234, 238);
  margin: 2px;
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1;
}

.dropdown-popover-body {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
}

#mt-count-tooltip:hover > #dropdown-popover {
  display: -ms-flexbox;
  display: flex;
  cursor: default;
}

#mt-count-tooltip > div > div {
  background: transparent !important;
}

#mt-count-tooltip div[class*='-container'] > div[class*='-menu'] {
  background: white !important;
  margin-top: 15px;
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  > div:nth-child(2)
  > div#dropdown-popover,
.Multi-Select-BT.with-label
  .multi-select-dropdown-with-count
  > div:nth-child(2)
  > div#dropdown-popover {
  background: white !important;
}

.multi-select-dropdown-with-count {
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: #fff;
  border-radius: 4px;
  height: 54px;
  width: 100%;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.multi-select-dropdown-with-count > .multi-select-label {
  color: #a100ff;
  font-family: 'Graphik-Medium';
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  padding: 0 14px;
}

.multi-select-dropdown-with-count:hover {
  border: 1.5px solid #a100ff !important;
}

.multi-select-dropdown-with-count > .multi-select-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin: 0px !important;
}

.multi-select-dropdown > div[class*='-container'] > div[class*='-control'] > div {
  max-height: 65px;
  overflow-y: auto;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer'] {
  padding: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.multi-select-dropdown-with-count div[class*='-menu'] {
  font-size: 16px !important;
  margin-top: 0;
}

.multi-select-dropdown-with-count div[class*='-indicatorContainer'] > svg {
  height: 30px !important;
  width: 50px !important;
}

.multi-select-dropdown-with-count div[class*='-control'] > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.multi-select-dropdown-with-count div[class*='-control'] > div:first-child {
  overflow-x: auto;
}

.multi-select-dropdown-with-count div[class*='-control'] > div:nth-child(2) {
  width: 120px;
}

.multi-select-dropdown-with-count div[class*='-multiValue'] {
  min-width: 0 !important;
  min-width: initial !important;
  padding: 0 5px;
  height: 30px;
}

.multi-select-dropdown-with-count
  > div[class*='-container']
  > div[class*='-control']
  > div
  > div[class*='-multiValue'] {
  min-width: unset !important;
}

.multi-select-dropdown-with-count div[class*='-multiValue'] > div:first-child {
  padding-left: 3px;
}
.multi-select-dropdown-with-count div[class*='-multiValue'] > div:nth-child(2) {
  padding: 0;
}

.selected-count-container {
  color: #fff;
  background: #a100ff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 28px;
  padding: 3px 10px;
  border-radius: 30px;
  font-size: 14px;
  font-family: 'Graphik-Medium';
  line-height: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  top: 15px;
  right: 75px;
}

.selected-count-container > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 13px !important;
  margin-top: -2px;
}

.Multi-Select-BT .multi-select-dropdown {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 54px;
  width: 100%;
}

.disabled-mulitSelect > div {
  cursor: not-allowed !important;
}
.Multi-Select-BT .multi-select-dropdown-with-count {
  border: 1px solid #ced4da !important;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count {
  background: rgb(249, 250, 251);
  cursor: not-allowed;
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-control']
  > div:first-child {
  overflow: hidden;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count div[class*='-multiValue'] {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(231, 234, 238);
  height: 22px;
  margin: 0 3px;
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-multiValue']
  > div {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.disabled-mulitSelect
  .multi-select-dropdown-with-count
  div[class*='-multiValue']
  > div:nth-child(2) {
  display: none;
}

.Multi-Select-BT.disabled-mulitSelect .multi-select-dropdown-with-count > div:nth-child(2) > div {
  background: transparent !important;
}

.Multi-Select-BT.disabled-mulitSelect .selected-count-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
}

.Multi-Select-BT .selected-count-container .svg-container > svg {
  color: rgb(99, 115, 129);
  font-size: 10px;
}

.Multi-Select-BT .selected-count-container .svg-container {
  width: 12px;
  height: 18px;
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count div[class*='-multiValue'] {
  background: rgb(250, 245, 253);
  border-radius: 16px;
  border: 1px solid rgb(161, 0, 255);
  height: 22px;
  margin: 0 3px;
}

.Multi-Select-BT.dwith-label .multi-select-dropdown-with-count div[class*='-multiValue'] > div {
  font-size: 10px !important;
  font-weight: 100;
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.with-label .multi-select-dropdown-with-count > div:nth-child(2) > div:first-child {
  background: transparent !important;
}

.Multi-Select-BT.with-label .selected-count-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
}

.Multi-Select-BT.with-label .selected-count-container .svg-container > svg {
  color: rgb(99, 115, 129);
  font-size: 10px;
}

.Multi-Select-BT.with-label .selected-count-container .svg-container {
  width: 12px;
  height: 18px;
}

.ALATable,
.program-approval-history,
.ALATable .card-header .btn-link {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ALATable .dimension-table-header,
.program-approval-history-content .dimension-table-header {
  color: rgb(33, 43, 54);
  letter-spacing: 0px;
  background: rgb(249, 250, 251);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
  padding: 12px 24px;
  border-bottom: none;
}
.dimension-table-collapse {
  width: 100%;
}

.ALATable .dimension-table-row > div button > span,
.program-approval-history-content .dimension-table-row > div button > span {
  font-size: 12px !important;
}

.dimension-table-row,
.ALATable .card-header .btn-link {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
      align-items: baseline;
  text-align: left;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.program-approval-history-content .dimension-table-body .dimension-table-row {
  padding: 16px 24px 16px 24px;
  border: 1px solid #E7EAEE;
  border-width: 1px 0px;
}

.ALATable .dimension-table-body,
.program-approval-history-content .dimension-table-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px 24px;
}
.program-approval-history-content .dimension-table-body {
  padding: 0;
  height: 350px;
  overflow: overlay;
}
.dimension-table-row > div,
.dimension-table-row-approved > div {
  padding: 0px 0px;
  margin-right: 12px;
  word-break: break-word;
}

.ALATable .dimension-table-row > div:first-child {
  width: 25%;
}

.ALATable .dimension-table-row > div:nth-child(2) {
  width: 16%;
  word-break: break-all;
}
.ALATable .dimension-table-row > div:nth-child(3) {
  width: 20%;
  position: relative;
}
.ALATable .dimension-table-row > div:nth-child(4) {
  width: 13%;
}
.ALATable .dimension-table-row > div:nth-child(5) {
  width: 15%;
}

.ALATable .dimension-table-row > div:nth-child(6) {
  width: 14%;
}

.ALATable .dimension-table-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.ALATable .dimension-table-row > div,
.program-approval-history-content .dimension-table-row > div {
  letter-spacing: -0.22px;
  line-height: 16px;
}

.dimension-table-collapse .card {
  border-radius: 0px;
}
.ALATable .card-header,
.ALATable .card-header .btn-link {
  padding: 8px 12px;
}
.ALATable .card-header {
  padding: 8px 12px;
  border: none;
  background: #fff;
}
.ALATable .card-body {
  padding: 16px 24px;
}

.program-status {
  background: rgb(255, 200, 44);
  border-radius: 3px;
  text-align: center;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Semibold;
  font-size: 11px;
  text-transform: uppercase;
}

.dimension-table-row input[type='text'] {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
  width: 100%;
}
.dimension-table-collapse .subdimension-rows {
  border-top: 1px solid rgb(223, 227, 232);
}
.dimension-table-collapse .card-body {
  padding: 0;
}
.dimension-table-collapse .card-body .dimension-table-row {
  padding: 16px 0;
}
.dimension-table-collapse .collapse.show ~ div.card-header .btn-link > div.svg-container {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.dimension-table-collapse .collapse ~ div.card-header .dimension-table-row > div {
  display: none;
}
.dimension-table-collapse .collapse ~ div.card-header .dimension-table-row > div:first-child {
  display: block;
}
.dimension-table-collapse .collapse.show ~ div.card-header .dimension-table-row > div {
  display: block;
}

.text-field {
  width: 265px;
  height: 78px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(181, 192, 202);
  border-radius: 4px;
}

textarea {
  width: 100%;
  height: 100%;
  background: white;
}

.approved-edit-modal .modal-header {
  padding: 20px 30px !important;
  margin: 0;
}

.approved-edit-modal {
  max-width: 600px !important;
}

.approved-edit-bold {
  font-weight: 600;
}

.approved-edit-modal .modal-body > div:first-child {
  padding-bottom: 20px;
  width: 100%;
  margin: 0px;
}
.approved-edit-modal .modal-body > div > div:nth-child(2) {
  width: 100% !important;
}

.approved-edit-btn {
  display: -ms-flexbox;
  display: flex;
  padding: 30px 0px 0px !important;
}

.approved-edit-btn > div:first-child {
  margin-left: auto;
}

.approved-edit-btn > div:nth-child(2) {
  margin-left: 10px;
}

.warning-edit-icon {
  width: 50px !important;
  height: 50px;
  margin-left: 25px;
}

.calendar-icon-dropdown {
  cursor: pointer;
}

.disabled-editIcon > #calendarIcon > .calendar-icon-dropdown {
  cursor: not-allowed;
  opacity: 0.5;
}

.calendar-icon-dropdown.disabled-edit {
  color: #bcbcbc;
  cursor: not-allowed;
  pointer-events: none;
}


/* ProgramApprovalHistoryModal CSS */
#groove-btn-id > button#btn-prog-approv-history {
  cursor: pointer;
  padding-left: 0;
}
.btn-prog-approv-history-container {
  display: inline-block;
}
#groove-btn-id > button#btn-prog-approv-history:active,
#groove-btn-id > button#btn-prog-approv-history:focus,
#groove-btn-id > button#btn-prog-approv-history:hover {
  background: transparent;
}

#groove-btn-id > button#btn-prog-approv-history > * {
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 0;
}

.programApprovalHistoryModal {
  max-width: 85vw!important;
}
.programApprovalHistoryModal .header24-semibold-midnight {
  width: 70%;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  line-height: 115%;
}
.pah-search-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.pah-search-bar > .svg-container.large {
  position: relative;
  left: 30px;
}
.pah-search-bar >input {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e7eaee;
  outline-style: none;
  width: 240px;
  padding: 5px 10px 5px 40px;
}
.dimension-boi-history {
  line-height: 1.4em;
}
.dimension-boi-history > div > .boi-divider {
  position: relative;
  padding: 0 5px;
}

.dimension-boi-history > div > .boi-divider:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 130%;
  background-color: rgb(99, 115, 129);
}


.dimension-boi-history > div:nth-child(6) {
  display: block;
}

.dimension-boi-history > div:first-child,
.dimension-boi-history > div:nth-child(2) {
  width: 12%;
}
.dimension-boi-history > div:nth-child(3) {
  width: 20%;
  position: relative;
}
.dimension-boi-history > div:nth-child(4) {
  width: 12%;
}
.dimension-boi-history > div:nth-child(5) {
  width: 20%;
}
.dimension-boi-history  > div:nth-child(6){
    width: 20%;
}
.upload-subtext {
  font-size: 14px;
  margin-top: 20px;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown) {
  border-top: 25px solid transparent !important;
  display: block;
  padding: 0px 20px;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  height: 135px;
  cursor: default;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder) {
  border-top: 25px solid transparent !important;
  display: block;
  padding: 0px 20px;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  height: 135px;
  cursor: default;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown) {
  border-top: 25px solid transparent !important;
  display: block;
  padding: 0px 20px;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  height: 135px;
  cursor: default;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.doc-upload-invalid-comment:not(:-moz-placeholder-shown) {
  outline: 1px solid #aa0025 !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.doc-upload-invalid-comment:not(:-ms-input-placeholder) {
  outline: 1px solid #aa0025 !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.doc-upload-invalid-comment:not(:placeholder-shown) {
  outline: 1px solid #aa0025 !important;
}

.comments .help-text-right {
  margin: 0 !important;
}

.comments .floating-label-textbox {
  border: 1px solid rgb(181, 192, 202);
  margin-bottom: 10px;
  border-radius: 4px;
}

.customboi-customparameter .selectmenu-selected-value,
.customboi-customparameter .selectmenu-floating-label {
  font-size: 11px;
  width: 90% !important;
}

#selectmenu-floating-label.selectmenu-floating-label.body-regular-disabled.default-display {
  font-size: 16px;
  font-family: 'Graphik-Regular';
  letter-spacing: -0.7px;
  font-weight: 400;
}

.leading-practice-label {
  width: 185px;
}
.customboi-customparameter .searchable-dropdown-input::-webkit-input-placeholder {
  font-size: 11px;
}
.customboi-customparameter .searchable-dropdown-input::-moz-placeholder {
  font-size: 11px;
}
.customboi-customparameter .searchable-dropdown-input:-ms-input-placeholder {
  font-size: 11px;
}
.customboi-customparameter .searchable-dropdown-input::-ms-input-placeholder {
  font-size: 11px;
}
.customboi-customparameter .searchable-dropdown-input::placeholder {
  font-size: 11px;
}

.customboi-customparameter
  div.selectmenu-options
  .searchable-dropdown-content
  .searchable-dropdown-input {
  width: 70% !important;
}

.duplicateErr {
  border-color: red !important;
  margin-bottom: 25px !important;
}

.custombtn-error-message {
  bottom: 5px;
  position: absolute;
  color: red;
  padding: 0px 15px;
  font-size: 12px;
  margin: 0;
}

.customuom-message {
  bottom: 5px;
  position: relative;
  /* color: rgb(181, 192, 202); */
  color: #c89a15;
  padding: 0px 15px;
  font-size: 12px;
  margin: 0;
  top: -10px;
}

.second-stage-edit-boi {
  width: 120px;
  margin-left: auto;
  padding-top: 10px;
}

#deleteboi-popover {
  width: 400px;
  background-color: #a100ff;
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  font-family: 'Graphik-Medium';
  letter-spacing: 0.3px;
}

#deleteboi-popover .arrow::before,
#deleteboi-popover .arrow::after {
  display: none;
}

#deleteboi-popover > .popover-body {
  color: #fff;
}
.tooltiptext-wrap {
  padding: 0 20px;
}
.tooltiptext-wrap table {
  width: 100%;
}
.tooltiptext-wrap tr {
  text-align: left;
}
.tooltiptext-wrap tr td:first-child {
  width: 130px;
}
#boi-tooltip-right table {
  width: 100%;
}
#boi-tooltip-right table tr th:first-child,
#boi-tooltip-right table tr td:first-child {
  padding-left: 0;
  padding-right: 20px !important;
}

span.boiAgnosticDimensionModal.tooltiptext {
  left: auto;
  margin-left: -6px;
  margin-top: 5px;
}

span.boiAgnosticSubdimensionModal.tooltiptext {
  left: auto;
  margin-left: -6px;
  margin-top: 5px;
}
span.boiAgnosticUOMModal.tooltiptext {
  left: auto;
  margin-left: -6px;
  margin-top: 5px;
}

input:hover + .tooltiptext,
div#groove-input:hover + .tooltiptext {
  display: table-caption;
}
#boi-tooltip-right table tr th:first-child,
#boi-tooltip-right table tr td:first-child {
  padding-left: 0;
  padding-right: 20px !important;
}
input#boidefinition-0 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80% !important;
}
.add-boi-field-container #groove-select-dropdown .default-display {
  width: 70% !important;
}
.disclaimer-message {
  height: 16px;
  color: rgb(170, 0, 37);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding-top: 12px;
  line-height: 16px;
  width: 90%;
  padding-bottom: 16px;
}

.multi-select-dropdown-with-count.sfa-approver-dd .requiredText {
  color: #637381;
}
.multi-select-dropdown-with-count.sfa-approver-dd .required-multi-select .css-1nszmjf::after {
  content: '*';
  color: #637381;
}

.comment-loader {
  height: 200px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.comment-loader .lds-ellipsis {
  position: relative;
  margin: 0;
}
.check-box {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
  float: left;
  margin-right: 11px;
}

.cvm-checkbox .svg-container {
  display: none;
  margin-right: 11px;
}

.cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.cvm-checked .svg-container {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px !important;
  padding: 11px;
}
.labels {
  position: relative;
  display: -webkit-inline-box;
}
.cont {
  height: 34px;
  overflow: scroll !important;
  overflow-x: hidden !important;
}
#mt-count-tooltip .label14-regular-midnight {
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  position: sticky;
  margin: 18px 13px 18px 13px;
}
#mt-count-tooltip .label14-regular-midnight .sea {
  width: 100%;
}
.dropdown-buttons {
  margin: 11px 0px 11px 0px !important;
}

.dropdown-cancel {
  margin: 0px;
}
.dropdown-apply {
  margin: 0px;
}
.dropdown-cancel .btn-outline-stat-alternate {
  margin-left: 23px;
}
#mt-count-tooltip .search-bar-container .search-bar > input {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  outline-style: none;
  padding-left: 42px !important;
  border-top: 1px solid lightgray !important;
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}
#mt-count-tooltip .css-1hwfws3 {
  overflow: hidden !important;
  height: 38px;
  /* overflow-x: auto !important; */
  cursor: pointer;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}

#mt-count-tooltip .css-15hpnlr-control {
  border-color: rgb(181, 192, 202);
}
#mt-count-tooltip .css-15hpnlr-control:hover {
  border-color: rgb(181, 192, 202);
}
.arigato {
  background-color: blue;
}

#mt-count-tooltip .css-bg1rzq-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}
#mt-count-tooltip .css-bg1rzq-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
}
#mt-count-tooltip .css-1szy77t-control {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}
#mt-count-tooltip .css-1szy77t-control:hover {
  background-color: hsl(0, 0%, 100%);
  border-style: none;
  border-color: white !important;
  position: relative;
}
#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer {
  position: relative !important;
}
#mt-count-tooltip .css-1wy0on6 .css-16pqwjk-indicatorContainer:hover {
  position: relative !important;
}
#mt-count-tooltip .css-1wy0on6 {
  width: 90px;
}
#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6 > svg {
  background-color: blue;
}
#mt-count-tooltip .css-1pcexqc-container .css-bg1rzq-controln .css-1wy0on6 > div > div {
  background-color: rgb(0, 255, 34);
}

#mt-count-tooltip .css-14ojxsb-control {
  border-color: white;
}

.data-IngestionContainer .client-details-button .multi-select-dropdown-with-count {
  width: 186px;
  min-height: 0 !important;
  width: 274px;
  border-radius: 30px;
}

.data-IngestionContainer .css-1nszmjf {
  color: #919eab;
  padding: 7px;
}

.Multi-Select-BT .multi-select-dropdown-with-count {
  cursor: pointer;
}
.multi-select-dropdown-with-count div[class*='-indicatorContainer'] > svg {
  height: 30px !important;
  width: 50px !important;
  color: #919eab;
}

.multi-select-dropdown-with-count .css-1gl4k7y {
  color: rgb(21, 24, 27);
  text-align: -webkit-left;
  text-align: left;
  margin-left: 2px;
  font-family: 'Graphik-Regular-Web';
}

.requiredText {
  color: red;
}

.required-multi-select .css-1nszmjf::after {
  content: '*';
  color: red;
}

.cvm-disabled-option {
  cursor: not-allowed !important;
  background: rgba(118, 118, 118, 0.3);
  /* color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170)); */
  border-color: rgba(118, 118, 118, 0.3) !important;
}
div[name='businessAdvisor'] .cvm-disabled-unselected {
  cursor: not-allowed !important;
  background: rgba(118, 118, 118, 0.3);
  border-color: rgba(118, 118, 118, 0.3) !important;
}

#groove-input.cvm-input {
    background-color: white;
    border-radius: 4px;
}

#groove-input.cvm-input .groove-input:focus{
    border-radius: 4px;
}

#groove-input.cvm-input.disabled .groove-input-custom-label {
    cursor: not-allowed;
}

#groove-input.cvm-input input.groove-input {
    position: relative;
    margin-bottom: 0;
}

#groove-input.cvm-input input.groove-input:disabled {
    background-color: transparent;
    border: 1px solid #dfe3e8;
    cursor: not-allowed;
    background: #f9fafb;
}

#groove-input.cvm-input .floating-label {
    margin-bottom: 0;
}

.cvm-shared-input{
  color: rgb(99, 115, 129);
  font-size: 1.2em;
  line-height: �1.42857143;
}
.card-title {
  max-width: 85%;
}

.cardH {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.titleText-medium-20 {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
}

.titleText-medium-18 {
  color: rgb(161, 0, 255);
  font-size: 18px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
}

.titleText-medium-14 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.titleText-medium-12 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
}

/* new rvd starts here */

.rvd-titleText-medium-12 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 15px;
  height: 20px;
  width: 96px;
}

.rvd-program-card-box {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 178px;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border: 1px solid transparent;
}

.rvd-titleText-medium-14 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rvd-titleText-regular-12 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.rvd-program-card-wrap {
  padding: 15px;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  /* height: 178px; */
  height: 78%;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: 45px 30% 19% auto auto auto;

}

/* new rvd ends here */

.titleText-regular-12 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

#createdby-text {
  width: 67px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding-right: 35px;
}

#created-text {
  width: 67px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding-right: 32px;
}

.ioa-mapped-suboffering-container {
  color: #ffc82c;
  font-size: 12px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.applyFlex-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.applyFlex-align-center.apply-relative .single-select-dropdown .single-select-label {
  width: 100px;
}

.program-dropdown-project-landing .single-select-label {
  width: 13%;
}

.program-dropdown-project-landing .css-1hwfws3,
.program-lead-landing .css-1hwfws3 {
  padding-left: 0px;
}

.applyFlex-justify-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.applyFlex-justify-end {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.applyFlex-space-between {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.landing-img-box {
  border-radius: 0.5px;
  border-left: 1px solid rgb(151, 151, 151);
  line-height: 1.5rem;
  margin-left: 1.5rem;
  padding: 0;
  /* display: none; */
}

.landing-img-box img:first-child {
  margin: -10px 5px -10px 5px;
  cursor: pointer;
}

.landing-img-box img:nth-child(2) {
  margin: -4px 0 0 0;
  cursor: pointer;
  /* color :rgb(99, 115, 129); */
  /* opacity: .4; */
}

.landing-img-box img.disabled-view {
  cursor: not-allowed;
}

.edited-program-project {
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px 8px;
  border: none;
  margin-top: 0.1em;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 50px;
  text-align: center;
  display: inline-block;
  margin-left: 3px;
  -ms-flex-item-align: start;
      align-self: flex-start;
}

/* clientDetails-popover css starts here */

@media only screen and (max-width: 995px) {
  .program-tooltip {
    background: #fff;
    position: fixed;
    top: 100px;
    left: 600px;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }

  .bs-popover-right>.arrow {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin: 0.3rem 1.1rem !important;
  }
}

#clientDetails-popover,
#programDetails-popover {
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
  max-width: 470px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 2000;
}

#clientDetails-popover .popover-body>div,
#programDetails-popover .popover-body>div {
  display: -ms-flexbox;
  display: flex;
}

#clientDetails-popover .popover-body>div>.sub-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 50px 0 0;
}

#programDetails-popover .popover-body>div>.sub-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0;
}

#clientDetails-popover.bs-popover-auto[x-placement^='right']>.arrow:before,
#clientDetails-popover.bs-popover-right>.arrow:before,
#programDetails-popover.bs-popover-auto[x-placement^='right']>.arrow:before,
#programDetails-popover.bs-popover-right>.arrow:before {
  border-right-color: rgba(0, 0, 0, 0.05);
}

#clientDetails-popover .close-btn,
#programDetails-popover .close-btn {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #637381;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

#clientDetails-popover .popover-header,
#programDetails-popover .popover-header {
  background: #fff;
  border-bottom: none;
  padding: 0;
}

#clientDetails-popover .popover-body,
#programDetails-popover .popover-body {
  padding: 0.5rem 1rem 0.5rem;
  color: rgb(78 78 78);
  font-size: 12px;
  letter-spacing: -0.21px;
  line-height: 20px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.detail-popup-section {
  margin-bottom: 1rem;
}

.detail-popup-section .section-title {
  font-family: Graphik-Medium;
  font-weight: 500;
}

.detail-popup-section .section-desc {
  font-family: Graphik-Regular;
  font-weight: normal;
  margin-bottom: 10px;
}

.detail-popup-section .section-desc:last-child {
  margin-bottom: 0;
}

.detail-popup-section .section-desc.ppc-multiple-users {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 190px;
}

.sub-section {
  display: -ms-flexbox;
  display: flex;
}

#clientDetails-popover .stage-status span,
#programDetails-popover .stage-status span {
  padding: 4px 9px;
  background: rgb(126, 195, 68);
  border-radius: 3px;
  line-height: 10px;
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.18px;
}

#clientDetails-popover .stage-status .stage-completed,
#programDetails-popover .stage-status .stage-completed {
  background: rgb(126, 195, 68);
  color: rgb(255, 255, 255);
}

/* clientDetails-popover css ends here */

.back-icon {
  font-size: 24px;
  color: rgb(161, 0, 255);
}

.dimension-table-row .info-icon {
  margin: -10px 10px 0 10px;
}

.info-icon {
  height: 13px;
  width: 13px;
}

.client-details-button {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  margin-bottom: 15px;
}

.client-details-button img {
  margin: 0 -8px 0 -15px;
}

.add-program-line {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 20px;
}

.add-program-line>div:nth-child(2) {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.apply-relative {
  position: relative;
}

/* program cards screen css starts here */

.program-card-box {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  -webkit-box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  cursor: pointer;
}

.program-card-wrap {
  padding: 15px;
  cursor: pointer;
  width: 100%;
  height: 78%;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: 45px 30% 19% auto auto auto;
}

.project-card-wrap {
  padding: 15px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-rows: 33px 26% 21% auto auto auto;
}

.project-card-wrap.project-disabled {
  cursor: not-allowed;
  opacity: .5;
}

.bottom-item {
  display: inline-block;
  white-space: nowrap;
  -ms-flex: 1 1;
      flex: 1 1;
}

#banIcon {
  position: relative;
  top: -5px;
}

.rejectedGenTooltip {
  z-index: 3010;
  margin-top: 3px;
}

.rejectedGenTooltip .tooltip-inner {
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
  text-align: left;
  max-width: 300px;
  padding: 10px;
}

@media only screen and (min-width: 1400px) {
  .program-card-wrap {
    grid-auto-rows: 45px 25% 21% auto auto auto;
  }
}



@media only screen and (max-width: 900px) {
  .program-card-wrap {
    grid-auto-rows: 45px 25% 21% auto auto auto;
  }

  .project-card-wrap {
    grid-auto-rows: 33px 26% 23% auto auto auto;
  }
}

.program-card-detail {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
  /* min-height: 26px; */
}

.program-card-detail>div:first-child {
  margin-right: 5px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.program-card-detail>img {
  padding: 5px 0;
  width: 25px;
  cursor: pointer;
  margin: auto 0;
}

.text-gap {
  margin-top: 5px;
}

.program-card-detail .ppc-text-ellipsis,
.project-card-title .ppc-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.program-card-detail .title-ellip {
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  min-inline-size: -webkit-fill-available;
  min-inline-size: -moz-available;
  white-space: unset;
  margin-bottom: 6px;
  line-height: 115%;
}

.program-card-detail .action-owner-list {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.program-card-container {
  margin-top: 12px;
}

.program-card-container>div>div {
  margin-bottom: 20px;
}

#program-details-status {
  margin: 0;
  width: auto;
}

.card-row-status {
  border-radius: 3px;
  line-height: 11px;
  font-size: 11px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.2px;
  padding: 3px 8px;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.text-tile {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.status-new {
  background: #018EFF;
}

.status-completed {
  background: rgb(126, 195, 68);
}

.status-rejected {
  background: rgb(213, 0, 47);
}

.status-inProgress {
  background: rgb(255, 200, 44);
}

.status-notStarted {
  background: rgb(145, 158, 171);
}

.status-conditionallyApproved {
  background: rgb(244, 147, 66);
}

.dim-status-completed {
  color: rgb(126, 195, 68);
  background-color: white;
}

.dim-status-rejected {
  color: rgb(213, 0, 47);
  background-color: white;
}

.dim-status-inProgress {
  color: rgb(255, 200, 44);
  background-color: white;
}

.dim-status-notStarted {
  color: rgb(145, 158, 171);
  background-color: white;
}

.dim-status-conditionallyApproved {
  color: rgb(244, 147, 66);
  background-color: white;
  padding: 0px;
}

.dim-no-status {
  background-color: white;
}

.dim-no-status {
  color: transparent;
  background-color: transparent;
}

#groove-btn-id button {
  display: -ms-flexbox;
  display: flex;
}

.program-card-box #groove-btn-id {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.program-card-box .detail-row {
  width: 100%;
  background: rgb(249, 250, 251);
  border-top: 1px solid rgb(223, 227, 232);
  border-radius: 0px 0px 8px 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: end;
      align-self: flex-end;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 5px;
  z-index: 100;
}

/* .program-card-box .program-card-wrap .wrap-bottom {
  margin-bottom: 42px;
} */

/* .program-card-completed + .wrap-bottom {
  margin-bottom: 100px !important ;
} */

/* program cards screen css ends here */

.program-lead-landing .single-select-label,
.program-lead-landing .css-1pcexqc-container {
  width: inherit;
}

.program-lead-landing .close-btn {
  position: static;
}

.program-lead-landing .applyFlex-space-between div:first-child {
  width: 100%;
}

.program-lead-landing .info-icon {
  top: 0;
  right: 0;
}

.program-lead-landing .info-icon svg {
  cursor: pointer;
}

@media (min-width: 768px) {
  .program-lead-landing .col-md-6 {
    -ms-flex: 0 0 46%;
        flex: 0 0 46%;
    max-width: 46%;
  }
}

/* Stepper css */

.program-lead-landing .md-step.active~div>.md-step-img-circle {
  background: #e3d1ff !important;
  color: #a100ff;
}

.program-lead-landing .md-stepper-horizontal .md-step .md-step-title,
.program-lead-landing .md-stepper-horizontal .md-step .md-step-optional {
  color: rgb(117, 0, 192);
}

textarea {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.add-program-buttons {
  background: white;
  border: none !important;
  padding: 20px 20px 20px 40px;
}

.add-program-buttons.ppc {
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.add-program-buttons.ppc.proj {
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: column;
      flex-direction: column;
}

.add-program-buttons.ppc>.missing-container {
  -ms-flex-pack: justify;
      justify-content: space-between;
  max-width: 80%;
  font-size: 14px;
  font-family: 'Graphik-Medium';
}

.add-program-buttons.ppc>.missing,
.add-program-buttons.ppc>.missing-container>.missing {
  padding: 0;
}
.missing.prog-creator {
  padding: 5px 0 5px 0;
  font-size: 14px;
  font-family: Graphik-Medium;
}
.missing.prog-creator.aa {
  padding: 5px 0 5px 20px;
  font-size: 14px;
  font-family: Graphik-Medium;
}
.missing-ppc-ptvs {
  color: #a100ff;
  font-size: 14px;
  font-family: Graphik-Medium;
}

.enable-new-client {
  border: 1px solid rgb(223, 227, 232) !important;
}

.program-lead-landing .md-stepper-horizontal {
  border-radius: 16px;
  margin: 24px 0px;
}

.approval-sent-time {
  width: 350px;
  height: 16px;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  line-height: 16px;
  margin-bottom: 2px;
}

.modal-form {
  width: 379px;
  height: 56px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(181, 192, 202);
  border-radius: 8px;
}

.raise-request {
  width: 300px;
  height: 14px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.27px;
}

.SendForApproval {
  width: 773px;
  height: 32px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  line-height: 16px;
}

.client-steakholder-title {
  width: 426px;
  height: 19px;
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding-top: 10px;
}

.confirm-btn {
  background-color: rgb(161, 0, 255);
  color: rgb(161, 0, 255);
}

.check-label {
  width: 342px;
  height: 16px;
  color: rgb(161, 0, 255);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin-left: 6px;
}

.upload-btn {
  margin-top: 20%;
  margin-left: 85%;
  width: 90px;
  height: 40px;
  background: rgb(161, 0, 255);
  border-radius: 20px;
  -ms-flex-align: center;
      align-items: center;
}

.confirmation {
  width: 318px;
  height: 34px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}

.upload-later .stepper-close {
  margin-top: 15px;
  margin-right: 50px;
}

.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

/* Program table css starts here */

.program-lead-landing .table-view-wrap {
  margin-top: 40px;
}

.program-lead-landing .dimension-table-row .info-icon {
  right: inherit;
  top: inherit;
}

/* Program table css ends here */

.BT-container {
  width: 100%;
  margin: 30px auto;
  padding: 20px 35px 50px 35px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.BT-Lead-Landing-btn .applyFlex-space-between {
  place-content: flex-end;
  margin-top: 15px;
  margin-bottom: 30px;
}

.BT-Lead-Landing-Page .stepper-bt {
  margin-top: 20px;
}

.BT-Lead-Landing-bottom-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-top: 50px;
  right: -50px;
}

.sticky-button {
  -ms-flex-align: self-end;
      align-items: self-end;
  padding-bottom: 12px;
  position: fixed;
  height: 64px !important;
  width: 100%;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  right: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-right: 81px;
  -webkit-box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
          box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
}

.hide-sfa {
  -ms-flex-align: self-end;
      align-items: self-end;
  padding-bottom: 12px;
  position: fixed;
  height: 64px !important;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  display: none;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-right: 81px;
  -webkit-box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
          box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
}

.sticky-button-edit {
  padding-bottom: 52px;
  position: fixed;
  height: 64px !important;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-right: 113px;
  -ms-flex-align: center;
      align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
          box-shadow: 0px -2px 16px 0px rgb(33 43 54 / 10%), 0px -1px 0px 0px rgb(223 227 232);
}

.hide-sticky {
  display: none;
}

.BT-Lead-Landing-bottom-btn button {
  width: 170px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.BT-Lead-Landing-bottom-btn .applyFlex-space-between {
  margin: 5px;
  margin-right: 20px;
}

.BT-Lead-Landing-Page,
.Rejected-BOIS {
  margin: 40px 80px;
}

.input .BT-Lead-Comment {
  width: 780px;
  height: 56px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 8px;
}

.BTLead-comments .BT-Lead-Comment {
  width: 723px;
  height: 88px;
  color: rgb(181, 192, 202);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 22px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 8px;
}

.BT-Lead-Comment #groove-textarea .groove-textbox {
  height: 229px;
  color: rgb(181, 192, 202);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 22px;
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 8px;
}

.BT-Lead-Comment #groove-input .groove-input {
  color: rgb(181, 192, 202);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 22px;
  background: rgb(239, 242, 245);
}

.modal-body>div:first-child {
  padding-bottom: 40px;
}

.Rejected-BOIS .title {
  width: 759px;
  height: 36px;
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}

.Rejected-BOIS .close-btn {
  right: 50px;
  top: 30px;
  color: #637381;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
}

.value-title {
  width: 260px;
  height: 34px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}

.rejected-BOIS-edit {
  float: right;
  margin-bottom: 10px;
}

.value-edit-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}

.Rejected-BOIS .table-view-wraps {
  /* display: table;
  width: 100%; */
  margin: 0 auto;
  padding: 20px 40px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.edit-BOIS-bottom-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 80px 0 30px 0;
}

.edit-BOIS-bottom-btn button {
  width: 165px;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  margin-left: 20px;
}

.btn-between {
  margin-right: 8px;
}

.textarea-add-program {
  padding: 10px;
}

.text-field {
  background: white;
}

.CACompleteDocPending {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.check-circle {
  font-size: 2px !important;
  margin-bottom: 10px;
  width: 76.5px;
  height: 76.5px;
  -ms-flex-item-align: center;
      align-self: center;
}

.Program-Configured-text {
  height: 18px;
  color: rgb(21, 24, 27);
  font-size: 18px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.28px;
  margin: 0 auto;
  margin-bottom: 10px;
  -ms-flex-item-align: center;
      align-self: center;
}

.CACompleteDocPending .configuration-text {
  width: 85%;
  word-break: break-all;
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  text-align: center;
  line-height: 24px;
  margin-bottom: 10px;
  -ms-flex-item-align: center;
      align-self: center;
}

.CACompleteDocPending .warning-msg {
  width: 315px;
  height: 17px;
  color: rgb(170, 0, 37);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: 30px;
}

/* Add BOI search bar */

.add-boi-field-container.duplicate-boi,
.duplicate-boi {
  border-color: red !important;
}

.add-boi-search {
  z-index: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}

.add-boi-search .search-bar {
  position: relative;
  width: 20%;
  min-width: 350px;
}

.add-boi-search .search-bar #searchBox {
  width: 100%;
}

.table-title {
  margin: 24px 0 15px 0;
}

.table-title>.header18-semibold-midnight {
  margin-bottom: 10px;
}

.first-line {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.program-lead-landing {
  width: 100%;
}

.page-title {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
  margin-bottom: 40px;
}

.accordion-action-btns,
.prog-acct-table-header-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.accordion-action-btns {
  margin-left: auto;
}

.expandAll-button.active>button,
.collapseAll-button.active>button {
  color: #a100ff;
}

/* Client Alignment */

.document-box {
  background: rgb(251, 249, 254);
  border-radius: 8px;
  border: 1.5px solid rgba(156, 106, 222, 0.13);
  margin-bottom: 40px;
}

.envelope {
  padding: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  min-height: 240px;
}

.document-name {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-top: 30px;
}

.document-box .icons {
  padding: 10px 20px;
  border-top: 1px solid rgba(156, 106, 222, 0.13);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.icons .icon-bottom {
  margin-left: 20px;
  cursor: pointer;
}

.confirmation-document .comments,
.comments {
  margin: 15px 0;
}

.confirmation-document #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown) {
  background: rgb(239, 242, 245);
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202) !important;
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.confirmation-document #groove-input .floating-label .groove-input:not(:-ms-input-placeholder) {
  background: rgb(239, 242, 245);
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202) !important;
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.confirmation-document #groove-input .floating-label .groove-input:not(:placeholder-shown) {
  background: rgb(239, 242, 245);
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202) !important;
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.confirmation-document #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown)+label, .comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown)+label {
  color: rgb(99, 115, 129) !important;
  font-size: 12px !important;
  font-family: Graphik-Medium !important;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

.confirmation-document #groove-input .floating-label .groove-input:not(:-ms-input-placeholder)+label, .comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder)+label {
  color: rgb(99, 115, 129) !important;
  font-size: 12px !important;
  font-family: Graphik-Medium !important;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

.confirmation-document #groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  color: rgb(99, 115, 129) !important;
  font-size: 12px !important;
  font-family: Graphik-Medium !important;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox {
  padding-top: 4.8px;
  border-radius: 4px;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown)+label {
  color: #637381 !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder)+label {
  color: #637381 !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label {
  color: #637381 !important;
}

.comments #groove-textarea .floating-label-textbox #projectDescription {
  max-height: 54px;
  overflow-y: scroll !important;
}

.Requestor-comments .groove-textbox-custom-label-disabled,
div.groove-textbox-custom-label {
  color: #a100ff !important;
  font-family: 'Graphik-Medium' !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px !important;
  line-height: 12px;
  margin: 5px 0;
  padding: 2px 7px;
  border: rgb(117, 0, 192) !important;
}

.groove-textbox-custom-label:not([hidden])~label {
  display: none;
}

/* Client Confirmarion Docs */

.check-box {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.cvm-checkbox {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.cvm-checkbox .svg-container {
  display: none;
}

.cvm-checked {
  background: rgb(161, 0, 255);
  border-radius: 4px;
}

.cvm-checked .svg-container {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px !important;
  padding: 11px;
}

.check-label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
}

.client-confirmation-docs {
  padding: 24px 0;
}

.cvm-dropzone {
  background: rgb(251, 249, 254);
  border-radius: 16px;
  border: 2px dashed rgb(145, 158, 171);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 60px;
  margin: 40px 0 15px;
}

.cvm-dropzone.invalid {
  border-color: red;
}

.cvm-dropzone.valid {
  border: 1px solid green;
  cursor: pointer;
  border-color: green;
}

.invalid-reason {
  color: red;
  margin-top: 8px;
}

.cvm-dropzone-label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-top: 22px;
}

.cvm-dropzone-label>span {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.cvm-dropzone-sub-label {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-top: 15px;
}

.uploaded-files-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 60px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.uploaded-files {
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 10px 0 0 0;
}

.uploaded-files-name {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  width: 300px !important;
}

.uploaded-files-progressbar {
  background: rgb(231, 234, 238);
  border-radius: 4px;
  height: 10px;
}

.upload-progress {
  height: 100%;
  width: 100%;
  background: rgb(156, 106, 222);
  border-radius: 4px;
}

.uploaded-files>div {
  width: 100%;
}

.uploaded-files-icon,
.uploaded-files-close {
  width: 15px !important;
}

.uploaded-files-close {
  padding: 0 10px;
}

.uploaded-files-close .svg-container.small>svg,
.uploaded-files-icon .svg-container.small>svg {
  width: 9px;
}

textarea.upload-file-comment {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
  resize: none;
  color: rgb(33, 43, 54);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  height: 200px;
  outline: none;
  padding: 10px;
}

.upload-pending-container {
  padding: 60px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.upload-pending-label {
  color: rgb(196, 205, 213);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.38px;
  margin: 30px 0 20px 0;
}

.upload-pending-desc {
  color: rgb(196, 205, 213);
  font-family: Graphik-Regular;
  font-size: 18px;
  font-weight: normal;
  height: 42px;
  letter-spacing: -0.28px;
  line-height: 21px;
  text-align: center;
  width: 792px;
}

.programEdit-icon:hover+.tooltiptext {
  visibility: visible;
}

#tooltiptext-program.tooltiptext {
  right: 42px;
  left: auto;
  width: auto;
}

.tooltiptext.programDetails {
  top: 0;
  left: 15px;
  background-color: transparent;
}

.tooltiptext.projectDetails {
  top: 0;
  left: auto;
  right: 25px;
  background-color: transparent;
}

.existing-program {
  color: red;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-right: 10%;
}

.edit-rejected-boi {
  cursor: not-allowed !important;
}

.edit-rejected-boi .delete-boi-button {
  color: #bcbcbc;
}

.no-confirmation-doc-container {
  padding: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.no-confirmation-doc-container>div {
  color: #495057 !important;
}

.no-confirmation-doc-container>svg {
  color: rgb(161, 0, 255);
  font-size: 70px;
}

.no-confirmation-doc-container .label-cont {
  padding-top: 2.5%;
  padding-bottom: 1%;
}

.deleted-header {
  font-weight: 400px;
}

.deleted-boi {
  margin-bottom: 8px;
}

.deleted-boi-field {
  height: 20px !important;
}

.modal-message {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 32px;
  line-height: 16px;
  margin-bottom: 10px;
}

.groove-dtpicker-custom-label-disabled,
div.groove-dtpicker-custom-label {
  padding: 0 0 0 10px !important;
}

.modal-title-special {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.modal-title-special .svg-container {
  height: auto;
  font-size: 1.2em !important;
}

.add-boi-back-button {
  margin-right: 10px;
  cursor: pointer;
}

.select-boi-header,
.select-boi-body-row {
  background: rgb(249, 250, 251);
  border-radius: 5px 5px 0px 0px;
  border: 1px solid rgb(231, 234, 238);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 10px 25px;
}

.select-boi-body-row {
  border-radius: 0;
  border-top: none;
  padding: 15px 25px;
}

.select-boi-body-row:nth-child(odd) {
  background: rgb(255, 255, 255);
}

.select-boi-modal-column {
  width: 15%;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.boi-name {
  overflow: hidden;
}

.select-boi-modal-column:first-child {
  width: 20px;
}

#uncheckboi-popover {
  width: 400px;
  background-color: #a100ff;
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  font-family: 'Graphik-Medium';
  letter-spacing: 0.3px;
  max-width: none;
  display: none;
  margin: -30px 20px;
  left: auto;
  top: auto;
}

#uncheckboi-popover .popover-body {
  width: 351px;
}

#uncheckboi-popover .arrow {
  display: none;
}

#uncheckboi-popover>.popover-body {
  color: #fff;
}

.select-boi-modal-column:hover #uncheckboi-popover {
  display: table-caption !important;
}

.select-boi-header>.select-boi-modal-column {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}

.select-boi-body-row>.select-boi-modal-column {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.select-boi-footer {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.31px;
  margin-top: 10px;
}

.cvm-custom-check-box {
  border-radius: 4px;
  border: 1px solid rgb(181, 192, 202);
  height: 20px;
  width: 20px;
  cursor: pointer;
  padding: 0 2.5px;
}

.cvm-custom-check-box:hover {
  border: 1px solid rgb(161, 0, 255);
  -webkit-box-shadow: 0 0 5px rgb(193 88 255);
          box-shadow: 0 0 5px rgb(193 88 255);
}

.select-all-boi-checked {
  background: rgb(244, 251, 255);
  border: 2px solid rgb(161, 0, 255);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.select-all-boi-checked>div {
  background: rgb(161, 0, 255);
  border-radius: 2px;
  width: 10px;
  height: 10px;
}

.cvm-custom-checked {
  background: rgb(161, 0, 255);
  border-color: rgb(161, 0, 255);
}

/* Custom Radio Button */

.custom-boi-label {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  letter-spacing: -0.35px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-weight: normal;
}

/* Hide the browser's default radio button */

.custom-boi-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.custom-boi-checkmark,
.custom-boi-checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(196, 205, 213);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.custom-boi-label:hover input~.custom-boi-checkmark,
.custom-boi-label:hover input~.custom-boi-checkmark2 {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.custom-boi-label input:checked~.custom-boi-checkmark {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(161, 0, 255);
  height: 24px;
  width: 24px;
}

.custom-boi-label input:checked~.custom-boi-checkmark2 {
  background: rgb(161, 0, 255);
  border: 1.5px solid rgb(161, 0, 255);
  height: 16px;
  width: 16px;
  left: 4px;
  top: 4px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.custom-boi-checkmark:after,
.custom-boi-checkmark2::after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

/* .custom-boi-label input:checked ~ .custom-boi-checkmark:after {
  display: block;
} */

/* Style the indicator (dot/circle) */

.custom-boi-label .custom-boi-checkmark:after,
.custom-boi-label .custom-boi-checkmark2:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.custom-boi-radio {
  display: -ms-flexbox;
  display: flex;
}

.custom-boi-radio label:first-child {
  padding-right: 50px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
}

.lead-options {
  display: -ms-flexbox;
  display: flex;
}

.lead-options label.custom-boi-label {
  font-family: Graphik-Regular;
}

.customboi-dropdown .dropdown-list .dropdown-item:first-child {
  border-bottom: 1px solid #e6e6e6;
}

.add-subdimension-show,
.reason-boidefinition-show,
.reason-aggrelogic-show {
  display: block !important;
}

.add-subdimension-hidden,
.reason-boidefinition-hidden,
.reason-aggrelogic-hidden {
  display: none !important;
}

.rvd-link {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.rvd-link a:hover {
  text-decoration: none;
}

.metric-calc {
  display: contents;
}

.boidefinition-parameter {
  /* display: flex; */
  width: 100%;
}

.boidefinition-parameter .row {
  margin: 0;
}

.customboi-add-header {
  width: 87%;
  text-align: left;
}

.customboi-selected-header {
  width: 73%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 480px) {
  .customboi-selected-header{
    width:30%;
  }
}
@media screen and (min-width: 840px) {
    .customboi-selected-header{
      width: 50%;
    }

}
.no-boidefinition-warning {
  background: rgb(255, 245, 247);
  border-radius: 8px;
  border: 1px solid rgb(213, 0, 47);
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  padding: 8px 15px;
  display: -ms-flexbox;
  display: flex;
}

.no-boidefinition-warning .no-boidefinition-text {
  width: 100%;
  color: rgb(197, 0, 6);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  padding: 0px 15px;
  position: relative;
  top: 3px;
}

.disable-row,
.disable-check-box {
  cursor: not-allowed;
}

.disable-check-box {
  background: #bcbcbc !important;
  border: 1px solid #bcbcbc;
}

.disable-check-box:hover {
  border: 1px solid #bcbcbc;
  -webkit-box-shadow: 0 0 5px #bcbcbc;
  box-shadow: 0 0 5px #bcbcbc;
}

.add-edit-top-right {
  float: right;
}

#cal-comments #groove-textarea .groove-textbox:disabled {
  background: rgb(239, 242, 245);
  border: 1px solid rgb(181, 192, 202) !important;
  font-size: 16px;
  border-top: 25px solid transparent !important;
}

#cal-comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown):disabled+label {
  color: rgb(99, 115, 129) !important;
}

#cal-comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder):disabled+label {
  color: rgb(99, 115, 129) !important;
}

#cal-comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown):disabled+label {
  color: rgb(99, 115, 129) !important;
}

.select-boi-modal-column:nth-child(2) {
  width: 480px !important;
}

.BT-Lead-Landing-Page>.empty-program-container {
  min-height: 602px;
}

.customboi-card-collapse {
  margin: 20px 0px;
  border-bottom: 1px solid red !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.customboi-card-collapse .card-header {
  height: 66px;
}

.customboi-list-card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 5px !important;
}

.customboi-header-btn {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  bottom: 28px;
  float: right;
  right: 50px;
}

.customboi-header-btn div {
  margin-left: 20px;
}

#deleteCusBoi-popover {
  display: none;
  background-color: #a100ff;
  color: #fff;
  text-align: center;
  padding: 15px 0px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 11px;
  font-family: 'Graphik-Medium';
  letter-spacing: 0.3px;
}

#deleteCusBoi-popover .arrow::before,
#deleteCusBoi-popover .arrow::after {
  display: none;
}

.delete-cusboi:hover #deleteCusBoi-popover {
  display: table-caption !important;
}

#deleteCusBoi-popover>.popover-body {
  color: #fff;
}

.disableWithtooltip:hover #deleteCusBoi-popover {
  display: table-caption;
}

#deleteCusBoi-popover[x-placement^='right'] {
  display: none;
  right: auto;
  left: auto;
  margin-left: 95%;
}

#deleteCusBoi-popover[x-placement^='left'] {
  display: none;
  left: auto;
  right: 80%;
}

#deleteCusBoi-popover .bs-popover-auto[x-placement^='left']>.arrow,
.bs-popover-left>.arrow {
  top: 33px;
}

#deleteCusBoi-popover .popover-body {
  width: 274px;
  max-width: 312px;
  margin-left: 0 !important;
}

.customboi-container {
  min-height: 500px;
}

#programDetails-popover .bs-popover-auto[x-placement^='left']>.arrow,
.bs-popover-left>.arrow {
  top: 0 !important;
}

span.tooltiptext.projectDetails {
  width: 400px;
}

.customboi-container .customboi-card-collapse #groove-btn-id .btn-stat-alternate.disabled,
.customboi-container .customboi-card-collapse #groove-btn-id .btn-stat-alternate:disabled {
  cursor: pointer;
}

.program-lead-landing .applyFlex-align-center.apply-relative .single-select-dropdown .single-select-label {
  width: 17%;
}

.add-program-line .single-select-dropdown .single-select-label {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.program-lead-landing .applyFlex-align-center.apply-relative.project-dropdown .single-select-dropdown .single-select-label {
  width: 64px;
  position: relative;
}

.projectEntry-container>.row>.applyFlex-align-center.apply-relative.pde-project-dropdown .css-1hwfws3 {
  position: relative;
  left: -29px;
}

.projectEntry-container>.row>.applyFlex-align-center.apply-relative .css-1hwfws3 {
  position: relative;
  left: -19px;
}

/* Agnostic BOI styles */
.exclamation-icon-container {
  margin-right: 8px;
  border: 2px solid rgb(117, 0, 192);
  border-radius: 50px;
  height: 30px;
  min-width: 30px;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.exclamation-icon-container>.svg-container {
  height: 70%;
}

.exclamation-icon {
  color: rgb(161, 0, 255);
}

.exclamation-icon-disclaimerMessage .exclamation-icon {
  margin-top: 4px;
}

.agnostic-disclaimer-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 88px;
  background: rgb(251, 245, 255);
  border: 1px solid rgb(227, 209, 255);
  border-radius: 8px;
  padding: 24px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgb(117, 0, 192);
}

.generated-project-label {
  /* background: #f4fbff; */
  background: #e7eaee;
  padding: 5px 10px;
  border-radius: 16px;
  opacity: 1;
  /* color: #637381; */
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  height: 18px;
  letter-spacing: 0.25px;
  line-height: 11px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  /* margin-left: 5px; */
}

.project-card-title {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.75rem;
  -ms-flex-align: end;
      align-items: end;
  -ms-flex-align: center;
      align-items: center;
}

.project-card-title>img {
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.project-card-title>.card-title {
  margin-bottom: unset;
  margin-right: 5px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.sort-icon {
  margin-left: 24px;
  cursor: pointer;
}

.sort-wrapper {
  position: relative;
}

.sort-menu {
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  -webkit-box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  position: absolute;
  width: 270px;
  right: 0;
  z-index: 5;
  font-size: 14px;
  top: -8px;
}

.sort-menu>div {
  padding: 10px;
  cursor: pointer;
}

.sort-menu>div>img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/*Disable system generated tile when rejected @ ppc*/

.rvd-program-card-wrap.disabled,
.table-action-buttons.disable>svg.svg-inline--fa.fa-edit.fa-icons {
  opacity: 0.5 !important;
  cursor: not-allowed;
}

#ban-icon svg {
  position: absolute;
  color: #000;
  opacity: 0.5 !important;
}

.creation-item {
  display: inline-block;
  /* white-space: nowrap; */
  -ms-flex: 1 1;
      flex: 1 1;
}

div#rvd-disabled-sys>div.tooltip-inner {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 11px;
  letter-spacing: -0.25px;
  line-height: 16px;
  text-align: left;
  max-width: 314px;
}

.boi-stat-warning {
  padding: 20px 40px 0 40px !important;
}

/*---End-----*/

.client-confirmation-docs .check-label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dd-tooltip-right {
  display: -ms-flexbox;
  display: flex;
}

.hover-view-prog {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  color: red;
  margin: 0;
  color: #a100ff;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  top: 37%;
}

#progBoxContainer:hover #progWrapContainer {
  background-color: white;
  opacity: 0.2;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  content: none;
}

#progWrapContainer:hover {
  background-color: white;
  opacity: 0.05;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#progBoxContainer:hover .hover-view-prog {
  opacity: 1 !important;
}

#progBoxContainer:hover .prog-hover {
  opacity: 0.5;
}

.prog-card-stage-cont {
  max-width: 83%;
  display: -ms-flexbox;
  display: flex;
}

span.prog-card-stage-name {
  text-overflow: ellipsis !important;
  /* display: block; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  /* word-break: break-word; */
  /* min-inline-size: -webkit-fill-available; */
  min-inline-size: -moz-available;
  /* white-space: unset; */
  /* margin-bottom: 6px; */
  line-height: 115%;
  overflow: hidden;
  white-space: nowrap;
}

.prog-stage {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.edit-boi-link-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.edit-boi-link-icon {
  height: 12.8px;
  width: 16px;
}

.edit-boi-link-span {
  color: #a100ff;
}

.edit-boi-link-divider {
  padding: 0px 10px;
  color: rgba(209, 213, 219);
}

.edit-boi-link {
  color: #637381;
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -.25px;
  margin-left: auto;
  margin-right: 5px;
}

.add-program-details .multiWithAddButton {
  margin-bottom: 1rem;
}

/* Sort Functionality Enhancements */
.column-search-container .search-bar {
  position: relative;
  margin: 0;
}

.column-search-container .rvd-sort-wrapper {
  top: 10%;
}

.column-search-container>.search-bar #searchBox {
  width: 300px;
}

.custom-sortmenu.popover {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  -webkit-box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  position: absolute;
  width: 265px;
  right: 0;
  z-index: 5;
  font-size: 14px;
  border: none;
}

.custom-sortmenu.popover .arrow:before {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.custom-sortmenulist>div {
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

.custom-sortmenulist .activesort-item,
.custom-sortmenulist>div:hover {
  background-color: #F3F4F6;
  font-weight: 500;
  border-radius: 4px;
}

.empty-project-list {
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: 400;
  color: #DC2626;
}

.project-filter-align {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.project-filter-align .sort-wrapper .sort-icon {
  margin-left: 24px;
}

.column-search-container .dIL-actionrow {
  margin-bottom: 0;
}

.main-reminder-button {
  position: absolute;
  right: 270px;
  top: 12px;
  z-index: 1
}

.reminder-button>.send-reminder-button {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.25px;
  line-height: 14px;
  border: none;
  padding: 0 8px;
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.reminder-button>.send-reminder-button>button>div.svg-container {
  -webkit-transform: none !important;
          transform: none !important;
}

.reminder-button>.send-reminder-button>button>.svg-container>.svg-inline--fa {
  height: 1.1rem;
  vertical-align: auto;
  width: auto;
}

.add-program-details .selected-menu-basic{
  color: #000 !important;
}

.add-program-details .display-selected{
  color: #000000 !important;
}

.add-new-project-popup .omid-container,
.add-new-project-popup .contract-container
{
  padding-bottom: 5px;
}

.detail-popup-section-oal,
.detail-popup-section-cxl {
  margin-bottom: 1rem;
}

.detail-popup-section-oal .section-desc,
.detail-popup-section-cxl .section-desc {
  font-family: Graphik-Regular;
  font-weight: normal;
  margin-bottom: 10px;
  width: 190px;
}

.detail-popup-section-oal .section-title,
.detail-popup-section-cxl .section-title {
  font-family: Graphik-Medium;
  font-weight: 500;
}

.detail-popup-section-cxl {
  margin-bottom: 1rem;
}

.detail-popup-section-cxl .section-desc {
  font-family: Graphik-Regular;
  font-weight: normal;
  margin-bottom: 10px;
  width: 190px;
}

.detail-popup-section-cxl .section-title {
  font-family: Graphik-Medium;
  font-weight: 500;
}

.ellipsis-hover {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-hover:hover {
  overflow: visible; 
  white-space: normal;
}

/* Invalid project creator and io approver dropdowns in project page */
#groove-select-dropdown.project-page-dropdown-invalid .form-control, 
#groove-select-dropdown.project-page-dropdown-invalid .form-control#selectmenu-anchor:hover {
  border: 1px solid red !important;
}
.cvm-date-range {
  position: relative;
}

.cvm-date-range-overlay {
  width: 100%;
  height: 100%;
  background: #a2a2a21f;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
}

.cvm-date-range-pop {
  width: 100%;
  background: white;
  position: absolute;
  bottom: -70px;
  z-index: 99;
  border-radius: 10px;
  padding: 20px 30px;
  -webkit-box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%);
          box-shadow: 0px 2px 48px 0px rgb(33 43 54 / 25%);
}

.date-range-selected {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  height: 54px !important;
  padding: .375rem .75rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-direction: column;
      flex-direction: column;
  cursor: pointer;
}

.date-range-selected.selected-date-field {
  border: 1.5px solid #a100ff !important;
  -webkit-box-shadow: 0 0 5px #a100ff54;
          box-shadow: 0 0 5px #a100ff54;
}

.date-range-label {
  color: #a100ff !important;
  font-family: "Graphik-Medium" !important;
  font-size: 11px !important;
  line-height: normal !important;
  line-height: initial !important;
}

.date-range-value {
  color: #212b36 !important;
  font-size: 16px !important;
  letter-spacing: -.25px !important;
}

.date-range-value.blank-value, .cvm-date-range-input.blank-value {
  color: #919eab !important;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.cvm-date-range-input.blank-value {
  padding: .375rem 1.45rem .375rem .75rem;
}

.cvm-date-range-input-required {
  border: 1px solid red !important;
  -webkit-box-shadow: 0 0 5px #ff00007d;
          box-shadow: 0 0 5px #ff00007d;
}

.date-range-picker-container {
  width: 100%;
  padding: 15px;
}

.date-range-picker-header {
  padding: 5px 20px;
  border: 1.5px solid #ced4da;
  border-radius: .25rem .25rem 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.date-range-picker-header > div {
  color: rgb(80 80 80) !important;
  font-family: "Graphik-Medium" !important;
  font-size: 14px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.adjust-year-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
}

.adjust-year-button > div {
  font-size: 18px !important;
}

.date-range-picker-body {
  border: 1.5px solid #ced4da;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 10px 15px;
}

.month-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  border: 1px solid #ced4da;
  border-bottom: none;
}

.month-row:last-child {
  border: 1px solid #ced4da;
}

.month-tile {
  width: 100%;
  text-align: center;
  padding: 5px;
  font-family: "Graphik-Medium" !important;
  cursor: pointer;
  height: 42px;
  font-size: 14px !important;
  color: rgb(80 80 80) !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.month-tile:hover {
  -webkit-box-shadow: 0 0 5px #a100ff29;
          box-shadow: 0 0 5px #a100ff29;
  background: #bf52ff29;
}

.month-tile.selected-month {
  background: #bf52ff29;
}

.month-tile:nth-child(2) {
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
}

.month-tile.disabled-month {
  background: #ececec9e;
  color: #5050507d !important;
  cursor: not-allowed;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.date-range-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 100%;
  padding: 5px 15px;
}


.cvm-date-range-input {
  width: 100%;
  font-family: "Graphik-Regular" !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: -.25px !important;
  line-height: 18px !important;
  height: 54px !important;  
  padding: 1.2rem 1.45rem .375rem .75rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -ms-flex-pack: justify;
      justify-content: space-between;
  color: #212b36 !important;
}

.cvm-date-range-input:hover {
  cursor: pointer;
  border: 1.5px solid #a100ff !important;
}

#cal-icon {
  font-size: 20px !important;
}

.custom-field-label {
  color: #a100ff !important;
  font-family: "Graphik-Medium";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -.25px;
  line-height: 11px;
  position: absolute;
  top: 8px;
}

.disabled-field {
  background: #ececec9e;
  color: #5050507d !important;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.projectEntry-container {
  padding: 30px 80px;
  min-height: 840px !important;
}

.projectEntry-container .btn-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 0px;
}

.projectEntry-container .btn-container .project-title-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.btn-container > div {
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  word-break: break-all;
}

.projectEntry-container .title-section {
  margin-bottom: 5px;
}

.projectEntry-container .title-section .fa-times {
  cursor: pointer;
}

.select-project-modal .add-new-program-container {
  overflow-y: visible;
}

.select-project-modal .modal-header {
  padding-bottom: 15px !important;
}

.program-details-column {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3px;
}

.program-details-row {
  border-right: 1px solid #dfe3e8;
}

.program-details-row:last-child {
  border-right: none;
}

.program-details-row.col-lg-4 {
  padding-right: 10px;
  padding-left: 12px;
}

.project-title {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Semibold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 18px;
}

.projectEntry-subcontainer {
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px 40px 50px;
}

.projectEntry-subcontainer .project-title {
  width: 100%;
  word-break: break-word;
}
.projectEntry-container .sub-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.computeAll-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.sub-title-header {
  margin-right: 0px !important;
  margin-left: 0px !important;
  text-align: center;
}

.projectEntry-container .sub-title .sub-title-header h5 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 24px;
  margin-bottom: 0;
  -ms-flex-item-align: center;
      align-self: center;
}

.reset-all-button,
.compute-all-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  border: #a100ff solid 1px;
  border-radius: 50px;
  cursor: pointer;
}

.reset-all-button {
  padding: 3px 0;
  padding-left: 15px;
}
.reset-all-button button {
  color: #a100ff !important;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 15px;
}
.compute-all-button button {
  color: #a100ff !important;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.reset-all-button img {
  height: 30px;
  width: 15px;
  position: absolute;
}

.compute-all-button img {
  height: 33px;
  width: 15px;
  position: absolute;
  margin-left: 15px;
}

.reset-all-button:hover,
.compute-all-button:hover {
  background-color: #a100ff;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.reset-all-button:hover button,
.compute-all-button:hover button {
  color: #fff !important;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.compute-all-button:hover img,
.reset-all-button:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.projectEntry-container .sub-title-right span,
.projectEntry-container .sub-title-right label {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  margin-left: 25px;
  cursor: pointer;
}

.reports-boiHistory-container {
  margin: 5px 0 10px 0;
}

.projectEntry-container .file-upload-wrap {
  padding: 19px 20px;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 24px 0px;
}

.projectEntry-container .file-upload-wrap.file-error {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
}

.projectEntry-container .file-upload-wrap.file-success {
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.projectEntry-container .accordion-action-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.subdimension-table {
  margin: 20px 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 0px;
}

.subdimension-header {
  background: rgb(249, 250, 251);
  border-bottom: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
}

.subdimension-header .row {
  padding: 12px 13px 12px 3%;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}

.subdimension-header > .row > div,
.subdimension-body .card-header > .row > div {
  padding: 0 5px;
}
/*css for columns based on status*/

@media (min-width: 992px) {
  .projectEntry-container .subdimension-header .row .col-lg-2,
  .projectEntry-container .subdimension-row.accordion .col-lg-2,
  .projectEntry-container .subdimension-header .row .col-lg-1,
  .projectEntry-container .subdimension-row.accordion .col-lg-1 {
    word-break: break-word;
  }

  .projectEntry-container .subdimension-row.accordion div:nth-child(3),
  .projectEntry-container .subdimension-row.accordion div:nth-child(4),
  .projectEntry-container .subdimension-row.accordion div:nth-child(5),
  .projectEntry-container .subdimension-row.accordion div:nth-child(6),
  .projectEntry-container .subdimension-row.accordion div:nth-child(7),
  .projectEntry-container .subdimension-row.accordion div:nth-child(8) {
    padding-left: unset !important;
  }

  .target-value-padding {
    padding-left: unset !important;
  }
}

.boiComm-awapp {
  left: 30px;
}
.wVis-awapp {
  left: 25px;
}
.projectEntry-subcontainer label.mini-toggle-switch {
  top: 6px;
}
.app-rej-awapp {
  left: 40px;
}
.app-rej-rejected,
.app-rej-rejected-new {
  -ms-flex-item-align: baseline;
      align-self: baseline;
  padding-left: unset;
}
.app-rej-rejected .rvd-rejection-comment #groove-textarea,
.app-rej-rejected-new .rvd-rejection-comment #groove-textarea,
.app-rej-awapp-new .rvd-rejection-comment #groove-textarea {
  width: 80%;
}

.boiComm-rejected {
  right: 23px;
}

.subdimension-section-collapse .subdimension-row .card-header > button {
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.subdimension-row .card-header {
  padding: 20px 30px;
}

.subdimension-row .card-header div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  word-break: break-word;
}

.bodytext14 {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.parameter-title-center {
  margin-left: 185px;
}

.false {
  font-family: Graphik-Medium;
}

.bodytext14-regular {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.fa-icons {
  color: rgb(161, 0, 255);
  padding: 3px;
  font-size: x-large;
  cursor: pointer;
}

.data-entries {
  margin: 15px;
  opacity: 0.5;
}

.new-pde {
  margin-bottom: 40px !important;
}

.report-prd {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 24px;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.new-proj-btn {
  display: inherit;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.subdimension-row .card-header > button .svg-container {
  display: inline;
  margin-right: 5px;
}

.subdimension-row.accordion .card-header {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  border-top: 1px solid rgb(223, 227, 232);
  border-radius: 0px !important;
}

.subdimension-row.accordion:first-child .card-header {
  border-top: none;
  padding-left: 0px;
  padding-right: 0px;
}

.subdimension-row.accordion .card-header .row {
  width: inherit;
}

.subdimension-row.accordion .card-header > button {
  width: 3%;
}

.rvd-subdimension-table-wrap .subdimension-row.accordion .card-header > button {
  width: 3%;
  padding-right: 7px;
}

.subdimension-section-collapse .card-header > button > .pde-subdimension-name {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.subdimension-section-collapse .card-header > button > .bodytext18-medium-midnight {
  margin-left: 20px;
}

.parameter-field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0px 50px 0px;
}

.field-text {
  width: 34%;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 19px;
}

.field-value {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.field-value input {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px 5px;
  margin: 0px 12px 0px 40px;
}

.field-row-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.subdimension-row.accordion .card {
  border: none;
}

.bodytext14-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.subdimension-row .card-header {
  background: #fff;
}

.subdimension-row .card.error-card {
  background: rgb(255, 245, 247);
  border-radius: 0px;
  border-left: 5px solid #d5002f;
}

.subdimension-row .card.error-card .card-header {
  background: rgb(255, 245, 247);
  padding-left: 0px;
}

img.comment-icon {
  margin-right: -5px;
}

.file-img {
  width: 14px;
  height: 19px;
  margin-right: 5px;
}

.projectEntry-container .errorbox {
  margin-bottom: 24px;
}

.errorMsg {
  color: rgb(197, 0, 6);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 14px;
}

.file-error-list table {
  width: 100%;
}

.file-error-list table,
.file-error-list th,
.file-error-list td {
  border: 1px solid rgb(223, 227, 232);
  border-collapse: collapse;
}

.file-error-list th,
.file-error-list td {
  padding: 6px;
  text-align: left;
  font-size: 12px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 12px;
}

.file-error-list th {
  font-family: Graphik-Medium-Web;
}

.error-list-table {
  margin-bottom: 24px;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 5px;
}

.fa-times {
  cursor: pointer;
}

.attachment-list-container {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px;
}

.attachment-Item:not(:last-child) {
  border-bottom: 1px solid rgb(223, 227, 232);
  margin-bottom: 15px;
}

.view-Attachment-modal .modal-body > div:first-child {
  padding: 16px 30px;
}

.view-Attachment-modal .title,
.view-Attachment-modal .value {
  font-size: 14px;
  line-height: 14px;
}

.view-Attachment-modal .file-img {
  width: 12px;
  height: 16px;
  margin-right: 5px;
}
.subdimension-row #groove-radio-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.subdimension-row .form-check {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 10px;
}

.subdimension-row .form-check-input {
  margin-top: 0.15rem;
}

/* @media (min-width: 992px){
.subdimension-table .col-lg-2:nth-child(2) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
.subdimension-table .col-lg-2:nth-child(3) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
} */

.subdimension-row.accordion .collapse.show ~ div.card-header .btn-link > div.svg-container {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.subdimension-row.accordion .collapse.show ~ div.card-header div.svg-container {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  cursor: pointer;
}

.validate {
  color: red;
  margin-left: 10px;
}

.rdv-view-comment {
  display: -ms-flexbox;
  display: flex;
}

.expandAll-button.active > button,
.collapseAll-button.active > button {
  color: #a100ff;
}

.info-icon {
  display: inline-block;
  margin: 0 10px;
  position: relative;
}

.info-icon .svg-container {
  -webkit-transform: none !important;
          transform: none !important;
  font-size: 0.9em !important;
}

.info-icon > .svg-container > svg {
  width: 11px;
  cursor: pointer;
}

.info-icon.top {
  position: absolute;
  right: -15px !important;
  top: -10px !important;
}
/* 
.ApprovalRequestModal .groove-textbox {
  margin-top: 9px;
} */

.custom-boi {
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px 10px;
  border: none;
  margin-top: 0.25em;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 70px;
  margin-left: 10px;
}

.custom-boi:hover {
  -webkit-box-shadow: 0 0 5px rgb(187 135 255);
          box-shadow: 0 0 5px rgb(187 135 255);
}

.modal-fade-custom-boi {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px;
  height: 1064px;
  width: 1440px;
}

.custom-boi-modal {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modalheader-title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  width: 308px;
}

.modalbody-details {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.2px;
  line-height: 20px;
  width: 63px;
}

.modalbody-desc {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.29px;
  line-height: 20px;
  width: 67px;
}

.modalbody-column {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modal-custom-BOI-container {
  /* position: fixed; */
  /* display: flex; */
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  height: 100%;
  /* width: 20vw; */
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.modal-container.show {
  opacity: 1;
  pointer-events: all;
}

.modal-Custom {
  width: 70vw;
  height: 80vh;
  /* max-width: 100%; */
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* background-color: #c47474; */
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.row-inside {
  display: -ms-flexbox;
  display: flex;
}

.row-outside {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
  display: -ms-flexbox;
  display: flex;
}

.row-outside h3 {
  position: absolute;
  margin: 2rem 3rem;
  font-size: x-large;
}

#close {
  position: absolute;
  margin: 1em 65rem;
  color: gray;
  border: none;
  background-color: white;
}

.col-50 {
  -ms-flex: 25% 1;
      flex: 25% 1;
  margin: 4rem 3rem;
}

.col-50 h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
}

.col-50 p {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.col-25 {
  -ms-flex: 12.5% 1;
      flex: 12.5% 1;
}

/* ==========================================CHANGES=================================== */

.modal-body .custom-boi-container {
  height: 400px;
}

.modal-custom-BOI-container .modal-body {
  padding: 1rem !important;
}

.modal-custom-BOI-container .modal-dialog {
  max-width: 800px;
}

.modal-custom-BOI-container h3 {
  font-size: x-large;
  padding-left: 16px;
}

.custom-boi-container {
  padding: 0 25px !important;
}

.modal-custom-BOI-container h6 {
  margin: 0;
  padding-bottom: 4px;
  /* color: rgb(99, 115, 129); */
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.modal-custom-BOI-container .modal-title.h4 {
  font-family: Graphik-Semibold;
  /* color: rgb(45, 58, 75); */
  color: #000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.42px;
}

.modal-custom-BOI-container p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 16px;
  word-break: break-all;
}

.custom-close-Modal {
  height: 16.25px;
  width: 16.25px;
}

.custom-boi-btn #custom-btn span {
  font-size: 11px;
  color: rgb(80, 35, 143);
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

#groove-btn-id.custom-boi-btn #custom-btn.btn-outline-stat-alternate {
  padding: 5px;
  background: rgb(235, 225, 248);
  border-radius: 16px;
  border: none;
}

.custom-boi-empty {
  padding-bottom: 24px;
}

.custom-boi-param {
  padding-left: 0px !important;
}
.param-char-length-err {
  color: red;
  margin-left: 37px;
  font-size: 13px;
  padding-top: 4px;
  height: 1px;
}

.warning-message-parameter {
  padding-left: 5%;
  width: 30%;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  color: #ffbd00;
}

.rdv-refresh-list {
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
}
.rdv-refresh-list button {
  color: #a100ff !important;
}
.rdv-refresh-list button > span {
  font-size: 16px !important;
}

#rvd-refresh-tooltip-bottom .tooltip-inner {
  max-width: 250px;
  padding: 8px 15px;
  font-size: 12px;
}
/* Rejection Comment CSS */
.rvd-rejection-comment {
  display: block !important;
}
.rvd-rejection-comment #groove-textarea {
  position: absolute;
  margin: 10px 0px;
  top: 20px;
  width: 68%;
}
.rvd-rejection-comment #groove-textarea .groove-textbox {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232) !important;
  border-radius: 4px;
}

.rvd-rejection-comment .floating-label-textbox label {
  padding: 0px !important;
}

.rvd-rejection-comment .floating-label-textbox {
  border-top: 1px solid rgb(223, 227, 232) !important;
  border-radius: 4px;
}
.rvd-rejection-comment div.groove-textbox-custom-label,
.rvd-rejection-comment div.groove-input-custom-label {
  color: #919eab !important;
  font-family: 'Graphik-Medium' !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px !important;
  line-height: 12px;
  margin-top: -9px;
}
.rvd-rejection-comment
  #groove-textarea
  .floating-label-textbox
  .groove-textbox.is-invalid:not(:-moz-placeholder-shown):disabled
  + label {
  transform: translateY(0);
}
.rvd-rejection-comment
  #groove-textarea
  .floating-label-textbox
  .groove-textbox.is-invalid:not(:-ms-input-placeholder):disabled
  + label {
  transform: translateY(0);
}
.rvd-rejection-comment
  #groove-textarea
  .floating-label-textbox
  .groove-textbox.is-invalid:not(:placeholder-shown):disabled
  + label {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

/* #boi-tooltip-right .tooltip-inner {
  -webkit-box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  background-color: #fff;
  color: #000;
  padding: 15px 15px;
  min-width: 550px !important;
  max-width: 650px !important;
  margin-bottom: 75px;
} */
#boi-tooltip-right.bs-tooltip-right .arrow:before {
  border-right-color: #fff !important;
}

.boi-details-wrap h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
  text-align: left;
}

.boi-details-wrap p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 14px;
  word-break: break-word;
  text-align: left;
}
/* View Aggregated BOI Details CSS */
.aggregated-boi-popover {
  width: 285px;
  height: 50px;
  color: #a100ff;
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
}

#aggregated-boi-popover,
#aggregated-boi-popover .popover-body {
  width: 634px;
  height: 651px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 5;
}

.aggregated-boi-popover-header {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding: 5px 14px;
}

.aggregated-boi-popover-header .aggregated-boi-popover-title {
  width: 90%;
  height: 25px;
  color: rgb(109, 114, 120);
  font-size: 18px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.28px;
}

.aggregated-boi-popover-header .aggregated-boi-popover-close-btn {
  width: 10%;
  color: rgb(33, 43, 54);
  font-weight: 500;
  cursor: pointer;
  font-size: 18px;
  padding-left: 40px;
}

.aggregated-boi-accordion-container {
  max-height: 582px;
  overflow-y: auto;
  padding: 2px;
}

.visualization-collapse.accordion .card {
  margin-bottom: 24px;
  border-radius: 5px !important;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

.visualization-collapse.accordion .card:last-child {
  margin-bottom: 0px;
}
.visualization-collapse.configure-client-collapse .card-header {
  padding: 20px 25px;
}

.visualization-collapse.configure-client-collapse .card-body {
  padding: 0px 25px 20px 25px;
}

.dim-tabs > div > div > div > div {
  margin-bottom: -7px !important;
  margin: 0 -15px;
}

.dim-tabs .MuiTabs-scroller.MuiTabs-scrollable > div {
  margin: 0 !important;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.aggregated-boi-detail-tabs button > span:first-child {
  text-transform: none;
  text-transform: initial;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 0 10px !important;
}

.aggregated-boi-detail-tabs button span {
  padding: 0;
  letter-spacing: -0.21px;
  text-align: center;
  word-wrap: break-word;
  display: block;
  max-width: 200px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.aggregated-boi-detail-tabs button > span:nth-child(2) {
  display: none;
}

.aggregated-boi-detail-tabs button {
  border: 3px solid transparent;
}

.aggregated-boi-detail-tabs button > svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.aggregated-boi-detail-tabs button > svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.aggregated-boi-detail-tabs button[aria-selected='true'] {
  border-bottom-color: #a100ff;
}

.aggregated-boi-detail-tabs button[aria-selected='false'] span,
.aggregated-boi-detail-tabs button[aria-selected='false'] span span span {
  color: rgb(45, 58, 75);
}

.aggregated-boi-detail-tabs > div > div > span {
  display: none;
}

.aggregated-boi-detail-tabs span.MuiTabs-indicator {
  display: none;
}

#aggregated-info-icon {
  margin: 0 10px !important;
}

.aggregated-boi-label-container {
  height: 45px;
  color: rgb(45, 58, 75);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  padding: 15px 15px;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.aggregated-boi-left-label {
  width: 70%;
  border-right: 1px solid #e7eaee;
  padding-right: 5px;
  display: -ms-flexbox;
  display: flex;
}
.aggregated-boi-left-label .aggregated-boi-left-text {
  /* width: 70%; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.aggregated-boi-right-label {
  width: 35%;
  padding-left: 5px;
}

.aggregated-boi-title-label #aggregated-info-icon > .svg-container > svg {
  font-size: 14px !important;
}

#tooltiptext-aggregated {
  top: 20px !important;
  clear: both !important;
  left: 10px !important;
  -webkit-box-shadow: 0 0 2px black;
          box-shadow: 0 0 2px black;
}

div[role='tabpanel'] > div.aggregated-tabpanel {
  max-height: 500px;
  overflow-x: hidden;
  padding: 10px;
  overflow-y: auto;
  display: inline;
}

.tooltip-parameter {
  margin-right: 50px;
}
.reporting-period-normal {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 18px;
  background-color: white;
}
.reporting-period-highlight {
  font-family: Graphik-Medium;
  font-size: 14px;
  line-height: 18px;
  background-color: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  color: rgb(197, 0, 6);
}
.rvdboi-new-info .new-info-icon {
  -webkit-transform: scale(1);
          transform: scale(1);
  margin-top: 0px;
}

.aggregated-info.info-icon-wrap {
  margin-top: -14px;
}
.rvd-invalid-realizedval {
  background-color: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  color: rgb(197, 0, 6);
}

#groove-radio-button.approve-reject-radio-button input.form-check-input {
  margin-top: 0;
  height: 20px;
  width: 20px;
  content: '';
  display: inline-block;
  visibility: visible;
  -moz-appearance: initial;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:after {
  content: '';
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1.5px solid #6c757d;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:hover:after,
#groove-radio-button.approve-reject-radio-button
  input.form-check-input[type='radio']:checked:after {
  height: 20px;
  width: 20px;
  border: 1.5px solid rgb(161, 0, 255) !important;
}

#groove-radio-button.approve-reject-radio-button
  input.form-check-input[type='radio']:checked::before {
  content: '';
  display: block;
  position: absolute;
  left: 3px;
  top: 3px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: rgb(161, 0, 255);
}

#groove-radio-button.approve-reject-radio-button .form-check > label {
  margin-left: 3px !important;
}

.aggregated-info img.new-info-icon {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

#boi-tooltip-right.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff;
}

.subdimension-header > .row > div.col-lg-1.wVis-false > a,
.subdimension-header > .row > div.col-lg-1.wVis-awapp-new > a {
  position: absolute;
  right: 0.1px;
}

.ApprovalRequestModal {
    overflow-y: visible!important;
}

.ApprovalRequestModal .submit-question {
    color: #2D3A4B;
    font-size: 18px;
    font-family: Graphik-Regular-Web;
    font-weight: normal;
    letter-spacing: -0.315px;
    line-height: 16px;
    margin-bottom: 12px;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal>.notifyModal-buttons.add-program-buttons{
    padding-bottom: 0px !important;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal>.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown)+label{
    font-size: 11px !important;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal>.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder)+label{
    font-size: 11px !important;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal>.comments #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label{
    font-size: 11px !important;
}

.comments #groove-textarea .floating-label-textbox .groove-textbox.ApprovalRequestModal-invalid-comment {
    outline: 1px solid #aa0025 !important;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal #groove-textarea .groove-textbox{
    font-size: 16px !important;
    letter-spacing: -0.35px;
}

.notifyModal-dialog.submit-modal .modal-title.h4 .header24-semibold-midnight{
    color: #2D3A4B;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal #groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown)+label{
    color: #637381 !important;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal #groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder)+label{
    color: #637381 !important;
}

.notifyModal-dialog.submit-modal .ApprovalRequestModal #groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown)+label,
.notifyModal-dialog.submit-modal .ApprovalRequestModal #groove-textarea .floating-label-textbox .groove-textbox:focus+label{
    color: #637381 !important;
}

.CVMSideModal-container-rejected {
    padding: 28px 25px 0px 23px;
    width: 368px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(220, 226, 231);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: fixed;
    display: block;
    grid-template-rows: auto;
    right: 0;
    top: 0 ;
    height: auto;
    z-index: 999!important;
}
.CVMSideModal-close {
    position: absolute;
    right: 25px;
    top: 18px;
    z-index: 9999!important;
    color: #919eab;
    font-weight: 500;
    cursor: pointer;
}

.CVMSideModal-title {
    color: rgb(45, 58, 75);
    font-size: 18px;
    font-family: Graphik-Semibold;
    font-weight: 600;
    text-align: left;
    letter-spacing: -0.28px;
    line-height: 18px;
    margin-bottom: 17px;
}
.CVMSideModal-footer {
    border-top: 1px solid rgb(223, 227, 232);
    border-radius: 0px 0px 16px 16px;
    padding: 16px;
    -ms-flex-pack: end;
        justify-content: flex-end;
    width: 100%;
    background: rgb(249, 250, 251);
}
.footer-position {
    position: absolute;
    bottom: 0;
    right: 0;
}
.rejection-modal-buttons.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end;
    margin: 0;
}
.rejection-modal-buttons button {
    font-family: "Graphik-Medium";
    font-size: 14px;
    padding: 9px 20px;
    margin: 0 0 25px 25px;
}

#comment-textbox-rejected{
    height: 120px !important;
    
}
#groove-textarea .floating-label-textbox #comment-textbox-rejected {
    border-top: 25px solid transparent !important;
    padding: .3rem 16px 15px;
}

/* #groove-textarea .floating-label-textbox #comment-textbox-rejected:focus {
    border-top: 1px solid #a100ff!important;
    padding: 1.3rem 16px 15px;
} */

#groove-textarea .floating-label-textbox .groove-textbox:not(:-moz-placeholder-shown) + label {
    font-family: "Graphik-Medium";
    color: #637381 !important;
    font-size: 11px;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:-ms-input-placeholder) + label {
    font-family: "Graphik-Medium";
    color: #637381 !important;
    font-size: 11px;
}

#groove-textarea .floating-label-textbox .groove-textbox:not(:placeholder-shown) + label {
    font-family: "Graphik-Medium";
    color: #637381 !important;
    font-size: 11px;
}
.CVMSideModal-container-comment {
    padding: 28px 25px 0px 23px;
    width: 368px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(220, 226, 231);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: absolute;
    display: block;
    height: 50%;
    right: 0px;
    z-index: 999!important;
    overflow-y: auto;
}
.CVMSideModal-close {
    position: absolute;
    right: 25px;
    top: 18px;
    z-index: 9999!important;
    color: #919eab;
    font-weight: 500;
    cursor: pointer;
}

.CVMSideModal-title {
    color: rgb(45, 58, 75);
    font-size: 18px;
    font-family: Graphik-Semibold;
    font-weight: 600;
    text-align: left;
    letter-spacing: -0.28px;
    line-height: 18px;
    margin-bottom: 17px;
}
.CVMSideModal-tab-container {
    overflow-y: auto;
    padding: 0;
    margin-top: 16px;
}
.CVMSideModal-tab-container .card {
    border: none;
}
.CVMSideModal-tab-container .card-header .btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}
.CVMSideModal-tab-container .card-header {
    border: none;
    background: #fff;
    padding: 0;
}
.CVMSideModal-tab-container .btn-link.focus, 
.CVMSideModal-tab-container .btn-link:focus, 
.CVMSideModal-tab-container .btn-link:hover {
    text-decoration: none;
}
.CVMSideModal-tab-container .btn-link {
    color: #a100ff;
}
.CVMSideModal-tab-container .btn-link:hover {
    color: #7500c0;
}
.CVMSideModal-tab-container .btn.focus, 
.CVMSideModal-tab-container .btn:focus {
    outline: 0;
    -webkit-box-shadow:none;
            box-shadow:none;
}
.CVMSideModal-tab-container .card-body {
    padding: 0 0 0 1.25rem;
}
.CVMSideModal-tab-container .card-header svg {
    margin-right: 10px;
}

.CVMSideModal-tab-container .collapse.show ~ .card-header svg{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}


/* #groove-textarea .floating-label-textbox #comment-textbox.groove-textbox {
    border: 1px solid #dfe3e8 !important;
    padding: 20px 15px 10px 15px !important;
    color: #212b36;
    font-size: 1rem;
    letter-spacing: -0.35px;
}
.CVMSideModal-container #groove-textarea .floating-label-textbox #comment-textbox.groove-textbox {
    height: 184px;
}
#groove-textarea .floating-label-textbox #comment-textbox.groove-textbox:focus {
    border: 1px solid #a100ff !important;
    padding: 20px 15px 10px 15px !important;
}
.rejection-modal-buttons #groove-btn-id > button {
    line-height: 1.4;
} */

.CVMSideModal-button-container {
    margin-top: 20px;
}
.rvd-commentary-modal {
  max-width: 600px !important;
}

.rvd-commentary-history-modal {
  max-width: 800px !important;
}

.rvd-commentary-history-modal .commentary-comments {
  border: none;
}

.rvd-commentary-history-modal .commentary-comment > div {
  width: 100% !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.rvd-commentary-history-modal .commentary-comments > div {
  padding: 10px 0px 0px;
}

.commentary-history-label {
  margin-bottom: 0px !important;
  background: white !important;
  cursor: default !important;
}

.commentary-boidetails-container .commentary-boi-details {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.commentary-boidetails-container .commentary-boi-details > div {
  width: 100%;
  font-size: 12px;
  padding-right: 20px;
}

.commentary-boi-details > div > div:first-child {
  font-weight: 600;
}

.rvd-commentary-modal .modal-header,
.rvd-commentary-history-modal .modal-header {
  padding: 20px 20px 0px !important;
}

.rvd-commentary-modal .modal-body > div:first-child,
.rvd-commentary-history-modal .modal-body > div:first-child {
  padding: 20px !important;
}

.commentary-add-comments {
  display: -ms-flexbox;
  display: flex;
}

.commentary-add-comments > div {
  padding: 0px 10px;
}

.commentary-add-comments > div:nth-child(3) {
  margin-top: 7px !important;
}

.commentary-add-comments #addComment {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
}

.commentary-add-comments #addComment::-webkit-input-placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment::-moz-placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment:-ms-input-placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment::-ms-input-placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment::placeholder {
  font-size: 12px;
}

.commentary-add-comments #addComment:focus-visible {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: #fff !important;
  border: 1px solid #a100ff;
  outline: none;
}

.commentary-add-comments #addComment.commentary-add-comment-invalid,
.commentary-update-comment #addComment.commentary-add-comment-invalid {
  border: 1px solid #aa0025 !important;
}

.rvd-commentary-modal .modal-title > div,
.rvd-commentary-history-modal .modal-body > div {
  font-size: 20px !important;
}

.commentary-comments {
  min-height: 200px;
  max-height: 300px;
  border-top: 1px solid rgb(223, 227, 232);
  border-bottom: 1px solid rgb(223, 227, 232);
  margin: 9px 0px;
  overflow-y: auto;
}

.commentary-comments > div {
  padding: 10px 0px;
}

.commentary-comments > div > div {
  display: -ms-flexbox;
  display: flex;
}

.commentary-comments > div > div > div > div {
  padding: 0px 5px;
}

.commentary-comments > div > div > div:nth-child(2) {
  padding: 5px 0px;
}

.commentary-comments > div > div > div:nth-child(2) > div:first-child {
  color: rgb(99, 115, 129);
  font-family: "Graphik-Medium-Web";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.commentary-comments > div > div > div:nth-child(2) > div:nth-child(2) {
  color: rgb(99, 115, 129);
  font-family: "Graphik-Regular-Web";
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 14px;
}

.commentary-comment > div {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%);
          box-shadow: 0px 2px 8px 0px rgb(33 43 54 / 10%);
  min-height: 40px;
  padding: 10px;
  width: 93%;
  margin-left: auto;
  margin-right: 5px;
  cursor: not-allowed;
}

.commentary-comment > div > span {
  color: rgb(145, 158, 171);
  font-family: Graphik-Regular-Web;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 16px;
  word-wrap: break-word;
}

.commentary-comment > div > span:nth-child(2) {
  float: right;
  padding-top: 5px;
  color: rgb(161, 0, 255);
  cursor: pointer;
}

.send-disabled {
  color: #bcbcbc;
  cursor: not-allowed;
  pointer-events: none;
}

.commentary-no-comment {
  width: 100%;
  text-align: center;
  font-size: 13px;
  padding: 40px 0px !important;
}

.commentary-no-comment > div {
  display: block !important;
}

.commentary-update-comment #addComment {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px;
  width: 93%;
  margin-left: auto;
  margin-right: 5px;
}

.commentary-update-comment #addComment::-webkit-input-placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment::-moz-placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment:-ms-input-placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment::-ms-input-placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment::placeholder {
  font-size: 12px;
}

.commentary-update-comment #addComment:focus-visible {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: #fff !important;
  border: 1px solid #a100ff;
  outline: none;
}

.update-comments-buttons {
  display: -ms-flexbox;
  display: flex;
  float: right;
}

.update-comments-buttons > div:first-child {
  margin-right: 15px;
}

.comment-edit-header > div {
  font-size: 20px;
  margin-left: 10px;
}

.comment-edit-header > svg {
  padding-top: 5px;
  cursor: pointer;
}

.commentary-boidetails-container .single-select-label{
  width: 40px;
}

.commentary-send-icon{
  cursor: pointer;
}
.projectEntry-container {
  padding: 30px 40px;
  min-height: 840px !important;
}

.projectEntry-container .btn-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 0px;
}

.projectEntry-container .btn-container .project-title-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.btn-container>div {
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  word-break: break-all;
}

.projectEntry-container .title-section {
  margin-bottom: 5px;
}

.projectEntry-container .title-section .fa-times {
  cursor: pointer;
}

.select-project-modal .add-new-program-container {
  overflow-y: visible;
}

.select-project-modal .modal-header {
  padding-bottom: 15px !important;
}

.program-details-column {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3px;
}

.program-details-row {
  border-right: 1px solid #dfe3e8;
}

.program-details-row:last-child {
  border-right: none;
}

.program-details-row.col-lg-4 {
  padding-right: 10px;
  padding-left: 12px;
}

.project-title {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Semibold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 18px;
}

.projectEntry-subcontainer {
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px 40px 50px;
}

.projectEntry-subcontainer .project-title {
  width: 100%;
  word-break: break-word;
}

.projectEntry-container .sub-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.computeAll-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;

}


.sub-title-header {
  margin-right: 0px !important;
  margin-left: 0px !important;
  text-align: center;
}

.projectEntry-container .sub-title .sub-title-header h5 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 24px;
  margin-bottom: 0;
  -ms-flex-item-align: center;
      align-self: center;
}

.reset-all-button,
.compute-all-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  border: #a100ff solid 1px;
  border-radius: 24px;
  cursor: pointer;
  padding: 0;
  height: 36px;
  margin-right: 10px;
  -ms-flex-align: center;
      align-items: center;
}

.reset-all-button-disabled,
.compute-all-button-disabled {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  border: #a100ff solid 1px;
  border-radius: 24px;
  cursor: not-allowed;
  padding: 0;
  height: 36px;
  margin-right: 10px;
  -ms-flex-align: center;
      align-items: center;
}

.compute-all-button button,
.reset-all-button button {
  color: #a100ff !important;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 15px 0 0;
}

.compute-all-button-disabled button,
.reset-all-button-disabled button {
  color: #a100ff !important;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 15px 0 0;
  cursor: not-allowed;
}

.separator-right {
  border-right: 1px solid rgba(209, 213, 219, 1);
}

.reset-all-button img,
.compute-all-button img {
  height: 15px;
  width: 15px;
  position: relative;
  margin-left: 15px;
}

.reset-all-button-disabled img,
.compute-all-button-disabled img {
  height: 15px;
  width: 15px;
  position: relative;
  margin-left: 15px;
}

.reset-all-button:hover,
.compute-all-button:hover {
  background-color: #a100ff;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.reset-all-button:hover button,
.compute-all-button:hover button {
  color: #fff !important;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.compute-all-button:hover img,
.reset-all-button:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.projectEntry-container .sub-title-right span,
.projectEntry-container .sub-title-right label {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  margin-left: 25px;
  cursor: pointer;
}

.reports-boiHistory-container {
  margin: 5px 0 10px 0;
}

.projectEntry-container .file-upload-wrap {
  padding: 19px 20px;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 24px 0px;
}

.projectEntry-container .file-upload-wrap.file-error {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
}

.projectEntry-container .file-upload-wrap.file-success {
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.projectEntry-container .accordion-action-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.subdimension-table {
  margin: 20px 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 0px;
}

.subdimension-header {
  background: rgb(249, 250, 251);
  border-bottom: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
}

.subdimension-header .row {
  padding: 12px 13px 12px 3%;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}

.subdimension-header>.row>div,
.subdimension-body .card-header>.row>div {
  padding: 0 5px;
}

/*css for columns based on status*/

@media (min-width: 992px) {

  .projectEntry-container .subdimension-header .row .col-lg-2,
  .projectEntry-container .subdimension-row.accordion .col-lg-2,
  .projectEntry-container .subdimension-header .row .col-lg-1,
  .projectEntry-container .subdimension-row.accordion .col-lg-1 {
    word-break: break-word;
  }

  .projectEntry-container .subdimension-row.accordion div:nth-child(3),
  .projectEntry-container .subdimension-row.accordion div:nth-child(4),
  .projectEntry-container .subdimension-row.accordion div:nth-child(5),
  .projectEntry-container .subdimension-row.accordion div:nth-child(6),
  .projectEntry-container .subdimension-row.accordion div:nth-child(7),
  .projectEntry-container .subdimension-row.accordion div:nth-child(8) {
    padding-left: unset !important;
  }

  .target-value-padding {
    padding-left: unset !important;
  }
}

.boiComm-awapp {
  left: 30px;
}

.wVis-awapp {
  left: 25px;
}

.projectEntry-subcontainer label.mini-toggle-switch {
  top: 0px;
}

.app-rej-awapp {
  left: 40px;
}

.app-rej-rejected,
.app-rej-rejected-new {
  -ms-flex-item-align: baseline;
      align-self: baseline;
  padding-left: unset;
}

.app-rej-rejected .rvd-rejection-comment #groove-textarea,
.app-rej-rejected-new .rvd-rejection-comment #groove-textarea,
.app-rej-awapp-new .rvd-rejection-comment #groove-textarea {
  width: 80%;
}

.boiComm-rejected {
  right: 23px;
}

.subdimension-section-collapse .subdimension-row .card-header>button {
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.subdimension-row .card-header {
  padding: 20px 30px;
}

.subdimension-row .card-header div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  word-break: break-word;
}

.bodytext14 {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.parameter-title-center {
  margin-left: 185px;
}

.false {
  font-family: Graphik-Medium;
}

.bodytext14-regular {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.fa-icons {
  color: rgb(161, 0, 255);
  padding: 3px;
  font-size: x-large;
  cursor: pointer;
}

.data-entries {
  margin: 15px;
  opacity: 0.5;
}

.new-pde {
  margin-bottom: 40px !important;
}

.report-prd {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 24px;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.report-prd .rvd-link img {
  height: 12.8px;
  width: 16px;
  margin-right: 2px;
}

.report-prd .rvd-link span {
  color: #a100ff;
  cursor: pointer;
}

.new-proj-btn {
  display: inherit;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.subdimension-row .card-header>button .svg-container {
  display: inline;
  margin-right: 5px;
}

.subdimension-row.accordion .card-header {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  border-top: 1px solid rgb(223, 227, 232);
  border-radius: 0px !important;
}

.subdimension-row.accordion:first-child .card-header {
  border-top: none;
  padding-left: 0px;
  padding-right: 0px;
}

.subdimension-row.accordion .card-header .row {
  width: inherit;
  /* margin-left: 0px; */
}

.subdimension-row.accordion .card-header>button {
  width: 3%;
}

.subdimension-section-collapse .card-header>button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: right !important;
      justify-content: right !important;
  width: 100%;
}

.subdimension-section-collapse .card-header>button>.bodytext18-medium-midnight {
  margin-right: 20px;
}

.parameter-field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 10px 0px 50px 0px;
}

.parameter-field-usd {
  margin: 10px 0px 70px 0px;
}

.field-text {
  width: 34%;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 19px;
}

.field-value {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.field-value input {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px 5px;
  margin: 0px 12px 0px 40px;
}

.field-row-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

.subdimension-row.accordion .card {
  border: none;
}

.bodytext14-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.subdimension-row .card-header {
  background: #fff;
}

.subdimension-row .card.error-card {
  background: rgb(255, 245, 247);
  border-radius: 0px;
}

.subdimension-row .card.error-card .card-header {
  background: rgb(255, 245, 247);
  padding-left: 0px;
}

img.comment-icon {
  margin-right: -5px;
}

.file-img {
  width: 14px;
  height: 19px;
  margin-right: 5px;
}

.projectEntry-container .errorbox {
  margin-bottom: 10px;
}

.errorMsg {
  color: rgb(197, 0, 6);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 14px;
}

.file-error-list table {
  width: 100%;
}

.file-error-list table,
.file-error-list th,
.file-error-list td {
  border: 1px solid rgb(223, 227, 232);
  border-collapse: collapse;
}

.file-error-list th,
.file-error-list td {
  padding: 6px;
  text-align: left;
  font-size: 12px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 12px;
}

.file-error-list th {
  font-family: Graphik-Medium-Web;
}

.error-list-table {
  margin-bottom: 24px;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 5px;
}

.fa-times {
  cursor: pointer;
}

.attachment-list-container {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px;
}

.attachment-Item:not(:last-child) {
  border-bottom: 1px solid rgb(223, 227, 232);
  margin-bottom: 15px;
}

.view-Attachment-modal .modal-body>div:first-child {
  padding: 16px 30px;
}

.view-Attachment-modal .title,
.view-Attachment-modal .value {
  font-size: 14px;
  line-height: 14px;
}

.view-Attachment-modal .file-img {
  width: 12px;
  height: 16px;
  margin-right: 5px;
}

.subdimension-row #groove-radio-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.subdimension-row .form-check {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 10px;
}

.subdimension-row .form-check-input {
  margin-top: 0.15rem;
}

/* @media (min-width: 992px){
.subdimension-table .col-lg-2:nth-child(2) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
.subdimension-table .col-lg-2:nth-child(3) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
} */

.subdimension-row.accordion .collapse.show~div.card-header .btn-link>div.svg-container {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.subdimension-row.accordion .collapse.show~div.card-header div.svg-container {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  cursor: pointer;
}

.validate {
  color: red;
  margin-left: 10px;
}

.rdv-view-comment {
  display: -ms-flexbox;
  display: flex;
}

.expandAll-button.active>button,
.collapseAll-button.active>button {
  color: #a100ff;
}

.info-icon {
  display: inline-block;
  margin: 0 10px;
  position: relative;
}

.info-icon .svg-container {
  -webkit-transform: none !important;
          transform: none !important;
  font-size: 0.9em !important;
}

.info-icon>.svg-container>svg {
  width: 11px;
  cursor: pointer;
}

.info-icon.top {
  position: absolute;
  right: -15px !important;
  top: -10px !important;
}

/* 
.ApprovalRequestModal .groove-textbox {
  margin-top: 9px;
} */

.custom-boi {
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px 10px;
  border: none;
  margin-top: 0.25em;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 70px;
  margin-left: 10px;
}

.custom-boi:hover {
  -webkit-box-shadow: 0 0 5px rgb(187 135 255);
          box-shadow: 0 0 5px rgb(187 135 255);
}

.modal-fade-custom-boi {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px;
  height: 1064px;
  width: 1440px;
}

.custom-boi-modal {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modalheader-title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  width: 308px;
}

.modalbody-details {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.2px;
  line-height: 20px;
  width: 63px;
}

.modalbody-desc {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.29px;
  line-height: 20px;
  width: 67px;
}

.modalbody-column {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modal-custom-BOI-container {
  /* position: fixed; */
  /* display: flex; */
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  height: 100%;
  /* width: 20vw; */
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.modal-container.show {
  opacity: 1;
  pointer-events: all;
}

.modal-Custom {
  width: 70vw;
  height: 80vh;
  /* max-width: 100%; */
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* background-color: #c47474; */
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.row-inside {
  display: -ms-flexbox;
  display: flex;
}

.row-outside {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
  display: -ms-flexbox;
  display: flex;
}

.row-outside h3 {
  position: absolute;
  margin: 2rem 3rem;
  font-size: x-large;
}

#close {
  position: absolute;
  margin: 1em 65rem;
  color: gray;
  border: none;
  background-color: white;
}

.col-50 {
  -ms-flex: 25% 1;
      flex: 25% 1;
  margin: 4rem 3rem;
}

.col-50 h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
}

.col-50 p {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.col-25 {
  -ms-flex: 12.5% 1;
      flex: 12.5% 1;
}

/* ==========================================CHANGES=================================== */

.modal-body .custom-boi-container {
  height: 400px;
}

.modal-custom-BOI-container .modal-body {
  padding: 1rem !important;
}

.modal-custom-BOI-container .modal-dialog {
  max-width: 800px;
}

.modal-custom-BOI-container h3 {
  font-size: x-large;
  padding-left: 16px;
}

.custom-boi-container {
  padding: 0 25px !important;
}

.modal-custom-BOI-container h6 {
  margin: 0;
  padding-bottom: 4px;
  /* color: rgb(99, 115, 129); */
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.modal-custom-BOI-container .modal-title.h4 {
  font-family: Graphik-Semibold;
  /* color: rgb(45, 58, 75); */
  color: #000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.42px;
}

.modal-custom-BOI-container p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 16px;
  word-break: break-all;
}

.custom-close-Modal {
  height: 16.25px;
  width: 16.25px;
}

.custom-boi-btn #custom-btn span {
  font-size: 11px;
  color: rgb(80, 35, 143);
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

#groove-btn-id.custom-boi-btn #custom-btn.btn-outline-stat-alternate {
  padding: 5px;
  background: rgb(235, 225, 248);
  border-radius: 16px;
  border: none;
}

.custom-boi-empty {
  padding-bottom: 24px;
}

.custom-boi-param {
  padding-left: 0px !important;
}

.param-char-length-err {
  color: red;
  margin-left: 37px;
  font-size: 13px;
  padding-top: 4px;
  height: 1px;
}

.warning-message-parameter {
  padding-left: 5%;
  width: 30%;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  color: #ffbd00;
}

.rdv-refresh-list {
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
}

.rdv-refresh-list button {
  color: #a100ff !important;
}

.rdv-refresh-list button>span {
  font-size: 16px !important;
}

#rvd-refresh-tooltip-bottom .tooltip-inner {
  max-width: 250px;
  padding: 8px 15px;
  font-size: 12px;
}

/* Rejection Comment CSS */

.rvd-rejection-comment {
  display: block !important;
}

.rvd-rejection-comment #groove-textarea {
  position: absolute;
  margin: 10px 0px;
  top: 20px;
  width: 68%;
}

.rvd-rejection-comment #groove-textarea .groove-textbox {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232) !important;
  border-radius: 4px;
}

.rvd-rejection-comment .floating-label-textbox {
  border-top: 1px solid rgb(223, 227, 232) !important;
  border-radius: 4px;
}

.rvd-rejection-comment div.groove-textbox-custom-label,
.rvd-rejection-comment div.groove-input-custom-label {
  color: #919eab !important;
  font-family: 'Graphik-Medium' !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px !important;
  line-height: 12px;
  margin-top: -9px;
}

.rvd-rejection-comment #groove-textarea .floating-label-textbox .groove-textbox.is-invalid:not(:-moz-placeholder-shown):disabled+label {
  transform: translateY(0);
}

.rvd-rejection-comment #groove-textarea .floating-label-textbox .groove-textbox.is-invalid:not(:-ms-input-placeholder):disabled+label {
  transform: translateY(0);
}

.rvd-rejection-comment #groove-textarea .floating-label-textbox .groove-textbox.is-invalid:not(:placeholder-shown):disabled+label {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

#boi-tooltip-right .tooltip-inner,
#calculation-tooltip .tooltip-inner {
  -webkit-box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  background-color: #fff;
  color: #000;
  padding: 15px 15px;
  min-width: 550px;
  max-width: 650px;
  margin-bottom: 75px;
}

#boi-tooltip-right.bs-tooltip-right .arrow:before,
#calculation-tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #fff !important;
}

.boi-details-wrap h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
  text-align: left;
}

.boi-details-wrap p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 14px;
  word-break: break-word;
  text-align: left;
}

/* View Aggregated BOI Details CSS */

.aggregated-boi-popover {
  width: 285px;
  height: 50px;
  color: #a100ff;
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
}

#aggregated-boi-popover,
#aggregated-boi-popover .popover-body {
  width: 634px;
  height: 651px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 5;
}

.aggregated-boi-popover-header {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding: 5px 14px;
}

.aggregated-boi-popover-header .aggregated-boi-popover-title {
  width: 90%;
  height: 25px;
  color: rgb(109, 114, 120);
  font-size: 18px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.28px;
}

.aggregated-boi-popover-header .aggregated-boi-popover-close-btn {
  width: 10%;
  color: rgb(33, 43, 54);
  font-weight: 500;
  cursor: pointer;
  font-size: 18px;
  padding-left: 40px;
}

.aggregated-boi-accordion-container {
  max-height: 582px;
  overflow-y: auto;
  padding: 2px;
}

.visualization-collapse.accordion .card {
  margin-bottom: 24px;
  border-radius: 5px !important;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

.visualization-collapse.accordion .card:last-child {
  margin-bottom: 0px;
}

.visualization-collapse.configure-client-collapse .card-header {
  padding: 20px 25px;
}

.visualization-collapse.configure-client-collapse .card-body {
  padding: 0px 25px 20px 25px;
}

.dim-tabs>div>div>div>div {
  margin-bottom: -7px !important;
  margin: 0 -15px;
}

.aggregated-boi-detail-tabs button>span:first-child {
  text-transform: none;
  text-transform: initial;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 0 10px !important;
}

.aggregated-boi-detail-tabs button span {
  padding: 0;
  letter-spacing: -0.21px;
  text-align: center;
  word-wrap: break-word;
  display: block;
  max-width: 200px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.aggregated-boi-detail-tabs button>span:nth-child(2) {
  display: none;
}

.aggregated-boi-detail-tabs button {
  border: 3px solid transparent;
}

.aggregated-boi-detail-tabs button>svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.aggregated-boi-detail-tabs button[aria-selected='true'] {
  border-bottom-color: #a100ff;
}

.aggregated-boi-detail-tabs button[aria-selected='false'] span,
.aggregated-boi-detail-tabs button[aria-selected='false'] span span span {
  color: rgb(45, 58, 75);
}

.aggregated-boi-detail-tabs>div>div>span {
  display: none;
}

#aggregated-info-icon {
  margin: 0 10px !important;
}

.aggregated-boi-label-container {
  height: 45px;
  color: rgb(45, 58, 75);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  padding: 15px 15px;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.aggregated-boi-left-label {
  width: 70%;
  border-right: 1px solid #e7eaee;
  padding-right: 5px;
  display: -ms-flexbox;
  display: flex;
}

.aggregated-boi-left-label .aggregated-boi-left-text {
  /* width: 70%; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.aggregated-boi-right-label {
  width: 35%;
  padding-left: 5px;
}

.aggregated-boi-title-label #aggregated-info-icon>.svg-container>svg {
  font-size: 14px !important;
}

#tooltiptext-aggregated {
  top: 20px !important;
  clear: both !important;
  left: 10px !important;
  -webkit-box-shadow: 0 0 2px black;
          box-shadow: 0 0 2px black;
}

div[role='tabpanel']>div.aggregated-tabpanel {
  max-height: 500px;
  overflow-x: hidden;
  padding: 10px;
  overflow-y: auto;
  display: inline;
}

.tooltip-parameter {
  margin-right: 50px;
}

.reporting-period-normal {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 18px;
  background-color: white;
}

.reporting-period-highlight {
  font-family: Graphik-Medium;
  font-size: 14px;
  line-height: 18px;
  background-color: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  color: rgb(197, 0, 6);
}

.rvdboi-new-info .new-info-icon {
  -webkit-transform: scale(1);
          transform: scale(1);
  margin-top: 0px;
}

.rvd-financial .rvdboi-new-info .new-info-icon {
  margin-top: -15px !important;
  position: relative;
}

.aggregated-info.info-icon-wrap {
  margin-top: -14px;
}

.rvd-invalid-realizedval {
  background-color: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  color: rgb(197, 0, 6);
}

#groove-radio-button.approve-reject-radio-button input.form-check-input {
  margin-top: 0;
  height: 20px;
  width: 20px;
  content: '';
  display: inline-block;
  visibility: visible;
  -moz-appearance: initial;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:after {
  content: '';
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1.5px solid #6c757d;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:hover:after,
#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:checked:after {
  height: 20px;
  width: 20px;
  border: 1.5px solid rgb(161, 0, 255) !important;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:checked::before {
  content: '';
  display: block;
  position: absolute;
  left: 3px;
  top: 3px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: rgb(161, 0, 255);
}

#groove-radio-button.approve-reject-radio-button .form-check>label {
  margin-left: 3px !important;
  margin-top: 4px !important;
}

.aggregated-info img.new-info-icon {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

#boi-tooltip-right.bs-tooltip-bottom .arrow:before,
#calculation-tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff;
}

.subdimension-header>.row>div.col-lg-1.wVis-false>a,
.subdimension-header>.row>div.col-lg-1.wVis-awapp-new>a {
  position: absolute;
  right: 0.1px;
}

/* Client Story Modal */

/* to change */

.modal-dialog.client-story-modal.modal-md.modal-dialog-centered {
  max-width: 95vw !important;
}

.client-story-modal {
  width: 100%;
  display: none;
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 10%);
  z-index: 1000;
  min-height: 40vw;
}

.client-story-modal .svg-container {
  float: right;
  width: auto;
  height: auto;
}

.client-story-modal .svg-container .close-modal-btn {
  margin: 20px 25px;
}

/* .client-story-modal .client-story-ack-modal-content {
  min-height: 40vw;
} */

.io-validation {
  pointer-events: none;
}

.io-validation #groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:checked::before {
  background: rgb(145, 158, 171);
}

.io-validation #groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:hover:after,
#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:checked:after {
  height: 20px;
  width: 20px;
  border: 1.5px solid rgb(145, 158, 171) !important;
}

.client-story-attachment {
  text-align: right;
  float: right;
  padding: 5px 15px;
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.client-story-attachment .svg-container.small {
  padding-right: 5px;
  width: 18px;
  height: 18px;
}

.attachment-invalid {
  background: rgb(255, 245, 247);
  border-radius: 8px;
  border: 1.5px solid rgb(213, 0, 47);
  padding: 10px;
  color: rgb(213, 0, 47);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.22px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 10px;
}

.attachment-invalid .svg-container.small>svg {
  color: rgb(213, 0, 47);
}

.attachment-invalid .close-btn-container {
  text-align: right;
}

.reset-all-button.client-story,
.reset-all-button.client-acknowledgement {
  padding-left: 0px;
}

.reset-all-button.client-story.hover,
.reset-all-button.client-acknowledgement.hover {
  background-color: #a100ff;
}

.reset-all-button.client-story.hover span,
.reset-all-button.client-acknowledgement.hover span {
  color: #fff;
}

.reset-all-button.client-story.no-attachment-error,
.reset-all-button.client-story.no-attachment-error span {
  border-color: rgb(213, 0, 47);
  color: rgb(213, 0, 47);
}

.sub-title .button-container {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.sub-title .button-container>.pde-btn {
  margin-right: 10px !important;
  margin-left: 0;
}

.client-story-modal.cs-show {
  display: block;
}

.backdrop-cs-modal {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
}

.newProjectDataEntry-Disclaimer-Message {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  width: 67%;
  height: 88px;
  background: rgb(251, 245, 255);
  border: 1px solid rgb(227, 209, 255);
  border-radius: 8px;
  padding: 40px;
  padding-left: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: rgb(161, 0, 255);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  height: 32px;
  letter-spacing: -0.25px;
  line-height: 16px;
  position: relative;
  left: 109px;
  padding-right: 54px;
}

.disclaimer-text {
  min-width: -webkit-fill-available;
}

.exclamation-icon-disclaimerMessage {
  margin-right: 8px;
  border: 2px solid rgb(117, 0, 192);
  border-radius: 50px;
  height: 30px;
  min-width: 30px;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

@media (min-width: 1919px) {

  .subdimension-header>.row>div.col-sm-3.app-rej-awapp-new,
  .subdimension-header>.row>div.col-sm-1.boiComm-awapp-new,
  .subdimension-header>.row>div.col-sm-1.wVis-awapp-new,
  .subdimension-header>.row>div.col-sm-3.app-rej-awapp,
  .subdimension-header>.row>div.col-sm-1.boiComm-awapp,
  .subdimension-header>.row>div.col-sm-1.wVis-awapp,
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-1.wVis-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp,
  .subdimension-body .card-header>.row>div.col-sm-1.wVis-awapp {
    margin-left: 42px;
  }

  .subdimension-header>.row>div.col-sm-3.app-rej-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp-new {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp {
    margin-left: 33px;
  }
}

.projectEntry-container .subdimension-row.accordion .card-header>.row>div:first-child {
  -ms-flex-align: start;
      align-items: flex-start;
}

.projectEntry-container .subdimension-row.accordion .card-header>.row>div>#groove-toggle .mini-toggle-switch {
  display: -ms-flexbox;
  display: flex;
  position: inherit;
  margin-top: 13px;
}

.subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp-new,
.subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp,
.projectEntry-container .subdimension-body .card-header>button.btn.btn-link {
  -ms-flex-item-align: baseline;
      align-self: baseline;
}

.subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp,
.subdimension-body .card-header>.row>div.col-sm-1.boiComm-false,
.subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp-new,
.subdimension-body .card-header>.row>div.col-sm-1.boiComm-rejected,
.subdimension-body .card-header>.row>div.col-sm-1.boiComm-approved {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.rvd-dimension-title {
  -ms-flex-item-align: center;
      align-self: center;
}

.projectEntry-container .custom-boi {
  margin-top: -0.75em;
}

.zero-target-value {
  border: 1px solid red;
  border-radius: 5px;
  margin-right: 25px;
  height: 20px;
  padding: 0px 5px;
}

.zero-target-value-msg {
  color: red;
  font-size: 13px;
  padding-top: 5px;
  margin-right: 25px;
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.dashboard-visibility-rvd .info-icon {
  display: inline;
  margin: 0;
  margin-left: 5px;
}

/**************************Business Advisor R5.0S1***************************************/
#addeditBA {
  padding-left: 10px;
  position: relative;
  top: -6px;
}

#addeditBA:hover {
  cursor: pointer;
}

.program-details-column.add-edit-ba {
  -ms-flex-align: center !important;
      align-items: center !important;
  left: -10px;
  top: -5px;
  position: relative;
  display: inline-block;
  width: inherit;
}

.bd-ba-info {
  display: -ms-flexbox;
  display: flex;
  padding-left: 10px;
  padding-top: 4px;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
}

.approved-entry-bd-container {
  background: rgb(251, 245, 255);
  border-radius: 8px;
  display: -ms-flexbox;
  display: flex;
}

.ba-required-bd-container {
  background: rgb(255, 245, 247);
  border-radius: 8px;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.ba-warning-msg {
  position: relative;
  padding-left: 30px;
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.22px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
}

.ba-warning-msg>span {
  color: rgb(170, 0, 37);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
  padding-top: 1.5px;
}

.warning-exclamation-icon-container {
  margin-right: 8px;
  border: 2px solid rgb(170, 0, 37);
  border-radius: 50px;
  height: 18px;
  min-width: 9px;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.warning-exclamation-icon-container>div:first-child {
  width: 14px !important;
  height: 14px !important;
  padding-left: 5px;
  /*previously 5.5px, but not working on SIT build*/
  padding-top: 1px;
}

.ba-db-error {
  color: red;
  font-size: 13px;
  font-family: Graphik-Medium-Web;
  padding-top: 5px;
  padding-left: 12px;
}

/***************************Client Value Identified R5.0S1***************************/
.subdimention-tabs {
  display: -ms-flexbox;
  display: flex;
}

.subdimention-tabs>div {
  padding: 12px;
  color: #637381;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  border: 1px solid rgb(196, 205, 213);
  border-radius: 4px 4px 0px 0px;
  border-bottom: unset;
  cursor: pointer;
}

.subdimention-tabs>div.active-tab {
  background-color: rgb(145, 158, 171);
  color: rgb(255, 255, 255);
}

.rvd-financial {
  margin-top: 0;
}

.rvd-financial .subdimension-header {
  background-color: rgb(145, 158, 171);
  border-top-left-radius: unset;
}

.rvd-financial .subdimension-header>.row {
  color: rgb(255, 255, 255);
  text-align: left;
}

.rvd-financial .subdimension-row.accordion .card-header,
.rvd-financial .subdimension-row.accordion .card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.rvd-financial input:disabled {
  background: rgb(249, 250, 251);
  border: 1px solid rgb(223, 227, 232);
  cursor: not-allowed;
}

.rvd-financial .field-value>div:first-child {
  display: -ms-flexbox;
  display: flex;
}

.rvd-financial .field-value>div #groove-input .groove-input.error {
  border: 1px solid red;
}

.rvd-financial .field-value>div #groove-input .groove-input.disable-super-admin {
  cursor: not-allowed;
  background-color: #e8e8e8;
}

.rvd-financial .field-value>div .param-uom {
  margin-left: 0.5rem;
}

.rvd-financial .field-cvi {
  -ms-flex: 1 1;
      flex: 1 1;
}

.rvd-financial .param-char-length-err {
  position: relative;
  top: 0;
  overflow-wrap: break-word;
  width: 290px;
  display: block !important;
}

#calculation-logic {
  min-width: unset;
  width: 250px !important;
}

#calculation-logic * {
  -ms-flex-align: start;
      align-items: flex-start;
}

.calculation-logic .row>div {
  text-align: left !important;
}

.calculation-logic-header {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  background: rgb(249, 250, 251);
  border-radius: 5px 5px 0 0;
  border: 1px solid rgb(231, 234, 238);
  padding: 12px;
}

.calculation-logic-body {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid rgb(231, 234, 238);
  padding: 12px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
}

#calculation-tooltip {
  opacity: 1;
}

#calculation-tooltip .tooltip-inner {
  min-width: 400px;
  max-width: 450px;
}

.value-tooltip,
.value-tooltip:hover {
  font-family: inherit;
  color: inherit;
  text-decoration: none;
}

.rvd-financial .field-value {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-direction: column;
      flex-direction: column;
}

.rvd-financial .field-cvi {
  margin-left: 20px;
}

.rvd-financial .field-value .param-char-length-err,
.rvd-financial .field-value input {
  margin-left: 0;
}

.rvd-financial .app-rej-rejected .rvd-rejection-comment #groove-textarea,
.rvd-financial .app-rej-rejected-new .rvd-rejection-comment #groove-textarea {
  height: 100px !important;
  top: -10px;
  margin: 0;
}

.rvd-financial .field-text.rejected-status {
  width: 25%;
}

.rvd-financial #comment-textbox-rejected {
  height: 80px !important;
}

.rvd-financial>.subdimension-header>.row>div,
.dashboard-visibility-rvd .info-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.col-sm-1.dashboard-visibility-rvd,
.col-sm-2.dashboard-visibility-rvd {
  display: -ms-flexbox;
  display: flex;
}

.dashboard-visibility-rvd .info-icon {
  display: block !important;
  margin-left: 1px !important;
}

.rvd-financial .info-icon {
  display: block !important;
  margin-left: 2px !important;
}

.rvd-financial .cvi-text .info-icon {
  display: inline !important;
}

.cvi-text-h6 {
  padding: 14px 0 0 0 !important;
}

.cvi-text {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

@media (min-width: 1919px) {
  .dashboard-visibility-rvd .info-icon {
    position: absolute;
    margin-left: 5px;
  }

  .rvd-financial .dashboard-visibility-rvd .info-icon {
    position: unset;
  }

  .subdimension-body .card-header>.row>div.col-sm-2.app-rej-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-2.app-rej-awapp {
    margin-left: 35px;
  }
}

@media (min-width: 1919px) and (max-width: 2559px) {
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-approved {
    margin-left: 25px;
  }
}

/*PBI 148727 - RVD*/
.subdimension-row.accordion .approved-project-level {
  border-left: 4px solid rgb(100, 156, 54);
  border-radius: unset;
}

.subdimension-row.accordion .rejected-project-level {
  border-left: 4px solid rgb(170, 0, 37);
  border-radius: unset;
}

.rejected-projBoi-warning {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.projectboi-rejected-alert svg,
.rejected-projBoi-warning svg,
.rejected-projBoi-warning {
  color: rgb(170, 0, 37);
}

.rejected-projBoi-warning svg {
  border: 2px solid rgb(170, 0, 37);
  border-radius: 50px;
  padding: 2px;
  min-width: 18px;
  /*Normal size in SIT but not in DEV/LOCAL*/
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.projectboi-rejected-alert {
  margin-top: 4px;
}

.rvd-financial .projectboi-rejected-alert {
  margin-top: unset;
}

.rvd-financial span+.projectboi-rejected-alert {
  margin-left: 10px;
}

.projectboi-rejected-alert.custom-with-alert {
  margin-top: 2px;
  margin-left: 5px;
}

.rejected-projBoi-warning svg {
  height: 18px;
  /*Normal size in SIT but not in DEV/LOCAL*/
  width: 18px !important;
  /*Normal size in SIT but not in DEV/LOCAL*/
}

.rejected-projBoi-warning>div:nth-child(2) {
  margin-left: 10px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-top: -4px;
}

.invalid-cumulative-cont {
  position: absolute;
  top: 98px;
}

.rvd-disclaimer-msg {
  padding-top: 15px !important;
  padding-bottom: 40px !important;
}

/*----end----*/

/* r10 */
.rvd-cvi-usd-toggle-hide {
  pointer-events: none;
}

.rvd-cvi-usd-toggle-hide>div>svg {
  color: transparent;
}

.rvd-financial .subdimension-row.accordion .card-body {
  padding-left: 2.9%;
}

.cvi-plus-icon-container {
  padding-right: 10px;
}

.cvi-plus-icon-container>.svg-container {
  border: 2px solid #a100ff;
  border-radius: 50%;
  padding-left: 2px;
  padding-top: 1px;
  cursor: pointer;
}



.rvd-accordion-cvi-container .col-year,
.rvd-accordion-cvi-container .col-qtr,
.rvd-accordion-cvi-container .col-month {
  -ms-flex-align: center !important;
      align-items: center !important;
  display: -ms-flexbox;
  display: flex;
}

div[class*='qtr-body-con'],
div[class*='ry-body-con'],
div[class*='fin-usd-main-con'] {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
  /* padding-bottom: 50px; */
}

.fin-usd-body-text {
  opacity: 1;
  color: rgba(99, 115, 129, 1);
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: left;
}

.rvd-financial .subdimension-row.accordion .card-body>.row>div {
  padding: 10px 5px;
  /* padding-bottom: 20px; */
}

.col-sm-1.col-month,
.col-sm-1.col-boi-comment {
  -ms-flex: 0 0 4.33333%;
      flex: 0 0 4.33333%;
  max-width: 4.33333%;
}

.col-sm-1.col-qtr {
  -ms-flex: 0 0 7.33333%;
      flex: 0 0 7.33333%;
  max-width: 7.33333%;
}

.total-per-qtr-con>.col-sm-2.col-qtr {
  -ms-flex: 0 0 11.73%;
      flex: 0 0 11.73%;
  max-width: 11.73%;
  font-size: 12px;
}

.cumulative-number-container>.col-sm-2.col-year,
.total-per-year-con>.col-sm-2.col-year {
  -ms-flex: 0 0 15.73%;
      flex: 0 0 15.73%;
  max-width: 14.73%;
  font-size: 12px;
}

.cumulative-number-container>.col-sm-2.col-year.cumulative-number-label {
  font-size: 14px;
  white-space: nowrap;
}

.rvd-financial .subdimension-row.accordion .expanded-header {
  padding-bottom: 30px;
}

.rvd-financial .subdimension-row.accordion .collapsed-header {
  padding-bottom: 0px;
}

.col-sm-2.col-boi-name {
  -ms-flex: 0 0 22.66667%;
      flex: 0 0 22.66667%;
  max-width: 22.66667%;
}

.subdimension-body .col-target-value {
  -ms-flex: 0 0 7.3%;
      flex: 0 0 7.3%;
  max-width: 7.3%;
}

.field-value.col-vic>div:first-child {
  padding-right: 10px;
}

.col-boi-comment {
  -ms-flex: 0 0 4.33333%;
      flex: 0 0 4.33333%;
  max-width: 4.33333%;
}

.card-body .col-vic,
.card-body .col-cvi {
  text-align: left;
}

.field-value.col-cvi>div:first-child {
  padding-right: 20px;
}

.expanded-header.card-header .col-cvi,
.expanded-header.card-header .col-vic {
  margin-top: -20px;
}

.rvd-financial .card-header .col-vic,
.rvd-financial .card-header .col-cvi {
  width: 100%;
  text-align: left;
  display: inline;
}

.col-cvi #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown):disabled+label, .col-vic #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown):disabled+label {
  color: #919eab !important;
}

.col-cvi #groove-input .floating-label .groove-input:not(:-ms-input-placeholder):disabled+label, .col-vic #groove-input .floating-label .groove-input:not(:-ms-input-placeholder):disabled+label {
  color: #919eab !important;
}

.col-cvi #groove-input .floating-label .groove-input:not(:placeholder-shown):disabled+label,
.col-vic #groove-input .floating-label .groove-input:not(:placeholder-shown):disabled+label {
  color: #919eab !important;
}

.col-sm-1.col-vic.rvd-fin-approver-rejected {
  -ms-flex: 0 0 11.33333%;
      flex: 0 0 11.33333%;
  max-width: 11.33333%;
}

.col-sm-1.target-realized-awapp-new.field-value.field-cv.col-cvi.rvd-fin-approver-rejected {
  -ms-flex: 0 0 15.33333%;
      flex: 0 0 15.33333%;
  max-width: 15.33333%;
}

.col-sm-2.col-boi-name.rvd-fin-approver-rejected {
  -ms-flex: 0 0 15.33333%;
      flex: 0 0 15.33333%;
  max-width: 15.33333%;
}

.col-sm-1.wVis-awapp-new.dashboard-visibility-rvd.col-dashboard.rvd-fin-approver-rejected {
  -ms-flex: 0 0 11.33333%;
      flex: 0 0 11.33333%;
  max-width: 11.33333%;
  padding-left: 25px;
}

.col-sm-1.col-year.rvd-fin-approver-rejected,
.col-sm-1.col-qtr.rvd-fin-approver-rejected {
  text-align: left;
  /* padding-left: 35px; */
}

.card-body .subdimension-header>div {
  /* text-align: center; */
  text-align: left;
}

.subdimension-header .col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected {
  padding-left: 20px;
}

.col-sm-1.wVis-awapp-new.dashboard-visibility-rvd.col-dashboard.rvd-fin-approver-rejected {
  -ms-flex: 0 0 10.33333%;
      flex: 0 0 10.33333%;
  max-width: 10.33333%;
  /* padding-left: 27px; */
}

.subdimension-header .col-sm-1.target-realized-awapp-new.col-cvi.rvd-fin-approver-rejected {
  -ms-flex: 0 0 11.33333%;
      flex: 0 0 11.33333%;
  max-width: 11.33333%;
}

.subdimension-header .col-sm-1.boiComm-awapp-new.col-boi-comment.rvd-fin-approver-rejected {
  /* flex: 0 0 12.33333%; */
  /* max-width: 5.33333%; */
  padding-left: 15px;
}

.param-char-length-err>div {
  display: inline-block !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* display: -webkit-box !important; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.param-char-length-err.error-message-wrap>div {
  white-space: break-spaces;
}

.col-cvi #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown)+label, .col-vic #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown)+label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(80%);
}

.col-cvi #groove-input .floating-label .groove-input:not(:-ms-input-placeholder)+label, .col-vic #groove-input .floating-label .groove-input:not(:-ms-input-placeholder)+label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(80%);
}

.col-cvi #groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
.col-vic #groove-input .floating-label .groove-input:not(:placeholder-shown)+label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(80%);
}

.finUSD-cont #groove-input {
  width: 100%;
}

.finUSD-cont div.label16-regular-silver>div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  display: block;
}

.finUSD-cont #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown)+label, .finUSD-cont #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown):focus+label {
  color: #a100ff !important;
}

.finUSD-cont #groove-input .floating-label .groove-input:not(:-ms-input-placeholder)+label, .finUSD-cont #groove-input .floating-label .groove-input:not(:-ms-input-placeholder):focus+label {
  color: #a100ff !important;
}

.finUSD-cont #groove-input .floating-label .groove-input:focus+label,
.finUSD-cont #groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
.finUSD-cont #groove-input .floating-label .groove-input:not(:placeholder-shown):focus+label {
  color: #a100ff !important;
}

.col-sm-3.non-usd,
.col-sm-2.non-usd,
.col-sm-1.non-usd {
  text-align: left;
}

.col-sm-1.non-usd-boi {
  text-align: left;
  margin-left: 0.5rem;
  margin-right: -1rem;
}

.fin-usd-parameter {
  position: absolute;
  display: block;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter {
  display: block;
  width: 100%;

  max-height: 34px;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-line-pack: center;
      align-content: center;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter>div.fin-usd-param-body {
  font-size: 13px;
  top: -5px;
  max-height: 150px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 1;
  padding-right: 5px;
  font-size: 14px;
  letter-spacing: -.22px;
  color: #212b36;
  font-family: Graphik-Regular-Web;
  font-weight: 400;
  line-height: 18px;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter>div.fin-usd-param-body .fin-usd-param-label {
  color: #637381;
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -.22px;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter>div.fin-usd-param-body.qtr-collapsed {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
  margin-bottom: 6px;
  line-height: 115%;
  /* padding-bottom: 0px; */
  max-height: 90px;
  top: -15px;
  padding: 0 5px 0 0;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter>div.fin-usd-param-body.yr-collapsed {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  margin-bottom: 6px;
  line-height: 115%;
  /* padding-bottom: 0px; */
  top: -15px;
  padding: 0 5px 0 0;
  line-height: 17px;
}


.fin-usd-body-container>.field-text {
  width: 100%;
}

@media (min-width: 1919px) and (max-width: 2561px) {

  /*67% browser*/
  .subdimension-header>.row>div.col-sm-3.app-rej-awapp-new,
  .subdimension-header>.row>div.col-sm-1.boiComm-awapp-new,
  .subdimension-header>.row>div.col-sm-1.wVis-awapp-new,
  .subdimension-header>.row>div.col-sm-3.app-rej-awapp,
  .subdimension-header>.row>div.col-sm-1.boiComm-awapp,
  .subdimension-header>.row>div.col-sm-1.wVis-awapp,
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-1.wVis-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp,
  .subdimension-body .card-header>.row>div.col-sm-1.wVis-awapp {
    margin-left: 0px;
  }

  .subdimension-body .card-header>.row>div.col-sm-2.app-rej-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-2.app-rej-awapp {
    margin-left: 0px;
  }
}

.col-sm-1.boiComm-false.non-usd-boi,
.col-sm-1.boiComm-approved.non-usd-boi {
  padding-left: 5px;
}

.col-sm-2.pde-comment-rejected.non-usd {
  padding-left: 18px;
}

.col-sm-2.pde-comment-rejected-new.non-usd {
  padding-left: 20px;
}

.col-sm-2.app-rej-awapp.non-usd,
.col-sm-2.app-rej-awapp-new.non-usd {
  padding-left: 21px;
}

/*Bug fix column headers & body - B215980*/

/* (Fin>NON-USD) */
.col-sm-2.pde-comment-rejected-new.fin-non-usd,
.col-sm-2.pde-comment-rejected-new.non-usd {
  margin-left: 0px;
}

@media (min-width: 639px) and (max-width: 1921px) {
  .col-sm-1.boiComm-rejected.fin-non-usd {
    margin-left: 35px;
  }

  .app-rej-awapp>.rvd-rejection-comment {
    /*Awaiting approval - rej*/
    margin-left: 0px;
  }
}

@media (min-width: 1919px) and (max-width: 2561px) {

  /*67% browser*/
  .col-sm-1.wVis-awapp-new.dashboard-visibility-rvd.col-dashboard.rvd-fin-approver-rejected {
    margin-left: 8px;
  }

  .col-sm-1.boiComm-awapp.fin-non-usd,
  .col-sm-1.wVis-awapp.dashboard-visibility-rvd.fin-non-usd,
  .col-sm-3.app-rej-awapp.fin-non-usd.heading,
  .col-sm-1.boiComm-awapp.fin-non-usd,
  .col-sm-1.wVis-awapp.fin-non-usd,
  .col-sm-3.app-rej-awapp.fin-non-usd,
  .col-sm-1.wVis-awapp-new.dashboard-visibility-rvd.fin-non-usd,
  .col-sm-1.boiComm-awapp-new.fin-non-usd,
  .col-sm-1.wVis-awapp-new.fin-non-usd,
  .col-sm-2.app-rej-awapp-new.fin-non-usd,
  .col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected.heading {
    margin-left: 0px !important;
  }
}

/* End of FIN>NON-USD */

/*FINANCIAL > USD : Approver/Rejeceted View*/
.col-sm-1.col-year.rvd-fin-approver-rejected,
.col-sm-1.col-qtr.rvd-fin-approver-rejected,
.col-sm-1.col-year.rvd-fin-approver-rejected.bodytext14,
.col-sm-1.col-qtr.rvd-fin-approver-rejected.bodytext14 {
  -ms-flex: 0 0 7.33333%;
      flex: 0 0 7.33333%;
  max-width: 7.33333%;
}

.col-sm-1.col-month.rvd-fin-approver-rejected {
  -ms-flex: 0 0 4.33333%;
      flex: 0 0 4.33333%;
  max-width: 4.33333%;
}

.col-sm-1.target-realized-rejected-new.field-value.field-cv.col-cvi.rvd-fin-approver-rejected,
.col-sm-1.target-realized-rejected.field-value.field-cv.col-cvi.rvd-fin-approver-rejected,
.col-sm-1.target-realized-awapp.field-value.field-cv.col-cvi.rvd-fin-approver-rejected {
  -ms-flex: 0 0 14.33333%;
      flex: 0 0 14.33333%;
  max-width: 14.33333%;
}

.col-sm-1.target-realized-rejected-new.col-cvi.rvd-fin-approver-rejected,
.col-sm-1.target-realized-rejected.col-cvi.rvd-fin-approver-rejected,
.col-sm-1.target-realized-awapp.col-cvi.rvd-fin-approver-rejected {
  -ms-flex: 0 0 12.33333%;
      flex: 0 0 12.33333%;
  max-width: 12.33333%;
}

.col-sm-2.app-rej-awapp.col-app-rej.rvd-fin-approver-rejected.heading {
  margin-left: 15px;
}

.col-sm-1.boiComm-rejected-new.col-boi-comment.rvd-fin-approver-rejected.heading,
.col-sm-1.boiComm-awapp.col-boi-comment.rvd-fin-approver-rejected.heading {
  margin-left: 5px;
}

.col-sm-1.boiComm-rejected.col-boi-comment.rvd-fin-approver-rejected.heading {
  margin-left: 28px;
}

.col-sm-1.wVis-awapp.dashboard-visibility-rvd.col-dashboard.rvd-fin-approver-rejected {
  margin-left: -15px;
}

.col-sm-1.boiComm-awapp.col-boi-comment.rvd-fin-approver-rejected {
  margin-left: -20px;
}

.col-sm-1.wVis-awapp.col-dashboard.rvd-fin-approver-rejected {
  margin-left: -15px
}

.col-sm-2.pde-comment-rejected-new,
.col-sm-2.app-rej-awapp.col-app-rej.rvd-fin-approver-rejected {
  margin-left: 25px;
}

.col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.open.body,
.col-sm-1.wVis-rejected.col-dashboard.rvd-fin-approver-rejected.rej.open.body {
  margin-left: -10px !important;
}

.col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.body {
  margin-left: 63px !important;
}

.col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected {
  margin-left: 22px !important;
}

.col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.open.body {
  margin-left: -13px !important;
}

.col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.body,
.col-sm-1.wVis-rejected.col-dashboard.rvd-fin-approver-rejected.rej.body {
  margin-left: 10px;
}

.col-sm-1.boiComm-rejected.col-boi-comment.rvd-fin-approver-rejected {
  margin-left: 20px;
}

@media (min-width: 1919px) and (max-width: 2561px) {

  /*67% browser*/
  .col-sm-1.boiComm-awapp.col-boi-comment.rvd-fin-approver-rejected {
    margin-left: -28px !important;
  }

  .col-sm-1.boiComm-awapp.col-boi-comment.rvd-fin-approver-rejected.heading {
    margin-left: 10px !important;
  }

  .col-sm-2.app-rej-awapp.col-app-rej.rvd-fin-approver-rejected.heading {
    margin-left: 3px;
  }

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.body {
    margin-left: 105px !important;
  }

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.open.body {
    margin-left: -8px !important;
  }

  .col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected {
    margin-left: 28px !important;
  }

  .col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected.heading {
    margin-left: 22px !important;
  }

  .col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.body {
    margin-left: 20px;
  }

}

@media (min-width: 1706px) and (max-width: 1918px) {
  /* 75% Browser*/

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.open.body {
    margin-left: -20px !important;
  }

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.body {
    margin-left: 75px !important;
  }

  .col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.body {
    margin-left: 16px;
  }
}

@media (min-width: 1599px) and (max-width: 1705px) {

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.open.body {
    margin-left: -20px !important;
  }

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.body {
    margin-left: 75px !important;
  }

  .col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.body {
    margin-left: 14px;
  }
}


/*BUG 218879 & 218825 */

.fin-dimension.card-body {
  padding-top: 20px;
}

.param-fin-error.card-body {
  padding-top: 30px;
}

.invalid-cumulative-cont.error-adj {
  top: 110px;
}

.param-char-length-err.fin-usd {
  max-width: 110px !important;
}

.rvd-fin-creator-view>div.param-char-length-err.fin-usd {
  max-width: 170px !important;
}

/*END of FINANCIAL>USD Column CSS*/

/* r10 end */

/*R11 Start*/
.clientApprover-field {
  width: -webkit-fill-available;
  margin-bottom: 0px !important;
}

.client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
.client-approver-role div#groove-select-dropdown,
.client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
  height: 25px;
  width: 248px;
}

.client-approver-role>#groove-select-dropdown div.selectmenu-options .dropdown-list {
  width: 100%;
}

.client-approver-role>#groove-select-dropdown div.selectmenu-options .dropdown-list>div>div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.client-approver-role .empty-err,
.client-approver-role .empty-err#groove-select-dropdown div#selectmenu-anchor,
.client-approver-role .empty-err#groove-select-dropdown div#selectmenu-anchor:hover {
  border-color: red !important;
  background: rgb(255, 245, 247) !important;
}

.client-approver-role #groove-select-dropdown div#selectmenu-anchor .display-field.field-label,
.client-approver-role label {
  display: none;
}

.client-approver-role div#selectmenu-dropdown-icon {
  top: -15px;
  margin: 0px;
}

.client-approver-role #groove-select-dropdown svg {
  color: #A100FF;
}

.client-approver-role #groove-select-dropdown .selectmenu-selected-value,
.client-approver-role #groove-select-dropdown .default-display {
  margin-top: -2px;
}

.client-approver-role #groove-select-dropdown,
.client-approver-role #groove-input,
.client-approver-role input#client-approver-role-textbox,
.client-approver-role input#client-approver-role-textbox:focus,
.client-approver-role .form-control.groove-input.empty-err:focus {
  height: 25px !important;
}

.client-approver-role .groove-input-custom-label-disabled div.groove-input-custom-label,
.client-approver-role div.groove-input-custom-label {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

.client-approver-role #groove-input .floating-label .groove-input:not(:-moz-placeholder-shown) {
  padding: 0 0 0 15px !important;
}

.client-approver-role #groove-input .floating-label .groove-input:not(:-ms-input-placeholder) {
  padding: 0 0 0 15px !important;
}

.client-approver-role #groove-input .floating-label .groove-input:not(:placeholder-shown),
.client-approver-role #groove-input .floating-label .groove-input:focus {
  padding: 0 0 0 15px !important;
}

.program-details-column .error-message {
  position: absolute;
  top: 100px;
  font-size: 12px;
  font-family: Graphik-medium;
}

.client-approver-role #groove-select-dropdown div#selectmenu-anchor,
.client-approver-role #groove-select-dropdown div#selectmenu-anchor:hover {
  border: none !important;
}

.client-approver-role #groove-select-dropdown #selectmenu-dropdown-icon {
  margin-right: 0px !important;
}

/* .client-approver-role #groove-select-dropdown div#selectmenu-anchor-icon:disabled{
  color: #A100FF;
} */
.client-approver-role .empty-err#groove-select-dropdown div#selectmenu-anchor,
.client-approver-role .empty-err#groove-select-dropdown div#selectmenu-anchor:hover {
  border: 1px solid red !important;
}

.client-approver-role #groove-select-dropdown div.selectmenu-options.display-list .dropdown-item {
  padding: 8px 10px 8px 8px
}

.client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] svg,
.client-approver-role>#groove-input input#client-approver-role-textbox[disabled] {
  opacity: 0.5;
}

.cumulative-popup {
  z-index: 1;
}

/* Invalid dropdown styles */
#groove-select-dropdown.program-creator-invalid .form-control,
#groove-select-dropdown.program-creator-invalid .form-control#selectmenu-anchor:hover {
  border: 1px solid red !important;
}

/* .program-details-column.car > div {
  width: 100%;
} */

.program-details-column.car .title {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

/* client approver role responsiveness */
/* 300% */
@media (min-width: 511px) and (max-width: 614px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 125px;
  }
}

/* 250% */
@media (min-width: 613px) and (max-width: 766px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 225px;
  }
}

/* 200% */
@media (min-width: 767px) and (max-width: 877px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 380px;
  }
}

/* 175% */
@media (min-width: 876px) and (max-width: 1024px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 485px;
  }
}

/* 150% check font*/
@media (min-width: 1023px) and (max-width: 1228px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 80px;
  }

  .client-approver-role #groove-select-dropdown .selectmenu-selected-value.selected {
    width: 20px;
  }
}

/* 125% */
@media (min-width: 1227px) and (max-width: 1396px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 145px;
  }
}

/* 110% */
@media (min-width: 1395px) and (max-width: 1535px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 205px;
  }
}

/* 90% */
@media (min-width: 1705px) and (max-width: 1919px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 305px;
  }
}

/* 80% */
@media (min-width: 1920px) and (max-width: 2047px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 370px;
  }
}

/* 75% */
@media (min-width: 2048px) and (max-width: 2303px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 415px;
  }
}

/* 67% */
@media (min-width: 2304px) and (max-width: 3071px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 500px;
  }
}

/* 50% */
@media (min-width: 3072px) and (max-width: 4607px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 760px;
  }
}

/* 33% */
@media (min-width: 4608px) and (max-width: 6143px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 1265px;
  }
}

/* 25% <= */
@media (min-width: 6144px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 1775px;
  }
}

/*R11 End*/
.client-story-recentFile {
  width: 100%;
  height: 56px;
  background: rgb(244, 251, 239);
  border: 1px solid rgb(126, 195, 68);
  border-radius: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
}

.download-clientstory-label {
  width: 53px;
  height: 12px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.download-clientstory-button {
  width: 100px;
  height: 32px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(181, 192, 202);
  border-radius: 16px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  /* line-height: 12px; */
  margin: 0 5px;
}

.clientstory-button-text {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.22px;
}

.clientstory-download {
  /* float: right;
  position: relative;
  padding: 1%; */
  width: 30%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding-right: 20px;
}

.download-icon {
  color: #b5c0ca;
  height: 16px;
  /* position: relative; */
  /* float: left; */
  /* margin-top: 4px; */
  padding-right: 6px;
  /* margin-left: 8px; */
}

.client-story-filename {
  padding: 0 0px 0 25px;
  height: 18px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.22px;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.download-icon-label {
  color: #b5c0ca;
  height: 16px;
  position: relative;
  float: left;
  padding-right: 11px;
  -webkit-filter: invert(70%) sepia(78%) saturate(368%) hue-rotate(44deg) brightness(87%) contrast(87%);
          filter: invert(70%) sepia(78%) saturate(368%) hue-rotate(44deg) brightness(87%) contrast(87%);
}
.rvd-client-story-acknowledgement-modal .project-detail-tabs button[aria-selected='false'] span {
    color: rgb(99, 115, 129);
  }
  
  .upload-container {
    text-align: center;
  }
  
  /* .file-history-container {
    min-height: 400px;
  } */
  
  .file-history-container .svg-container {
    float: none;
    width: 20px;
    height: 20px;
    font-size: 1em;
  }
  
  .file-history-column{
    padding-right: 25px;
  }
  
  .file-history-column.select-boi-modal-column.boi-name {
    padding-left: 5px;
  }
  
  .select-boi-header .file-history-column:first-child {
    padding-right: 0;
  } 
  
  .file-history-column .select-boi-body {
    height: 350px;
  }
  
  .file-history-column:first-child {
    width: 3%;
  }
  
  .file-history-column:nth-child(2) {
    width: 37%;
  }
  
  .file-history-column:nth-child(3) {
    width: 20%;
  }
  
  .file-history-column:nth-child(4) {
    width: 20%;
  }
  
  .file-history-column:nth-child(5) {
    width: 20%;
  }
  
  .file-history-column:last-child {
    padding-right: 0;
  }
  
  .file-name {
    /* padding-left: 15px; */
    overflow: hidden;
  }
  
  .file-name>.svg-container>svg {
    -webkit-filter: invert(50%) sepia(1%) saturate(1655%) hue-rotate(176deg) brightness(111%) contrast(85%);
            filter: invert(50%) sepia(1%) saturate(1655%) hue-rotate(176deg) brightness(111%) contrast(85%);
  }
  
  .file-download {
    /* filter: brightness(1) sepia(3) hue-rotate(205deg) saturate(42); */
    float: right;
    padding-right: 10px;
    cursor: pointer;
  }
  
  .download-history-btn>button>span {
    padding-top: 2px;
  }
  
  .download-history-btn>button>div>svg {
    margin-top: 4px;
  }
  
  .client-story-modal .client-story-ack-modal-container {
    margin: 0px;
  }
  
  .client-story-modal .client-story-ack-modal-container .react-tabs__tab-list {
    border-bottom: 1px solid rgb(231, 234, 238);
    padding: 10px 20px 0 20px;
  }
  
  .client-story-modal .client-story-ack-modal-container div[datatest-id='uploadFile'], .client-story-modal .client-story-ack-modal-container div[datatest-id='fileHistory'] {
    padding: 20px;
  }
  
  .file-history-container > .empty-program-container {
    padding-top: 65px;
  }
  
  .modal-dialog.file-history-modal {
    max-width: 85% !important;
  }
  
  .modal-dialog.file-history-modal .modal-header {
    padding: 15px 20px !important;
    border-bottom: 1px solid rgba(231,234,238, 1) !important;
  }
  
  .modal-dialog.file-history-modal .modal-header .header24-semibold-midnight{
    opacity: 1;
    color: rgba(99,115,129,1);
    font-family: "Graphik-Medium";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.25px;
    text-align: left;
    line-height: 14px;
  }
  
  .modal-dialog.file-history-modal .select-boi-modal{
    padding: 0px 20px;
  }
  
  .file-history-column .download-history-btn {
    position: relative;
    margin-left: auto;
    left: 25px;
  }

  .file-history-container .select-boi-body-row:nth-child(even) {
    background: rgb(255, 255, 255);
  }
  
  .file-history-container .select-boi-body-row:nth-child(odd) {
    background: rgb(249, 250, 251);
  }
  
  .file-history-container .select-boi-body-row:first-child {
    border-radius: 5px 5px 0px 0px;
    border: 1px solid rgb(231, 234, 238);
  }
  
  .file-history-container .select-boi-body-row:last-child {
    border-radius: 0px 0px 5px 5px;
  }
  
  .file-history-container .select-boi-header{
    background: rgb(255, 255, 255);
    border-radius: 0;
    border: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding: 10px 25px;
  }
  
  .file-history-container .cvm-table-pagination-container{
    background: none;
    border: none;
    padding: 15px 0px;
  }
  
  .file-history-container .cvm-table-pagination-container .page-size {
    display: none;
  }
.upload-container {
  text-align: center;
  min-height: 400px;
}

.upload-container .uploaded-files-container {
  margin-bottom: 0;
}

.uploaded-files-container .cs-uploaded-files {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
}

.cs-uploaded-files>div {
  width: 100%;
}

.cs-uploaded-files>.uploaded-files-icon {
  padding-left: 25px;
  padding-bottom: 5px;
  padding-right: 10px;
}

.uploaded-files-container .cs-uploaded-files .uploaded-files-close {
  padding: 0 20px;
}

.cs-uploaded-files .progress {
  height: 8px;
}

.cs-uploaded-files .progress .progress-bar {
  background: rgb(156, 106, 222);
}

.cs-uploaded-files .progress-bar-animated {
  -webkit-animation: progress-bar-stripes 0.05s linear infinite;
          animation: progress-bar-stripes 0.05s linear infinite;
  -webkit-transition-duration: 1ms;
          transition-duration: 1ms;
}

.progress-label {
  color: rgb(109, 114, 120);
  font-family: Graphik-Regular;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.16px;
  width: 20%;
  margin: 0;
  text-align: left;
  padding: 0 10px;
}

.upload-container .invalid-reason {
  font-family: Graphik-Regular;
  text-align: left;
}

.download-template-header-btn {
  width: 185px;
  height: 14px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding-left: 9px;
  cursor: pointer;
}

.download-template-header img {
  width: 12px;
  height: 16px;
}

.cs-file-name {
  font-family: Graphik-Regular;
}

.upload-container .cvm-dropzone.enable-dz:hover {
  cursor: pointer;
}

.upload-container .uploaded-files-name {
  font-family: Graphik-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.recently-uploaded-container {
  min-height: 400px;
}

.recently-uploaded-label {
  width: 145px;
  height: 24px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.31px;
  line-height: 24px;
  margin-bottom: 10px;
}

.cvm-dropzone.disable-dz:hover {
  cursor: not-allowed;
}

div[role='tabpanel']>div.tab-component-content {
  left: 0;
}

/* CLIENT STORY AND CLIENT ALIGNMENT CSS */
.client-upload-collapse-container{
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: 25px;
}

.download-template-header{
  margin-left: auto;
  margin-right: 20px;
}

/*new changes related to client story start*/
.client-story-btn-parent{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin: 16px 8px 0px 0px;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px; 
}
.client-story-btn-parent .download-client-story-disabled-btn-container,
.client-story-btn-parent .download-client-story-btn-container{
    height: 32px;
    width: 177px;
    background-color: white;
    color: rgb(161, 0, 255);
    border: 2px solid rgb(161, 0, 255);
    border-radius: 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    cursor: pointer;
}

.client-story-btn-parent .download-client-story-btn-container:hover .fileDownloadWhite,
.client-story-btn-parent .download-client-story-disabled-btn-container .fileDownload,
.client-story-btn-parent .download-client-story-btn-container .fileDownload{
  height: 16px;
  width: 12px;
}
.client-story-btn-parent .download-client-story-disabled-btn-container .download-template-header-btn,
.client-story-btn-parent .download-client-story-btn-container .download-template-header-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 12px;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding-left: 10px;
  cursor: pointer;
}
.client-story-btn-parent .download-client-story-disabled-btn-container .fileDownload,
.client-story-btn-parent .download-client-story-disabled-btn-container .download-template-header-btn,
.client-story-btn-parent .download-client-story-disabled-btn-container{
  opacity: 0.65;
  cursor: not-allowed;
}
.client-story-btn-parent .download-client-story-btn-container:hover{
  background-color:rgb(161, 0, 255);
}
.client-story-btn-parent .download-client-story-btn-container:hover .fileDownloadWhite{
 display: block;
}
.client-story-btn-parent .download-client-story-btn-container:hover .fileDownload{
  display: none;
 }
 .client-story-btn-parent .download-client-story-btn-container .fileDownload{
  display: block;
 }
 .client-story-btn-parent .download-client-story-disabled-btn-container .fileDownloadWhite,
 .client-story-btn-parent .download-client-story-btn-container .fileDownloadWhite{
  display: none;
 }
.client-story-btn-parent .download-client-story-btn-container:hover .download-template-header-btn{
  color: white;
}
.client-story-editor-wrapper-class{
  border-radius: 8px;
  border: 1px solid #ced4da;
  background-color: rgba(255,255,255, 1);
  height: 160px;
  width: 100%;
  overflow: hidden;
}
.client-story-editor-error-wrapper-class{
  border-color: #d5002f;
}
.clientStory-container .__react_component_tooltip.place-top::before{
  height: 6px !important;
}
.client-story-toolbar-class{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 8px;
}
.client-story-toolbar-class .rdw-inline-wrapper,
.client-story-toolbar-class .rdw-list-wrapper,
.client-story-toolbar-class .rdw-text-align-wrapper{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex: 1 1;
      flex: 1 1;
}
.client-story-editor-class{
  padding: 15px;
  overflow-y: scroll;
  height: 120px;
}
.client-story-editor-class span{
  font-family: auto;
  font-weight: inherit;
  word-break: break-all;
}
.client-story-editor-class .public-DraftEditorPlaceholder-inner{
  color: grey;
  font-size: 14px;
}
/*new changes related to client story end*/

.download-template-header-btn {
  width: 185px;
  height: 14px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding-left: 9px;
  cursor: pointer;
}

.download-template-header img {
  width: 12px;
  height: 12px;
}

.with-additional-header-buttons .card-header {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.with-additional-header-buttons .btn-link {
  width: 25px !important;
}

.with-additional-header-buttons #basicDetailsExpand{
  margin-left: auto;
}

.with-additional-header-buttons .client-attachment span {
  color: rgba(99,115,129,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 14px;
  -ms-flex-item-align: center;
      align-self: center;
}

.with-additional-header-buttons .client-attachment {
  padding-left: 20px;
}

.with-additional-header-buttons .client-upload-ico{
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.with-additional-header-buttons .reset-all-button {
  padding: 3px 5px 3px 10px !important;
  -ms-flex-align: center;
      align-items: center;
}

.client-upload-btn {
  -ms-flex-direction: row;
      flex-direction: row;
  border: 1px solid #a100ff;
  border-radius: 50px;
  color: #a100ff!important;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  font-family: "Graphik-Medium";
  font-size: 12px;
  padding: 3px 20px;
}

.client-upload-btn:hover {
  background-color: #a100ff;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  color: #fff!important;
}

.client-upload-btn:hover img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.with-additional-header-buttons .client-dropzone-button{
  border-right: 1px solid rgba(209,213,219, 1);
  padding-right: 20px;
}

.with-additional-header-buttons #configCardID {
  padding-bottom: 0px !important;
}

.with-additional-header-buttons .card-body{
  padding: 12px 20px;
  border-top: 1px solid rgb(223, 227, 232);
  background-color: rgba(249,250,251, 1);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.with-additional-header-buttons .empty-program-container span {
  opacity: 1;
  color: rgba(99,115,129,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: left;
  margin: 0px;
}

.with-additional-header-buttons .empty-program-container {
  padding: 0;
  -ms-flex-align: initial;
      align-items: initial;
}

.with-additional-header-buttons .cs-uploaded-files .progress, 
.with-additional-header-buttons .uploaded-files-close,
.with-additional-header-buttons .cs-uploaded-files>.uploaded-files-icon {
  -ms-flex-item-align: center;
      align-self: center;
}

.with-additional-header-buttons .uploaded-files-name {
  -ms-flex-item-align: center;
      align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.with-additional-header-buttons .cs-uploaded-files>.uploaded-files-icon {
  padding: 0 20px 5px 0;
}

.with-additional-header-buttons .cs-uploaded-files{
  display: -ms-flexbox;
  display: flex;
}

.with-additional-header-buttons .client-story-recentFile {
  background: none;
  border: none;
  border-radius: 0px;
  height: auto;
}

.with-additional-header-buttons .client-story-filename {
  padding: 0px;
  opacity: 1;
  color: rgba(99,115,129,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: left;
}

.with-additional-header-buttons .download-icon-label {
  -webkit-filter: none;
          filter: none;
}

.with-additional-header-buttons .download-clientstory-button {
  width: auto;
  height: auto;
  background: none;
  border: none;
  opacity: 1;
  color: rgba(161,0,255,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 14px;
  cursor: pointer;
}

.client-invalid-reason {
  opacity: 1;
  color: rgba(213,0,47,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: left;
  line-height: 20px;
}

.with-additional-header-buttons .configure-error {
  border: 1px solid rgba(213,0,47, 1) !important;
}

.with-additional-header-buttons .collapse.show ~ div.card-header .client-attachment div.svg-container {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.with-additional-header-buttons .client-upload-btn.disabled-upload,
.with-additional-header-buttons .download-template-header-btn.disabled-download {
  cursor: not-allowed;
  color: #a100ffbf !important;
  background-color: transparent;
  border-color: #a100ffbf !important;
}

.with-additional-header-buttons .client-upload-btn.disabled-upload:hover img,
.with-additional-header-buttons .client-upload-btn.disabled-upload img,
.with-additional-header-buttons .download-template-header.disabled-download img  {
  -webkit-filter: opacity(.7);
          filter: opacity(.7);
}

/*client story text area functionality*/
clientStory-container #groove-textarea label {
  display: none !important;
}

.clientStory-container #groove-textarea .floating-label-textbox .configure-error:not(:-moz-placeholder-shown){
  border-top: 1px solid rgba(213,0,47, 1) !important;
}

.clientStory-container #groove-textarea .floating-label-textbox .configure-error:not(:-ms-input-placeholder){
  border-top: 1px solid rgba(213,0,47, 1) !important;
}

.clientStory-container #groove-textarea .floating-label-textbox .configure-error:focus,
.clientStory-container #groove-textarea .floating-label-textbox .configure-error:not(:placeholder-shown){
  border-top: 1px solid rgba(213,0,47, 1) !important;
}

.clientStory-container #groove-textarea .floating-label-textbox textarea:not(:-moz-placeholder-shown){
  border-top: 1px solid #ced4da !important;
}

.clientStory-container #groove-textarea .floating-label-textbox textarea:not(:-ms-input-placeholder){
  border-top: 1px solid #ced4da !important;
}

.clientStory-container #groove-textarea .floating-label-textbox textarea:focus,
.clientStory-container #groove-textarea .floating-label-textbox textarea:not(:placeholder-shown){
  border-top: 1px solid #ced4da !important;
}

.clientStory-container #groove-textarea .hidden-feedback {
  visibility: visible;
  font-size: 12px;
  font-family: 'Graphik-medium';
  color: red;
}

.clientStory-textArea {
  border-radius: 8px !important;
  border: 1px solid #ced4da !important;
  background-color: rgba(255,255,255, 1) !important;
  text-align: justify;
  padding: 15px !important;
}

.clientStory-container div.groove-textbox-custom-label {
  opacity: 1;
  color: rgba(145,158,171,1) !important;
  font-family: "Graphik-Regular" !important;
  font-size: 12px !important;
  font-style: normal;
  letter-spacing: -0.26px !important;
  text-align: left;
  line-height: 16px;
}

.clientStory-container > div {
  padding: 0 10px;
  width: 100%;
}

.clientStory-container > div > div:first-child {
  opacity: 1;
  color: rgba(33,43,54,1);
  font-family: "Graphik-Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 10px;
  text-align: center;
}

.clientStory-container {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.clienStory-label-icon {
  width: 25px;
  height: 25px;
  position: relative;
  bottom: 1px;
  margin-right: 8px;
}
/*End of functionality*/

/* END */
div.stepperClose-Alert.cpModal {
  padding: 0 30px;
}

.stepperClose-Alert.cpModal .stepperClose-Alert-Content {
  -ms-flex-pack: center;
      justify-content: center;
  padding-top: 20px;
  display: -ms-flexbox;
  display: flex;
}

.stepperClose-Alert-text.cpModal h5 {
  color: rgb(45, 58, 75);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.24px;
}

.notifyModal-buttons.cpModal {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0px 40px 40px 40px;
  background: white;
  border: 0px;
}

.notifyModal-buttons.cpModal button {
  width: 101px;
  -ms-flex-pack: center;
      justify-content: center;
}

/*Buttons*/
.notifyModal-buttons.cpModal #groove-btn-id .btn-outline-stat-alternate {
  border-radius: 24px;
  color: white;
  border-color: rgb(213, 0, 47);
  background: rgb(213, 0, 47);
}

.notifyModal-buttons.cpModal #groove-btn-id .btn-outline-stat-alternate:hover {
  border-radius: 24px;
  color: white;
  border-color: rgb(200, 0, 47);
  background: rgb(200, 0, 47);
}

.notifyModal-buttons.cpModal #groove-btn-id .btn-stat-alternate {
  background: rgb(126, 195, 68);
  border-radius: 16px;
  border-color: rgb(126, 195, 68);
}

.notifyModal-buttons.cpModal #groove-btn-id .btn-stat-alternate:hover {
  background: rgb(117, 180, 65);
  border-radius: 16px;
  border-color: rgb(117, 180, 65);
}

.stepperClose-Alert-text.cpModal h5:nth-child(2) {
  text-align: center;
  font-weight: bold;
  color: rgb(161, 0, 255);
}


#table-ureport .tabulator .tabulator-header,
.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}

#table-ureport .tabulator-row .tabulator-cell {
  display: inline-block;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  word-break: break-word;
}

.conModal .modal-content {
  border-radius: 0px;
  height: auto;
}

#userExtractOk {
  background: none;
  border: none;
  color: rgb(99, 115, 129);
  font-family: "Graphik-Medium-Web";
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
  text-align: center;
}

#userExtractCancel {
  background: none;
  border: none;
  color: rgb(99, 115, 129);
  font-family: "Graphik-Medium-Web";
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
  text-align: center;
}

.ba_export_header {
  margin: 0;
  padding: 0;
}

.rfa-table-report-container .alignEntries label {
  margin: 0px;
  padding-top: 8px;
}

.userReport-fetch-btn,
.userReport-refresh-btn {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(196, 205, 213);
  border-radius: 20px;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.fetch-btn {
  cursor: not-allowed !important;
}

.userReport-refresh-icon {
  width: 15px;
  margin-right: 7px;
}

.userReport-fetch-icon {
  height: 15.6px;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  float: left;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

#dl-modal-container {
  text-align: center;
}
#dl-modal-container button {
  margin-top: 25px;
  display: unset;
}
#dl-loading-screen {
  height: 519px;
}
#progress-container {
  width: 30%;
  margin: auto;
  margin-top: 100px;
}
#progress-container h5 {
  text-align: center;
}

.tileCanned {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
}

.tileAdhoc {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  margin-left: 50px;
}

.tileContainer {
  padding: 80px 0;
  margin: 0 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.tileContainer.row>div {
  display: -ms-flexbox;
  display: flex;
  min-height: 240px;
  max-width: 580px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 49%;
}

.a {
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 28px;
  letter-spacing: -0.35px;
  line-height: 28px;
  width: 91px;
  margin-top: 10px;
}

.b {
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 28px;
  letter-spacing: -0.35px;
  line-height: 28px;
  width: 263px;
  margin-top: 10px;
}

.c {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 36px;
  letter-spacing: -0.25px;
  line-height: 18px;
  width: 266px;
  margin-top: 10px;
  display: block;
}

.d {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.22px;
  width: 97px;
  white-space: nowrap;
}

.img {
  margin-top: 40px;
  margin-bottom: 10px;

}

.leftbutton {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 40px;
  width: 40px;
  color: rgb(161, 0, 255);
  font-family: "Graphik-Medium-Web";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  cursor: pointer;
  -ms-flex-item-align: center;
      align-self: center;
  -ms-flex-align: center;
      align-items: center;
  margin: auto;
  margin-bottom: 50px;

}

a:link, a[href^="/DataIngestion"] {
  color: rgb(161, 0, 255)
}

.progress-animation .loading-animation {
  display: block;
  text-align: center;
  position: relative;
}

.data-extract-completed, .data-extract-failed {
  text-align: center;
}

.data-extract-completed h6,
.download-content h6,
.data-extract-failed h6 {
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
}

.data-extract-spinner.spinner-border {
  color: rgb(161, 0, 255) !important;
  width: 3em;
  height: 3em;
}

.data-extract-failed svg {
  width: 5em !important;
  height: 5em !important;
}

.data-extract-modal-container {
  max-width: 700px;
}
