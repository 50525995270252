.projectEntry-container {
  padding: 30px 40px;
  min-height: 840px !important;
}

.projectEntry-container .btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}

.projectEntry-container .btn-container .project-title-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-container>div {
  display: flex;
  width: 60%;
  word-break: break-all;
}

.projectEntry-container .title-section {
  margin-bottom: 5px;
}

.projectEntry-container .title-section .fa-times {
  cursor: pointer;
}

.select-project-modal .add-new-program-container {
  overflow-y: visible;
}

.select-project-modal .modal-header {
  padding-bottom: 15px !important;
}

.program-details-column {
  display: flex;
  margin-bottom: 3px;
}

.program-details-row {
  border-right: 1px solid #dfe3e8;
}

.program-details-row:last-child {
  border-right: none;
}

.program-details-row.col-lg-4 {
  padding-right: 10px;
  padding-left: 12px;
}

.project-title {
  color: rgb(33, 43, 54);
  font-family: 'Graphik-Semibold';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.42px;
  line-height: 18px;
}

.projectEntry-subcontainer {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px 40px 50px;
}

.projectEntry-subcontainer .project-title {
  width: 100%;
  word-break: break-word;
}

.projectEntry-container .sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.computeAll-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}


.sub-title-header {
  margin-right: 0px !important;
  margin-left: 0px !important;
  text-align: center;
}

.projectEntry-container .sub-title .sub-title-header h5 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 24px;
  margin-bottom: 0;
  align-self: center;
}

.reset-all-button,
.compute-all-button {
  display: flex;
  flex-direction: row;
  border: #a100ff solid 1px;
  border-radius: 24px;
  cursor: pointer;
  padding: 0;
  height: 36px;
  margin-right: 10px;
  align-items: center;
}

.reset-all-button-disabled,
.compute-all-button-disabled {
  display: flex;
  flex-direction: row;
  border: #a100ff solid 1px;
  border-radius: 24px;
  cursor: not-allowed;
  padding: 0;
  height: 36px;
  margin-right: 10px;
  align-items: center;
}

.compute-all-button button,
.reset-all-button button {
  color: #a100ff !important;
  align-self: center;
  justify-content: center;
  padding: 0 15px 0 0;
}

.compute-all-button-disabled button,
.reset-all-button-disabled button {
  color: #a100ff !important;
  align-self: center;
  justify-content: center;
  padding: 0 15px 0 0;
  cursor: not-allowed;
}

.separator-right {
  border-right: 1px solid rgba(209, 213, 219, 1);
}

.reset-all-button img,
.compute-all-button img {
  height: 15px;
  width: 15px;
  position: relative;
  margin-left: 15px;
}

.reset-all-button-disabled img,
.compute-all-button-disabled img {
  height: 15px;
  width: 15px;
  position: relative;
  margin-left: 15px;
}

.reset-all-button:hover,
.compute-all-button:hover {
  background-color: #a100ff;
  transition: all 0.15s ease-in-out;
}

.reset-all-button:hover button,
.compute-all-button:hover button {
  color: #fff !important;
  transition: all 0.15s ease-in-out;
}

.compute-all-button:hover img,
.reset-all-button:hover img {
  filter: brightness(0) invert(1);
  transition: all 0.15s ease-in-out;
}

.projectEntry-container .sub-title-right span,
.projectEntry-container .sub-title-right label {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  margin-left: 25px;
  cursor: pointer;
}

.reports-boiHistory-container {
  margin: 5px 0 10px 0;
}

.projectEntry-container .file-upload-wrap {
  padding: 19px 20px;
  background: rgb(251, 245, 255);
  border-radius: 8px;
  justify-content: space-between;
  margin: 24px 0px;
}

.projectEntry-container .file-upload-wrap.file-error {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
}

.projectEntry-container .file-upload-wrap.file-success {
  border: 1px solid #7ec344;
  background-color: #f2f9ec;
}

.projectEntry-container .accordion-action-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subdimension-table {
  margin: 20px 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 234, 238);
  border-radius: 0px;
}

.subdimension-header {
  background: rgb(249, 250, 251);
  border-bottom: 1px solid rgb(231, 234, 238);
  border-radius: 5px 5px 0px 0px;
}

.subdimension-header .row {
  padding: 12px 13px 12px 3%;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}

.subdimension-header>.row>div,
.subdimension-body .card-header>.row>div {
  padding: 0 5px;
}

/*css for columns based on status*/

@media (min-width: 992px) {

  .projectEntry-container .subdimension-header .row .col-lg-2,
  .projectEntry-container .subdimension-row.accordion .col-lg-2,
  .projectEntry-container .subdimension-header .row .col-lg-1,
  .projectEntry-container .subdimension-row.accordion .col-lg-1 {
    word-break: break-word;
  }

  .projectEntry-container .subdimension-row.accordion div:nth-child(3),
  .projectEntry-container .subdimension-row.accordion div:nth-child(4),
  .projectEntry-container .subdimension-row.accordion div:nth-child(5),
  .projectEntry-container .subdimension-row.accordion div:nth-child(6),
  .projectEntry-container .subdimension-row.accordion div:nth-child(7),
  .projectEntry-container .subdimension-row.accordion div:nth-child(8) {
    padding-left: unset !important;
  }

  .target-value-padding {
    padding-left: unset !important;
  }
}

.boiComm-awapp {
  left: 30px;
}

.wVis-awapp {
  left: 25px;
}

.projectEntry-subcontainer label.mini-toggle-switch {
  top: 0px;
}

.app-rej-awapp {
  left: 40px;
}

.app-rej-rejected,
.app-rej-rejected-new {
  align-self: baseline;
  padding-left: unset;
}

.app-rej-rejected .rvd-rejection-comment #groove-textarea,
.app-rej-rejected-new .rvd-rejection-comment #groove-textarea,
.app-rej-awapp-new .rvd-rejection-comment #groove-textarea {
  width: 80%;
}

.boiComm-rejected {
  right: 23px;
}

.subdimension-section-collapse .subdimension-row .card-header>button {
  justify-content: flex-start;
}

.subdimension-row .card-header {
  padding: 20px 30px;
}

.subdimension-row .card-header div {
  display: flex;
  align-items: flex-start;
  word-break: break-word;
}

.bodytext14 {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.parameter-title-center {
  margin-left: 185px;
}

.false {
  font-family: Graphik-Medium;
}

.bodytext14-regular {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 14px;
}

.fa-icons {
  color: rgb(161, 0, 255);
  padding: 3px;
  font-size: x-large;
  cursor: pointer;
}

.data-entries {
  margin: 15px;
  opacity: 0.5;
}

.new-pde {
  margin-bottom: 40px !important;
}

.report-prd {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 24px;
  justify-content: flex-end;
}

.report-prd .rvd-link img {
  height: 12.8px;
  width: 16px;
  margin-right: 2px;
}

.report-prd .rvd-link span {
  color: #a100ff;
  cursor: pointer;
}

.new-proj-btn {
  display: inherit;
  justify-content: flex-end;
}

.subdimension-row .card-header>button .svg-container {
  display: inline;
  margin-right: 5px;
}

.subdimension-row.accordion .card-header {
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid rgb(223, 227, 232);
  border-radius: 0px !important;
}

.subdimension-row.accordion:first-child .card-header {
  border-top: none;
  padding-left: 0px;
  padding-right: 0px;
}

.subdimension-row.accordion .card-header .row {
  width: inherit;
  /* margin-left: 0px; */
}

.subdimension-row.accordion .card-header>button {
  width: 3%;
}

.subdimension-section-collapse .card-header>button {
  display: flex;
  justify-content: right !important;
  width: 100%;
}

.subdimension-section-collapse .card-header>button>.bodytext18-medium-midnight {
  margin-right: 20px;
}

.parameter-field {
  display: flex;
  align-items: center;
  margin: 10px 0px 50px 0px;
}

.parameter-field-usd {
  margin: 10px 0px 70px 0px;
}

.field-text {
  width: 34%;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.22px;
  line-height: 19px;
}

.field-value {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.field-value input {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 10px 5px;
  margin: 0px 12px 0px 40px;
}

.field-row-btn {
  display: flex;
  justify-content: center;
}

.subdimension-row.accordion .card {
  border: none;
}

.bodytext14-medium {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.subdimension-row .card-header {
  background: #fff;
}

.subdimension-row .card.error-card {
  background: rgb(255, 245, 247);
  border-radius: 0px;
}

.subdimension-row .card.error-card .card-header {
  background: rgb(255, 245, 247);
  padding-left: 0px;
}

img.comment-icon {
  margin-right: -5px;
}

.file-img {
  width: 14px;
  height: 19px;
  margin-right: 5px;
}

.projectEntry-container .errorbox {
  margin-bottom: 10px;
}

.errorMsg {
  color: rgb(197, 0, 6);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 14px;
}

.file-error-list table {
  width: 100%;
}

.file-error-list table,
.file-error-list th,
.file-error-list td {
  border: 1px solid rgb(223, 227, 232);
  border-collapse: collapse;
}

.file-error-list th,
.file-error-list td {
  padding: 6px;
  text-align: left;
  font-size: 12px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 12px;
}

.file-error-list th {
  font-family: Graphik-Medium-Web;
}

.error-list-table {
  margin-bottom: 24px;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 5px;
}

.fa-times {
  cursor: pointer;
}

.attachment-list-container {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px;
}

.attachment-Item:not(:last-child) {
  border-bottom: 1px solid rgb(223, 227, 232);
  margin-bottom: 15px;
}

.view-Attachment-modal .modal-body>div:first-child {
  padding: 16px 30px;
}

.view-Attachment-modal .title,
.view-Attachment-modal .value {
  font-size: 14px;
  line-height: 14px;
}

.view-Attachment-modal .file-img {
  width: 12px;
  height: 16px;
  margin-right: 5px;
}

.subdimension-row #groove-radio-button {
  display: flex;
  align-items: center;
}

.subdimension-row .form-check {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.subdimension-row .form-check-input {
  margin-top: 0.15rem;
}

/* @media (min-width: 992px){
.subdimension-table .col-lg-2:nth-child(2) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
.subdimension-table .col-lg-2:nth-child(3) {
    flex: 0 0 14.66667%;
    max-width: 14.66667%;
}
} */

.subdimension-row.accordion .collapse.show~div.card-header .btn-link>div.svg-container {
  transform: rotate(90deg);
}

.subdimension-row.accordion .collapse.show~div.card-header div.svg-container {
  transform: rotate(0deg);
  cursor: pointer;
}

.validate {
  color: red;
  margin-left: 10px;
}

.rdv-view-comment {
  display: flex;
}

.expandAll-button.active>button,
.collapseAll-button.active>button {
  color: #a100ff;
}

.info-icon {
  display: inline-block;
  margin: 0 10px;
  position: relative;
}

.info-icon .svg-container {
  transform: none !important;
  font-size: 0.9em !important;
}

.info-icon>.svg-container>svg {
  width: 11px;
  cursor: pointer;
}

.info-icon.top {
  position: absolute;
  right: -15px !important;
  top: -10px !important;
}

/* 
.ApprovalRequestModal .groove-textbox {
  margin-top: 9px;
} */

.custom-boi {
  background: rgb(235, 225, 248);
  border-radius: 16px;
  padding: 2px 10px;
  border: none;
  margin-top: 0.25em;
  color: rgb(80, 35, 143);
  font-family: 'Graphik-Medium-Web';
  font-size: 11px;
  font-weight: 500;
  width: 70px;
  margin-left: 10px;
}

.custom-boi:hover {
  box-shadow: 0 0 5px rgb(187 135 255);
}

.modal-fade-custom-boi {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px;
  height: 1064px;
  width: 1440px;
}

.custom-boi-modal {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modalheader-title {
  color: rgb(45, 58, 75);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  height: 24px;
  letter-spacing: -0.42px;
  width: 308px;
}

.modalbody-details {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.2px;
  line-height: 20px;
  width: 63px;
}

.modalbody-desc {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  letter-spacing: -0.29px;
  line-height: 20px;
  width: 67px;
}

.modalbody-column {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
}

.modal-custom-BOI-container {
  /* position: fixed; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  height: 100%;
  /* width: 20vw; */
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.modal-container.show {
  opacity: 1;
  pointer-events: all;
}

.modal-Custom {
  width: 70vw;
  height: 80vh;
  /* max-width: 100%; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* background-color: #c47474; */
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.row-inside {
  display: flex;
}

.row-outside {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: 626px;
  width: 963px;
  display: flex;
}

.row-outside h3 {
  position: absolute;
  margin: 2rem 3rem;
  font-size: x-large;
}

#close {
  position: absolute;
  margin: 1em 65rem;
  color: gray;
  border: none;
  background-color: white;
}

.col-50 {
  flex: 25%;
  margin: 4rem 3rem;
}

.col-50 h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
}

.col-50 p {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.col-25 {
  flex: 12.5%;
}

/* ==========================================CHANGES=================================== */

.modal-body .custom-boi-container {
  height: 400px;
}

.modal-custom-BOI-container .modal-body {
  padding: 1rem !important;
}

.modal-custom-BOI-container .modal-dialog {
  max-width: 800px;
}

.modal-custom-BOI-container h3 {
  font-size: x-large;
  padding-left: 16px;
}

.custom-boi-container {
  padding: 0 25px !important;
}

.modal-custom-BOI-container h6 {
  margin: 0;
  padding-bottom: 4px;
  /* color: rgb(99, 115, 129); */
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.modal-custom-BOI-container .modal-title.h4 {
  font-family: Graphik-Semibold;
  /* color: rgb(45, 58, 75); */
  color: #000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.42px;
}

.modal-custom-BOI-container p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 16px;
  word-break: break-all;
}

.custom-close-Modal {
  height: 16.25px;
  width: 16.25px;
}

.custom-boi-btn #custom-btn span {
  font-size: 11px;
  color: rgb(80, 35, 143);
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

#groove-btn-id.custom-boi-btn #custom-btn.btn-outline-stat-alternate {
  padding: 5px;
  background: rgb(235, 225, 248);
  border-radius: 16px;
  border: none;
}

.custom-boi-empty {
  padding-bottom: 24px;
}

.custom-boi-param {
  padding-left: 0px !important;
}

.param-char-length-err {
  color: red;
  margin-left: 37px;
  font-size: 13px;
  padding-top: 4px;
  height: 1px;
}

.warning-message-parameter {
  padding-left: 5%;
  width: 30%;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  color: #ffbd00;
}

.rdv-refresh-list {
  display: flex;
  margin-left: 20px;
}

.rdv-refresh-list button {
  color: #a100ff !important;
}

.rdv-refresh-list button>span {
  font-size: 16px !important;
}

#rvd-refresh-tooltip-bottom .tooltip-inner {
  max-width: 250px;
  padding: 8px 15px;
  font-size: 12px;
}

/* Rejection Comment CSS */

.rvd-rejection-comment {
  display: block !important;
}

.rvd-rejection-comment #groove-textarea {
  position: absolute;
  margin: 10px 0px;
  top: 20px;
  width: 68%;
}

.rvd-rejection-comment #groove-textarea .groove-textbox {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232) !important;
  border-radius: 4px;
}

.rvd-rejection-comment .floating-label-textbox {
  border-top: 1px solid rgb(223, 227, 232) !important;
  border-radius: 4px;
}

.rvd-rejection-comment div.groove-textbox-custom-label,
.rvd-rejection-comment div.groove-input-custom-label {
  color: #919eab !important;
  font-family: 'Graphik-Medium' !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: -0.25px !important;
  line-height: 12px;
  margin-top: -9px;
}

.rvd-rejection-comment #groove-textarea .floating-label-textbox .groove-textbox.is-invalid:not(:placeholder-shown):disabled+label {
  transform: translateY(0);
}

#boi-tooltip-right .tooltip-inner,
#calculation-tooltip .tooltip-inner {
  -webkit-box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 15%);
  background-color: #fff;
  color: #000;
  padding: 15px 15px;
  min-width: 550px;
  max-width: 650px;
  margin-bottom: 75px;
}

#boi-tooltip-right.bs-tooltip-right .arrow:before,
#calculation-tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #fff !important;
}

.boi-details-wrap h6 {
  margin: 0;
  padding-bottom: 4px;
  color: gray;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 20px;
  text-align: left;
}

.boi-details-wrap p {
  color: rgb(0, 0, 0);
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  font-size: 14px;
  word-break: break-word;
  text-align: left;
}

/* View Aggregated BOI Details CSS */

.aggregated-boi-popover {
  width: 285px;
  height: 50px;
  color: #a100ff;
  font-size: 14px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
}

#aggregated-boi-popover,
#aggregated-boi-popover .popover-body {
  width: 634px;
  height: 651px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 226, 231);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 5;
}

.aggregated-boi-popover-header {
  width: 100%;
  display: flex;
  height: 30px;
  padding: 5px 14px;
}

.aggregated-boi-popover-header .aggregated-boi-popover-title {
  width: 90%;
  height: 25px;
  color: rgb(109, 114, 120);
  font-size: 18px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.28px;
}

.aggregated-boi-popover-header .aggregated-boi-popover-close-btn {
  width: 10%;
  color: rgb(33, 43, 54);
  font-weight: 500;
  cursor: pointer;
  font-size: 18px;
  padding-left: 40px;
}

.aggregated-boi-accordion-container {
  max-height: 582px;
  overflow-y: auto;
  padding: 2px;
}

.visualization-collapse.accordion .card {
  margin-bottom: 24px;
  border-radius: 5px !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

.visualization-collapse.accordion .card:last-child {
  margin-bottom: 0px;
}

.visualization-collapse.configure-client-collapse .card-header {
  padding: 20px 25px;
}

.visualization-collapse.configure-client-collapse .card-body {
  padding: 0px 25px 20px 25px;
}

.dim-tabs>div>div>div>div {
  margin-bottom: -7px !important;
  margin: 0 -15px;
}

.aggregated-boi-detail-tabs button>span:first-child {
  text-transform: initial;
  min-width: max-content;
  padding: 0 10px !important;
}

.aggregated-boi-detail-tabs button span {
  padding: 0;
  letter-spacing: -0.21px;
  text-align: center;
  word-wrap: break-word;
  display: block;
  max-width: 200px;
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.aggregated-boi-detail-tabs button>span:nth-child(2) {
  display: none;
}

.aggregated-boi-detail-tabs button {
  border: 3px solid transparent;
}

.aggregated-boi-detail-tabs button>svg {
  border-radius: 50%;
  background: #ebe1f8;
  color: #a100ff;
}

.aggregated-boi-detail-tabs button[aria-selected='true'] {
  border-bottom-color: #a100ff;
}

.aggregated-boi-detail-tabs button[aria-selected='false'] span,
.aggregated-boi-detail-tabs button[aria-selected='false'] span span span {
  color: rgb(45, 58, 75);
}

.aggregated-boi-detail-tabs>div>div>span {
  display: none;
}

#aggregated-info-icon {
  margin: 0 10px !important;
}

.aggregated-boi-label-container {
  height: 45px;
  color: rgb(45, 58, 75);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.22px;
  padding: 15px 15px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.aggregated-boi-left-label {
  width: 70%;
  border-right: 1px solid #e7eaee;
  padding-right: 5px;
  display: flex;
}

.aggregated-boi-left-label .aggregated-boi-left-text {
  /* width: 70%; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.aggregated-boi-right-label {
  width: 35%;
  padding-left: 5px;
}

.aggregated-boi-title-label #aggregated-info-icon>.svg-container>svg {
  font-size: 14px !important;
}

#tooltiptext-aggregated {
  top: 20px !important;
  clear: both !important;
  left: 10px !important;
  box-shadow: 0 0 2px black;
}

div[role='tabpanel']>div.aggregated-tabpanel {
  max-height: 500px;
  overflow-x: hidden;
  padding: 10px;
  overflow-y: auto;
  display: inline;
}

.tooltip-parameter {
  margin-right: 50px;
}

.reporting-period-normal {
  font-family: Graphik-Medium;
  color: #212b36;
  font-size: 14px;
  line-height: 18px;
  background-color: white;
}

.reporting-period-highlight {
  font-family: Graphik-Medium;
  font-size: 14px;
  line-height: 18px;
  background-color: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  color: rgb(197, 0, 6);
}

.rvdboi-new-info .new-info-icon {
  transform: scale(1);
  margin-top: 0px;
}

.rvd-financial .rvdboi-new-info .new-info-icon {
  margin-top: -15px !important;
  position: relative;
}

.aggregated-info.info-icon-wrap {
  margin-top: -14px;
}

.rvd-invalid-realizedval {
  background-color: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  color: rgb(197, 0, 6);
}

#groove-radio-button.approve-reject-radio-button input.form-check-input {
  margin-top: 0;
  height: 20px;
  width: 20px;
  content: '';
  display: inline-block;
  visibility: visible;
  -moz-appearance: initial;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:after {
  content: '';
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1.5px solid #6c757d;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:hover:after,
#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:checked:after {
  height: 20px;
  width: 20px;
  border: 1.5px solid rgb(161, 0, 255) !important;
}

#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:checked::before {
  content: '';
  display: block;
  position: absolute;
  left: 3px;
  top: 3px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: rgb(161, 0, 255);
}

#groove-radio-button.approve-reject-radio-button .form-check>label {
  margin-left: 3px !important;
  margin-top: 4px !important;
}

.aggregated-info img.new-info-icon {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

#boi-tooltip-right.bs-tooltip-bottom .arrow:before,
#calculation-tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #fff;
}

.subdimension-header>.row>div.col-lg-1.wVis-false>a,
.subdimension-header>.row>div.col-lg-1.wVis-awapp-new>a {
  position: absolute;
  right: 0.1px;
}

/* Client Story Modal */

/* to change */

.modal-dialog.client-story-modal.modal-md.modal-dialog-centered {
  max-width: 95vw !important;
}

.client-story-modal {
  width: 100%;
  display: none;
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 10%);
  z-index: 1000;
  min-height: 40vw;
}

.client-story-modal .svg-container {
  float: right;
  width: auto;
  height: auto;
}

.client-story-modal .svg-container .close-modal-btn {
  margin: 20px 25px;
}

/* .client-story-modal .client-story-ack-modal-content {
  min-height: 40vw;
} */

.io-validation {
  pointer-events: none;
}

.io-validation #groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:checked::before {
  background: rgb(145, 158, 171);
}

.io-validation #groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:hover:after,
#groove-radio-button.approve-reject-radio-button input.form-check-input[type='radio']:checked:after {
  height: 20px;
  width: 20px;
  border: 1.5px solid rgb(145, 158, 171) !important;
}

.client-story-attachment {
  text-align: right;
  float: right;
  padding: 5px 15px;
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
}

.client-story-attachment .svg-container.small {
  padding-right: 5px;
  width: 18px;
  height: 18px;
}

.attachment-invalid {
  background: rgb(255, 245, 247);
  border-radius: 8px;
  border: 1.5px solid rgb(213, 0, 47);
  padding: 10px;
  color: rgb(213, 0, 47);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.22px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.attachment-invalid .svg-container.small>svg {
  color: rgb(213, 0, 47);
}

.attachment-invalid .close-btn-container {
  text-align: right;
}

.reset-all-button.client-story,
.reset-all-button.client-acknowledgement {
  padding-left: 0px;
}

.reset-all-button.client-story.hover,
.reset-all-button.client-acknowledgement.hover {
  background-color: #a100ff;
}

.reset-all-button.client-story.hover span,
.reset-all-button.client-acknowledgement.hover span {
  color: #fff;
}

.reset-all-button.client-story.no-attachment-error,
.reset-all-button.client-story.no-attachment-error span {
  border-color: rgb(213, 0, 47);
  color: rgb(213, 0, 47);
}

.sub-title .button-container {
  display: inline-flex;
}

.sub-title .button-container>.pde-btn {
  margin-right: 10px !important;
  margin-left: 0;
}

.client-story-modal.cs-show {
  display: block;
}

.backdrop-cs-modal {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.5;
}

.newProjectDataEntry-Disclaimer-Message {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 67%;
  height: 88px;
  background: rgb(251, 245, 255);
  border: 1px solid rgb(227, 209, 255);
  border-radius: 8px;
  padding: 40px;
  padding-left: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: rgb(161, 0, 255);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  height: 32px;
  letter-spacing: -0.25px;
  line-height: 16px;
  position: relative;
  left: 109px;
  padding-right: 54px;
}

.disclaimer-text {
  min-width: -webkit-fill-available;
}

.exclamation-icon-disclaimerMessage {
  margin-right: 8px;
  border: 2px solid rgb(117, 0, 192);
  border-radius: 50px;
  height: 30px;
  min-width: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
}

@media (min-width: 1919px) {

  .subdimension-header>.row>div.col-sm-3.app-rej-awapp-new,
  .subdimension-header>.row>div.col-sm-1.boiComm-awapp-new,
  .subdimension-header>.row>div.col-sm-1.wVis-awapp-new,
  .subdimension-header>.row>div.col-sm-3.app-rej-awapp,
  .subdimension-header>.row>div.col-sm-1.boiComm-awapp,
  .subdimension-header>.row>div.col-sm-1.wVis-awapp,
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-1.wVis-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp,
  .subdimension-body .card-header>.row>div.col-sm-1.wVis-awapp {
    margin-left: 42px;
  }

  .subdimension-header>.row>div.col-sm-3.app-rej-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp-new {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp {
    margin-left: 33px;
  }
}

.projectEntry-container .subdimension-row.accordion .card-header>.row>div:first-child {
  align-items: flex-start;
}

.projectEntry-container .subdimension-row.accordion .card-header>.row>div>#groove-toggle .mini-toggle-switch {
  display: flex;
  position: inherit;
  margin-top: 13px;
}

.subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp-new,
.subdimension-body .card-header>.row>div.col-sm-3.app-rej-awapp,
.projectEntry-container .subdimension-body .card-header>button.btn.btn-link {
  align-self: baseline;
}

.subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp,
.subdimension-body .card-header>.row>div.col-sm-1.boiComm-false,
.subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp-new,
.subdimension-body .card-header>.row>div.col-sm-1.boiComm-rejected,
.subdimension-body .card-header>.row>div.col-sm-1.boiComm-approved {
  align-self: flex-start;
}

.rvd-dimension-title {
  align-self: center;
}

.projectEntry-container .custom-boi {
  margin-top: -0.75em;
}

.zero-target-value {
  border: 1px solid red;
  border-radius: 5px;
  margin-right: 25px;
  height: 20px;
  padding: 0px 5px;
}

.zero-target-value-msg {
  color: red;
  font-size: 13px;
  padding-top: 5px;
  margin-right: 25px;
  width: max-content !important;
}

.dashboard-visibility-rvd .info-icon {
  display: inline;
  margin: 0;
  margin-left: 5px;
}

/**************************Business Advisor R5.0S1***************************************/
#addeditBA {
  padding-left: 10px;
  position: relative;
  top: -6px;
}

#addeditBA:hover {
  cursor: pointer;
}

.program-details-column.add-edit-ba {
  align-items: center !important;
  left: -10px;
  top: -5px;
  position: relative;
  display: inline-block;
  width: inherit;
}

.bd-ba-info {
  display: flex;
  padding-left: 10px;
  padding-top: 4px;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
}

.approved-entry-bd-container {
  background: rgb(251, 245, 255);
  border-radius: 8px;
  display: flex;
}

.ba-required-bd-container {
  background: rgb(255, 245, 247);
  border-radius: 8px;
  display: flex;
  width: fit-content;
}

.ba-warning-msg {
  position: relative;
  padding-left: 30px;
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.22px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
}

.ba-warning-msg>span {
  color: rgb(170, 0, 37);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
  padding-top: 1.5px;
}

.warning-exclamation-icon-container {
  margin-right: 8px;
  border: 2px solid rgb(170, 0, 37);
  border-radius: 50px;
  height: 18px;
  min-width: 9px;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.warning-exclamation-icon-container>div:first-child {
  width: 14px !important;
  height: 14px !important;
  padding-left: 5px;
  /*previously 5.5px, but not working on SIT build*/
  padding-top: 1px;
}

.ba-db-error {
  color: red;
  font-size: 13px;
  font-family: Graphik-Medium-Web;
  padding-top: 5px;
  padding-left: 12px;
}

/***************************Client Value Identified R5.0S1***************************/
.subdimention-tabs {
  display: flex;
}

.subdimention-tabs>div {
  padding: 12px;
  color: #637381;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  border: 1px solid rgb(196, 205, 213);
  border-radius: 4px 4px 0px 0px;
  border-bottom: unset;
  cursor: pointer;
}

.subdimention-tabs>div.active-tab {
  background-color: rgb(145, 158, 171);
  color: rgb(255, 255, 255);
}

.rvd-financial {
  margin-top: 0;
}

.rvd-financial .subdimension-header {
  background-color: rgb(145, 158, 171);
  border-top-left-radius: unset;
}

.rvd-financial .subdimension-header>.row {
  color: rgb(255, 255, 255);
  text-align: left;
}

.rvd-financial .subdimension-row.accordion .card-header,
.rvd-financial .subdimension-row.accordion .card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.rvd-financial input:disabled {
  background: rgb(249, 250, 251);
  border: 1px solid rgb(223, 227, 232);
  cursor: not-allowed;
}

.rvd-financial .field-value>div:first-child {
  display: flex;
}

.rvd-financial .field-value>div #groove-input .groove-input.error {
  border: 1px solid red;
}

.rvd-financial .field-value>div #groove-input .groove-input.disable-super-admin {
  cursor: not-allowed;
  background-color: #e8e8e8;
}

.rvd-financial .field-value>div .param-uom {
  margin-left: 0.5rem;
}

.rvd-financial .field-cvi {
  flex: 1;
}

.rvd-financial .param-char-length-err {
  position: relative;
  top: 0;
  overflow-wrap: break-word;
  width: 290px;
  display: block !important;
}

#calculation-logic {
  min-width: unset;
  width: 250px !important;
}

#calculation-logic * {
  align-items: flex-start;
}

.calculation-logic .row>div {
  text-align: left !important;
}

.calculation-logic-header {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  background: rgb(249, 250, 251);
  border-radius: 5px 5px 0 0;
  border: 1px solid rgb(231, 234, 238);
  padding: 12px;
}

.calculation-logic-body {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid rgb(231, 234, 238);
  padding: 12px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
}

#calculation-tooltip {
  opacity: 1;
}

#calculation-tooltip .tooltip-inner {
  min-width: 400px;
  max-width: 450px;
}

.value-tooltip,
.value-tooltip:hover {
  font-family: inherit;
  color: inherit;
  text-decoration: none;
}

.rvd-financial .field-value {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.rvd-financial .field-cvi {
  margin-left: 20px;
}

.rvd-financial .field-value .param-char-length-err,
.rvd-financial .field-value input {
  margin-left: 0;
}

.rvd-financial .app-rej-rejected .rvd-rejection-comment #groove-textarea,
.rvd-financial .app-rej-rejected-new .rvd-rejection-comment #groove-textarea {
  height: 100px !important;
  top: -10px;
  margin: 0;
}

.rvd-financial .field-text.rejected-status {
  width: 25%;
}

.rvd-financial #comment-textbox-rejected {
  height: 80px !important;
}

.rvd-financial>.subdimension-header>.row>div,
.dashboard-visibility-rvd .info-icon {
  display: flex;
  align-items: center;
}

.col-sm-1.dashboard-visibility-rvd,
.col-sm-2.dashboard-visibility-rvd {
  display: flex;
}

.dashboard-visibility-rvd .info-icon {
  display: block !important;
  margin-left: 1px !important;
}

.rvd-financial .info-icon {
  display: block !important;
  margin-left: 2px !important;
}

.rvd-financial .cvi-text .info-icon {
  display: inline !important;
}

.cvi-text-h6 {
  padding: 14px 0 0 0 !important;
}

.cvi-text {
  width: max-content;
}

@media (min-width: 1919px) {
  .dashboard-visibility-rvd .info-icon {
    position: absolute;
    margin-left: 5px;
  }

  .rvd-financial .dashboard-visibility-rvd .info-icon {
    position: unset;
  }

  .subdimension-body .card-header>.row>div.col-sm-2.app-rej-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-2.app-rej-awapp {
    margin-left: 35px;
  }
}

@media (min-width: 1919px) and (max-width: 2559px) {
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-approved {
    margin-left: 25px;
  }
}

/*PBI 148727 - RVD*/
.subdimension-row.accordion .approved-project-level {
  border-left: 4px solid rgb(100, 156, 54);
  border-radius: unset;
}

.subdimension-row.accordion .rejected-project-level {
  border-left: 4px solid rgb(170, 0, 37);
  border-radius: unset;
}

.rejected-projBoi-warning {
  display: flex;
  align-items: center;
}

.projectboi-rejected-alert svg,
.rejected-projBoi-warning svg,
.rejected-projBoi-warning {
  color: rgb(170, 0, 37);
}

.rejected-projBoi-warning svg {
  border: 2px solid rgb(170, 0, 37);
  border-radius: 50px;
  padding: 2px;
  min-width: 18px;
  /*Normal size in SIT but not in DEV/LOCAL*/
  justify-content: center;
  display: flex;
  align-items: center;
}

.projectboi-rejected-alert {
  margin-top: 4px;
}

.rvd-financial .projectboi-rejected-alert {
  margin-top: unset;
}

.rvd-financial span+.projectboi-rejected-alert {
  margin-left: 10px;
}

.projectboi-rejected-alert.custom-with-alert {
  margin-top: 2px;
  margin-left: 5px;
}

.rejected-projBoi-warning svg {
  height: 18px;
  /*Normal size in SIT but not in DEV/LOCAL*/
  width: 18px !important;
  /*Normal size in SIT but not in DEV/LOCAL*/
}

.rejected-projBoi-warning>div:nth-child(2) {
  margin-left: 10px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-top: -4px;
}

.invalid-cumulative-cont {
  position: absolute;
  top: 98px;
}

.rvd-disclaimer-msg {
  padding-top: 15px !important;
  padding-bottom: 40px !important;
}

/*----end----*/

/* r10 */
.rvd-cvi-usd-toggle-hide {
  pointer-events: none;
}

.rvd-cvi-usd-toggle-hide>div>svg {
  color: transparent;
}

.rvd-financial .subdimension-row.accordion .card-body {
  padding-left: 2.9%;
}

.cvi-plus-icon-container {
  padding-right: 10px;
}

.cvi-plus-icon-container>.svg-container {
  border: 2px solid #a100ff;
  border-radius: 50%;
  padding-left: 2px;
  padding-top: 1px;
  cursor: pointer;
}



.rvd-accordion-cvi-container .col-year,
.rvd-accordion-cvi-container .col-qtr,
.rvd-accordion-cvi-container .col-month {
  align-items: center !important;
  display: flex;
}

div[class*='qtr-body-con'],
div[class*='ry-body-con'],
div[class*='fin-usd-main-con'] {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  /* padding-bottom: 50px; */
}

.fin-usd-body-text {
  opacity: 1;
  color: rgba(99, 115, 129, 1);
  font-family: 'Graphik-Regular';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.22px;
  text-align: left;
}

.rvd-financial .subdimension-row.accordion .card-body>.row>div {
  padding: 10px 5px;
  /* padding-bottom: 20px; */
}

.col-sm-1.col-month,
.col-sm-1.col-boi-comment {
  flex: 0 0 4.33333%;
  max-width: 4.33333%;
}

.col-sm-1.col-qtr {
  flex: 0 0 7.33333%;
  max-width: 7.33333%;
}

.total-per-qtr-con>.col-sm-2.col-qtr {
  flex: 0 0 11.73%;
  max-width: 11.73%;
  font-size: 12px;
}

.cumulative-number-container>.col-sm-2.col-year,
.total-per-year-con>.col-sm-2.col-year {
  flex: 0 0 15.73%;
  max-width: 14.73%;
  font-size: 12px;
}

.cumulative-number-container>.col-sm-2.col-year.cumulative-number-label {
  font-size: 14px;
  white-space: nowrap;
}

.rvd-financial .subdimension-row.accordion .expanded-header {
  padding-bottom: 30px;
}

.rvd-financial .subdimension-row.accordion .collapsed-header {
  padding-bottom: 0px;
}

.col-sm-2.col-boi-name {
  flex: 0 0 22.66667%;
  max-width: 22.66667%;
}

.subdimension-body .col-target-value {
  flex: 0 0 7.3%;
  max-width: 7.3%;
}

.field-value.col-vic>div:first-child {
  padding-right: 10px;
}

.col-boi-comment {
  flex: 0 0 4.33333%;
  max-width: 4.33333%;
}

.card-body .col-vic,
.card-body .col-cvi {
  text-align: left;
}

.field-value.col-cvi>div:first-child {
  padding-right: 20px;
}

.expanded-header.card-header .col-cvi,
.expanded-header.card-header .col-vic {
  margin-top: -20px;
}

.rvd-financial .card-header .col-vic,
.rvd-financial .card-header .col-cvi {
  width: 100%;
  text-align: left;
  display: inline;
}

.col-cvi #groove-input .floating-label .groove-input:not(:placeholder-shown):disabled+label,
.col-vic #groove-input .floating-label .groove-input:not(:placeholder-shown):disabled+label {
  color: #919eab !important;
}

.col-sm-1.col-vic.rvd-fin-approver-rejected {
  flex: 0 0 11.33333%;
  max-width: 11.33333%;
}

.col-sm-1.target-realized-awapp-new.field-value.field-cv.col-cvi.rvd-fin-approver-rejected {
  flex: 0 0 15.33333%;
  max-width: 15.33333%;
}

.col-sm-2.col-boi-name.rvd-fin-approver-rejected {
  flex: 0 0 15.33333%;
  max-width: 15.33333%;
}

.col-sm-1.wVis-awapp-new.dashboard-visibility-rvd.col-dashboard.rvd-fin-approver-rejected {
  flex: 0 0 11.33333%;
  max-width: 11.33333%;
  padding-left: 25px;
}

.col-sm-1.col-year.rvd-fin-approver-rejected,
.col-sm-1.col-qtr.rvd-fin-approver-rejected {
  text-align: left;
  /* padding-left: 35px; */
}

.card-body .subdimension-header>div {
  /* text-align: center; */
  text-align: left;
}

.subdimension-header .col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected {
  padding-left: 20px;
}

.col-sm-1.wVis-awapp-new.dashboard-visibility-rvd.col-dashboard.rvd-fin-approver-rejected {
  flex: 0 0 10.33333%;
  max-width: 10.33333%;
  /* padding-left: 27px; */
}

.subdimension-header .col-sm-1.target-realized-awapp-new.col-cvi.rvd-fin-approver-rejected {
  flex: 0 0 11.33333%;
  max-width: 11.33333%;
}

.subdimension-header .col-sm-1.boiComm-awapp-new.col-boi-comment.rvd-fin-approver-rejected {
  /* flex: 0 0 12.33333%; */
  /* max-width: 5.33333%; */
  padding-left: 15px;
}

.param-char-length-err>div {
  display: inline-block !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* display: -webkit-box !important; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.param-char-length-err.error-message-wrap>div {
  white-space: break-spaces;
}

.col-cvi #groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
.col-vic #groove-input .floating-label .groove-input:not(:placeholder-shown)+label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(80%);
}

.finUSD-cont #groove-input {
  width: 100%;
}

.finUSD-cont div.label16-regular-silver>div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  display: block;
}

.finUSD-cont #groove-input .floating-label .groove-input:focus+label,
.finUSD-cont #groove-input .floating-label .groove-input:not(:placeholder-shown)+label,
.finUSD-cont #groove-input .floating-label .groove-input:not(:placeholder-shown):focus+label {
  color: #a100ff !important;
}

.col-sm-3.non-usd,
.col-sm-2.non-usd,
.col-sm-1.non-usd {
  text-align: left;
}

.col-sm-1.non-usd-boi {
  text-align: left;
  margin-left: 0.5rem;
  margin-right: -1rem;
}

.fin-usd-parameter {
  position: absolute;
  display: block;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter {
  display: block;
  width: 100%;

  max-height: 34px;
  justify-content: flex-start;
  align-content: center;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter>div.fin-usd-param-body {
  font-size: 13px;
  top: -5px;
  max-height: 150px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 1;
  padding-right: 5px;
  font-size: 14px;
  letter-spacing: -.22px;
  color: #212b36;
  font-family: Graphik-Regular-Web;
  font-weight: 400;
  line-height: 18px;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter>div.fin-usd-param-body .fin-usd-param-label {
  color: #637381;
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -.22px;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter>div.fin-usd-param-body.qtr-collapsed {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
  margin-bottom: 6px;
  line-height: 115%;
  /* padding-bottom: 0px; */
  max-height: 90px;
  top: -15px;
  padding: 0 5px 0 0;
}

.fin-usd-body-container.row.fin-usd-body-text.cvi-non-header.fin-usd-parameter>div.fin-usd-param-body.yr-collapsed {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  margin-bottom: 6px;
  line-height: 115%;
  /* padding-bottom: 0px; */
  top: -15px;
  padding: 0 5px 0 0;
  line-height: 17px;
}


.fin-usd-body-container>.field-text {
  width: 100%;
}

@media (min-width: 1919px) and (max-width: 2561px) {

  /*67% browser*/
  .subdimension-header>.row>div.col-sm-3.app-rej-awapp-new,
  .subdimension-header>.row>div.col-sm-1.boiComm-awapp-new,
  .subdimension-header>.row>div.col-sm-1.wVis-awapp-new,
  .subdimension-header>.row>div.col-sm-3.app-rej-awapp,
  .subdimension-header>.row>div.col-sm-1.boiComm-awapp,
  .subdimension-header>.row>div.col-sm-1.wVis-awapp,
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-1.wVis-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-1.boiComm-awapp,
  .subdimension-body .card-header>.row>div.col-sm-1.wVis-awapp {
    margin-left: 0px;
  }

  .subdimension-body .card-header>.row>div.col-sm-2.app-rej-awapp-new,
  .subdimension-body .card-header>.row>div.col-sm-2.app-rej-awapp {
    margin-left: 0px;
  }
}

.col-sm-1.boiComm-false.non-usd-boi,
.col-sm-1.boiComm-approved.non-usd-boi {
  padding-left: 5px;
}

.col-sm-2.pde-comment-rejected.non-usd {
  padding-left: 18px;
}

.col-sm-2.pde-comment-rejected-new.non-usd {
  padding-left: 20px;
}

.col-sm-2.app-rej-awapp.non-usd,
.col-sm-2.app-rej-awapp-new.non-usd {
  padding-left: 21px;
}

/*Bug fix column headers & body - B215980*/

/* (Fin>NON-USD) */
.col-sm-2.pde-comment-rejected-new.fin-non-usd,
.col-sm-2.pde-comment-rejected-new.non-usd {
  margin-left: 0px;
}

@media (min-width: 639px) and (max-width: 1921px) {
  .col-sm-1.boiComm-rejected.fin-non-usd {
    margin-left: 35px;
  }

  .app-rej-awapp>.rvd-rejection-comment {
    /*Awaiting approval - rej*/
    margin-left: 0px;
  }
}

@media (min-width: 1919px) and (max-width: 2561px) {

  /*67% browser*/
  .col-sm-1.wVis-awapp-new.dashboard-visibility-rvd.col-dashboard.rvd-fin-approver-rejected {
    margin-left: 8px;
  }

  .col-sm-1.boiComm-awapp.fin-non-usd,
  .col-sm-1.wVis-awapp.dashboard-visibility-rvd.fin-non-usd,
  .col-sm-3.app-rej-awapp.fin-non-usd.heading,
  .col-sm-1.boiComm-awapp.fin-non-usd,
  .col-sm-1.wVis-awapp.fin-non-usd,
  .col-sm-3.app-rej-awapp.fin-non-usd,
  .col-sm-1.wVis-awapp-new.dashboard-visibility-rvd.fin-non-usd,
  .col-sm-1.boiComm-awapp-new.fin-non-usd,
  .col-sm-1.wVis-awapp-new.fin-non-usd,
  .col-sm-2.app-rej-awapp-new.fin-non-usd,
  .col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected.heading {
    margin-left: 0px !important;
  }
}

/* End of FIN>NON-USD */

/*FINANCIAL > USD : Approver/Rejeceted View*/
.col-sm-1.col-year.rvd-fin-approver-rejected,
.col-sm-1.col-qtr.rvd-fin-approver-rejected,
.col-sm-1.col-year.rvd-fin-approver-rejected.bodytext14,
.col-sm-1.col-qtr.rvd-fin-approver-rejected.bodytext14 {
  flex: 0 0 7.33333%;
  max-width: 7.33333%;
}

.col-sm-1.col-month.rvd-fin-approver-rejected {
  flex: 0 0 4.33333%;
  max-width: 4.33333%;
}

.col-sm-1.target-realized-rejected-new.field-value.field-cv.col-cvi.rvd-fin-approver-rejected,
.col-sm-1.target-realized-rejected.field-value.field-cv.col-cvi.rvd-fin-approver-rejected,
.col-sm-1.target-realized-awapp.field-value.field-cv.col-cvi.rvd-fin-approver-rejected {
  flex: 0 0 14.33333%;
  max-width: 14.33333%;
}

.col-sm-1.target-realized-rejected-new.col-cvi.rvd-fin-approver-rejected,
.col-sm-1.target-realized-rejected.col-cvi.rvd-fin-approver-rejected,
.col-sm-1.target-realized-awapp.col-cvi.rvd-fin-approver-rejected {
  flex: 0 0 12.33333%;
  max-width: 12.33333%;
}

.col-sm-2.app-rej-awapp.col-app-rej.rvd-fin-approver-rejected.heading {
  margin-left: 15px;
}

.col-sm-1.boiComm-rejected-new.col-boi-comment.rvd-fin-approver-rejected.heading,
.col-sm-1.boiComm-awapp.col-boi-comment.rvd-fin-approver-rejected.heading {
  margin-left: 5px;
}

.col-sm-1.boiComm-rejected.col-boi-comment.rvd-fin-approver-rejected.heading {
  margin-left: 28px;
}

.col-sm-1.wVis-awapp.dashboard-visibility-rvd.col-dashboard.rvd-fin-approver-rejected {
  margin-left: -15px;
}

.col-sm-1.boiComm-awapp.col-boi-comment.rvd-fin-approver-rejected {
  margin-left: -20px;
}

.col-sm-1.wVis-awapp.col-dashboard.rvd-fin-approver-rejected {
  margin-left: -15px
}

.col-sm-2.pde-comment-rejected-new,
.col-sm-2.app-rej-awapp.col-app-rej.rvd-fin-approver-rejected {
  margin-left: 25px;
}

.col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.open.body,
.col-sm-1.wVis-rejected.col-dashboard.rvd-fin-approver-rejected.rej.open.body {
  margin-left: -10px !important;
}

.col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.body {
  margin-left: 63px !important;
}

.col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected {
  margin-left: 22px !important;
}

.col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.open.body {
  margin-left: -13px !important;
}

.col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.body,
.col-sm-1.wVis-rejected.col-dashboard.rvd-fin-approver-rejected.rej.body {
  margin-left: 10px;
}

.col-sm-1.boiComm-rejected.col-boi-comment.rvd-fin-approver-rejected {
  margin-left: 20px;
}

@media (min-width: 1919px) and (max-width: 2561px) {

  /*67% browser*/
  .col-sm-1.boiComm-awapp.col-boi-comment.rvd-fin-approver-rejected {
    margin-left: -28px !important;
  }

  .col-sm-1.boiComm-awapp.col-boi-comment.rvd-fin-approver-rejected.heading {
    margin-left: 10px !important;
  }

  .col-sm-2.app-rej-awapp.col-app-rej.rvd-fin-approver-rejected.heading {
    margin-left: 3px;
  }

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.body {
    margin-left: 105px !important;
  }

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.open.body {
    margin-left: -8px !important;
  }

  .col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected {
    margin-left: 28px !important;
  }

  .col-sm-2.app-rej-awapp-new.col-app-rej.rvd-fin-approver-rejected.heading {
    margin-left: 22px !important;
  }

  .col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.body {
    margin-left: 20px;
  }

}

@media (min-width: 1706px) and (max-width: 1918px) {
  /* 75% Browser*/

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.open.body {
    margin-left: -20px !important;
  }

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.body {
    margin-left: 75px !important;
  }

  .col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.body {
    margin-left: 16px;
  }
}

@media (min-width: 1599px) and (max-width: 1705px) {

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.open.body {
    margin-left: -20px !important;
  }

  .col-sm-1.wVis-awapp-new.col-dashboard.rvd-fin-approver-rejected.body {
    margin-left: 75px !important;
  }

  .col-sm-1.wVis-rejected-new.col-dashboard.rvd-fin-approver-rejected.rej.body {
    margin-left: 14px;
  }
}


/*BUG 218879 & 218825 */

.fin-dimension.card-body {
  padding-top: 20px;
}

.param-fin-error.card-body {
  padding-top: 30px;
}

.invalid-cumulative-cont.error-adj {
  top: 110px;
}

.param-char-length-err.fin-usd {
  max-width: 110px !important;
}

.rvd-fin-creator-view>div.param-char-length-err.fin-usd {
  max-width: 170px !important;
}

/*END of FINANCIAL>USD Column CSS*/

/* r10 end */

/*R11 Start*/
.clientApprover-field {
  width: -webkit-fill-available;
  margin-bottom: 0px !important;
}

.client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
.client-approver-role div#groove-select-dropdown,
.client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
  height: 25px;
  width: 248px;
}

.client-approver-role>#groove-select-dropdown div.selectmenu-options .dropdown-list {
  width: 100%;
}

.client-approver-role>#groove-select-dropdown div.selectmenu-options .dropdown-list>div>div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.client-approver-role .empty-err,
.client-approver-role .empty-err#groove-select-dropdown div#selectmenu-anchor,
.client-approver-role .empty-err#groove-select-dropdown div#selectmenu-anchor:hover {
  border-color: red !important;
  background: rgb(255, 245, 247) !important;
}

.client-approver-role #groove-select-dropdown div#selectmenu-anchor .display-field.field-label,
.client-approver-role label {
  display: none;
}

.client-approver-role div#selectmenu-dropdown-icon {
  top: -15px;
  margin: 0px;
}

.client-approver-role #groove-select-dropdown svg {
  color: #A100FF;
}

.client-approver-role #groove-select-dropdown .selectmenu-selected-value,
.client-approver-role #groove-select-dropdown .default-display {
  margin-top: -2px;
}

.client-approver-role #groove-select-dropdown,
.client-approver-role #groove-input,
.client-approver-role input#client-approver-role-textbox,
.client-approver-role input#client-approver-role-textbox:focus,
.client-approver-role .form-control.groove-input.empty-err:focus {
  height: 25px !important;
}

.client-approver-role .groove-input-custom-label-disabled div.groove-input-custom-label,
.client-approver-role div.groove-input-custom-label {
  transform: translateY(2px);
}

.client-approver-role #groove-input .floating-label .groove-input:not(:placeholder-shown),
.client-approver-role #groove-input .floating-label .groove-input:focus {
  padding: 0 0 0 15px !important;
}

.program-details-column .error-message {
  position: absolute;
  top: 100px;
  font-size: 12px;
  font-family: Graphik-medium;
}

.client-approver-role #groove-select-dropdown div#selectmenu-anchor,
.client-approver-role #groove-select-dropdown div#selectmenu-anchor:hover {
  border: none !important;
}

.client-approver-role #groove-select-dropdown #selectmenu-dropdown-icon {
  margin-right: 0px !important;
}

/* .client-approver-role #groove-select-dropdown div#selectmenu-anchor-icon:disabled{
  color: #A100FF;
} */
.client-approver-role .empty-err#groove-select-dropdown div#selectmenu-anchor,
.client-approver-role .empty-err#groove-select-dropdown div#selectmenu-anchor:hover {
  border: 1px solid red !important;
}

.client-approver-role #groove-select-dropdown div.selectmenu-options.display-list .dropdown-item {
  padding: 8px 10px 8px 8px
}

.client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] svg,
.client-approver-role>#groove-input input#client-approver-role-textbox[disabled] {
  opacity: 0.5;
}

.cumulative-popup {
  z-index: 1;
}

/* Invalid dropdown styles */
#groove-select-dropdown.program-creator-invalid .form-control,
#groove-select-dropdown.program-creator-invalid .form-control#selectmenu-anchor:hover {
  border: 1px solid red !important;
}

/* .program-details-column.car > div {
  width: 100%;
} */

.program-details-column.car .title {
  max-width: max-content;
}

/* client approver role responsiveness */
/* 300% */
@media (min-width: 511px) and (max-width: 614px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 125px;
  }
}

/* 250% */
@media (min-width: 613px) and (max-width: 766px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 225px;
  }
}

/* 200% */
@media (min-width: 767px) and (max-width: 877px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 380px;
  }
}

/* 175% */
@media (min-width: 876px) and (max-width: 1024px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 485px;
  }
}

/* 150% check font*/
@media (min-width: 1023px) and (max-width: 1228px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 80px;
  }

  .client-approver-role #groove-select-dropdown .selectmenu-selected-value.selected {
    width: 20px;
  }
}

/* 125% */
@media (min-width: 1227px) and (max-width: 1396px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 145px;
  }
}

/* 110% */
@media (min-width: 1395px) and (max-width: 1535px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 205px;
  }
}

/* 90% */
@media (min-width: 1705px) and (max-width: 1919px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 305px;
  }
}

/* 80% */
@media (min-width: 1920px) and (max-width: 2047px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 370px;
  }
}

/* 75% */
@media (min-width: 2048px) and (max-width: 2303px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 415px;
  }
}

/* 67% */
@media (min-width: 2304px) and (max-width: 3071px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 500px;
  }
}

/* 50% */
@media (min-width: 3072px) and (max-width: 4607px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 760px;
  }
}

/* 33% */
@media (min-width: 4608px) and (max-width: 6143px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 1265px;
  }
}

/* 25% <= */
@media (min-width: 6144px) {

  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor,
  .client-approver-role div#groove-select-dropdown,
  .client-approver-role>#groove-select-dropdown div#selectmenu-anchor[disabled] {
    width: 1775px;
  }
}

/*R11 End*/