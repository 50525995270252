.cvm-text-input {
  outline: none;
}

.cvm-text-input::placeholder {
  font-size: 11px;
  text-align: center;
}


.disabled-id-val-field {
  cursor: not-allowed;
}

.cvm-radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cvm-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cvm-radio-button {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(196, 205, 213);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 3px;
  padding: 2px;
}

.cvm-radio.selected-radio>.cvm-radio-button {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(161, 0, 255);
}

.cvm-radio.selected-radio>.cvm-radio-button>div {
  background: rgb(161, 0, 255);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.cvm-radio-label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 20px 10px 20px 0;
  white-space: nowrap;
}

.selected-radio>.cvm-radio-label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
}

.cvm-input-textarea {
  resize: none;
  outline: none;
  height: 100px;
  border-radius: 4px;
  font-size: 12px;
}

.cvm-input-textarea-rejected-req {
  border: 1px solid rgb(170, 0, 37);
}

.cvm-input-textarea-con-app-req {
  border: 1px solid rgb(244, 147, 66) !important;
}

.cvm-input-textarea-rejected {
  background: rgb(255, 245, 247);
}

.cvm-input-textarea-con-app {
  background: rgb(253, 239, 228)
}


.cvm-text-input:focus, .cvm-input-textarea:focus {
  border: 1px solid rgb(161, 0, 255) !important;
  box-shadow: 0 0 4px rgb(161 0 255 / 79%);
}

.cvm-text-input.input-error-rejected {
  border: 1px solid rgb(170, 0, 37) !important;
  box-shadow: 0 0 4px rgb(170, 0, 37);
}

.cvm-text-input.input-error-con-app {
  border: 1px solid rgb(244, 147, 66) !important;
  box-shadow: 0 0 4px rgb(244, 147, 66);
}

.disabled-toggle {
  pointer-events: none;
}

.disabled-toggle-parent {
  cursor: not-allowed;
}

input:hover+.tooltiptext {
  display: table-caption;
}

span.targetvalue.tooltiptext {
  left: 400px;
  width: fit-content;
  margin-top: 5px;
}

.svg-inline--fa.reject-BOI-msg {
  color: white;
  background-color: rgb(170, 0, 37);
  transform: none;
  height: 15px;
  width: 15px;
  border-radius: 15px;
}

.reject-msg {
  color: rgb(170, 0, 37);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.25px;
  line-height: 16px;
  width: calc(100vw - 59vw);
}

.reject-msg .svg-container {
  height: auto;
}

div#reject-rdbtn-tooltip > div.tooltip-inner{
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 11px;
  letter-spacing: -0.25px;
  line-height: 16px;
  text-align: left;
  max-width: 314px;
}
.agnostic-boi-input-container{
  padding: 10px 4px !important;
}