.clientStage-page-container {
  padding: 30px 80px;
}
.stage-back {
  margin: 0 0 35px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.stage-back svg {
  color: rgb(161, 0, 255);
}

.client-stage-header {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.reporting-year-dropdown {
  /* display: flex;
  justify-content: flex-end; */
  margin-left: 790px;
}

.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  padding: 30px 50px 31px 50px;
  border-bottom: 1px solid rgb(234, 234, 234);
  background: rgb(255, 255, 255);
  border-radius: 16px 16px 0 0;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
}

.md-stepper-horizontal .md-step {
  display: table-cell;
  position: relative;
  padding: 0;
  text-align: center;
  width: 20%;
}
.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
}

.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 4px;
}
.md-stepper-horizontal .md-step .md-step-title-1 {
  margin-top: 0px;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  font-weight: 600;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Semibold-Web;
  text-align: center;
  letter-spacing: 0px;
  line-height: 14.3px;
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  /* top: 50px; */
  top: 38px;
  height: 2px;
  border-top: 1.5px solid rgb(227, 209, 255);
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 30px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 30px;
}
.stepper-section {
  border-radius: 16px;
  background: transparent;
  /* box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1),
    1px 0px 0px 0px rgb(244, 246, 248); */
  position: relative;
}

.progress-detail-section {
  display: flex;
}
.progress-bar-unit {
  width: 126px;
  height: 126px;
  margin-left: 28px;
}
.progressbar-innertext {
  text-align: center;
}
.progressbar-innertext h5 {
  color: rgb(33, 43, 54);
  font-size: 18px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 22px;
  margin-bottom: 8px;
}
.progressbar-innertext p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.21px;
  margin: 0 5px;
}
.text-label {
  width: 140px;
  display: inline-block;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: 'Graphik-Medium-Web';
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 20px;
}
.text-value {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: 'Graphik-Regular-Web';
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
}
.progress-label {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.21px;
  margin: 20px 0px 0px 0px;
  display: inline-block;
}
.project-list-details {
  padding-right: 20px;
  max-height: 430px;
  overflow-y: auto;
}
.project-list-details h4 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin: 0px 0px 0px 8px;
}
.project-detail-section {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  padding: 21px 27px;
  margin-top: 24px;
}
.project-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.project-detail-header h5 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0;
}
.project-detail-row {
  margin-bottom: 15px;
}
.project-detail-row .row-label {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 20px;
  margin-right: 10px;
}
.project-detail-row .row-value {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 20px;
}

.md-stepper-horizontal .md-step .md-step-img-circle {
  border-radius: 50%;
  /* background: none !important; */
  height: 50px;
  width: 50px;
  margin: 13px;
  background: '#eff2f5';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}

.md-stepper-horizontal .md-step.done .md-step-img-circle,
.md-stepper-horizontal .md-step.active .md-step-img-circle {
  cursor: pointer;
}

.md-step-img-circle.stepper-initial-pointer{
  cursor: default !important;
}

/* .md-step.active > .md-step-img-circle {
  background: linear-gradient(
    90deg,
    rgb(117, 0, 192) 0%,
    rgb(161, 0, 255) 100%
  ) !important;
} */

.md-stepper-horizontal > div > .md-step-img-circle {
  background: rgb(228 231 234);
  opacity: 0.5;
}
.md-stepper-horizontal > div > .md-step-title {
  opacity: 0.5;
}

.md-step.active > div.md-step-img-circle {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  box-shadow: 0px 1px 4px 0px rgb(98, 54, 255);
  opacity: 1;
}

.md-step.active > .md-step-title {
  opacity: 1;
}

/* .md-step.active ~ div > .md-step-img-circle {
  background: none !important;
  opacity: 1 !important;
} */

.md-stepper-horizontal .md-step.done .md-step-img-circle {
  background: rgb(161, 0, 255);
  opacity: 1;
}
.md-stepper-horizontal .md-step.done .md-step-title {
  color: rgb(117, 0, 192);
  font-weight: normal;
}
.md-stepper-horizontal .md-step.active .md-step-title {
  color: rgb(70, 0, 115);
}

/* .container-PIV {
  padding: 20px 15px 20px 20px;
} */
.container-PIV-box {
  padding-right: 25px;
}
.errorbox {
  background: rgb(255, 245, 247);
  border: 1px solid rgb(213, 0, 47);
  border-radius: 8px;
  padding: 11px 16px;
  padding-right: 9rem;
  color: rgb(197, 0, 6);
  font-size: 12px;
  font-family: Graphik-Medium-Web;
  font-weight: 500;
  letter-spacing: -0.19px;
}
.stepper-close {
  /* position: absolute;
  right: 25px;
  top: 18px; */
  color: #919eab;
  font-weight: 500;
  cursor: pointer;
  font-size: 24px;
}
.stepperClose-Alert-Content {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.stepperClose-Alert-Icon {
  font-size: 32px;
  margin-right: 50px;
  color: #ffc82c;
}
.stepperClose-Alert-text {
  font-family: Graphik-Regular-Web;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.stepperClose-Alert-text h5 {
  font-size: 16px;
  line-height: 16px;
  font-family: Graphik-Medium-Web;
}
.stepperClose-Alert-text p {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 5px;
}
.infoIcon {
  height: 13px;
  width: 13px;
  border: 1.2px solid #a100ff;
  border-radius: 50%;
  position: absolute;
  top: 0;
}
.stage-title {
  position: relative;
}
.close-btn {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #919eab;
  font-weight: 500;
  cursor: pointer;
  font-size: 18px;
}

#info-popover.popover {
  width: 22rem;
  max-width: none;
  border: none;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 10%);
  border-radius: 10px;
}
#info-popover .popover-header {
  font-size: 14px;
  font-family: 'Graphik-Medium-Web';
  background: white;
  border-bottom: none;
  border-radius: 10px 14px 0px 0px;
  max-width: 90%;
}
#info-popover .popover-body {
  padding: 0.25rem 1.25rem;
  padding-bottom: 0.75rem;
}
#info-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
#info-popover ul li {
  width: 100%;
  display: inline-flex;
  line-height: 14px;
  margin-bottom: 0.5rem;
}
#info-popover ul li .line-label {
  font-size: 14px;
  font-family: 'Graphik-Regular-Web';
  width: 40%;
  color: rgb(99, 115, 129);
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 14px;
}
#info-popover ul li .line-text {
  font-size: 14px;
  font-family: 'Graphik-Medium-Web';
  width: 60%;
  color: rgb(99, 115, 129);
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 14px;
}
/* Placeholder css */
 ::-webkit-scrollbar {
  height: 7px;
  width: 15px;
  border-radius: 5px;
  
}
::-webkit-scrollbar-thumb {
  background: #9a9da1 !important;
  min-height: 50px;
  height: 70%;
  width: 70%;
  
  
}
 
 ::-webkit-scrollbar-track {
  display: #f1f1f1;
} 
